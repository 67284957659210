import {Guild, GuildsApi} from "@devour/client";
import {ButtonHTMLAttributes, ReactElement, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import Toast from "@/components/Toast";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {TiStarFullOutline} from "react-icons/ti";
import FrameButton from "@/components/buttons/FrameButton";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import useThemePreference from "@/hooks/useThemePreference";
import {useQueryClient} from "@tanstack/react-query";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    guild: Guild;
}

export default function JoinGuildModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [showToast, setShowToast] = useState<boolean>(false);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {refetch: refetchGuildProgress} = useGetGuildProgress(props.guild.id, fullToken, false, false);
    const {data: guildOwnerships, refetch: refetchGuildOwnerships} = useGetGuildOwnerships(fullToken, true);
    const { isOnDarkMode } = useThemePreference();
    const userGuild = useMemo(() => guildOwnerships ? guildOwnerships.qualifiedGuilds.find(guild => guild.id === guildOwnerships.membership?.guild)?.name : null, [guildOwnerships]);

    async function handleUserJoiningGuild(): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new GuildsApi(getConfig()).joinGuild({
                id: props.guild.id,
            });
            setShowToast(true);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
            props.toggle();
        }

    }

    async function handleToastDismissal() {
        setShowToast(false);
        void queryClient.invalidateQueries({queryKey: ["current-guild-top-members"]});
        await Promise.all([
            refetchGuildProgress(),
            refetchGuildOwnerships(),
        ]);
    }

    return (
        <>
            <Toast
                variant="success"
                message={`Awesome! you're now a member of ${props.guild.name} Guild!`}
                isOpen={showToast}
                onDismiss={handleToastDismissal}
                duration={2000}
            />
            <FrameOneModal
                isOpen={props.isOpen}
                toggle={props.toggle}
                contentClassName="gofrens-rep-community-modal"
            >
                <FrameModalBody className="gofrens-rep-community-modal_body">
                    {isOnDarkMode
                        ? <img
                            src={`${import.meta.env.VITE_CDN_URL}/images/DM-gofrens-rep-community-star.svg`}
                            alt="star-icon"/>
                        : <TiStarFullOutline className="gofrens-rep-community-modal_body_star"/>
                    }

                    <p className="gofrens-rep-community-modal_body_title">
                        Join this Guild?
                    </p>
                    <p className="gofrens-rep-community-modal_body_message">
                        {guildOwnerships?.membership
                            ? `By joining ${props.guild.name}, you will leave ${userGuild}. You can only switch guilds within the first 7 days of a month.`
                            : `By joining ${props.guild.name}, you can only switch guilds within the first 7 days of a month.`
                        }
                    </p>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple-blue-gradient"
                        size="normal"
                        className="gofrens-rep-community-modal_body_button"
                        onClick={handleUserJoiningGuild}
                    >
                        Join Now!
                    </FrameButton>
                    <button
                        className="gofrens-rep-community-modal_body_cancel"
                        onClick={props.toggle}
                    >
                        Cancel
                    </button>
                </FrameModalBody>
            </FrameOneModal>
        </>
    );
}