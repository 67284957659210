/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChallengeOnboardingDetails,
    ChallengeOnboardingDetailsFromJSON,
    ChallengeOnboardingDetailsFromJSONTyped,
    ChallengeOnboardingDetailsToJSON,
} from './';

/**
 * Represents the onboarding details for a brand map challenge.
 * @export
 * @interface ChallengeOnboarding
 */
export interface ChallengeOnboarding {
    /**
     * 
     * @type {string}
     * @memberof ChallengeOnboarding
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeOnboarding
     */
    description: string;
    /**
     * 
     * @type {Array<ChallengeOnboardingDetails>}
     * @memberof ChallengeOnboarding
     */
    getStartedSteps?: Array<ChallengeOnboardingDetails>;
    /**
     * 
     * @type {ChallengeOnboardingDetails}
     * @memberof ChallengeOnboarding
     */
    instantReward?: ChallengeOnboardingDetails;
    /**
     * 
     * @type {ChallengeOnboardingDetails}
     * @memberof ChallengeOnboarding
     */
    prizePool?: ChallengeOnboardingDetails;
}

export function ChallengeOnboardingFromJSON(json: any): ChallengeOnboarding {
    return ChallengeOnboardingFromJSONTyped(json, false);
}

export function ChallengeOnboardingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeOnboarding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'getStartedSteps': !exists(json, 'getStartedSteps') ? undefined : ((json['getStartedSteps'] as Array<any>).map(ChallengeOnboardingDetailsFromJSON)),
        'instantReward': !exists(json, 'instantReward') ? undefined : ChallengeOnboardingDetailsFromJSON(json['instantReward']),
        'prizePool': !exists(json, 'prizePool') ? undefined : ChallengeOnboardingDetailsFromJSON(json['prizePool']),
    };
}

export function ChallengeOnboardingToJSON(value?: ChallengeOnboarding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'getStartedSteps': value.getStartedSteps === undefined ? undefined : ((value.getStartedSteps as Array<any>).map(ChallengeOnboardingDetailsToJSON)),
        'instantReward': ChallengeOnboardingDetailsToJSON(value.instantReward),
        'prizePool': ChallengeOnboardingDetailsToJSON(value.prizePool),
    };
}


