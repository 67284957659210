import {ReactElement} from "react";
import ThirdwebLogin from "@/pages/ThirdwebLogin";
import classNames from "classnames";

interface Props {
    isOnDialog?: boolean;
}

function LoginPage(props: Props): ReactElement {
    return (
        <div
            className={classNames("login-page", {
                "is-on-dialog": !!props.isOnDialog,
            })}
        >
            <ThirdwebLogin isOnDialog={props.isOnDialog}/>

            <div className="login-page_spacer-bottom"/>
        </div>
    );
}

export default LoginPage;
