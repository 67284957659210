import React, {ReactElement} from "react";
import {NftOwnershipInformation, HandoffOptions, UsersApi, Restaurant} from "@devour/client";
import classNames from "classnames";
import {Link} from "react-router";
import RestaurantHoursSummary from "./RestaurantHoursSummary";
import {BsDot} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import RestaurantCardGoFrens from "./RestaurantCardGoFrens";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {useGetFavouriteRestaurants} from "@/hooks/useGetFavouriteRestaurants";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";

const kmInMile = 1.609;

interface Props {
    nftOwnershipResponse?: NftOwnershipInformation;
    restaurant: Restaurant;
}

function RestaurantCardFavorites(props: Props): ReactElement {
    const dispatch = useDispatch();
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {refetch: refetchFavouriteRestaurants} = useGetFavouriteRestaurants(fullToken);
    const {data: currentUser, refetch: refetchUserProfile} = useGetUserProfile(fullToken);
    const defaultAddress = currentUser?.user.addresses?.find(address => address.isDefault);
    const placeId = lastSearchedPlaceId || defaultAddress?.placeId || "";
    /**
	 * Favorite / unfavorite this restaurant.
	 */
    async function onFavorite(): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new UsersApi(getConfig(fullToken)).favoriteBusiness({
                businessId: props.restaurant.id,
            });
            refetchUserProfile();
            refetchFavouriteRestaurants();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    if (!props.restaurant) {
        return null;
    }

    return (
        <div
            className={classNames("restaurant-card", {
                "is-open": props.restaurant.isOpen,
            })}
        >
            <div className="restaurant-card_favorite">
                {currentUser?.user && !currentUser.user.favorites?.includes(props.restaurant.id) &&
                <button
					    className="reset-button restaurant-card_favorite_add"
					    title="Add to saved list"
					    onClick={onFavorite}
                >
					    <AiOutlineHeart/>
                </button>
                }
                {currentUser?.user && currentUser.user.favorites?.includes(props.restaurant.id) &&
                <button
					    className="reset-button restaurant-card_favorite_remove"
					    title="Remove from saved list"
					    onClick={onFavorite}
                >
					    <AiFillHeart/>
                </button>
                }
            </div>
            <Link to={`${props.restaurant.url}/${placeId}`}>
                <RestaurantCardGoFrens
                    restaurant={props.restaurant}
                    nftOwnershipResponse={props.nftOwnershipResponse}
                    showPromos={"on"}
                />
                <div className="restaurant-card_image">
                    <img
                        src={props.restaurant.headerImage?.url || process.env.PUBLIC_URL + "/images/placeholderitem.png"}
                        alt={props.restaurant?.name}
                    />
                    {!props.restaurant.isOpen &&
                    <div className="restaurant-card_image_closed">
						    <div className="restaurant-card_image_closed_text">
								Now Closed
						    </div>
                    </div>
                    }
                </div>
                <div className="restaurant-card_row-between">
                    <div
                        className="restaurant-card_name"
                        title={props.restaurant?.name}
                    >
                        {props.restaurant?.name}
                    </div>
                    {/* <div className="restaurant-card_rating">*/}
                    {/*	<AiFillStar/>*/}
                    {/*	4.9*/}
                    {/* </div>*/}
                </div>
                {props.restaurant.isOpen
                    ? <div className="restaurant-card_row">
                        {props.restaurant?.distance !== undefined &&
                        <React.Fragment>
							    <div className="restaurant-card_delivery-distance">
							        {(props.restaurant.distance / kmInMile).toFixed(1)} mi
							    </div>
							    <BsDot/>
                        </React.Fragment>
                        }
                        <div className="restaurant-card_delivery-time">
                            {handoff === HandoffOptions.DELIVERY
                                ? `${props.restaurant.prepTime + Math.ceil((props.restaurant?.distance || 0) / kmInMile) * 3} min`
                                : `${props.restaurant.prepTime} min`}
                        </div>
                        {handoff === HandoffOptions.DELIVERY && props.restaurant.isDeliveryAvailable &&
                        <React.Fragment>
							    <BsDot/>
							    <div className="restaurant-card_delivery-fee">
									${props.restaurant.deliveryCharge.toFixed(2)} delivery fee
							    </div>
                        </React.Fragment>
                        }
                    </div>
				 : <div className="restaurant-card_hours">
                        <RestaurantHoursSummary restaurant={props.restaurant} hideMoreInfoIfClosed={true}/>
                    </div>
                }
            </Link>
        </div>

    );
}

export default RestaurantCardFavorites;
