import {Challenge} from "@devour/client";
import {RiLockFill} from "react-icons/ri";
import {FaChevronDown} from "react-icons/fa";
import AnimateHeight from "react-animate-height";
import {useState} from "react";
import classNames from "classnames";

interface Props {
    challenge: Challenge;
}

export default function BrandChallengeFAQs({challenge}: Props) {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    if (!challenge) {
        return null;
    }

    function toggleFAQ(index: number) {
        setActiveIndex(activeIndex === index ? null : index);
    }
    return (
        <div className="brand-challenge_faqs brand-challenge_game">
            <div className="brand-challenge_faqs_header">
                <h3>GOT QUESTIONS</h3>
                <div>Frequently Asked Questions (FAQs)</div>
            </div>
            <div className="brand-challenge_faqs_body">
                {challenge.faqs.map((faq, index) => (
                    <div key={index} className="brand-challenge_faqs_body_faq">
                        <div className="brand-challenge_faqs_body_question" onClick={() => toggleFAQ(index)}>
                            <h5>{faq.question}</h5>
                            <FaChevronDown size="1.25rem" className={classNames({"flipped": activeIndex === index})}/>
                        </div>
                        <AnimateHeight
                            duration={300}
                            height={activeIndex === index ? "auto" : 0}
                        >
                            <hr />
                            <p className="brand-challenge_faqs_body_answer">{faq.answer}</p>
                        </AnimateHeight>
                    </div>
                ))}
            </div>

            <div className="brand-challenge_faqs_footer">
                <div className="brand-challenge_faqs_footer_top">
                    <RiLockFill />
                    DevourPlay is safe and secured.
                </div>
                <div className="brand-challenge_faqs_footer_text">
                    We have passed thorough security and authenticity tests from Overwolf to be displayed on the app
                    store. Check out <a
                        href="https://legal.overwolf.com/docs/overwolf/platform/platform-terms-of-use/"
                        target="_blank" rel="noreferrer"
                    >
                        Overwolf’s Legal Policy
                    </a> for more info.
                </div>
            </div>
        </div>
    );
}

