import { useMemo, useRef, useState, useEffect, useCallback, useLayoutEffect } from "react";
import { FixedSizeList as List } from "react-window";
import {MenuCategory,
    MenuCategorySearchEssential} from "@devour/client";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import classNames from "classnames";
import useWindowSize, {getScreenWidthStr, WindowBreakpointSizes} from "@/hooks/useWindowSize";

interface CategoryCarouselProps {
    viewAllHref?: string;
    // categoryName: string;
    categoryData: MenuCategory | MenuCategorySearchEssential
    items: Array<JSX.Element>
}

export function CategoryCarousel(props: CategoryCarouselProps) {
    const {viewAllHref, categoryData, items} = props;
    const listRef = useRef(null);
    // outerRef is where we'll do the smooth scrolling
    const outerRef = useRef(null);

    const containerRef = useRef(null);

    const [scrollData, setScrollData] = useState<{
        offsetWidth: number;
        scrollLeft: number;
        scrollWidth: number;
        scrollbarHeight: number;
    }>({
        offsetWidth: 0,
        scrollLeft: 0,
        scrollWidth: 0,
        scrollbarHeight: 0,
    });

    const [itemHeight, setItemHeight] = useState(150);
    const [listWidth, setListWidth] = useState(0);

    const windowSize = useWindowSize();
    const windowWidthGrouping = getScreenWidthStr(windowSize[0]);

    const useMobileLayout = windowSize[0] < 834;


    const largestItemRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setListWidth(containerRef.current.getBoundingClientRect().width);
            if (!outerRef.current) return;
            setScrollData({
                offsetWidth: outerRef.current.offsetWidth,
                scrollLeft: outerRef.current.scrollLeft,
                scrollWidth: outerRef.current.scrollWidth,
                scrollbarHeight: outerRef.current.offsetHeight - outerRef.current.clientHeight,
            });
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (!largestItemRef.current) return;
        setItemHeight(largestItemRef.current.getBoundingClientRect().height);
        const resizeObserver = new ResizeObserver(() => {
            setItemHeight(largestItemRef.current.getBoundingClientRect().height);
        });
        resizeObserver.observe(largestItemRef.current);
        return () => resizeObserver.disconnect();
    }, [largestItemRef?.current]);

    const itemWidth = useMemo(() => {
        const shouldUseSmallerPageSize = [WindowBreakpointSizes.MEDIUM, WindowBreakpointSizes.SMALL, WindowBreakpointSizes.XSMALL].includes(windowWidthGrouping);
        if (shouldUseSmallerPageSize) {
            const itemsPerPage = 2.5;
            const itemWidth = listWidth / itemsPerPage;
            return itemWidth;
        // return itemSize
        }
        const itemsPerPage = 5;
        const itemWidth = listWidth / itemsPerPage;
        return itemWidth;
    }, [windowWidthGrouping, listWidth]);


    const itemCount = items.length;


    useLayoutEffect(() => {
        if (!outerRef.current) return;
        /*
         * outerRef.current.addEvent
         * add event listener to record scrollLeft of outerref
         */
        setScrollData({
            offsetWidth: outerRef.current.offsetWidth,
            scrollLeft: outerRef.current.scrollLeft,
            scrollWidth: outerRef.current.scrollWidth,
            scrollbarHeight: outerRef.current.offsetHeight - outerRef.current.clientHeight,
        });
        outerRef.current?.addEventListener("scroll", (e) => {
            setScrollData({
                offsetWidth: outerRef.current.offsetWidth,
                scrollLeft: outerRef.current.scrollLeft,
                scrollWidth: outerRef.current.scrollWidth,
                scrollbarHeight: outerRef.current.offsetHeight - outerRef.current.clientHeight,
            });
        });
        return () => {
            outerRef.current?.removeEventListener("scroll", (e) => {
                setScrollData({
                    offsetWidth: outerRef.current.offsetWidth,
                    scrollLeft: outerRef.current.scrollLeft,
                    scrollWidth: outerRef.current.scrollWidth,
                    scrollbarHeight: outerRef.current.offsetHeight - outerRef.current.clientHeight,
                });
            });
        };
    }, [outerRef?.current]);

    const prevDisabled = useMemo(() => {
        return scrollData.scrollLeft === 0;
    }, [scrollData]);

    const nextDisabled = useMemo(() => {
        return Math.round(scrollData.offsetWidth + scrollData.scrollLeft) >= scrollData.scrollWidth;
    }, [scrollData]);

    const renderColumn = useCallback(({index, style}) => {
        return <div style={{
            ...style,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: index === 0 ? "auto" : `${itemHeight}px`,
        }} className="category-carousel_item" ref={index === 0 ? largestItemRef : null}>
            {items[index]}
        </div>;
    }, [items, largestItemRef, itemHeight]);

    /**
     * When an item is partially visible on the right hand side, that item will become fully in view on the left.
     * If the item on the right hand side is fully in view, a full page is jumped
     */
    const handleNextPage = () => {
        if (!outerRef.current) return;

        const itemsPerPage = Math.round(listWidth / itemWidth * 100) / 100;

        const partialViewedIndex = Math.round(outerRef.current.scrollLeft / itemWidth * 100) / 100 + itemsPerPage;

        const indexToScrollTo = partialViewedIndex % 1 === 0 ? partialViewedIndex : Math.floor(partialViewedIndex);
        const newOffset = indexToScrollTo * itemWidth;

        outerRef.current.scrollTo({
            left: newOffset,
            behavior: "smooth",
        });
    };

    /**
     * When an item is partially visible on the left hand side, that item will become fully in view on the right.
     * If the item on the left hand side is fully in view, a full page is jumped
     */
    const handlePrevPage = () => {
        if (!outerRef.current) return;

        const itemsPerPage = Math.round(listWidth / itemWidth * 100) / 100;

        const partialViewedIndex = Math.round(outerRef.current.scrollLeft / itemWidth * 100) / 100;

        const indexToScrollTo = partialViewedIndex % 1 === 0 ? partialViewedIndex - itemsPerPage : Math.floor(partialViewedIndex) - itemsPerPage + 1;
        const newOffset = indexToScrollTo * itemWidth;

        outerRef.current.scrollTo({
            left: newOffset,
            behavior: "smooth",
        });
    };

    return (
        <div style={{width: "100%", overflowY: "hidden", overflowX: "hidden"}} ref={containerRef}>
            <div className="category-carousel_header">
                <div className="category-carousel_header-left">
                    <div className="category-carousel_category-name">{categoryData.name}</div>
                    {!useMobileLayout &&
              <a href={viewAllHref} className="category-carousel_view-all-href">
                  View All <FaChevronRight size={14}/>
              </a>
                    }
                </div>
                {!useMobileLayout ? <div className="category-carousel_scroll-controls">
                    <button className={classNames("category-carousel_scroll-button", {
                        "category-carousel_scroll-button_disabled": prevDisabled,
                    })} onClick={handlePrevPage}><FaChevronLeft className={classNames("category-carousel_scroll-button_icon", {
                            "category-carousel_scroll-button_icon_disabled": prevDisabled,
                        })} size={14}/></button>
                    <button className={classNames("category-carousel_scroll-button", {
                        "category-carousel_scroll-button_disabled": nextDisabled,
                    })} onClick={handleNextPage}><FaChevronRight className={classNames("category-carousel_scroll-button_icon", {
                            "category-carousel_scroll-button_icon_disabled": nextDisabled,
                        })} size={14}/></button>
                </div> : <a href={viewAllHref} className="category-carousel_view-all-href">
                    View All <FaChevronRight size={14}/>
                </a>}
            </div>
            <List
                className="category-carousel_list"
                ref={listRef}
                outerRef={outerRef} // <-- Attach our custom ref to the outer container
                height={itemHeight + 15}
                itemCount={itemCount}
                itemSize={itemWidth}
                layout="horizontal"
                width={listWidth}
            >
                {renderColumn}
            </List>
        </div>
    );
}