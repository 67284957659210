/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOverwolfEventDumpsResponseAllOf,
    GetOverwolfEventDumpsResponseAllOfFromJSON,
    GetOverwolfEventDumpsResponseAllOfFromJSONTyped,
    GetOverwolfEventDumpsResponseAllOfToJSON,
    OWEventDump,
    OWEventDumpFromJSON,
    OWEventDumpFromJSONTyped,
    OWEventDumpToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOverwolfEventDumpsResponse
 */
export interface GetOverwolfEventDumpsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<OWEventDump>}
     * @memberof GetOverwolfEventDumpsResponse
     */
    eventDumps?: Array<OWEventDump>;
}

export function GetOverwolfEventDumpsResponseFromJSON(json: any): GetOverwolfEventDumpsResponse {
    return GetOverwolfEventDumpsResponseFromJSONTyped(json, false);
}

export function GetOverwolfEventDumpsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOverwolfEventDumpsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'eventDumps': !exists(json, 'eventDumps') ? undefined : ((json['eventDumps'] as Array<any>).map(OWEventDumpFromJSON)),
    };
}

export function GetOverwolfEventDumpsResponseToJSON(value?: GetOverwolfEventDumpsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'eventDumps': value.eventDumps === undefined ? undefined : ((value.eventDumps as Array<any>).map(OWEventDumpToJSON)),
    };
}


