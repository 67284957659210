import {WalletId} from "thirdweb/wallets";

export interface ThirdWebWalletListItem {
    id: WalletId;
    name: string;
    hasMobileSupport: boolean;
    imageFile?: string;
}

export const thirdwebWalletList: Array<ThirdWebWalletListItem> = [
    {
        "id": "io.metamask",
        "name": "MetaMask",
        "hasMobileSupport": true,
        "imageFile": "https://production.assets.cdn.devourgo.io/strange_tan_orangutan.webp",
    },
    {
        "id": "com.coinbase.wallet",
        "name": "Coinbase Wallet",
        "hasMobileSupport": false,
        "imageFile": "https://production.assets.cdn.devourgo.io/regulatory_red_salmon.webp",
    },
    {
        "id": "app.phantom",
        "name": "Phantom",
        "hasMobileSupport": true,
        "imageFile": "https://production.assets.cdn.devourgo.io/drunk_gold_dragonfly.webp",
    },
    {
        "id": "com.trustwallet.app",
        "name": "Trust Wallet",
        "hasMobileSupport": true,
        "imageFile": "https://production.assets.cdn.devourgo.io/systematic_red_bobcat.webp",
    },
    {
        "id": "walletConnect",
        "name": "WalletConnect",
        "hasMobileSupport": false,
        "imageFile": "https://production.assets.cdn.devourgo.io/welcome_white_starfish.webp",
    },
    {
        "id": "com.okex.wallet",
        "name": "OKX Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bitget.web3",
        "name": "Bitget Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.binance",
        "name": "Binance Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.uniswap",
        "name": "Uniswap Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.safepal",
        "name": "SafePal",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "me.rainbow",
        "name": "Rainbow",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bybit",
        "name": "Bybit Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "pro.tokenpocket",
        "name": "TokenPocket",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ledger",
        "name": "Ledger Live",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.timelesswallet",
        "name": "Timeless X",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "global.safe",
        "name": "Safe",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.zerion.wallet",
        "name": "Zerion",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.robinhood.wallet",
        "name": "Robinhood Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.1inch.wallet",
        "name": "1inch Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.crypto.wallet",
        "name": "Crypto.com Onchain",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.exodus",
        "name": "Exodus",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.argent",
        "name": "Argent",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "im.token",
        "name": "imToken",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.blockchain",
        "name": "Blockchain.com",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.magiceden.wallet",
        "name": "Magic Eden",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.kraken",
        "name": "Kraken Wallet ",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.backpack",
        "name": "Backpack",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.zengo",
        "name": "Zengo Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.mewwallet",
        "name": "MEW wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.fireblocks",
        "name": "Fireblocks",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.mathwallet",
        "name": "MathWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.alphawallet",
        "name": "AlphaWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.roninchain.wallet",
        "name": "Ronin Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.unstoppabledomains",
        "name": "Unstoppable Domains",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.keyring",
        "name": "KEYRING PRO",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.frontier.wallet",
        "name": "Frontier",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.omni",
        "name": "Omni",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "technology.obvious",
        "name": "Obvious",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ambire",
        "name": "Ambire Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.mtpelerin",
        "name": "Bridge Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.internetmoney",
        "name": "Internet Money Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.walletnow",
        "name": "NOW Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bitcoin",
        "name": "Bitcoin.com Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.alpha-u.wallet.web",
        "name": "αU wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.coin98",
        "name": "Coin98 Super Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.myabcwallet",
        "name": "ABC Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.arculus",
        "name": "Arculus Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.opera",
        "name": "Opera Crypto Browser",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.chain",
        "name": "Chain",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.huddln",
        "name": "Huddln",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.get-verso",
        "name": "Verso",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "me.haha",
        "name": "HaHa",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "pk.modular",
        "name": "Modular Wallet Prod",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.kelp",
        "name": "Kelp",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.clingon",
        "name": "Cling Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.broearn",
        "name": "Broearn Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.coinomi",
        "name": "Coinomi",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ripio",
        "name": "Ripio Portal",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.sabay.wallet",
        "name": "Sabay Wallet App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.tokoin",
        "name": "Tokoin | My-T Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "world.fncy",
        "name": "Fncy Mobile Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.copiosa",
        "name": "Copiosa",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.liberawallet",
        "name": "Libera",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.certhis",
        "name": "Certhis",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.burritowallet",
        "name": "Burrito",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.ancrypto",
        "name": "Ancrypto",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.cvl",
        "name": "CVL Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.cypherhq",
        "name": "Cypher Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.status",
        "name": "Status",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.enjin",
        "name": "Enjin Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.trinity-tech",
        "name": "Essentials",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.everspace",
        "name": "Everspace",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.kriptomat",
        "name": "Kriptomat",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.oxalus",
        "name": "Oxalus Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.thetatoken",
        "name": "Theta Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.leapwallet",
        "name": "Leap Cosmos Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.islamicoin",
        "name": "ISLAMIwallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.coca",
        "name": "COCA Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.monarchwallet",
        "name": "Monarch Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.filwallet",
        "name": "FILWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.valoraapp",
        "name": "Valora",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.coincircle",
        "name": "CoinCircle",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "money.snowball",
        "name": "Snowball",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.paraswap",
        "name": "ParaSwap Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.mrhb",
        "name": "Sahal Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.apollox",
        "name": "ApolloX",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ennowallet",
        "name": "Enno Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.loopring.wallet",
        "name": "Loopring",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bee",
        "name": "BeeWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "cc.localtrade.lab",
        "name": "LocalTrade Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.xcapit",
        "name": "Xcapit",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.safematrix",
        "name": "Safematrix",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.neonwallet",
        "name": "Neon Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.sequence",
        "name": "Sequence Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.linen",
        "name": "Linen",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.nabox",
        "name": "Nabox",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "net.spatium",
        "name": "Spatium",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.cryptnox",
        "name": "Cryptnox Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.rktechworks",
        "name": "ID Pocket",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "pro.assure",
        "name": "Assure",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "trade.flooz.wallet",
        "name": "Flooz",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.keplr",
        "name": "Keplr",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.crossmint",
        "name": "Crossmint",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.pierwallet",
        "name": "pier",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.core",
        "name": "Core",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.keeper-wallet",
        "name": "Keeper",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.dcentwallet",
        "name": "D'CENT Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.withpaper",
        "name": "Paper",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.klever",
        "name": "Klever Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.edge",
        "name": "Edge Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.neftipedia",
        "name": "NeftiWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.goldbit",
        "name": "GoldBit",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.coingrig",
        "name": "Coingrig",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.xfun",
        "name": "XFUN Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.ricewallet",
        "name": "RiceWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.antiersolutions",
        "name": "Ancrypto Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.okse",
        "name": "Okse Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.aktionariat",
        "name": "Aktionariat",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.itoken",
        "name": "iToken Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.zelus",
        "name": "Zelus",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.cardstack",
        "name": "Card Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.paybolt",
        "name": "PayBolt",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.arianee",
        "name": "Arianee Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.slavi",
        "name": "Slavi Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.plasma-wallet",
        "name": "Plasma Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "tech.defiantapp",
        "name": "Defiant",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "cc.avacus",
        "name": "Avacus",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.bytebank",
        "name": "ByteBank",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.coolbitx.cwsapp",
        "name": "CoolWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.optowallet",
        "name": "Opto Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.trustkeys",
        "name": "TK Finance",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.beewallet",
        "name": "Bee Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.ttmwallet",
        "name": "MDAO Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.pltwallet",
        "name": "PLTwallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.helixid",
        "name": "helix id",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "it.airgap",
        "name": "AirGap Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.qubic.wallet",
        "name": "Qubic Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.holdstation",
        "name": "Holdstation Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.saakuru.app",
        "name": "Saakuru All-in-One crypto App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.3swallet",
        "name": "3S Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.payperless",
        "name": "Payperless",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "digital.minerva",
        "name": "Minerva Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.voltage",
        "name": "Volt: DeFi",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.lif3",
        "name": "Lif3 Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "net.shinobi-wallet",
        "name": "Shinobi-Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.kryptogo",
        "name": "KryptoGO Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.feralfile.app",
        "name": "Feral File",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bifrostwallet",
        "name": "Bifrost Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.nufinetes",
        "name": "Nufinetes",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.wallet3",
        "name": "Wallet 3",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.abra",
        "name": "Abra Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.imem",
        "name": "iMe",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.premanft",
        "name": "PREMA Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "so.onekey.app.wallet",
        "name": "OneKey",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.slingshot",
        "name": "Slingshot Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.kriptonio",
        "name": "Kriptonio",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.ctrl",
        "name": "Ctrl Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.streakk",
        "name": "Streakk Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.saitamatoken",
        "name": "SaitaPro",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.flowfoundation.wallet",
        "name": "Flow Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.hippowallet",
        "name": "Hippo Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.cosmostation",
        "name": "Cosmostation",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.bitizen",
        "name": "Bitizen",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.blocto",
        "name": "Blocto",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.humbl",
        "name": "HUMBL WALLET",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.passpay",
        "name": "PassPay Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.ultimate",
        "name": "Ultimate",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "me.astrox",
        "name": "Me Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.thorwallet",
        "name": "THORWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.fizzwallet",
        "name": "Fizz",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.stickey",
        "name": "Stickey Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.klipwallet",
        "name": "Klip",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.coinstats",
        "name": "CoinStats",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "land.liker",
        "name": "LikerLand App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.krystal",
        "name": "Krystal",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "fi.pillar",
        "name": "Pillar",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.harti",
        "name": "HARTi Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "net.stasis",
        "name": "Stasis Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.novawallet",
        "name": "Nova Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.dttd",
        "name": "DTTD",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.foxwallet",
        "name": "FoxWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.haqq",
        "name": "HAQQ Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.tomi",
        "name": "tomi Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.tradestrike",
        "name": "StrikeX Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.subwallet",
        "name": "SubWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "tech.okto",
        "name": "Okto",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.catecoin",
        "name": "Catecoin Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.ukiss",
        "name": "UKISS Hub",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.tellaw",
        "name": "Tellaw Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.tangem",
        "name": "Tangem Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "is.callback",
        "name": "Callback",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.summonersarena",
        "name": "SA ASSISTANT",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.xellar",
        "name": "Xellar",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.talken",
        "name": "Talken Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.uniultra.wallet",
        "name": "U2U Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.ozonewallet",
        "name": "OzoneWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.tiduswallet",
        "name": "Tidus Wallet ",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "world.ixo",
        "name": "Impact Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.zelcore",
        "name": "Zelcore",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "world.dosi.vault",
        "name": "DOSI Vault",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ullapay",
        "name": "WOW EARN",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ellipal",
        "name": "ELLIPAL",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "money.unstoppable",
        "name": "Unstoppable Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "dev.auroracloud",
        "name": "Aurora Pass",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "zone.bitverse",
        "name": "Bitverse",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.konio",
        "name": "Konio",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "net.gateweb3",
        "name": "GateWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.utorg",
        "name": "UTORG",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.coinsdo",
        "name": "CoinWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.ammer",
        "name": "Ammer Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "us.binance",
        "name": "Binance.US",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.muza",
        "name": "MUZA",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.fxwallet",
        "name": "FxWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.ryipay",
        "name": "RYIPAY",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.dota168",
        "name": "MetaWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.altme",
        "name": "Altme",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bitpie",
        "name": "Bitpie",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.moonstake",
        "name": "MOONSTAKE",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "gg.indi",
        "name": "IndiGG",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.yusetoken",
        "name": "Yuse Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.coininn",
        "name": "Coininn Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.functionx",
        "name": "f(x)Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.pockie",
        "name": "pockie",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.amazewallet",
        "name": "AmazeWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.paliwallet",
        "name": "Pali Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "me.easy",
        "name": "EASY",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "live.superex",
        "name": "SuperWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.secuxtech",
        "name": "SecuX",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.didwallet",
        "name": "DIDWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "social.halo",
        "name": "Halo Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.sinohope",
        "name": "Sinohope",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ballet",
        "name": "Ballet Crypto",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.opz",
        "name": "OPZ Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.fizen",
        "name": "Fizen Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.kresus",
        "name": "Kresus SuperApp",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.midoin",
        "name": "midoin",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.onto",
        "name": "ONTO",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.oasys-wallet",
        "name": "Oasys Passport",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.gooddollar",
        "name": "GoodDollar",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "id.competence",
        "name": "Competence.id",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "ai.spotonchain.platform",
        "name": "Spot On Chain App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.dgg",
        "name": "DGG Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "llc.besc",
        "name": "BeanBag",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.gamic",
        "name": "Gamic",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "baby.smart",
        "name": "Smart.Baby",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.gridlock",
        "name": "Gridlock Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.zeal",
        "name": "Zeal",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ivirse",
        "name": "IApp",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "ch.dssecurity",
        "name": "DS Security SA",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.concordium",
        "name": "Concordium",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.zkape",
        "name": "Ape Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.thirdweb",
        "name": "thirdweb",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.pitaka",
        "name": "Pitaka",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.trusteeglobal",
        "name": "Trustee Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.mugambo",
        "name": "rss wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "cc.dropp",
        "name": "Dropp",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.roam.wallet",
        "name": "Roam",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "world.qoin",
        "name": "Qoin Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.meld.app",
        "name": "MELDapp",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bestwallet",
        "name": "Best Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.hyperpay",
        "name": "HyperPay",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.xucre",
        "name": "Xucre",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.herewallet",
        "name": "HERE Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.cakewallet",
        "name": "Cake Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.unagi.unawallet",
        "name": "una Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.ethos",
        "name": "Ethos Self-Custody Vault",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.pluswallet",
        "name": "Plus Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.authentrend",
        "name": "AT.Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.plena",
        "name": "Plena-App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.wemix",
        "name": "WemixWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.gemwallet",
        "name": "Gem Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.caesiumlab",
        "name": "Caesium",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "pro.fintoken",
        "name": "FINTOKEN",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.peakdefi",
        "name": "PEAKDEFI",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.nodle",
        "name": "Nodle",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.cryptokara",
        "name": "Cryptokara",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.poolsmobility.wallet",
        "name": "poolswallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.veworld",
        "name": "VeWorld Mobile",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.azcoiner",
        "name": "AZCoiner",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "technology.jambo",
        "name": "Jambo",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "vc.uincubator.api",
        "name": "UIIC",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.m1nty",
        "name": "M1NTY",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.noone",
        "name": "Noone Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bitso",
        "name": "Bitso Web3 Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "online.puzzle",
        "name": "Puzzle Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.blackfort",
        "name": "BlackFort Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.armana.portal",
        "name": "Armana Portal",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.bharatbox",
        "name": "BharatBox App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.greengloryglobal",
        "name": "Blockaura",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.x9wallet",
        "name": "X9Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.miraiapp",
        "name": "Mirai App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.kigo",
        "name": "Kigo",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.getcogni",
        "name": "Cogni ",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.fastex.wallet",
        "name": "Fastex Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.wallacy",
        "name": "Wallacy",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.talkapp",
        "name": "T+ Wallet ",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.usecapsule",
        "name": "Capsule",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.unitywallet",
        "name": "Unity Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.sinum",
        "name": "Sinum",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.soulswap.app",
        "name": "SoulSwap",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.shapeshift",
        "name": "ShapeShift",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.panaroma",
        "name": "Panaroma Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.neopin",
        "name": "NEOPIN",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.alicebob",
        "name": "Alicebob Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.cyber.wallet",
        "name": "CyberWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.dextrade",
        "name": "DexTrade",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.hashpack.wallet",
        "name": "HashPack",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.thepulsewallet",
        "name": "The Pulse Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "id.co.pintu",
        "name": "Pintu",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.bladewallet",
        "name": "Blade Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.pandoshi",
        "name": "Pandoshi Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "money.keychain",
        "name": "Keychain",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.mpcvault.broswerplugin",
        "name": "MPCVault | Team crypto wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.legacynetwork",
        "name": "Legacy Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.getclave",
        "name": "Clave",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "me.iopay",
        "name": "ioPay",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.kabila",
        "name": "Kabila Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "one.mixin.messenger",
        "name": "Mixin Messenger",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bettatrade",
        "name": "Bettatrade",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.scramberry",
        "name": "ScramberryWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.earthwallet",
        "name": "Earth Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.nestwallet",
        "name": "Nest Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.echooo",
        "name": "Echooo Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "net.myrenegade",
        "name": "Renegade",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.ready",
        "name": "Ready",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "ai.hacken",
        "name": "hAI by Hacken",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.plutope",
        "name": "Plutope",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.trustasset",
        "name": "Trust Asset Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.dfinnwallet",
        "name": "Dfinn Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bmawallet",
        "name": "BMA Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "net.spatium.wallet",
        "name": "Spatium",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.transi",
        "name": "Transi",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.dolletwallet",
        "name": "Dollet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.wombat",
        "name": "Wombat",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "fi.dropmate",
        "name": "Dropmate",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "pub.dg",
        "name": "DGPub App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.icewal",
        "name": "icewal",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "one.metapro.wallet",
        "name": "metapro wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.bonuz",
        "name": "Bonuz Social Smart Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.shido",
        "name": "Shido App",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.lifedefi",
        "name": "Life DeFi",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "fun.tobi",
        "name": "Tobi",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "inc.tomo",
        "name": "Tomo Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.clot",
        "name": "Clot",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "me.komet.app",
        "name": "Komet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.guardiianwallet",
        "name": "GUARDIIAN Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.wallypto",
        "name": "Wallypto",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.safemoon",
        "name": "SafeMoon",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.elrond.maiar.wallet",
        "name": "xPortal",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.swopme",
        "name": "SWOP",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bitpay",
        "name": "BitPay Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.tofee",
        "name": "Tofee Wallet Official",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.zypto",
        "name": "Zypto",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "xyz.orion",
        "name": "Orion",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.uptn.dapp-web",
        "name": "UPTN",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.compasswallet",
        "name": "Compass Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "app.nicegram",
        "name": "Nicegram Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.openwallet",
        "name": "Open Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.tastycrypto",
        "name": "tastycrypto",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.ipmb",
        "name": "IPMB Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.daffione",
        "name": "DaffiOne",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.owallet",
        "name": "OWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.beexo",
        "name": "Beexo",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.webauth",
        "name": "WebAuth",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "id.plumaa",
        "name": "Plumaa ID",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "social.gm2",
        "name": "GM² Social",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "nl.greenhood.wallet",
        "name": "Greenhood",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.companyname.swaptobe",
        "name": "TobeWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "finance.porta",
        "name": "PortaWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.alephium",
        "name": "Alephium Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "network.over",
        "name": "OverFlex",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.walletverse",
        "name": "Walletverse",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.berasig",
        "name": "BeraSig",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "org.shefi",
        "name": "SheFi",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.wemixplay",
        "name": "WEMIX Play",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "co.family.wallet",
        "name": "Family",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.legionnetwork",
        "name": "LegionNetwork",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "com.bitnovo",
        "name": "Bitnovo Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.safecryptowallet",
        "name": "SafeWallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "ag.jup",
        "name": "Jupiter",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.koalawallet",
        "name": "Koala Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.nonbank",
        "name": "NonBank",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.universaleverything",
        "name": "UniversalProfiles",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "io.rabby",
        "name": "Rabby",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "com.brave.wallet",
        "name": "Brave Wallet",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "com.moongate.one",
        "name": "MG",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "com.blanqlabs.wallet",
        "name": "Blanq",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "tech.levain",
        "name": "Levain",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "com.enkrypt",
        "name": "Enkrypt",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "com.scramble",
        "name": "Scramble",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "io.finoa",
        "name": "FinoaConnect",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "app.nightly",
        "name": "Nightly",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "com.blazpay.wallet",
        "name": "Blazpay",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "io.getjoin.prd",
        "name": "JOIN MOBILE APP",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "xyz.talisman",
        "name": "Talisman Wallet",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "eu.flashsoft.clear-wallet",
        "name": "clear-wallet",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "app.berasig",
        "name": "BeraSig",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "com.lootrush",
        "name": "LootRush",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "app.core.extension",
        "name": "Core",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "xyz.dawnwallet",
        "name": "Dawn Wallet",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "xyz.abs",
        "name": "Abstract Global Wallet",
        "hasMobileSupport": false,
        "imageFile": "",
    },
    {
        "id": "smart",
        "name": "Smart Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "inApp",
        "name": "In-App Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
    {
        "id": "embedded",
        "name": "In-App Wallet",
        "hasMobileSupport": true,
        "imageFile": "",
    },
];