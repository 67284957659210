import {GetGuildProgressResponse, OWReward, OWUserStat} from "@devour/client";
import GoVipOWQuestRewardCard from "@/components/goVip/GoVipOWQuestRewardCard";
import { useMemo, useState } from "react";
import GuildQualifierCard from "@/pages/guilds/GuildQualifierCard";
import { useNavigate } from "react-router";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

interface Props {
    gameId: string;
    quests: Array<OWReward>;
    alwaysMobile?: boolean;
    userStats: Array<OWUserStat>;
    guildProgress?: GetGuildProgressResponse;
    forceModalDesign?: boolean;
}

export default function OverwolfGoVipQuestRewards(props: Props) {
    const [isClaimingInProgress, setIsClaimingInProgress] = useState<boolean>(false);
    const navigate = useNavigate();
    const isOnOverwolf = useSelector((store: IStore) => store.metaStore.isOnOverwolf);

    const questStats = useMemo(() => {
        return props.quests.map((quest) => {
            const userStat = props.userStats?.find((stat) => stat.reward.id === quest.id);
            return {
                quest,
                userStat,
                isClaimed: userStat?.isClaimed ?? false,
                completed: userStat?.progress >= quest.target,
            };
        });
    }, [props.quests, props.userStats]);
    const sortedQuests = useMemo(() => {
        return [...questStats].sort((a, b) => {
            // 1. Put !isClaimed && isCompleted at the top
            if (!a.isClaimed && a.completed && (b.isClaimed || !b.completed)) {
                return -1;
            }
            if (!b.isClaimed && b.completed && (a.isClaimed || !a.completed)) {
                return 1;
            }

            // 2. Sort the rest normally (by completion status)
            if (a.completed && !b.completed) {
                return 1;
            }
            if (!a.completed && b.completed) {
                return -1;
            }

            // 3. Put isClaimed && isCompleted at the bottom
            if (a.isClaimed && a.completed && (!b.isClaimed || !b.completed)) {
                return 1;
            }
            if (b.isClaimed && b.completed && (!a.isClaimed || !a.completed)) {
                return -1;
            }

            return 0;
        });
    }, [questStats]);

    return (
        <div className="overwolf-govip_rewards_quest_list">
            {props.guildProgress && <GuildQualifierCard
                quests={props.guildProgress.quests}
                guild={props.guildProgress.guild}
                badgeImageUrl={props.guildProgress.badgeImageUrl}
                isOnModal={!isOnOverwolf}
            />}
            {sortedQuests.map(({ quest, userStat }) => {
                return (
                    <GoVipOWQuestRewardCard
                        forceModalDesign={props.forceModalDesign}
                        className="overlay-card"
                        quest={quest}
                        userStat={userStat}
                        alwaysMobile={props.alwaysMobile}
                        gameId={props.gameId}
                        isClaimingInProgress={isClaimingInProgress}
                        setIsClaimingInProgress={setIsClaimingInProgress}
                        key={quest.id}
                    />
                );
            })}
        </div>
    );
}
