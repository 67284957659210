import {ReactElement} from "react";
import classNames from "classnames";

interface Props {
    isModal?: boolean;
    leaderboardName: string;
}

function GoFrensTopLeaderboardCommunityTableHeader({
    isModal,
    leaderboardName = "Community",
}: Props): ReactElement {
    return (
        <div className={classNames(
            "my-community-leaderboard_header",
            {"is-modal": isModal},
        )}>
            <div className="my-community-leaderboard_header_rank">Rank</div>
            <div className="my-community-leaderboard_header_member">{leaderboardName}</div>
            <div className="my-community-leaderboard_header_exp">XP</div>
        </div>
    );
}

export default GoFrensTopLeaderboardCommunityTableHeader;
