import {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "../buttons/FrameButton";
import FrameAutoPanelFooter from "./autoPanelComponents/FrameAutoPanelFooter";
import {AccountDeletionApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import getConfig from "../../utils/getConfig";
import {addError, decrementLoading, incrementLoading, updateLastDeletionEmailSent} from "@/redux/meta/metaActions";
import useOnLogout from "@/hooks/useOnLogout";
import {useNavigate} from "react-router";
import Toast from "../Toast";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

function ConfirmDeleteModal(props: Props): ReactElement {

    const [
        showVerify,
        setShowVerify,
    ] = useState<boolean>(false);
    const [
        verificationCode,
        setVerificationCode,
    ] = useState<string>("");
    const [
        waitTime,
        setWaitTime,
    ] = useState<number>(0);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const lastEmailCall = useSelector((store: IStore) => store.metaStore.lastDeletionEmailSent);
    const {devourLogout} = useOnLogout();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        if (waitTime > 0) {
            setShowToast(true);
        }

    }, [waitTime]);

    async function sendVerificationEmail() {
        setShowVerify(true);
        try {
            dispatch(incrementLoading());
            await new AccountDeletionApi(getConfig(fullToken)).createDeletionVerificationCode();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    async function deleteAccount() {
        try {
            dispatch(incrementLoading());
            await new AccountDeletionApi(getConfig(fullToken)).deleteAccount({
                deleteAccountRequestBody: {
                    code: verificationCode,
                },
            });
            devourLogout();
            navigate("/");

        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function dismissModal() {
        props.toggle();
        setShowVerify(false);
        setVerificationCode("");
    }

    async function handleToastDismissal() {
        setWaitTime(0);
        setShowToast(false);
    }

    async function handleEmailCalls() {

        const now = Date.now();

        if (lastEmailCall && now - lastEmailCall < 600000) {
            const wait = (600000 - (now - lastEmailCall)) / 60000;
            setWaitTime(Math.round(wait));

        } else {
            dispatch(updateLastDeletionEmailSent(now));
            await sendVerificationEmail();

        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={dismissModal}
            size="sm"
            contentClassName="acc-deactivate-modal"
        >
            <Toast
                message={`You must wait ${waitTime} minute(s) before sending another code.`}
                isOpen={showToast}
                onDismiss={handleToastDismissal}
            />
            <FrameModalHeader
                title="Confirm Account Deletion"
                toggle={dismissModal}
            />

            <FrameAutoPanelBody>
                <p>Are you really sure you want to delete your account?</p>
                <p className="acc-deactivate-modal_warning-text">
                    <strong>You cannot undo this action!</strong>
                </p>

                {
                    showVerify &&
                    <>
					    <hr/>
					    <p className="acc-deactivate-panel_container_row_more-info">
							A verification code was sent to your email, please enter it below.
					    </p>
					    <input
					        className="acc-deactivate-modal_input"
					        placeholder="Enter verification code"
					        value={verificationCode}
					        onChange={(e) => setVerificationCode(e.target.value)}
					    />
                    </>
                }

            </FrameAutoPanelBody>

            <FrameAutoPanelFooter>

                {
                    showVerify &&
                    <FrameButton
					    <ButtonHTMLAttributes<HTMLButtonElement>>
					    color="gray"
					    size="narrow"
					    onClick={async () => {
					        await handleEmailCalls();
					    }}
                    >
						Re-send Code
                    </FrameButton>
                }

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="danger"
                    size="narrow"
                    onClick={async () => {

                        if (!showVerify) {
                            await handleEmailCalls();
                            setShowVerify(true);

                        } else {
                            await deleteAccount();
                        }
                    }}
                    forwardProps={{disabled: showVerify && (verificationCode.length < 6 || isNaN(Number(verificationCode)))}}
                >
                    {showVerify
                        ? "Delete Account"
                        : "Confirm Deletion"}
                </FrameButton>
            </FrameAutoPanelFooter>

        </FrameOneAutoPanel>
    );
}

export default ConfirmDeleteModal;
