
export function hexToRgb(hex: string): { r: number; g: number; b: number } {
    if (!hex) return;
    const hexNumber = parseInt(hex.replace("#", ""), 16);
    if (isNaN(hexNumber)) return undefined;
    return {
        r: (hexNumber >> 16) & 255,
        g: (hexNumber >> 8) & 255,
        b: hexNumber & 255,
    };
}