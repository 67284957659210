import {ReactElement} from "react";
import {
    NftOwnershipInformation,
    HandoffOptions,
    RestaurantSearchResult,
} from "@devour/client";
import classNames from "classnames";
import {Link} from "react-router";
import RestaurantHoursSummary from "./RestaurantHoursSummary";
import {BsDot} from "react-icons/bs";
import {RestaurantSearchFiltersQuery} from "@/utils/restaurantsSearchFilter";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import RestaurantCardGoFrens from "./RestaurantCardGoFrens";
import {addError} from "@/redux/meta/metaActions";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useRestaurant} from "@/hooks/useRestaurant";
import {useUpdateFavoriteBusiness} from "@/hooks/useUpdateFavoriteBusiness";

const kmInMile = 1.609;

interface Props {
    restaurant: RestaurantSearchResult;
    restaurantFilters?: RestaurantSearchFiltersQuery;
    nftOwnershipResponse?: NftOwnershipInformation;
    showPromos: "on" | "off" | "auto";
    placeId?: string;
}

function RestaurantCard(props: Props): ReactElement {
    const dispatch = useDispatch();
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: restaurant} = useRestaurant(props.restaurant._id, props.placeId);
    const {data: currentUser} = useGetUserProfile(fullToken);
    const {mutateAsync: mutateUpdateFavouriteBusiness} = useUpdateFavoriteBusiness(fullToken, props?.restaurant);

    const distance = restaurant?.distance || props.restaurant?.distance || 0;

    async function onFavorite(isFavourite: boolean): Promise<void> {
        try {
            await mutateUpdateFavouriteBusiness({isFavourite});
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    if (!props.restaurant) {
        return null;
    }

    return (
        <div
            className={classNames("restaurant-card", {
                "is-open": props.restaurant.isOpen,
            })}
        >
            <div className="restaurant-card_favorite">
                {currentUser?.user && !currentUser.user.favorites?.includes(props.restaurant._id) &&
										<button
										    className="reset-button restaurant-card_favorite_add"
										    title="Add to saved list"
										    onClick={() => onFavorite(true)}
										>
										    <AiOutlineHeart/>
										</button>
                }
                {currentUser?.user && currentUser.user.favorites?.includes(props.restaurant._id) &&
										<button
										    className="reset-button restaurant-card_favorite_remove"
										    title="Remove from saved list"
										    onClick={() => onFavorite(false)}
										>
										    <AiFillHeart/>
										</button>
                }
            </div>
            <Link to={`${props.restaurant.url}/${props.placeId || ""}`}>
                <RestaurantCardGoFrens
                    restaurant={props.restaurant}
                    restaurantFilters={props.restaurantFilters}
                    nftOwnershipResponse={props.nftOwnershipResponse}
                    showPromos={props.showPromos}
                />
                <div className="restaurant-card_image">
                    <img
                        src={props.restaurant.headerImage || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                        alt={props.restaurant?.name}
                    />
                    {!props.restaurant.isOpen &&
												<div className="restaurant-card_image_closed">
												    <div className="restaurant-card_image_closed_text">
																Now Closed
												    </div>
												</div>
                    }
                </div>
                <div className="restaurant-card_row-between">
                    <div
                        className="restaurant-card_name"
                        title={props.restaurant?.name}
                    >
                        {props.restaurant?.name}
                    </div>
                    {/* <div className="restaurant-card_rating">*/}
                    {/*	<AiFillStar/>*/}
                    {/*	4.9*/}
                    {/* </div>*/}
                </div>
                {props.restaurant.isOpen
                    ? <div className="restaurant-card_row">
                        <div className="restaurant-card_delivery-distance">
                            {(distance / kmInMile).toFixed(1)} mi
                        </div>
                        <BsDot/>
                        <div className="restaurant-card_delivery-time">
                            {handoff === HandoffOptions.DELIVERY
                                ? `${props.restaurant.prepTime + Math.ceil(distance / kmInMile) * 3} min`
                                : `${props.restaurant.prepTime} min`}
                        </div>
                        {handoff === HandoffOptions.DELIVERY && typeof restaurant?.deliveryCharge === "number" && restaurant?.isDeliveryAvailable &&
														<>
														    <BsDot/>
														    <div className="restaurant-card_delivery-fee">
																		${restaurant.deliveryCharge.toFixed(2)} delivery fee
														    </div>
														</>
                        }
                    </div>
                    : <div className="restaurant-card_hours">
                        <RestaurantHoursSummary restaurant={restaurant || props.restaurant}
                            hideMoreInfoIfClosed={true}/>
                    </div>
                }
            </Link>
        </div>

    );
}

export default RestaurantCard;
