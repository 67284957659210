import {isMobile} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import {LinkProps} from "react-router";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";

export default function OverwolfIndustryPassPromoBanner() {
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const industryCollectionRequiredToLevelUp = accountLevel?.userRequiresIndustryCollectionNftToLevelUp &&
        !accountLevel?.userOwnsIndustryCollectionNft &&
        accountLevel?.experienceRequiredForNextLevel <= 0;

    if (!accountLevel || !industryCollectionRequiredToLevelUp) {
        return <></>;
    }

    return (
        <div className="overwolf-govip_industry-pass">
            <div className="overwolf-govip_industry-pass_content">
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/gradient-sparkles.svg`}
                    alt="gradient sparkles"
                />
                <div className="overwolf-govip_industry-pass_content_text">
                    Ready to hit Level 4?{isMobile ? <br /> : " "}
                    Grab the <b>Industry Pass</b> and keep the grind going!
                </div>
            </div>
            <FrameButton
                <LinkProps>
                to="/the-industry-pass"
                size="narrow"
                className="overwolf-govip_button_purple-blue-gradient"
            >
                Unlock Level 4
            </FrameButton>
        </div>
    );
}