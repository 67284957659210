import {PrizePool} from "@devour/client";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {useEffect, useRef, useState} from "react";

export default function ChallengePrizeImageCarousel({prizes}: { prizes: Array<PrizePool> }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        handleScroll(currentIndex);
    }, [currentIndex]);

    function handleScroll(index: number) {
        if (!containerRef.current) {
            return;
        }

        const stepElement = containerRef.current.querySelector(`#brand-challenge_prize_carousel_item-${index}`);
        if (stepElement) {
            const containerWidth = stepElement.getBoundingClientRect().width * prizes.length;
            (containerRef.current as HTMLElement).scrollTo({
                left: containerWidth - stepElement.getBoundingClientRect().width * (prizes.length - index),
                behavior: "smooth",
            });
        }
    }

    return (
        <div className="brand-challenge_prize_carousel">
            <div
                ref={containerRef}
                className="brand-challenge_prize_carousel_container"
            >
                {prizes.map((prize, index) => {
                    return (
                        <div
                            key={prize.id}
                            className="brand-challenge_prize_carousel_item"
                            id={`brand-challenge_prize_carousel_item-${index}`}
                            style={{backgroundImage: `url(${prize.image.url})`}}
                        />
                    );
                })}
            </div>


            {prizes?.length > 1 && <>
                <button
                    disabled={currentIndex === 0}
                    onClick={() => setCurrentIndex(currentIndex - 1)}
                    className="brand-challenge_prize_carousel_arrow prev reset-button">
                    <IoIosArrowBack size="1.75rem"/>

                </button>
                <button
                    disabled={currentIndex === prizes.length - 1}
                    onClick={() => setCurrentIndex(currentIndex + 1)}
                    className="brand-challenge_prize_carousel_arrow next reset-button">
                    <IoIosArrowForward size="1.75rem"/>
                </button>
            </>}
        </div>
    );
}