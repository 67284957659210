import {MutableRefObject, ReactElement, useContext, useEffect, useState} from "react";
import CheckoutPaymentOption from "@/components/checkout/checkoutPayments/CheckoutPaymentOption";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import CheckoutPaymentCreditModal from "@/components/checkout/checkoutPayments/credit/CheckoutPaymentCreditModal";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import {
    addError, decrementModalCount,
    removeDpayPriceExpiryTime,
    updateDpayPriceExpiryTime,
} from "@/redux/meta/metaActions";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {MenuOrdersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import Toast from "@/components/Toast";
import {roundNumber} from "@/utils/roundNumber";
import CheckoutPaymentSplitSetupModal from "@/components/checkout/checkoutPayments/CheckoutPaymentSplitSetupModal";
import {StripePaymentMethodObject} from "@/types/Stripe";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {
    ActivePaymentMethod,
    getMenuOrderPaymentMethod,
} from "@/utils/getMenuOrderPaymentMethod";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {useGate} from "statsig-react";
import CheckoutFuelPromoBanner from "@/components/checkout/CheckoutFuelPromoBanner";
import CheckoutPaymentLoadDpayModal from "./CheckoutPaymentLoadDPayModal";
import { ENABLE_LOOT_SHOP_FUEL_BACK_GATE_KEY, useScopedGate } from "@/hooks/useScopedGate";

export const DPAY_QUOTE_EXPIRY_IN_MINUTES = 30;
const DPAY_DECIMALS = 7;

enum TimerState {
    NOT_SET = "NOT_SET",
    RESET = "RESET",
}

interface Props {
    isCheckoutInProgress: MutableRefObject<Boolean>;
}

/**
 * Converts fiat to token by flooring the number to an integer.
 * If the calculated token exceeds the balance by a cent or less, then return the balance instead.
 * This is to prevent rounding locking users out of completing their purchase.
 *
 * @param fiat
 * @param tokenBalance
 * @param tokenPrice
 */
function convertFiatToDpay(fiat: number, tokenBalance: number, tokenPrice: number) {

    const calculatedDpay = fiat / tokenPrice;
    const dpayPerCent = 0.01 / tokenPrice;

    if (calculatedDpay &&
        calculatedDpay - tokenBalance > 0 &&
        calculatedDpay - tokenBalance <= dpayPerCent) {
        return tokenBalance;
    }

    return calculatedDpay;
}

function CheckoutPaymentMethods(props: Props): ReactElement {

    const {menuOrderId, isDigitalStore} = useContext(RestaurantContext);
    const {value: isFuelCashbackEnabledGate} = useScopedGate(ENABLE_LOOT_SHOP_FUEL_BACK_GATE_KEY);
    const isFuelCashbackEnabled = isFuelCashbackEnabledGate && isDigitalStore;

    const dispatch = useDispatch();
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dpayPriceExpiryTime = useSelector((store: IStore) => store.metaStore.dpayPriceExpiryTime);
    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);

    const [
        isPaymentMethodLoading,
        setIsPaymentMethodLoading,
    ] = useState<boolean>(false);

    const [showDpayModal, setShowDpayModal] = useState<boolean>(false);
    const [showCreditModal, setShowCreditModal] = useState<boolean>(false);
    const [showSplitSetupModal, setShowSplitSetupModal] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<string>(TimerState.NOT_SET);

    const [showQuoteToast, setShowQuoteToast] = useState<boolean>(false);
    const [showSlippageToast, setShowSlippageToast] = useState<boolean>(false);

    const [externalBalance, setExternalBalance] = useState<number>(undefined);
    const [showLoadDpay, setShowLoadDpay] = useState<boolean>(false);

    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const {
        data: transactionData,
        refetch: refetchUserTransactions,
    } = useGetTransactions(fullToken, currentUser?.user?.id);

    // Fetch list of the user's Stripe payment methods
    const {data: paymentMethodData} = useGetStripePaymentMethodList(fullToken);
    const paymentMethods = (paymentMethodData?.paymentMethods) as Array<StripePaymentMethodObject>;
    const menuOrderPaymentMethod = getMenuOrderPaymentMethod(menuOrder, paymentMethods, undefined, transactionData);

    // const {data: externalDpayBalance} = useReadContract({
    //     address: import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM as `0x${string}`,
    //     abi: dpayAbi,
    //     query: {enabled: account.isConnected},
    //     functionName: "balanceOf",
    //     args: [account.address],
    // });
    //
    // const {data: externalDpayDecimals} = useReadContract({
    //     address: import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM as `0x${string}`,
    //     abi: dpayAbi,
    //     query: {enabled: account.isConnected},
    //     functionName: "decimals",
    // });

    useEffect(() => {
        void refetchUserTransactions();
    }, []);

    useEffect(() => {
        void verifyPaymentMethod();
    }, [menuOrderPaymentMethod]);

    useEffect(() => {
        if (menuOrder?.exceededSlippage) {
            void resetTimer(true);
        }
    }, [menuOrder?.exceededSlippage]);

    useEffect(() => {
        if (dpayPriceExpiryTime) {
            const interval = setTimer();
            return () => clearInterval(interval);
        }

    }, [dpayPriceExpiryTime]);

    // useEffect(() => {
    //     // Handle getting DPAY prices and checking the status of Magic connection
    //     if ((showSplitSetupModal || showDpayModal) && transactionData) {
    //
    //         void updateMenuOrderDpayValue();
    //         void getIsMagicConnected();
    //
    //     } else if (!showDpayModal || !showSplitSetupModal) {
    //         // Recheck magic connection each time
    //         setIsMagicConnected(false);
    //     }
    // }, [showDpayModal, transactionData, menuOrder, showSplitSetupModal]);

    // useEffect(() => {
    //     if ((showDpayModal || showSplitSetupModal) && isMagicConnected && menuOrder?.dpayFiatAtOrderTime) {
    //         if (!magicDecimals) {
    //             // We don't need to fetch this more than once
    //             void getMagicDecimals();
    //         } else {
    //             void getMagicDpayBalance();
    //         }
    //     }
    // }, [showDpayModal, showSplitSetupModal, isMagicConnected, magicDecimals, menuOrder]);

    // useEffect(() => {
    //     if ((showDpayModal || showSplitSetupModal) && account?.isConnected && externalDpayBalance && externalDpayDecimals) {
    //         getExternalDpayBalance();
    //     }
    // }, [showDpayModal, showSplitSetupModal, account?.isConnected, externalBalance, externalDpayDecimals]);

    /**
     * Sets timer for DPAY expiration.
     * If the timer expires, then it closes the DPAY modal if active,
     * re-fetches the price of DPAY, and clears other relevant states
     * back to default starting values.
     */
    function setTimer(): NodeJS.Timeout {
        return setInterval(() => {
            const distance = dpayPriceExpiryTime - Date.now() || 0;
            if (distance > 0) {
                const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
                const seconds = Math.floor(distance % (1000 * 60) / 1000);
                setCountdown(`${minutes}m ${seconds}s`);

            } else {
                void resetTimer();
            }
        }, 1000);
    }

    async function resetTimer(showSlippageToast: boolean = false) {
        dispatch(removeDpayPriceExpiryTime());

        if (showSlippageToast) {
            setShowSlippageToast(true);
        }

        if (showDpayModal) {
            toggleDpayModal();
        }

        if (showSplitSetupModal) {
            toggleSplitSetupModal();
        }

        if (!props.isCheckoutInProgress.current) {
            await resetCart(true);

            // re-fetch price
            await refetchUserTransactions();
        }

        // reset timer and dependent states
        setCountdown(TimerState.NOT_SET);

    }

    async function verifyPaymentMethod(): Promise<void> {
        if (!menuOrderPaymentMethod) {
            return;
        }

        const now = Date.now();

        if (menuOrderPaymentMethod.method === ActivePaymentMethod.DPAY || menuOrderPaymentMethod.method === ActivePaymentMethod.SPLIT) {

            if (now > dpayPriceExpiryTime) {
                await resetCart();

            }

            // If using onchain DPAY, check if account has a connected wallet
            // if (menuOrder.onChainDpay && !menuOrder.isMagicWallet && !account.isConnected) {
            //     await resetCart();
            // }
        }

    }

    async function resetCart(resetDpayPrice: boolean = false): Promise<void> {
        if (props.isCheckoutInProgress.current) {
            return;
        }

        try {
            const lastDpayPrice: number = menuOrder.dpayFiatAtOrderTime;

            await new MenuOrdersApi(getConfig()).updateMenuOrder({
                id: menuOrder.id,
                createMenuOrderBody: {
                    dpay: 0,
                    vdpay: 0,
                    onChainDpay: 0,
                    dpayFiatAtOrderTime: resetDpayPrice
                        ? 0
                        : lastDpayPrice,
                    isMagicWallet: false,
                    isCoinbase: false,
                },
            });
            await refetchMenuOrder();

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    /**
     * Toggles the DPAY methods modal.
     *
     */
    async function toggleDpayModal(): Promise<void> {
        /*
         * on-chain payments temporarily disabled.
         * Only allow on-account tokens for now.
         * setShowDpayModal(!showDpayModal);
         */

        const tokenPrice = transactionData?.dPayPricePerUsd || 0;
        const balance = transactionData?.balance || 0;
        const totalStripeTotalInDpay = convertFiatToDpay(menuOrder.grandTotal, balance, tokenPrice);

        await new MenuOrdersApi(getConfig()).updateMenuOrder({
            id: menuOrder.id,
            createMenuOrderBody: {
                paymentMethodId: "",
                isCoinbase: false,
                dpayFiatAtOrderTime: tokenPrice,
                dpay: totalStripeTotalInDpay,
                vdpay: totalStripeTotalInDpay,
                onChainDpay: 0,
                isMagicWallet: false,
            },
        });

        // Show price of DPAY
        setShowQuoteToast(true);

        // Update the timer
        const now = new Date();
        now.setMinutes(now.getMinutes() + DPAY_QUOTE_EXPIRY_IN_MINUTES); // 30 minutes limit
        dispatch(updateDpayPriceExpiryTime(now.getTime()));

        await refetchMenuOrder();

    }

    function handleQuoteToastDismissal(): void {
        setShowQuoteToast(false);
    }

    /**
     * Toggles the Credit methods modal.
     *
     */
    function toggleCreditModal(): void {
        setShowCreditModal(!showCreditModal);
        dispatch(decrementModalCount());
    }

    function handleSlippageToastDismissal() {
        setShowSlippageToast(false);
    }

    function toggleSplitSetupModal(): void {
        setShowSplitSetupModal(!showSplitSetupModal);
    }

    /* ===== DPAY Related Functions ===== */

    /*
     * These are handled on the parent level because DPAY and Split needs to use the same functions
     * and state. This is to prevent non-trivial duplication of the code.
     */

    /**
     * Updates the menu order with DPAY fiat value if no expiration was set
     * or if the expiration passed.
     * If so, set a new expiry time for which this value is valid.
     */
    // async function updateMenuOrderDpayValue(): Promise<void> {
    //     if (!dpayPriceExpiryTime || dpayPriceExpiryTime < Date.now()) {
    //
    //         // Show price of DPAY
    //         setShowQuoteToast(true);
    //
    //         // We only update the cart with the DPAY value if one wasn't already assigned
    //         await updateMenuOrderWithDpayValue(transactionData?.dPayPricePerUsd);
    //
    //         // Update the timer
    //         const now = new Date();
    //         now.setMinutes(now.getMinutes() + DPAY_QUOTE_EXPIRY_IN_MINUTES); // 30 minutes limit
    //         dispatch(updateDpayPriceExpiryTime(now.getTime()));
    //
    //         await refetchMenuOrder();
    //     } else if (dpayPriceExpiryTime && menuOrder?.dpayFiatAtOrderTime === 0) {
    //
    //         /**
    //          * To counter the scenario when we got some stray expiry time for 1 menu order, then we
    //          * want to check out a different order (different restaurant), the countdown is not reset.
    //          */
    //         void resetTimer();
    //     }
    //
    // }

    /**
     * Updates the active menu order with the USD value of DPAY to calculate against.
     * @param dpayValue
     */
    // async function updateMenuOrderWithDpayValue(dpayValue: number): Promise<void> {
    //     try {
    //         await new MenuOrdersApi(getConfig()).updateMenuOrder({
    //             id: menuOrder.id,
    //             createMenuOrderBody: {
    //                 dpayFiatAtOrderTime: dpayValue,
    //             },
    //         });
    //     } catch (e) {
    //         dispatch(await addError(e));
    //     }
    // }

    /**
     * Checks to see if the user's Magic session is active.
     * If not, then display warning Toast and log them out.
     */
    // async function getIsMagicConnected(): Promise<void> {
    //     try {
    //         const isMagicActive: boolean = await magic.user.isLoggedIn();
    //         if (isMagicActive === false) {
    //             await magic.user.logout();
    //             setShowMagicSessionErrToast(true);
    //         } else {
    //             setIsMagicConnected(isMagicActive);
    //         }
    //     } catch (e) {
    //         setShowMagicSessionErrToast(true);
    //     }
    //
    // }

    // function handleMagicSessionErrToastDismissal(): void {
    //     setShowMagicSessionErrToast(false);
    //     devourLogout();
    //     navigate("/log-in");
    // }

    /**
     * Gets the wei exponential value for the DPAY contract.
     */
    // async function getMagicDecimals(): Promise<void> {
    //     const provider = new ethers.BrowserProvider(magic.rpcProvider);
    //     const signer = await provider.getSigner();
    //     const contract = new ethers.Contract(import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM, dpayAbi, signer);
    //     const decimals: bigint = await contract.decimals();
    //     setMagicDecimals(decimals.toString()); // should be small enough to cast as number
    // }

    /**
     * Gets the DPAY balance of a user's Magic wallet and converts it from wei denomination.
     */
    // async function getMagicDpayBalance(): Promise<number> {
    //     const provider = new ethers.BrowserProvider(magic.rpcProvider);
    //     const signer = await provider.getSigner();
    //     const contract = new ethers.Contract(import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM, dpayAbi, signer);
    //     const magicDpay: bigint = await contract.balanceOf((await magic.user.getMetadata()).publicAddress);
    //     const dpay: bigint = magicDpay / BigInt(10) ** BigInt(magicDecimals);
    //
    //     const magicBalanceAsNumber = Number(dpay);
    //     setMagicBalance(magicBalanceAsNumber);
    //     return magicBalanceAsNumber;
    // }

    /**
     * Gets the DPAY balance on a user's connected external wallet.
     */
    /*
     * function getExternalDpayBalance(): number {
     *     const dpay: bigint = ethers.getBigInt(externalDpayBalance as BigNumberish) / BigInt(10) ** ethers.getBigInt(externalDpayDecimals as BigNumberish);
     *
     *     const externalBalanceAsNumber = parseInt(dpay.toString(), 10);
     *
     *     setExternalBalance(externalBalanceAsNumber);
     *     return externalBalanceAsNumber;
     * }
     */

    /**
     * Predicate for skeleton rendering or displaying DPAY content.
     */
    function showDpayContent(): boolean {
        return transactionData &&
            menuOrder.dpayFiatAtOrderTime > 0 &&
            dpayPriceExpiryTime > Date.now();
    }

    async function toggleCoinbase(): Promise<void> {
        setIsPaymentMethodLoading(true);
        await new MenuOrdersApi(getConfig()).updateMenuOrder({
            id: menuOrder.id,
            createMenuOrderBody: {
                dpay: 0,
                vdpay: 0,
                onChainDpay: 0,
                isMagicWallet: false,
                paymentMethodId: "",
                isCoinbase: true,
            },
        });
        await refetchMenuOrder();
        setIsPaymentMethodLoading(false);
    }

    function renderTimer(): boolean {
        if (countdown === TimerState.NOT_SET) {
            return false;
        }

        if (menuOrderPaymentMethod && (menuOrderPaymentMethod.method === ActivePaymentMethod.DPAY ||
            menuOrderPaymentMethod.method === ActivePaymentMethod.SPLIT)) {
            return true;
        }
        return false;
    }

    if (!menuOrder) {
        return null;
    }

    return (
        <>
            <Toast
                variant="error"
                duration={5000}
                message={`The current FUEL price at $${roundNumber(transactionData?.dPayPricePerUsd, DPAY_DECIMALS)} is good for ${DPAY_QUOTE_EXPIRY_IN_MINUTES} minutes.`}
                isOpen={showQuoteToast && showDpayContent()}
                showButton={true}
                buttonMessage="Got it!"
                onDismiss={handleQuoteToastDismissal}
                removeMarginAdjustment={true}
            />
            <Toast
                variant="error"
                duration={5000}
                message="The price of FUEL has changed too much - please enter it again."
                isOpen={showSlippageToast}
                showButton={false}
                onDismiss={handleSlippageToastDismissal}
                removeMarginAdjustment={true}
            />
            <CheckoutPaymentLoadDpayModal isOpen={showLoadDpay} toggle={() => setShowLoadDpay(false)} size="xs2"/>

            {/* <CheckoutPaymentDpayModal*/}
            {/*    isOpen={showDpayModal}*/}
            {/*    toggle={toggleDpayModal}*/}
            {/*    showDpayContent={showDpayContent()}*/}
            {/*    magicBalance={magicBalance}*/}
            {/*    externalDpayBalance={externalBalance}*/}
            {/*    resetCart={resetCart}*/}
            {/*    isMagicConnected={isMagicConnected}*/}
            {/* />*/}

            <CheckoutPaymentCreditModal
                isOpen={showCreditModal}
                toggle={toggleCreditModal}
            />

            <CheckoutPaymentSplitSetupModal
                isOpen={showSplitSetupModal}
                toggle={toggleSplitSetupModal}
                showDpayContent={showDpayContent()}
                magicBalance={0}
                externalDpayBalance={externalBalance}
                resetCart={resetCart}
                isMagicConnected={false}
            />

            <div className="checkout-payments checkout-page_wrapper_content_container">

                <div className="checkout-payments_title-container">
                    <h4>Payment Method</h4>
                    {renderTimer() &&
                        <div className="checkout-payments_title-container_timer">
                            <p>{countdown}</p>
                        </div>
                    }
                </div>


                {transactionData && (isFuelCashbackEnabled || !isDigitalStore) &&
                    <CheckoutFuelPromoBanner
                        onLoadClicked={() => {
                            setShowLoadDpay(true);
                        }}
                        isOnDigitalStore={isDigitalStore}
                    />
                }
                <div className="checkout-payments_method-container">
                    {dpayStatus &&
                        <CheckoutPaymentOption
                            isActive={menuOrderPaymentMethod?.method === ActivePaymentMethod.DPAY}
                            paymentMethod={ActivePaymentMethod.DPAY}
                            activePaymentMethodTag={menuOrderPaymentMethod?.activeMethodText}
                            toggleModal={toggleDpayModal}
                            showEdit={false}
                            isLoading={isPaymentMethodLoading}
                        />
                    }

                    <CheckoutPaymentOption
                        isActive={menuOrderPaymentMethod?.method === ActivePaymentMethod.COINBASE}
                        paymentMethod={ActivePaymentMethod.COINBASE}
                        activePaymentMethodTag={menuOrderPaymentMethod?.activeMethodText}
                        toggleModal={toggleCoinbase}
                        isLoading={isPaymentMethodLoading}
                    />

                    {!isDigitalStore && <CheckoutPaymentOption
                        isActive={menuOrderPaymentMethod?.method === ActivePaymentMethod.CREDIT}
                        paymentMethod={ActivePaymentMethod.CREDIT}
                        activePaymentMethodTag={menuOrderPaymentMethod?.activeMethodText}
                        toggleModal={toggleCreditModal}
                        showEdit={true}
                        isLoading={isPaymentMethodLoading}
                    />}

                    {dpayStatus && !isDigitalStore &&
                        <CheckoutPaymentOption
                            isActive={menuOrderPaymentMethod?.method === ActivePaymentMethod.SPLIT}
                            paymentMethod={ActivePaymentMethod.SPLIT}
                            activePaymentMethodTag={menuOrderPaymentMethod?.activeMethodText}
                            toggleModal={toggleSplitSetupModal}
                            showEdit={true}
                            isLoading={isPaymentMethodLoading}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default CheckoutPaymentMethods;
