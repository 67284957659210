import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GuildsHeader from "@/pages/guilds/GuildsHeader";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import DevourTopNav from "@/components/DevourTopNav";
import GuildsExplore from "@/pages/guilds/GuildsExplore";
import GuildsSearchResults from "@/pages/guilds/GuildsSearchResults";
import {useSearchParams} from "react-router";
import classNames from "classnames";
import GuildBadges from "@/pages/guilds/GuildBadges";
import GuildBadgesViewAll from "@/pages/guilds/GuildBadgesViewAll";
import GuildsTopLeaderboard from "@/pages/guilds/GuildsTopLeaderboard";
import MyOtherGuildsViewAll from "@/pages/guilds/MyOtherGuildsViewAll";
import GuildMemberHeader from "@/pages/guilds/GuildMemberHeader";
import GuildMembershipInfo from "@/pages/guilds/GuildMembershipInfo";
import {useEffect, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import { InviteModal } from "@/components/InviteModal";
import GuildInfoDrawer from "./guilds/GuildInfoDrawer";
import { useGetGuild } from "@/hooks/useGetGuild";
import { useDispatch } from "react-redux";
import { toggleLoginOpen } from "@/redux/meta/metaActions";

export default function GuildsPage() {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: guildOwnerships, refetch: refetchGuildOwnerships} = useGetGuildOwnerships(fullToken, true);
    const queryClient = useQueryClient();
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const guildInviteId = searchParams.get("guildId");
    const hasInvite = searchParams.has("ref");
    const {data: refGuildInfo} = useGetGuild(guildInviteId);
    const [isRefGuildModalOpen, setIsRefGuildModalOpen] = useState(!!guildInviteId);

    const currentGuild = guildOwnerships?.qualifiedGuilds.find(guild => guild.id === guildOwnerships.membership?.guild);
    useEffect(() => {
        void refetchGuildOwnerships();
        queryClient.invalidateQueries({ queryKey: ["top-guilds-leaderboard"]});
        queryClient.invalidateQueries({ queryKey: ["top-guild-leaders-leaderboard"]});
        queryClient.invalidateQueries({ queryKey: ["current-guild-top-members"]});
        if (hasInvite && guildInviteId && !fullToken) {
            dispatch(toggleLoginOpen(true));
        }
    }, []);
    const handleOpenInviteModal = () => {
        setIsInviteModalOpen(true);
    };

    if (searchParams.get("search")) {
        return <GuildsSearchResults />;
    } if (searchParams.get("view") === "badges") {
        return <GuildBadgesViewAll />;
    } if (searchParams.get("view") === "others") {
        return <MyOtherGuildsViewAll />;
    }

    return (
        <div className={classNames("guilds-page", {
            "guilds-page_default": !fullToken || guildOwnerships?.badges.length === 0,
        })}>
            <DevourTopNav text="Guilds"/>
            <InviteModal isVisible={isInviteModalOpen} onClose={() => setIsInviteModalOpen(false)} guildId={currentGuild?.id}/>
            {refGuildInfo && <GuildInfoDrawer
                isOpen={isRefGuildModalOpen}
                toggle={() => setIsRefGuildModalOpen(false)}
                guild={refGuildInfo}
            />}
            {currentGuild
                ? <GuildMemberHeader onOpenInvite={handleOpenInviteModal} guild={currentGuild}/>
                : <GuildsHeader />
            }
            {guildOwnerships?.badges.length > 0 &&
                <>
                    {/* guild member => guild leaderboard */}
                    {currentGuild && <>
                        <GuildMembershipInfo onOpenInvite={handleOpenInviteModal} />
                        <div className="gofrens_divider"/>
                    </>}

                    {/* top leaderboard   */}
                    <GuildsTopLeaderboard/>
                    <div className="gofrens_divider"/>

                    {/* my badges    */}
                    <GuildBadges badges={guildOwnerships.badges}/>
                    <div className="gofrens_divider"/>
                </>
            }
            {/*  explore game guilds  */}
            <GuildsExplore />
        </div>
    );
}