import {AnchorHTMLAttributes, ReactElement} from "react";
import FrameButton from "./buttons/FrameButton";
import ThirdwebConnectButton from "@/components/auth/ThirdwebConnectButton";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import { WalletType } from "@devour/client";

function AccountMagicWalletPrivateKeyExportPanel(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const magicWallet = userProfileData?.wallets.find((wallet) => wallet.type === WalletType.MAGIC);

    return (
        <>
            <h3>DevourGO Wallet</h3>

            <div className="acc-magic-export-panel">
                <div className="acc-magic-export-panel_text">
                    <p>
                        <strong>
                            Thirdweb Private key
                        </strong>
                    </p>
                    <p className="acc-magic-export-panel_text_description">
                        Click the button to reveal your DevourGO wallet's private key
                    </p>
                </div>

                <ThirdwebConnectButton />
            </div>

            {magicWallet &&
                <div className="acc-magic-export-panel">
                    <div className="acc-magic-export-panel_text">
                        <p>
                            <strong>
                               Magic Private key
                            </strong>
                        </p>
                        <p className="acc-magic-export-panel_text_description">
                            Click the button to reveal your DevourGO wallet's private key
                        </p>
                    </div>

                    <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        color="purple"
                        size="narrow"
                        href="https://reveal.magic.link/devourgo"
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Reveal Private Key
                    </FrameButton>
                </div>
            }
        </>
    );
}

export default AccountMagicWalletPrivateKeyExportPanel;