/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChallengeFaqDetails,
    ChallengeFaqDetailsFromJSON,
    ChallengeFaqDetailsFromJSONTyped,
    ChallengeFaqDetailsToJSON,
    ChallengeOnboarding,
    ChallengeOnboardingFromJSON,
    ChallengeOnboardingFromJSONTyped,
    ChallengeOnboardingToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChallengeOnboardingBody
 */
export interface ChallengeOnboardingBody {
    /**
     * 
     * @type {ChallengeOnboarding}
     * @memberof ChallengeOnboardingBody
     */
    onboardingSection?: ChallengeOnboarding;
    /**
     * 
     * @type {Array<ChallengeFaqDetails>}
     * @memberof ChallengeOnboardingBody
     */
    faqs?: Array<ChallengeFaqDetails>;
}

export function ChallengeOnboardingBodyFromJSON(json: any): ChallengeOnboardingBody {
    return ChallengeOnboardingBodyFromJSONTyped(json, false);
}

export function ChallengeOnboardingBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeOnboardingBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onboardingSection': !exists(json, 'onboardingSection') ? undefined : ChallengeOnboardingFromJSON(json['onboardingSection']),
        'faqs': !exists(json, 'faqs') ? undefined : ((json['faqs'] as Array<any>).map(ChallengeFaqDetailsFromJSON)),
    };
}

export function ChallengeOnboardingBodyToJSON(value?: ChallengeOnboardingBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onboardingSection': ChallengeOnboardingToJSON(value.onboardingSection),
        'faqs': value.faqs === undefined ? undefined : ((value.faqs as Array<any>).map(ChallengeFaqDetailsToJSON)),
    };
}


