import FrameButton from "@/components/buttons/FrameButton";
import DevourTopNav from "@/components/DevourTopNav";
import MintIndustryPassModal from "@/components/modals/mintIndustryPassModal/MintIndustryPassModal";
import MintIndustryPassSuccessWrapper from "@/components/modals/mintIndustryPassModal/MintIndustryPassSuccessWrapper";
import useWindowSize, { WindowBreakpointSizes } from "@/hooks/useWindowSize";
import { useState } from "react";
import { FaGift } from "react-icons/fa";
import { LuGamepad2 } from "react-icons/lu";
import { RiVipDiamondLine } from "react-icons/ri";
import { Link, useParams } from "react-router";

export const LootBoxPage = () => {
    const {nftPurchaseId} = useParams<{ nftPurchaseId?: string; }>();
    const [
        resetFlowFromSuccess,
        setResetFlowFromSuccess,
    ] = useState<boolean>(false);
    function resetSuccess(): void {
        setResetFlowFromSuccess(!resetFlowFromSuccess);
    }
    function getWindowBreakpoint(windowWidth: number): WindowBreakpointSizes {
        if (windowWidth < 768) {
            return WindowBreakpointSizes.SMALL;
        }
        if (windowWidth >= 768 && windowWidth < 992) {
            return WindowBreakpointSizes.MEDIUM;
        }
        return WindowBreakpointSizes.LARGE;
    }

    function scrollToPurchase() {
        const purchaseSection = document.getElementById("purchase-section-anchor");
        if (purchaseSection) {
            purchaseSection.scrollIntoView({ behavior: "smooth" });
        }
    }


    const windowBreakPoint = getWindowBreakpoint(useWindowSize()[0]);
    return (
        <div className="loot-box-page">
            <DevourTopNav text="Devour LootBox" />
            <div className="loot-box-page_content">
                <div className="loot-box-page_content_heading">
                    <h3>
                        <span className="purple">Grab your Devour LootBox now!</span> <br />
                        Win with Every Box!
                    </h3>
                    <span>$1 Per - Scoop them up!</span>
                </div>
                <div className="loot-box-page_content_benefits-card_wrapper">
                    <div className="loot-box-page_content_benefits-card">
                        <div className="loot-box-page_content_benefits-card_body">
                            <div className="loot-box-page_content_benefits-card_body_title">
                                <div className="loot-box-page_content_benefits-card_body_title_container">
                                    <h5>Devour LootBox</h5>
                                    <img src={`${import.meta.env.VITE_CDN_URL}/images/Devour-Lootbox.png`} alt="Devour Lootbox" />
                                </div>
                                <span>$1 Each - Win With Every Box!</span>
                            </div>
                            <div className="loot-box-page_content_benefits-card_body_benefits">
                                <div className="loot-box-page_content_benefits-card_body_benefits_benefit">
                                    <RiVipDiamondLine /> <span>XP, Fuel and Order Discounts</span>
                                </div>
                                <div className="loot-box-page_content_benefits-card_body_benefits_benefit">
                                    <LuGamepad2 /> <span>Gaming Gear & Gift Cards</span>
                                </div>
                                <div className="loot-box-page_content_benefits-card_body_benefits_benefit">
                                    <FaGift /> <span>Merch and Industry Passes</span>
                                </div>
                            </div>
                        </div>
                        <FrameButton
                            size="normal"
                            onClick={scrollToPurchase}
                            color="purple"
                            className="loot-box-page_content_benefits-card_action"
                        >
                        Purchase
                        </FrameButton>
                    </div>
                    <div className="loot-box-page_content_benefits-card_wrapper_images">
                        <img src={`${import.meta.env.VITE_CDN_URL}/images/Devour-Lootbox.png`} alt="Devour Lootbox" />
                        <img src={`${import.meta.env.VITE_CDN_URL}/images/Devour-Lootbox.png`} alt="Devour Lootbox" />
                    </div>
                </div>
                <div className="loot-box-page_content_bonus-lootboxes">
                    <h5 className="loot-box-page_content_bonus-lootboxes_title">
                        <span className="purple">Bonus</span> Lootboxes
                    </h5>
                    <div className="loot-box-page_content_bonus-lootboxes_bonuses">
                        <span>While supplies last, earn bonus LootBoxes:</span>
                        <Link to="/the-industry-pass">Industry Passes</Link>
                        <span>For every pass purchased - 10 Bonus LootBoxes</span>
                        <Link to="/load-fuel">Fuel Loads</Link>
                        <span>$50 Load - 3 Bonus LootBoxes</span>
                        <span>$150 Load - 10 Bonus LootBoxes</span>
                    </div>
                </div>
            </div>
            <div
                className="loot-box-page_content_purchase"
                style={{
                    backgroundImage: `url(${`${import.meta.env.VITE_CDN_URL}/images/LootBox-Background-${windowBreakPoint}.png`})`,
                }}
            >
                <div className="loot-box-page_content_purchase_heading" id="purchase-section-anchor">
                    <span>$1 Per Box</span>
                    <h5>GET YOUR LOOTBOXES</h5>
                </div>
                <div className="loot-box-page_content_purchase_content">
                    {nftPurchaseId && !resetFlowFromSuccess
                        ? <MintIndustryPassSuccessWrapper
                            resetSuccess={resetSuccess}
                        />
                        : <MintIndustryPassModal customPurchase={{
                            address: "0xD7B60d6026905C9beE714862a80310Bd97DA977e",
                            allowedMethods: [
                                "credit",
                            ],
                            isLootBox: true,
                            product: {
                                name: "Devour LootBox",
                                shortName: "Devour LootBox",
                                price: 1,
                                maxQuantity: 500,
                                image: `${import.meta.env.VITE_CDN_URL}/images/Devour-Lootbox.png`,
                                description: "Open to discover your prize inside!",
                            },
                            successMessage: <>You have successfully purchased your LootBoxes! Head to <Link to="/collectibles">My Rewards</Link>  in My Collectibles to Redeem</>,
                            title: "Number of Boxes",
                        }}/>
                    }
                </div>
                <div className="loot-box-page_content_purchase_redeem">
                    <h5>To Redeem</h5>
                    <ol>
                        <li>Go to My Collectibles.</li>
                        <li>Select My Rewards.</li>
                        <li>Choose your Devour LootBox to redeem your prize.</li>
                    </ol>
                </div>
            </div>
        </div>
    );
};
