import {ChallengeInfoResponse, OWReward} from "@devour/client";
import {CgProfile} from "react-icons/cg";
import classNames from "classnames";
import ChallengePrizeImageCarousel from "@/pages/challenge/ChallengePrizeImageCarousel";
import {FaExclamationTriangle} from "react-icons/fa";
import {IoMdCheckmark} from "react-icons/io";
import {formatDate} from "@/utils/formatDate";
import Spacer from "@/components/Spacer";

interface Props {
    challenge: ChallengeInfoResponse;
    quests: Array<OWReward>;
}

export default function ChallengePrizePool(props: Props) {
    const {challenge, quests} = props;

    const completedQuestsCount = quests.reduce((acc, quest) => acc + (quest.userStat?.progress >= quest.target ? 1 : 0), 0);

    function getLastCompletedQuestDate() {
        let lastCompletedQuestDate = null;
        quests.forEach(quest => {
            if (quest.userStat?.updatedAt >= lastCompletedQuestDate) {
                lastCompletedQuestDate = quest.userStat.updatedAt;
            }
        });

        return formatDate(lastCompletedQuestDate);
    }

    function renderInfo() {
        if (challenge.hasEnded && challenge.winners?.length > 0) {
            return <div>
                <h4>📣 Prize Pool Winners</h4>
                <div className="brand-challenge_prize_info">
                    Prize Pool winners have been drawn on {formatDate(challenge.end)}. Congratulations
                    to {challenge.winners.length} winners!
                    <ul className="reset-list brand-challenge_prize_winners">
                        {challenge.winners.map(winner =>
                            <li key={winner.id} className="brand-challenge_prize_winner">
                                <div className="brand-challenge_prize_winner_img-container">
                                    <img src={winner.user.gravatar} alt="challenge winner"/>
                                </div>
                                <h5>{winner.user.nickname || "GoVIP Player"}</h5>
                                <Spacer/>
                                {challenge.prizes.find(prize => prize.id === winner.prizeWon)?.name}
                            </li>)}
                    </ul>
                </div>
            </div>;
        }

        const progressPercentage = quests.length > 0 ? completedQuestsCount / quests.length * 100 : 0;
        return <>
            <div>
                <h4>Unlock Your Prize Pool Entry 🔥</h4>
                <div className="brand-challenge_prize_info">
                    Enter for a chance to win a prize by completing the challenge quests:
                    <ul className="reset-list">
                        {challenge.prizes.map((prize, i) =>
                            <li key={prize.id}>Prize #{i + 1}: {prize.name} x {prize.quantity}</li>)}
                    </ul>
                </div>

                <div className="brand-challenge_prize_summary">
                    <div className="brand-challenge_prize_summary_info">
                        <h5>
                            <img src={`${import.meta.env.VITE_CDN_URL}/images/brand-challenge-winners-icon.svg`} alt="winners"/>
                            {challenge.prizes.reduce((acc, prize) => acc + prize.quantity, 0)}
                        </h5>
                        Winners
                    </div>
                    <div className="brand-challenge_prize_summary_divider"/>
                    <div className="brand-challenge_prize_summary_info">
                        <h5><CgProfile/>{challenge.numberOfEntrants}</h5>
                        Entrants
                    </div>
                </div>
            </div>

            {/* bar */}
            <div className="brand-challenge_prize_progress">
                <div className="brand-challenge_prize_progress_header">
                    <h5>Quests</h5>
                    <div className="brand-challenge_prize_progress_header_progress">
                        <h5>{completedQuestsCount}</h5>
                        / {quests.length} Completed
                    </div>
                </div>

                <div className="go-vip-ow_quest_milestones_progress">
                    {Array.from({length: quests.length - 1}, (_, i) =>
                        <div
                            className={classNames("go-vip-ow_quest_milestones_progress_marker", {
                                "completed": completedQuestsCount >= i + 1,
                            })}
                            key={`marker-${i}`}
                        />)}
                    <div
                        className="go-vip-ow_quest_milestones_progress_bar"
                        style={{width: `${progressPercentage}%`}}
                    />
                </div>
            </div>
        </>;
    }

    return (
        <div className="brand-challenge_prize">
            {/* carousel */}
            <ChallengePrizeImageCarousel prizes={challenge.prizes}/>
            {renderInfo()}
            {/* status    */}

            <div className="brand-challenge_prize_status">
                {quests.length > completedQuestsCount && !challenge.hasEnded
                    ? <>
                        <FaExclamationTriangle size="1rem"/>
                        Complete challenge quests to enter
                    </>
                    : quests.length <= completedQuestsCount && <>
                        <IoMdCheckmark size="1.25rem" strokeWidth="1rem"/>
                        You've entered in the pool on {getLastCompletedQuestDate()}
                    </>}
            </div>
        </div>
    );
}