import {ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {MenuOrder, MenuOrderItem} from "@devour/client";
import moment from "moment";
import OrderReceiptMenuItem from "../OrderReceiptMenuItem";
import MenuOrderCheckoutTotals from "../menuOrder/MenuOrderCheckoutTotals";
import Accordion from "../Accordion";
import Tag from "../Tag";
import { getOrderStatusTag } from "@/pages/menu-orders/MenuOrderListPage";
import FrameOneAutoPanel from "./autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "./autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "./autoPanelComponents/FrameAutoPanelBody";
import classNames from "classnames";
import formatDateToHumanReadable from "@/utils/formatDateToHumanReadable";
import { useRestaurant } from "@/hooks/useRestaurant";
import Skeleton from "react-loading-skeleton";


interface Props {
    isOpen: boolean;
    order: MenuOrder;
    onClose: () => void;
}

function OrderReceiptModal(props: Props): ReactElement {
    const headerRef = useRef<HTMLDivElement>(null);
    const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);
    const [isSticky, setIsSticky] = useState(false);
    const { data: restaurant, isLoading: isLoadingRestaurant } = useRestaurant(props.order?.business);
    const restaurantTimeZone = restaurant?.timeZone || moment.tz.guess();
    const sumQuantity: number = props.order?.orderItems.reduce((accumulator, object) => {
        return accumulator + object.quantity;
    }, 0);


    function renderOrderItem(item: MenuOrderItem, i: number): ReactNode {
        return (
            <OrderReceiptMenuItem
                item={item}
                key={`receipt-item_${i}`}
            />
        );
    }

    function getFormattedDate() {
        const { date, time} = formatDateToHumanReadable(props.order.createdAt, restaurantTimeZone);
        return `${date} at ${time}`;
    }

    const handleSticky = () => {
        setTimeout(() => {
            if (bodyRef === null) return;
            setIsSticky(bodyRef.scrollTop > 0);
        }, 180);
    };


    useEffect(() => {
        if (!bodyRef) return;
        bodyRef.addEventListener("scroll", handleSticky);
        handleSticky();
        return () => {
            bodyRef.removeEventListener("scroll", handleSticky);
        };
    }, [bodyRef, props.isOpen]);

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            modalOnTablet={true}
            size="xs2"
            contentClassName={classNames("order-receipt-modal", {
                "sticky": isSticky,
            })}
        >
            <FrameAutoPanelHeader
                title={`Order #${props.order?.orderUuid || props.order?.pickupCode}`}
                toggle={props.onClose}
            >
                <div className="order-receipt-modal_body_sub-header" ref={headerRef}>
                    <Tag rounded {...getOrderStatusTag(props.order)}/>
                    <span className="order-receipt-modal_body_sub-header_time">
                        {"  ● "}  {isLoadingRestaurant ? <Skeleton count={1}/> : getFormattedDate()}
                    </span>
                </div>
            </FrameAutoPanelHeader>

            <FrameAutoPanelBody className="order-receipt-modal_body" modalBodyRef={(body) => {
                setBodyRef(body);
            }}>

                <div className="order-receipt-modal_body_order-items">
                    <Accordion
                        header={<p>{sumQuantity ?? 0} {sumQuantity === 1
                            ? "Item"
                            : "Items"}</p>}
                        body={
                            <>
                                {props.order.orderItems.map(renderOrderItem)}
                            </>
                        }
                        showDivider={true}
                        showBorder={false}
                        showChevron={true}
                    />
                </div>
                <MenuOrderCheckoutTotals
                    menuOrder={props.order}
                />

                {/* <div className="order-receipt-modal_body_values">*/}
                {/*	{renderCalculationKeyValuePair("Subtotal", "$" + props.order.subtotal.toFixed(2))}*/}
                {/*	{renderCalculationKeyValuePair("Delivery fee", "$" + props.order.deliveryCharge.toFixed(2))}*/}
                {/*	{renderCalculationKeyValuePair("Taxes", "$" + props.order.tax.toFixed(2))}*/}
                {/*	{renderCalculationKeyValuePair("Restaurant Tip", "$" + props.order.tipRestaurant.toFixed(2))}*/}
                {/*	{renderCalculationKeyValuePair("Driver Tip", "$" + props.order.tipDelivery.toFixed(2))}*/}
                {/* </div>*/}

                {/* <div className="order-receipt-modal_body_total-row">*/}
                {/*	<span className="order-receipt-modal_body_total-row_label">*/}
                {/*		Total*/}
                {/*	</span>*/}

                {/*	<span className="order-receipt-modal_body_total-row_value">*/}
                {/*		${props.order.stripeTotal.toFixed(2)}*/}
                {/*	</span>*/}
                {/* </div>*/}
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default OrderReceiptModal;
