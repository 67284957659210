import {ReactElement, useEffect, useState} from "react";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import {IoCloseSharp} from "react-icons/io5";
import RedeemLootboxButton from "@/components/RedeemLootboxButton";


interface Props {
    isOpen: boolean;
    contractAddress: string;
    nftId: number;
    onTransactionSubmit: () => void;
    onTransactionConfirmed: (transactionHash: `0x${string}`) => void;
    toggle: () => void;
}

function GoVipRedeemNftConfirmModal(props: Props): ReactElement {
    const [
        disclaimerChecked,
        setDisclaimerChecked,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (props.isOpen) {
            setDisclaimerChecked(false);
        }
    }, [props.isOpen]);

    function onDisclaimerToggle() {
        setDisclaimerChecked(s => !s);
    }

    return (
        <FrameOneModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            contentClassName="govip-nft-redeem-confirm-modal"
        >
            <FrameModalBody className="govip-nft-redeem-confirm-modal_body">
                <div className="govip-nft-redeem-confirm-modal_body_close-wrapper">
                    <IoCloseSharp onClick={() => props.toggle()}/>
                </div>

                <div className="govip-nft-redeem-confirm-modal_body_content">
                    <AiOutlineExclamationCircle />
                    <div className="govip-nft-redeem-confirm-modal_body_content_header">
                        Redeem Loot Box NFT?
                    </div>
                    <div className="govip-nft-redeem-confirm-modal_body_content_message">
                        Redeeming will burn the NFT and be removed from your DevourGO Wallet. Please check the agreement
                        below to continue and claim the prize.
                    </div>
                </div>

                <FrameOneCheckbox
                    onToggle={onDisclaimerToggle}
                    checked={disclaimerChecked}
                    background={"purple"}
                    className="govip-nft-redeem-confirm-modal_body_checkbox"
                >
                    I understand and consent that redeeming my NFT from the DevourGO wallet will lead to the permanent
                    removal of the NFT from the wallet.
                </FrameOneCheckbox>

                <RedeemLootboxButton
                    nftId={props.nftId}
                    contractAddress={props.contractAddress}
                    onTransactionSubmit={props.onTransactionSubmit}
                    onTransactionConfirmed={props.onTransactionConfirmed}
                    disabled={!disclaimerChecked}
                />

            </FrameModalBody>
        </FrameOneModal>
    );
}

export default GoVipRedeemNftConfirmModal;