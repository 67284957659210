import {useNavigate} from "react-router";
import useThemePreference from "@/hooks/useThemePreference";
import GoFrensLeaderboardRow from "@/components/goFrens/GoFrensLeaderboardRow";
import FrameButton from "@/components/buttons/FrameButton";
import {ButtonHTMLAttributes} from "react";

interface Props {
    isCurrentAllTime?: boolean;
    isModal?: boolean;
}

export default function GuildMembersLeaderboardEmpty(props: Props) {
    const history = useNavigate();
    const {isOnDarkMode, getThemedImageUrl} = useThemePreference();

    return (
        <div className="my-community-leaderboard_top-members-unranked">
            <div className="my-community-leaderboard_top-members-unranked_header">
                <img src={`${import.meta.env.VITE_CDN_URL}/images/gofrens-top-members-empty-state.webp`} alt="Empty state logo"/>
                <div className="my-community-leaderboard_top-members-unranked_header_text-content">
                    <h4>{props.isCurrentAllTime ? "Become a Top Member!" : "Whoops, there's no records..."}</h4>
                    <p>
                        {props.isCurrentAllTime
                            ? "We are waiting for guild members to be listed on this leaderboard. Start earning XP to become a Top Member!"
                            : "We couldn't find any records of top members of the community for this month. Please select another month to view the top members."
                        }
                    </p>
                </div>
            </div>
            {props.isCurrentAllTime &&
                <GoFrensLeaderboardRow
                    rank={1}
                    profile={{
                        name: "You",
                        photo: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/gofrens-top-members-empty-state-profile.webp`),
                    }}
                    accumulatedPoints="100 XP"
                    className="gofrens-leaderboard-row_top-members-unranked"
                    bgColor={isOnDarkMode ? "DM-light-gray" : "light-gray"}
                />
            }
            {props.isCurrentAllTime && !props.isModal && <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                size="normal"
                color={isOnDarkMode ? "purple" : "purple-blue-gradient"}
                onClick={() => history("/go-vip-dashboard")}
            >
                Get Started
            </FrameButton>}
        </div>
    );
}