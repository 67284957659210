import {OWReward} from "@devour/client";
import useGetOverwolfGameInfo from "@/hooks/useGetOverwolfGameInfo";
import {useAsset} from "@/hooks/useAsset";
import ChallengeQuest from "@/pages/challenge/ChallengeQuest";

interface Props {
    quests: Array<OWReward>;
    gameId: string;
}
export default function GameChallengeQuests(props: Props) {
    const {data: game} = useGetOverwolfGameInfo(props.gameId);
    const {data: bannerImageUrl} = useAsset(game?.bannerImageLarge);
    const {data: logoImageUrl} = useAsset(game?.logo);

    return (
        <div className="brand-challenge_game">
            <div className="brand-challenge_game_header">
                {bannerImageUrl && <img
                    src={bannerImageUrl.url}
                    alt={game?.name}
                    className="brand-challenge_game_header_bg"
                />}

                {!props.gameId && <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/ow-time-govip-bg.webp`}
                    alt={game?.name}
                    className="brand-challenge_game_header_bg"
                />}
                <div className="brand-challenge_game_header_content">
                    {props.gameId
                        ? <img src={logoImageUrl?.url} alt={game?.name}/>
                        : <h3>OTHER GAMES</h3>
                    }
                    <div>Quests</div>
                </div>
            </div>
            <div className="brand-challenge_game_body">
                {props.quests.map((quest) =>
                    <ChallengeQuest quest={quest} key={quest.id}/>)}
            </div>
        </div>
    );
}