import {ReactElement, useContext} from "react";
import {FaArrowLeft} from "react-icons/fa6";
import {useNavigate} from "react-router";
import {isDesktop} from "react-device-detect";
import {useParams} from "react-router";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useRestaurant} from "@/hooks/useRestaurant";

function CheckoutPageBackLink(): ReactElement {
    const {menuOrderId} = useParams<{ menuOrderId: string; }>();
    const {data: menuOrder} = useMenuOrder(menuOrderId);
    const {setBrandMapState} = useContext(BrandMapContext);
    const {embeddedMenu} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(menuOrder?.business);

    const navigate = useNavigate();

    if (!isDesktop) return null;

    return (
        isDesktop &&
        <div
            className="checkout-page_back-link"
            onClick={() => {
                if (menuOrderId) {
                    navigate(`${restaurant?.url}/${menuOrder.address.placeId}`);
                } else if (embeddedMenu) {
                    setBrandMapState(BrandMapStates.ORDER_IN_PROGRESS);
                }
            }}
        >
            <FaArrowLeft className="checkout-page_back-link_arrow"/>
            <p>Back to {embeddedMenu
                ? "Menu"
                : "Store"}</p>
        </div>
    );
}

export default CheckoutPageBackLink;

