import {ButtonHTMLAttributes, ReactElement, useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useParams} from "react-router";
import {useNavigate} from "react-router";
import {decrementModalCount, removeMenuOrder} from "@/redux/meta/metaActions";
import FrameButton from "../../components/buttons/FrameButton";
import FrameOneModal from "../../components/modals/modalComponents/FrameOneModal";
import FrameModalHeader from "../../components/modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../../components/modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../../components/modals/modalComponents/FrameModalFooter";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";


interface Props {
    menuOrderId?: string;
    onModalClose?: () => void;
}

function MenuOrderFailPage(props: Props): ReactElement {
    const { embeddedMenu, setCheckoutState} = useContext(RestaurantContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let {menuOrderId} = useParams<{ menuOrderId: string }>();
    menuOrderId = menuOrderId || props.menuOrderId;
    const menuOrders = useSelector((store: IStore) => store.metaStore.menuOrders);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);

    /**
	 * If order id exists, call the api to get details. If it doesn't exist, re-direct to order history.
	 *
	 */
    useEffect(() => {
        if (!menuOrderId) {
            navigate("/menu-orders");
        } else {
            removeMenuOrderRedux();
        }
    }, [menuOrderId]);

    /**
	 * Remove the just-failed order from the redux store.
	 *
	 */
    function removeMenuOrderRedux(): void {
        for (const restaurantId in menuOrders) {
            if (menuOrders[restaurantId] === menuOrderId) {
                dispatch(removeMenuOrder(restaurantId));
            }
        }
    }

    async function handleOrderFoodRouteForAuthedUser(): Promise<void> {
        if (props.onModalClose) {
            dispatch(decrementModalCount());
            props.onModalClose();
            return;
        }

        if (embeddedMenu) {
            setCheckoutState(BrandMapStates.ORDER_IN_PROGRESS);
            return;
        }

        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }
    }

    return (
        <div className="menu-order-fail-page">
            <FrameOneModal
                isOpen={true}
                size="xs2"
                contentClassName="order-unavailable-modal"
            >
                <FrameModalHeader
                    title={"Order Unavailable"}
                />

                <FrameModalBody className="order-unavailable-modal_body">
                    <p>
						Sorry, we are unable to submit this order to the merchant right now.
						We will cancel and refund your original form of payment.
						Please try ordering from another nearby restaurant.
                    </p>
                </FrameModalBody>
                <FrameModalFooter>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        onClick={handleOrderFoodRouteForAuthedUser}
                        color="gray"
                        size="normal"
                    >
						Okay
                    </FrameButton>
                </FrameModalFooter>
            </FrameOneModal>
        </div>
    );
}

export default MenuOrderFailPage;
