/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    AddressBody,
    AddressBodyFromJSON,
    AddressBodyToJSON,
    AddressBook,
    AddressBookFromJSON,
    AddressBookToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    AutoCompleteStatusResponse,
    AutoCompleteStatusResponseFromJSON,
    AutoCompleteStatusResponseToJSON,
    CreateAddressBookBody,
    CreateAddressBookBodyFromJSON,
    CreateAddressBookBodyToJSON,
} from '../models';

export interface CreateAddressBookRequest {
    createAddressBookBody?: CreateAddressBookBody;
}

export interface DeleteAddressBookRequest {
    id: string;
    address?: Address;
}

export interface UpdateAddressBookRequest {
    id: string;
    createAddressBookBody?: CreateAddressBookBody;
}

/**
 * AddressBooksApi - interface
 * @export
 * @interface AddressBooksApiInterface
 */
export interface AddressBooksApiInterface {
    /**
     * Used to get the status of an autocomplete. This will return a response of whether to use the autocomplete or manual form.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressBooksApiInterface
     */
    autocompleteStatusRaw(): Promise<runtime.ApiResponse<AutoCompleteStatusResponse>>;

    /**
     * Used to get the status of an autocomplete. This will return a response of whether to use the autocomplete or manual form.
     */
    autocompleteStatus(): Promise<AutoCompleteStatusResponse>;

    /**
     * Used by user to create a new address in their book
     * @param {CreateAddressBookBody} [createAddressBookBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressBooksApiInterface
     */
    createAddressBookRaw(requestParameters: CreateAddressBookRequest): Promise<runtime.ApiResponse<AddressBook>>;

    /**
     * Used by user to create a new address in their book
     */
    createAddressBook(requestParameters: CreateAddressBookRequest): Promise<AddressBook>;

    /**
     * Used by user to remove an existing address from their book
     * @param {string} id 
     * @param {Address} [address] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressBooksApiInterface
     */
    deleteAddressBookRaw(requestParameters: DeleteAddressBookRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by user to remove an existing address from their book
     */
    deleteAddressBook(requestParameters: DeleteAddressBookRequest): Promise<void>;

    /**
     * Used by user to get all addresses in their book
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressBooksApiInterface
     */
    getAddressBooksRaw(): Promise<runtime.ApiResponse<Array<AddressBook>>>;

    /**
     * Used by user to get all addresses in their book
     */
    getAddressBooks(): Promise<Array<AddressBook>>;

    /**
     * Used by user to update an existing address in their book
     * @param {string} id 
     * @param {CreateAddressBookBody} [createAddressBookBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressBooksApiInterface
     */
    updateAddressBookRaw(requestParameters: UpdateAddressBookRequest): Promise<runtime.ApiResponse<AddressBook>>;

    /**
     * Used by user to update an existing address in their book
     */
    updateAddressBook(requestParameters: UpdateAddressBookRequest): Promise<AddressBook>;

}

/**
 * no description
 */
export class AddressBooksApi extends runtime.BaseAPI implements AddressBooksApiInterface {

    /**
     * Used to get the status of an autocomplete. This will return a response of whether to use the autocomplete or manual form.
     */
    async autocompleteStatusRaw(): Promise<runtime.ApiResponse<AutoCompleteStatusResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/autocomplete-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AutoCompleteStatusResponseFromJSON(jsonValue));
    }

    /**
     * Used to get the status of an autocomplete. This will return a response of whether to use the autocomplete or manual form.
     */
    async autocompleteStatus(): Promise<AutoCompleteStatusResponse> {
        const response = await this.autocompleteStatusRaw();
        return await response.value();
    }

    /**
     * Used by user to create a new address in their book
     */
    async createAddressBookRaw(requestParameters: CreateAddressBookRequest): Promise<runtime.ApiResponse<AddressBook>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/address-books`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAddressBookBodyToJSON(requestParameters.createAddressBookBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressBookFromJSON(jsonValue));
    }

    /**
     * Used by user to create a new address in their book
     */
    async createAddressBook(requestParameters: CreateAddressBookRequest): Promise<AddressBook> {
        const response = await this.createAddressBookRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by user to remove an existing address from their book
     */
    async deleteAddressBookRaw(requestParameters: DeleteAddressBookRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAddressBook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/address-books/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by user to remove an existing address from their book
     */
    async deleteAddressBook(requestParameters: DeleteAddressBookRequest): Promise<void> {
        await this.deleteAddressBookRaw(requestParameters);
    }

    /**
     * Used by user to get all addresses in their book
     */
    async getAddressBooksRaw(): Promise<runtime.ApiResponse<Array<AddressBook>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/address-books`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressBookFromJSON));
    }

    /**
     * Used by user to get all addresses in their book
     */
    async getAddressBooks(): Promise<Array<AddressBook>> {
        const response = await this.getAddressBooksRaw();
        return await response.value();
    }

    /**
     * Used by user to update an existing address in their book
     */
    async updateAddressBookRaw(requestParameters: UpdateAddressBookRequest): Promise<runtime.ApiResponse<AddressBook>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAddressBook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/address-books/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAddressBookBodyToJSON(requestParameters.createAddressBookBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressBookFromJSON(jsonValue));
    }

    /**
     * Used by user to update an existing address in their book
     */
    async updateAddressBook(requestParameters: UpdateAddressBookRequest): Promise<AddressBook> {
        const response = await this.updateAddressBookRaw(requestParameters);
        return await response.value();
    }

}
