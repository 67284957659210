import FrameButton from "@/components/buttons/FrameButton";
import LoginModal from "@/components/modals/LoginModal.tsx";
import useThemePreference from "@/hooks/useThemePreference";
import {store} from "@/redux";
import {IStore} from "@/redux/defaultStore";
import {decrementModalCount, toggleSidebar} from "@/redux/meta/metaActions";
import {ThemePreference} from "@/types/Theme";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import GoVipOWDualRewardingGames from "@/components/goVip/GoVipOWDualRewardingGames";
import GoVipOWTimeBasedGames from "@/components/goVip/GoVipOWTimeBasedGames";
import {isDesktop, isTablet} from "react-device-detect";
import useWindowSize from "@/hooks/useWindowSize";
import OWComingSoonGames from "@/components/devourPlay/OWComingSoonGames";
import classNames from "classnames";
import OWFooter from "@/components/devourPlay/OWFooter";
import OWDiscoverEarnXPModal from "@/components/devourPlay/OWDiscoverEarnXPModal";
import { OverwolfLevelRewardsCarousel } from "./components/OverwolfLevelRewardsCarousel";
import OwIntroductionModal from "@/components/devourPlay/OWIntroductionModal";
import GoVipSpin2WinModal from "@/components/modals/GoVipSpin2WinModal";

const DEVOUR_CDN = import.meta.env.VITE_CDN_URL;

export default function OverwolfDiscoverPage() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const dispatch = useDispatch();
    const windowSize = useWindowSize()[0];
    const isOnMobile = !isDesktop && !isTablet || windowSize < 600;
    const [showWaysToEarnXPModal, setShowWaysToEarnXPModal] = useState<boolean>(false);
    const [showIntroModal, setShowIntroModal] = useState<boolean>(false);
    const [showSpinModal, setShowSpinModal] = useState<boolean>(false);

    // Close the sidebar menu if open
    useEffect(() => {
        dispatch(toggleSidebar(false));
    }, []);

    const getHeaderBannerImageUrl = () => {
        let screenSize: string;

        if (isOnMobile) {
            screenSize = "SM";
        } else {
            screenSize = "LG";
        }
        return `${DEVOUR_CDN}/images/OW-Discover-Header-Bnr-${screenSize}.webp`;
    };

    const handlePlayOrLogin = (fullToken) => {
        if (fullToken) {
            toggleSpinModal();
        } else {
            setIsLoginModalOpen(true);
        }
    };

    // Render the "Ready to Devour & Play" Banner
    const renderDevourAndPlayBanner = (className?: string) =>
        <div className={classNames("overwolf-discover-page_infos_col2", className)}>
            <div
                className="overwolf-discover-page_infos_col2_img"
                style={{
                    backgroundImage: `url(${DEVOUR_CDN}/images/OW-Discover-Onboarding-Bnr.webp)`,
                }}
            />
            <div className="overwolf-discover-page_infos_col2_text">
                <span className="col2-welcome">Welcome</span>
                <span className="col2-title">
          READY TO DEVOUR <span className="col2-title and">&</span> PLAY?
                </span>
                <span className="col2-caption">
          Learn how DevourPlay works to get you started on your journey!
                </span>
                <FrameButton
                    size="normal"
                    color="devourplay-purple"
                    className="overwolf-discover-page_infos_col2_btn"
                    onClick={() => setShowIntroModal(true)}
                >
                    Let's Go!
                </FrameButton>
            </div>
        </div>;

    // Render the Header
    const renderHeader = () =>
        <div
            className="overwolf-discover-page_header-bnr"
            style={{
                backgroundImage: `url(${getHeaderBannerImageUrl()})`,
            }}
        >
            {/* Header Title */}
            <span className="overwolf-discover-page_header-bnr_title">
                {isOnMobile ? "WELCOME" : "WELCOME PLAYER!"}
            </span>

            {/* Description */}
            <span className="overwolf-discover-page_header-bnr_description">
                We are an in-game app that integrates food ordering and gaming sessions into{" "}
                {!isOnMobile && "rewards — converting gameplay into"} real-world benefits.
            </span>

            {/* Login Button */}
            <FrameButton
                color="devourplay-gradient"
                size="normal"
                className="overwolf-discover-page_login-btn overwolf-discover-page_button_purple-blue-gradient-shadow"
                onClick={() => handlePlayOrLogin(fullToken)}
            >
                {fullToken ? "Spin the Wheel" : "Login or Sign up"}
            </FrameButton>
        </div>;

    function toggleSpinModal(): void {
        dispatch(decrementModalCount());
        setShowSpinModal(!showSpinModal);
    }

    return (
        <>
            <GoVipSpin2WinModal
                isOpen={showSpinModal}
                toggle={toggleSpinModal}
            />

            <OwIntroductionModal
                isOpen={showIntroModal}
                onClose={() => setShowIntroModal(false)}
            />

            <OWDiscoverEarnXPModal
                isOpen={showWaysToEarnXPModal}
                onClose={() => setShowWaysToEarnXPModal(false)}
            />

            <div
                className="overwolf-discover-page"
            >
                <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)}/>

                {/* HEADER SECTION */}
                {renderHeader()}

                {/* NON HEADER - REST OF THE PAGE CONTENT */}
                <div className="overwolf-discover-page_container">

                    <div className="overwolf-discover-page_infos">
                        {/* COLUMN 1 - VIDEO COLUMN */}
                        <div className="overwolf-discover-page_infos_col1">

                            <video
                                className="overwolf-discover-page_infos_col1_video"
                                autoPlay
                                muted
                                playsInline
                                loop
                                controls
                            >
                                <source src={`${DEVOUR_CDN}/videos/OW-Discover-Video.mp4`}
                                    type="video/mp4"/>
                            </video>

                            {isOnMobile && renderDevourAndPlayBanner()}

                            <div
                                className="overwolf-discover-page_infos_col1_xp"
                                onClick={() => setShowWaysToEarnXPModal(true)}
                            >
                                <div className="overwolf-discover-page_infos_col1_xp_text">
                                    <span className="xp-title">5 Ways to Earn XP</span>
                                    <span className="xp-caption">Discover How to Earn XP</span>
                                </div>
                                <div className="right"
                                    style={{
                                        backgroundImage: `url(${DEVOUR_CDN}/images/OW-Discover-Right.png)`,
                                    }}
                                />
                            </div>
                        </div>

                        {/* COLUMN 2 (only desktop) - How to Devour and Play Banner */}
                        {renderDevourAndPlayBanner(classNames({"hide": isOnMobile}))}
                    </div>

                    {/* TEXTURE */}
                    <div className="overwolf-discover-page_texture" style={{
                        backgroundImage: `url(${DEVOUR_CDN}/images/devourplay-texture.webp)`,
                    }}>
                        <img src={`${DEVOUR_CDN}/images/OW-Discover-Down.png`}
                            alt="discover-page-down"
                            className="down"
                        />
                    </div>

                    {/* LEVEL REWARDS SECTION */}
                    {/* TODO: Implement Level Rewards Section here*/}
                    <div className="overwolf-discover-page_lvl-rewards">
                        <div className="overwolf-discover-page_lvl-rewards_heading">
                            <h2 >LEVEL REWARDS</h2>
                            <p>Level up to get food discounts, Fuel tokens, merch items, or loot boxes with epic prizes!</p>
                        </div>
                        <OverwolfLevelRewardsCarousel/>
                    </div>

                    {/* GAMES SECTION */}
                    <div className="go-vip-overwolf">
                        <GoVipOWTimeBasedGames isOWDiscoverPage/>

                        <GoVipOWDualRewardingGames isOWDiscoverPage/>

                        <OWComingSoonGames/>
                    </div>

                    {/* FOOTER */}
                    <div className="overwolf-discover-page_footer">
                        <OWFooter/>
                    </div>
                </div>
            </div>
        </>
    );
}
