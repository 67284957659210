import {ReactElement, MouseEvent, useContext} from "react";
import {MenuItemSearchEssential} from "@devour/client";
import classNames from "classnames";
import {htmlDecode} from "@/utils/htmlDecode";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";

interface Props {
    menuItem: MenuItemSearchEssential;
}

function ConvenienceMenuItemCardPreload(props: Props): ReactElement {

    const {setActiveMenuItemId} = useContext(RestaurantContext);

    function cardOnClick(event: MouseEvent<HTMLDivElement>): void {
        event.stopPropagation();
        setActiveMenuItemId?.(props.menuItem.id || "");
    }

    if (!props.menuItem) {
        return null;
    }
    return (

        <>

            <div className="convenience-menu-item-card" key={props.menuItem.id} onClick={cardOnClick}>
                <div style={{position: "relative", minHeight: "50%"}}>
                    <div className="convenience-menu-item-card_image">
                        <div className="convenience-menu-item-card_image_inner">
                            <img
                                src={props.menuItem.image}
                                alt={props.menuItem?.name}
                                style={{marginBottom: "10px", borderRadius: "10px"}}
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = `${import.meta.env.VITE_CDN_URL}/images/empty.png`;
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="convenience-menu-item-card_price">${props.menuItem.price.toFixed(2)}</div>
                <div title={props.menuItem.name} className="convenience-menu-item-card_name">{props.menuItem.name}</div>
            </div>

            {/* <div
                className={classNames("menu-item-card", {
                    "is-enabled": restaurant.isOpen && props.menuItem.isEnabled,
                    "is-lock-nft": lockNftGroupings,
                    "is-hover-nft": hoverNftGroupings,
                })}
                onClick={cardOnClick}
            >

                <div className="menu-item-card_left">
                    {props.menuItem.nftGroupings?.length > 0 &&
                    <div className="menu-item-card_secret-item">
						    <FrameButton
						        <ButtonHTMLAttributes<HTMLButtonElement>>
						        onClick={toggleLockNftGroupings}
						        color="purple"
						        size="pill"
						        forwardProps={{
						            onMouseEnter: toggleHoverNftGroupings,
						            onMouseLeave: toggleHoverNftGroupings,
						        }}
						    >
								Secret
						    </FrameButton>
                    </div>
                    }
                    <div>
                        <div className="menu-item-card_header">
                            <div className="menu-item-card_name">
                                {htmlDecode(props.menuItem?.name)}
                            </div>
                            {props.menuItem.nftGroupings?.length > 0 &&
                            <button
								    title="Secret item NFT requirements"
								    className="reset-button"
								    onClick={toggleLockNftGroupings}
								    onMouseEnter={toggleHoverNftGroupings}
								    onMouseLeave={toggleHoverNftGroupings}
                            >
								    <FaAngleDown/>
                            </button>
                            }
                        </div>

                        <div className="menu-item-card_description">
                            {htmlDecode(props.menuItem.description)}
                        </div>
                    </div>

                    <div className={classNames("menu-item-card_price", {
                        "is-free": props.menuItem.price <= 0,
                    })}
                    >
                        {props.menuItem.price > 0
                            ? `$${props.menuItem.price.toFixed(2)}`
                            : "$0.00"}
                    </div>

                    <MenuItemCardNftGroupings
                        menuItem={props.menuItem}
                    />

                </div>

            </div> */}
        </>
    );
}

export default ConvenienceMenuItemCardPreload;
