import { useGetChallenges } from "@/hooks/useGetChallenges";
import { OverwolfGoVipChallengeCard } from "./OverwolfGoVipChallengesCard";
import { IStore } from "@/redux/defaultStore";
import { useSelector } from "react-redux";
import useWindowSize from "@/hooks/useWindowSize";
import {BiChevronLeft, BiChevronRight } from "react-icons/bi";
import {useRef, useState, useMemo, useEffect, useCallback} from "react";
import DragScroll from "@/components/DragScroll";

export const OverwolfGoVipChallenges = () => {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const { data: challengesData, isFetching: isFetchingChallenges } = useGetChallenges({
        limit: Number.MAX_SAFE_INTEGER,
        offset: 0,
        userId: currentUser?.id,
    });
    const windowSize = useWindowSize()[0];
    const isOnSmallScreen = windowSize < 768;
    const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
    const scrollLeftRef = useRef<number>(0);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const handleScrollContainerRef = useCallback((node: HTMLDivElement | null) => setScrollContainer(node), []);

    useEffect(() => {
        if (!scrollContainer) return;
        updateScrollStates(); // Initial check
        scrollContainer.addEventListener("scroll", () => updateScrollStates());
        return () => scrollContainer.removeEventListener("scroll", () => updateScrollStates());
    }, [scrollContainer]);

    const challenges = useMemo(() => challengesData?.challenges ?? [], [challengesData]);

    function updateScrollStates(scrollLeft?: number) {
        if (scrollLeft !== undefined) {
            scrollLeftRef.current = scrollLeft;
        }
        const scrollRemaining = scrollContainer.scrollWidth - scrollContainer.clientWidth - scrollLeftRef.current;
        setCanScrollLeft(scrollLeftRef.current > 0);
        setCanScrollRight(scrollRemaining > 1); // 1px buffer
    }

    const scrollToNext = () => {
        if (!scrollContainer) return;
        const cardWidth = scrollContainer.firstElementChild?.getBoundingClientRect().width || 0;
        scrollLeftRef.current = scrollLeftRef.current + cardWidth + 16;
        scrollContainer.scrollTo({ left: scrollLeftRef.current, behavior: "smooth" });
    };

    const scrollToPrev = () => {
        if (!scrollContainer) return;
        const cardWidth = scrollContainer.firstElementChild?.getBoundingClientRect().width || 0;
        scrollLeftRef.current = Math.max(scrollLeftRef.current - cardWidth - 16, 0);
        scrollContainer.scrollTo({ left: scrollLeftRef.current, behavior: "smooth" });
    };

    // TODO: remove later for skeleton loading
    if (!challengesData?.challenges?.length) {
        return;
    }

    return (
        <div className="overwolf-go-vip-challenges_container" style={{ position: "relative" }}>
            <div className="overwolf-go-vip-challenges_heading">
                <div className="overwolf-go-vip-challenges_heading_info">
                    <h5>Challenges</h5>
                    <span>Complete the challenge or instant reward quest to earn exclusive rewards!</span>
                </div>
                <div className="overwolf-go-vip-challenges_heading_buttons">
                    <button
                        className="overwolf-go-vip-challenges_heading_buttons_button"
                        onClick={scrollToPrev}
                        disabled={!canScrollLeft}
                    >
                        <BiChevronLeft />
                    </button>
                    <button
                        className="overwolf-go-vip-challenges_heading_buttons_button"
                        onClick={scrollToNext}
                        disabled={!canScrollRight}
                    >
                        <BiChevronRight />
                    </button>
                </div>
            </div>

            {isFetchingChallenges || !challengesData
                ? <div className="overwolf-go-vip-challenges">
                    {Array.from({length: isOnSmallScreen ? 2 : 3}, (_, i) =>
                        <div className="overwolf-go-vip-challenges_card react-loading-skeleton" key={i}/>)}
                </div>
                : <DragScroll
                    className="overwolf-go-vip-challenges"
                    ref={handleScrollContainerRef}
                    onScroll={updateScrollStates}
                >
                    {challenges.map((challenge) =>
                        <OverwolfGoVipChallengeCard
                            key={challenge.id}
                            challenge={challenge}
                        />)}
                </DragScroll>
            }
        </div>
    );
};
