import {ReactElement, useEffect, useState} from "react";
import GoVipSpin2WinModal from "../modals/GoVipSpin2WinModal";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {getDateInEst} from "@/utils/getDateInEst";
import {isDesktop, isMobile} from "react-device-detect";
import classNames from "classnames";
import useThemePreference from "@/hooks/useThemePreference";
import { useLocation, useNavigate } from "react-router";
import getAnonymousSpin from "@/utils/getAnonymousSpin";

function GoVipSpin2Win(): ReactElement {

    const [
        showSpinModal,
        setShowSpinModal,
    ] = useState<boolean>(false);
    const [
        isSpinAvailable,
        setIsSpinAvailable,
    ] = useState<boolean>(false);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const anonymousSpin = useSelector((store: IStore) => store.metaStore.anonymousSpin);
    const { getThemedImageUrl } = useThemePreference();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("spinWheel") && !currentUser) {
            setShowSpinModal(true);
            navigate("/go-vip-dashboard");
        }
    }, [location, currentUser]);
    useEffect(() => {
        if (currentUser) {
            const lastSpinDate = currentUser?.user?.lastSpin
                ? getDateInEst(new Date(currentUser?.user?.lastSpin))
                : null;

            const currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
            const startOfToday = new Date(currentDate);
            startOfToday.setHours(0, 0, 0, 0);
            const spinEnabled: boolean = !lastSpinDate || lastSpinDate < startOfToday;
            setIsSpinAvailable(spinEnabled);
        } else {
            setIsSpinAvailable(!getAnonymousSpin());
        }
    }, [currentUser?.user?.lastSpin, currentUser, anonymousSpin]);

    useEffect(() => {
        const driftWidget = document.getElementById("drift-widget-wrapper");

        if (isSpinAvailable && driftWidget) {
            driftWidget?.classList.add("drift-frame-controller-hide");
        } else {
            driftWidget?.classList.remove("drift-frame-controller-hide");
        }

        return () => {
            driftWidget?.classList.remove("drift-frame-controller-hide");
        };
    }, [isSpinAvailable]);

    function toggleSpinModal(): void {
        setShowSpinModal(!showSpinModal);
    }

    return (
        <>
            <GoVipSpin2WinModal
                isOpen={showSpinModal}
                toggle={toggleSpinModal}
            />
            {isSpinAvailable &&
                <div
                    className={classNames(
                        "go-vip-spin2win",
                        {"go-vip-spin2win_mobile": isMobile},
                    )}
                >
                    {
                        isDesktop && <img
                            src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/spin2wheel-button-desktop.webp`)}
                            alt="Spin2Wheel Button on Desktop"
                            className="go-vip-spin2win_card_spin-button desktop"
                            onClick={toggleSpinModal}
                        />
                    }

                    {
                        isMobile && <img
                            src={getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/spin2wheel-button-mobile.webp`)}
                            alt="Spin2Wheel Button on Mobile"
                            className="go-vip-spin2win_card_spin-button mobile"
                            onClick={toggleSpinModal}
                        />
                    }
                </div>
            }
        </>
    );
}

export default GoVipSpin2Win;
