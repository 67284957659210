import useOnLogout from "@/hooks/useOnLogout";
import {useWalletDetailsModal, useActiveWalletConnectionStatus} from "thirdweb/react";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import useThemePreference from "@/hooks/useThemePreference";
import {base, mainnet, polygon} from "thirdweb/chains";
import {useDispatch} from "react-redux";
import {toggleReauthenticateModal} from "@/redux/meta/metaActions";

/**
 * Open Thirdweb details modal
 */
export default function useThirwebWallet() {
    const dispatch = useDispatch();
    const {isOnDarkMode} = useThemePreference();
    const detailsModal = useWalletDetailsModal();
    const thirdwebStatus = useActiveWalletConnectionStatus();
    const {devourLogout} = useOnLogout();

    function onOpenThirdwebWallet(): void {
        if (thirdwebStatus !== "connected") {
            dispatch(toggleReauthenticateModal(true));
            return;
        }

        detailsModal.open({
            client: thirdwebAuthClient,
            theme: isOnDarkMode ? "dark" : "light",
            chains: [mainnet, base, polygon],
            onDisconnect: () => devourLogout(),
        });
    }

    return {onOpenThirdwebWallet};
}
