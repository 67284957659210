/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChallengeReward,
    ChallengeRewardFromJSON,
    ChallengeRewardFromJSONTyped,
    ChallengeRewardToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChallengeBody
 */
export interface ChallengeBody {
    /**
     * The title of the challenge
     * @type {string}
     * @memberof ChallengeBody
     */
    name: string;
    /**
     * The title of the challenge on the card
     * @type {string}
     * @memberof ChallengeBody
     */
    cardTitle?: string;
    /**
     * The description of the challenge
     * @type {string}
     * @memberof ChallengeBody
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeBody
     */
    backgroundImageLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeBody
     */
    backgroundImageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeBody
     */
    brandLogo?: string;
    /**
     * Hex color code for the brand
     * @type {string}
     * @memberof ChallengeBody
     */
    brandColor?: string;
    /**
     * 
     * @type {Array<ChallengeReward>}
     * @memberof ChallengeBody
     */
    rewards: Array<ChallengeReward>;
    /**
     * UNIX timestamp for when the challenge starts
     * @type {number}
     * @memberof ChallengeBody
     */
    start: number;
    /**
     * UNIX timestamp for when the challenge ends
     * @type {number}
     * @memberof ChallengeBody
     */
    end: number;
    /**
     * The ID of the instant reward quest
     * @type {string}
     * @memberof ChallengeBody
     */
    instantRewardQuest?: string;
    /**
     * Whether the challenge is active
     * @type {boolean}
     * @memberof ChallengeBody
     */
    isActive?: boolean;
    /**
     * Whether to show a notification when the challenge is completed
     * @type {boolean}
     * @memberof ChallengeBody
     */
    showChallengeCompleteNotification?: boolean;
    /**
     * Whether to show a notification when the instant reward quest is completed
     * @type {boolean}
     * @memberof ChallengeBody
     */
    showInstantRewardQuestCompleteNotification?: boolean;
    /**
     * The message to show when the challenge is completed
     * @type {string}
     * @memberof ChallengeBody
     */
    challengeCompleteNotificationMessage?: string;
    /**
     * The message to show when the instant reward quest is completed
     * @type {string}
     * @memberof ChallengeBody
     */
    instantRewardQuestCompleteNotificationMessage?: string;
    /**
     * Terms and conditions URL
     * @type {string}
     * @memberof ChallengeBody
     */
    termsUrl?: string;
}

export function ChallengeBodyFromJSON(json: any): ChallengeBody {
    return ChallengeBodyFromJSONTyped(json, false);
}

export function ChallengeBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'cardTitle': !exists(json, 'cardTitle') ? undefined : json['cardTitle'],
        'description': json['description'],
        'backgroundImageLarge': !exists(json, 'backgroundImageLarge') ? undefined : json['backgroundImageLarge'],
        'backgroundImageSmall': !exists(json, 'backgroundImageSmall') ? undefined : json['backgroundImageSmall'],
        'brandLogo': !exists(json, 'brandLogo') ? undefined : json['brandLogo'],
        'brandColor': !exists(json, 'brandColor') ? undefined : json['brandColor'],
        'rewards': ((json['rewards'] as Array<any>).map(ChallengeRewardFromJSON)),
        'start': json['start'],
        'end': json['end'],
        'instantRewardQuest': !exists(json, 'instantRewardQuest') ? undefined : json['instantRewardQuest'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'showChallengeCompleteNotification': !exists(json, 'showChallengeCompleteNotification') ? undefined : json['showChallengeCompleteNotification'],
        'showInstantRewardQuestCompleteNotification': !exists(json, 'showInstantRewardQuestCompleteNotification') ? undefined : json['showInstantRewardQuestCompleteNotification'],
        'challengeCompleteNotificationMessage': !exists(json, 'challengeCompleteNotificationMessage') ? undefined : json['challengeCompleteNotificationMessage'],
        'instantRewardQuestCompleteNotificationMessage': !exists(json, 'instantRewardQuestCompleteNotificationMessage') ? undefined : json['instantRewardQuestCompleteNotificationMessage'],
        'termsUrl': !exists(json, 'termsUrl') ? undefined : json['termsUrl'],
    };
}

export function ChallengeBodyToJSON(value?: ChallengeBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'cardTitle': value.cardTitle,
        'description': value.description,
        'backgroundImageLarge': value.backgroundImageLarge,
        'backgroundImageSmall': value.backgroundImageSmall,
        'brandLogo': value.brandLogo,
        'brandColor': value.brandColor,
        'rewards': ((value.rewards as Array<any>).map(ChallengeRewardToJSON)),
        'start': value.start,
        'end': value.end,
        'instantRewardQuest': value.instantRewardQuest,
        'isActive': value.isActive,
        'showChallengeCompleteNotification': value.showChallengeCompleteNotification,
        'showInstantRewardQuestCompleteNotification': value.showInstantRewardQuestCompleteNotification,
        'challengeCompleteNotificationMessage': value.challengeCompleteNotificationMessage,
        'instantRewardQuestCompleteNotificationMessage': value.instantRewardQuestCompleteNotificationMessage,
        'termsUrl': value.termsUrl,
    };
}


