import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {truncateMiddle} from "@/utils/truncateMiddle";
import {FiX} from "react-icons/fi";
import {AiOutlinePlus} from "react-icons/ai";
import FrameButton from "@/components/buttons/FrameButton";
import {EvmWallet, Token, UsersApi, WalletType} from "@devour/client";
import WalletRemovalSuccessModal from "@/components/modals/WalletRemovalSuccessModal";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import WalletConnectRegistryModal from "@/components/modals/WalletConnectRegistryModal";
import useThemePreference from "@/hooks/useThemePreference";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";

function DevourWalletDropdownMenuBody(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData, refetch: refetchUserProfileData} = useGetUserProfile(fullToken);
    const { isOnDarkMode } = useThemePreference();
    const dispatch = useDispatch();

    const [
        showSuccess,
        setShowSuccess,
    ] = useState(false);
    const [
        showWalletConnectModal,
        setShowWalletConnectModal,
    ] = useState(false);

    const {refetch: refetchNftOwnerships} = useGetNftOwnerships(fullToken as Token);
    const {refetch: refetchNftOwnershipsForUser} = useGetNftOwnershipsForUser(fullToken as Token);

    function handleDismissSuccessModal(): void {
        setShowSuccess(false);
    }

    async function removeWallet(wallet: EvmWallet): Promise<void> {
        dispatch(incrementLoading());
        try {
            await new UsersApi(getConfig(fullToken)).removeWallet({
                removeWalletRequestBody: {
                    walletId: wallet.id,
                },
            });

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            await dispatch(updateCurrentUser(userRes));
            setShowSuccess(true);
            void refetchUserProfileData();
            void refetchNftOwnerships();
            void refetchNftOwnershipsForUser();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function renderWalletRow(): Array<ReactElement> {
        return userProfileData?.wallets?.map((wallet: EvmWallet, index: number) => {
            return (
                <div key={wallet.id} className="devour-top-nav_dropdown_wallet_content_row">
                    <FrameButton
                        key={`wallet-${index}`}
                        color="gray"
                        size="pill"
                        href={`https://opensea.io/${wallet.address}`}
                        className="devour-top-nav_dropdown_wallet_content_row_pill"
                    >
                        {truncateMiddle(wallet.address, 12)}
                    </FrameButton>
                    {wallet.type !== WalletType.MAGIC && wallet.type !== WalletType.THIRDWEB &&
                    <FiX
                        onClick={async () => await removeWallet(wallet)}
                        className="devour-top-nav_dropdown_wallet_content_row_icon"
                    />
                    }
                </div>
            );
        });
    }

    return (
        <>
            <WalletRemovalSuccessModal
                isOpen={showSuccess}
                onClose={handleDismissSuccessModal}
            />
            <WalletConnectRegistryModal
                isOpen={showWalletConnectModal}
                toggle={() => setShowWalletConnectModal(!showWalletConnectModal)}
            />

            <div className="devour-top-nav_dropdown_wallet_content">
                {renderWalletRow()}
                <hr />
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    forwardProps={{type: "button"}}
                    color={isOnDarkMode
                        ? "purple-text-no-border"
                        : "ghost"}
                    size="narrow"
                    leftIcon={AiOutlinePlus}
                    onClick={() => setShowWalletConnectModal(!showWalletConnectModal)}
                >
                    Wallet Connect
                </FrameButton>
            </div>
        </>
    );
}

export default DevourWalletDropdownMenuBody;
