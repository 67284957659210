import {ReactElement} from "react";
import {WalletId, injectedProvider} from "thirdweb/wallets";
import {ThirdWebWalletListItem} from "@/data/thirdwebWalletList";
import FrameButton from "@/components/buttons/FrameButton";
import useThemePreference from "@/hooks/useThemePreference";

/**
 * List of wallets that trigger on the DOM. So far only WalletConnect app known.
 * Will not trigger the DevourGO overlay spinner so user can still interact with the DOM.
 */
const walletsActivatedInDOM: Array<WalletId> = [
    "walletConnect",
    "com.walletconnect.com",
];

interface Props {
    wallet: ThirdWebWalletListItem;
    onSelect: (walletId: WalletId, loadingOverlay?: boolean) => void;
}

function WalletConnectListButton(props: Props): ReactElement {
    const activatesInDom = walletsActivatedInDOM.includes(props.wallet.id);
    const isWalletInstalled = injectedProvider(props.wallet.id);
    const {isOnDarkMode} = useThemePreference();

    return (
        <FrameButton
            color={isOnDarkMode
                ? "purple-gradient-dark"
                : "white-drop-shadow-devour"}
            size="normal"
            forwardProps={{type: "button"}}
            className="wallet-connect-list-button"
            onClick={() => props.onSelect(props.wallet.id, !activatesInDom)}
        >
            <span className="wallet-connect-list-button_image">
                {props.wallet.imageFile &&
										<img
										    src={props.wallet.imageFile}
										    alt={props.wallet.name}
										/>
                }
            </span>
            <span className="wallet-connect-list-button_details">
                <span className="wallet-connect-list-button_details_name">
                    {props.wallet.name}
                </span>
                {isWalletInstalled &&
										<span className="wallet-connect-list-button_details_installed">
												Installed
										</span>
                }
            </span>
        </FrameButton>
    );
}

export default WalletConnectListButton;
