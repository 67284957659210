import {ReactElement, useEffect, useState} from "react";
import SEO, {SEOProps} from "./SEO";
import {useLocation} from "react-router";
import {useRestaurant} from "@/hooks/useRestaurant";

/**
 * NOTE: Please update /meta/documentation/apis/seo.md as these values get updated.
 */

enum Pages {
    BASE = "BASE",
    SEARCH = "SEARCH",
    RESTAURANT = "RESTAURANT",
    LOGIN = "LOGIN",
    GOVIP = "GOVIP",
    GOFRIENDS = "GOFRIENDS",
}

const defaultSEOProps: SEOProps = {
    description: "DevourGo is a cutting-edge web3 food ordering and delivery marketplace, connecting you with local restaurants in your web3 community.",
    url: import.meta.env.VITE_WEB_URL,
    type: "text/css",
    title: "DevourGO – Play, earn, & unlock rewards while you game.",
    additionalKeywords: [
        "DevourGO",
        "Devour",
        "GO",
        "web3",
        "online food ordering",
        "mobile food ordering",
        "restaurants",
        "NFT",
        "The Industry Pass",
        "The Industry Collection",
    ],
};

function SEOHandler(): ReactElement {
    const location = useLocation();
    const [
        seoProps,
        setSEOProps,
    ] = useState<SEOProps>(defaultSEOProps);

    const paths: string[] = location.pathname.split("/").filter(Boolean);
    const length: number = paths.length;
    const isSearch: boolean = length === 1 || paths[1] === "search";
    const [
        restaurantID,
        placeID,
    ] =
        paths[0] === "restaurants" && !isSearch && (length === 3 || length === 7)
            ? [
                paths[length - 2],
                paths[length - 1],
            ] // restaurantId, placeId
            : paths[0] === "restaurants" && !isSearch && (length === 2 || length === 6)
                ? [
                    paths[length - 1],
                    undefined,
                ] // restaurantId
                : [
                    undefined,
                    undefined,
                ]; // default: not a restaurant page
    const {data: restaurant} = useRestaurant(restaurantID, placeID);

    useEffect(() => {
        handlePageChange();
    }, [
        location.pathname,
        restaurant,
    ]);

    function handlePageChange(): void {
        if (length <= 0) {
            updateSEOProps(Pages.BASE);
            return;
        }

        switch (paths[0]) {
            case "restaurants":
                if (isSearch) {
                    //  /restaurants/ or /restaurants/search/...
                    updateSEOProps(Pages.SEARCH);
                } else {
                    updateSEOProps(Pages.RESTAURANT);
                }
                return;
            case "log-in":
            case "auth-social":
            case "auth-regular":
            case "sign-up":
                updateSEOProps(Pages.LOGIN);
                return;
            case "go-vip-dashboard":
            case "go-vip":
                updateSEOProps(Pages.GOVIP);
                return;
            case "gofriends":
                updateSEOProps(Pages.GOFRIENDS);
                return;
            default:
                updateSEOProps(Pages.BASE);
                return;
        }
    }

    function updateSEOProps(pageType: Pages): void {
        switch (pageType) {
            case Pages.RESTAURANT:
                if (restaurant) {
                    setSEOProps({
                        description: `${restaurant.name} - ${restaurant.description}`,
                        type: "restaurant.restaurant",
                        title: `DevourGO - ${restaurant.name} Menu & Online Ordering | ${restaurant.address.line1}, ${restaurant.address.locality}`,
                        url: `${import.meta.env.VITE_WEB_URL}${restaurant.url}`,
                        additionalKeywords: defaultSEOProps.additionalKeywords.concat([
                            restaurant.name,
                            `${restaurant.name} menu`,
                            `${restaurant.name} online ordering`,
                            "food delivery",
                            "food pickup",
                        ]),
                    });
                }
                return;
            case Pages.LOGIN:
                setSEOProps({
                    ...defaultSEOProps,
                    description: "Log in to DevourGo to access your web3 food community. Enjoy personalized experiences, exclusive promotions, and more.",
                    url: `${import.meta.env.VITE_WEB_URL}${location.pathname}`,
                    title: "DevourGO - Login",
                    additionalKeywords: defaultSEOProps.additionalKeywords.concat([
                        "Login",
                        "Log in",
                        "Signup",
                        "Sign up",
                        "Magic Link",
                    ]),
                });
                return;
            case Pages.GOFRIENDS:
                setSEOProps({
                    ...defaultSEOProps,
                    description: "Connect your web3 wallet with DevourGo's GoFriends and gain access to exclusive web3 communities. Reap rewards with special food promotions.",
                    url: `${import.meta.env.VITE_WEB_URL}${location.pathname}`,
                    title: "DevourGO - GoFriends",
                    additionalKeywords: defaultSEOProps.additionalKeywords.concat(["GoFriends"]),
                });
                return;
            case Pages.GOVIP:
                setSEOProps({
                    ...defaultSEOProps,
                    description: "Become a GoVIP member with DevourGo and earn rewards and DPAY, a cryptocurrency, to use towards your favorite food orders.",
                    url: `${import.meta.env.VITE_WEB_URL}${location.pathname}`,
                    title: "DevourGO - GoVIP",
                    additionalKeywords: defaultSEOProps.additionalKeywords.concat(["GoVIP"]),
                });
                return;
            case Pages.SEARCH:
                setSEOProps({
                    ...defaultSEOProps,
                    description: "Find and explore restaurants in your web3 community with DevourGo. Discover local flavors and order your favorite meals online.",
                    title: "DevourGO - Search for Restaurants",
                    url: `${import.meta.env.VITE_WEB_URL}/restaurants/search`,
                });
                return;
            case Pages.BASE:
            default:
                setSEOProps({
                    ...defaultSEOProps,
                    url: `${import.meta.env.VITE_WEB_URL}${location.pathname}`,
                });
                return;
        }
    }

    return (
        <SEO
            description={seoProps.description}
            url={seoProps.url}
            type={seoProps.type}
            title={seoProps.title}
            additionalKeywords={seoProps.additionalKeywords}
        />
    );
}

export default SEOHandler;
