import {ReactElement, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {restaurantSearchRedirect} from "@/utils/restaurantSearchRedirect";
import {useNavigate} from "react-router";
import useThemePreference from "@/hooks/useThemePreference";
import {ThemePreference} from "@/types/Theme";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import getConfig from "@/utils/getConfig";
import {AddressBooksApi} from "@devour/client";

interface Props {
    checkOverwolf?: boolean;
}

function InitialRedirect(props: Props): ReactElement {
    const {isOnOverwolf} = useOverwolfInterop();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const originalTheme = useSelector((store: IStore) => store.metaStore.themePreference);
    const navigate = useNavigate();
    const {setTheme} = useThemePreference();
    const isRedirectingRef = useRef(false);

    useEffect(() => {
        handleRedirect();
        setTheme(ThemePreference.DARK);

        return () => {
            if (originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, []);

    async function handleRedirect(): Promise<void> {
        if (isRedirectingRef.current) {
            return;
        }
        isRedirectingRef.current = true;
        if (isOnOverwolf && props.checkOverwolf) {
            isRedirectingRef.current = false;
            navigate("/overwolf-landing");
            return;
        }

        // Auto redirect to restaurant search location if route is "/" and user is logged in
        if (fullToken) {
            try {
                const addressBooks = await new AddressBooksApi(getConfig(fullToken)).getAddressBooks();
                const redirectRoute = await restaurantSearchRedirect(addressBooks, lastSearchedPlaceId);
                if (redirectRoute) {
                    isRedirectingRef.current = false;
                    navigate(redirectRoute);
                    return;
                }
            } catch { /* empty */ }
        }

        isRedirectingRef.current = false;
        navigate("/home");
    }

    // to prevent flickering while we check the location status
    return (
        <div className="landing_loading"/>
    );

}

export default InitialRedirect;
