import {BrandMap, OrderDiscount} from "@devour/client";
import DragScroll from "@/components/DragScroll";
import classNames from "classnames";
import {isDesktop, isMobileOnly, isTablet} from "react-device-detect";
import {BrandMapOtherPromoCard} from "@/components/brands/BrandMapOtherPromoCard";
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import {useCallback, useEffect, useRef, useState} from "react";

interface Props {
    brandMap: BrandMap;
    isPromoSelectionStep: boolean;
    promos: OrderDiscount[];
    toggleEmailModal: (showModal: boolean) => void;
}

export default function BrandChallengePageSecondaryPromos(props: Props) {
    const {brandMap, promos, toggleEmailModal} = props;
    const scrollableRef = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);
    const displayedPromosCount = isMobileOnly ? 1 : 4;

    const updateScrollButtons = useCallback(() => {
        const scrollable = scrollableRef.current;
        if (!scrollable) return;

        setCanScrollLeft(scrollable.scrollLeft > 0);
        setCanScrollRight(scrollable.scrollLeft < scrollable.scrollWidth - scrollable.clientWidth);
    }, []);

    useEffect(() => {
        const scrollable = scrollableRef.current;
        if (!scrollable) return;

        updateScrollButtons(); // Initialize button state
        scrollable.addEventListener("scroll", updateScrollButtons);

        return () => scrollable.removeEventListener("scroll", updateScrollButtons);
    }, [updateScrollButtons]);

    const scrollBy = useCallback((direction: "next" | "prev") => {
        return () => {
            const scrollable = scrollableRef.current;
            if (!scrollable) return;

            const flexGap = getFlexGap();
            const containerWidth = scrollable.getBoundingClientRect().width;
            const scrollOffset = direction === "next" ? containerWidth + flexGap : -containerWidth - flexGap;

            scrollable.scrollTo({
                left: scrollable.scrollLeft + scrollOffset,
                behavior: "smooth",
            });
        };
    }, []);

    function getFlexGap() {
        const scrollable = scrollableRef.current;
        if (!scrollable) return 0;

        const flexGap = parseInt(window.getComputedStyle(scrollable).gap, 10);
        return isNaN(flexGap) ? 16 : flexGap;
    }

    return (
        <div className={classNames("brand-map-promotions_others-challenge", {"is-mobile": isMobileOnly})}>
            <div className="brand-map-promotions_others-challenge_header">
                <div>Discover More ({promos.length} promotion{promos.length > 1 && "s"})</div>
                {(isDesktop || isTablet) && promos.length > displayedPromosCount && <div className="overwolf-go-vip-challenges_heading_buttons">
                    <button
                        className="overwolf-go-vip-challenges_heading_buttons_button"
                        onClick={scrollBy("prev")}
                        disabled={!canScrollLeft}
                    >
                        <BiChevronLeft size="1.5rem" strokeWidth="0.35px"/>
                    </button>
                    <button
                        className="overwolf-go-vip-challenges_heading_buttons_button"
                        onClick={scrollBy("next")}
                        disabled={!canScrollRight}
                    >
                        <BiChevronRight size="1.5rem" strokeWidth="0.35px"/>
                    </button>
                </div>}
            </div>

            <DragScroll ref={scrollableRef} className={classNames(
                "brand-map-promotions_others_list",
                {"is-desktop": isDesktop},
            )}>
                {promos.map(promo =>
                    <BrandMapOtherPromoCard
                        brandMap={brandMap}
                        promo={promo}
                        toggleEmailModal={toggleEmailModal}
                        key={promo.id}/>)}
            </DragScroll>
        </div>
    );
}