import {ReactElement, useEffect, useState} from "react";
import {ScrollRestoration, useLocation, useNavigate} from "react-router";
import classNames from "classnames";
import LoadingManager from "../modals/LoadingManager";
import AuthenticatedRoute from "./AuthenticatedRoute";
import ErrorModalManager from "../modals/errorManager/ErrorModalManager";
import FrameOneSidebar from "../sideBar/FrameOneSidebar";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import NavigationTracking from "../NavigationTracking";
import DevourStickyFooter from "../sideBar/DevourStickyFooter";
import ReferralHandler from "../ReferralHandler";
import {Toaster} from "react-hot-toast";
import {
    resetModalCount,
    setOverwolfGameInfo,
    setOverwolfUserInfo,
    toggleChatOpen,
    toggleLoginOpen,
    toggleSidebar,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import LevelUpHandler from "../LevelUpHandler";
import SEOHandler from "../SEOHandler";
import IndustryPassPromoHandler from "@/components/IndustryPassPromoHandler";
import DevourFooter from "@/components/DevourFooter";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import AtlasChatWidget from "@/components/AtlasChatWidget";
import useThemePreference from "@/hooks/useThemePreference";
import {store} from "@/redux";
import {ThemePreference} from "@/types/Theme";
import LoginModal from "../modals/LoginModal";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import useOnLogout from "@/hooks/useOnLogout";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import {GetOverwolfXpSubjectEnum, OWRewardType} from "@devour/client";
import EditProfilePictureModal from "../modals/EditProfilePictureModal";
import {useGetOverwolfSummary} from "@/hooks/useGetOverwolfSummary";
import TokenManager from "@/components/auth/TokenManager";
import {BrandChallengesPrizeNotifier} from "@/pages/challenge/BrandChallengesPrizeNotifier";
import useRefreshRewardData from "@/hooks/useRefreshRewardData";
import ThirdwebReauthenticateModal from "@/components/auth/ThirdwebReauthenticateModal";

interface Props {
    page: ReactElement;
    sidebarDockingEnabled: boolean; // set to `true` for pages where you want the sidebar to be rendered. Don't forget to also configure the sidebar in _f1-sidebar.scss!
    showMobileStickyFooter?: boolean; // set for pages where you want to always show or hide mobile sticky footer menu.
    controlMobileStickyFooterWithRedux?: boolean; // Override the showMobileStickyFooter prop and control the mobile sticky footer menu with redux (isShowStickyFooter)
    authRequired?: boolean; // true -> user will be redirected to login page if no token is present & error message shown. For more complicated auth at this routing level, change this type to an interface of your choice and implement accordingly in the AuthenticatedRoute component.
    pagePaddingAdjusterClassName?: string;
    showFooter?: boolean;
    themeExcluded?: boolean;
    hideLoader?: boolean;
    forceDarkTheme?: boolean;
}

function PageWrapper({
    page,
    sidebarDockingEnabled,
    controlMobileStickyFooterWithRedux,
    authRequired,
    pagePaddingAdjusterClassName,
    themeExcluded,
    hideLoader,
    forceDarkTheme,
    showMobileStickyFooter = true,
    showFooter = true,
}: Props): ReactElement {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const activeModals = useSelector((store: IStore) => store.metaStore.activeModals);
    const isShowStickyFooter = useSelector((store: IStore) => store.metaStore.isShowStickyFooter);
    const isLoginOpen = useSelector((store: IStore) => store.metaStore.isLoginOpen);
    const isOnOverwolf = useSelector((store: IStore) => store.metaStore.isOnOverwolf);
    const [isProfileDialogOpen, setIsProfileDialogOpen] = useState<boolean>(false);
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);
    const {refetch: refetchUserSummary} = useGetOverwolfSummary();
    const {refetch: refetchOverwolfRewardsData} = useGetUserOverwolfXP(
        fullToken,
        GetOverwolfXpSubjectEnum.TIMEREWARD,
        undefined,
    );
    const {devourLogout} = useOnLogout();
    const {refetch: refreshOWRewardData} = useRefreshRewardData();
    const {setTheme} = useThemePreference();
    const {sendMessageToOW} = useOverwolfInterop(async (msg) => {
        switch (msg.type) {
            case "ow:game-info":
                dispatch(setOverwolfGameInfo(msg.payload));
                break;
            case "ow:user-info":
                dispatch(setOverwolfUserInfo(msg.payload));
                break;
            case "ow:reload":
                window.location.reload();
                break;
            case "ow:size-status-change":
                if (msg.payload === "collapsed") {
                    sessionStorage.setItem("alwaysMobile", "true");
                } else {
                    sessionStorage.removeItem("alwaysMobile");
                }
                window.location.reload();
                break;
            case "ow:navigate":
                navigate(msg.payload);
                break;
            case "ow:login":
                dispatch(toggleLoginOpen(true));
                break;
            case "ow:logout":
                devourLogout();
                sendMessageToOW({type: "de:logout"});
                break;
            case "ow:rewarded-xp":
                void refetchOverwolfRewardsData();
                void refetchUserSummary();
                void refreshOWRewardData(OWRewardType.XP);
                break;
            case "ow:edit-profile-picture":
                setIsProfileDialogOpen(true);
                break;
            case "ow:open-help":
                dispatch(toggleChatOpen(true));
                break;
            case "ow:open-menu":
                dispatch(toggleSidebar(true));
                break;
            default:
                break;
        }
    });

    useEffect(() => {
        const getPreferredTheme = () => {
            return store.getState().metaStore.themePreference;
        };
        // listens for browser's theme changes

        if (!themeExcluded) {
            const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
            darkModeQuery.addEventListener("change", () => {
                if (getPreferredTheme() === ThemePreference.AUTO) {
                    setTheme(ThemePreference.AUTO);
                }
            });
            setTheme(getPreferredTheme());
        } else {
            document.documentElement.removeAttribute("data-theme");
        }
    }, [themeExcluded]);

    useEffect(() => {
        const fetchInitialUserProfile = async () => {
            const updatedUserProfile = await refetchUserProfile();
            if (updatedUserProfile.data) {
                dispatch(updateCurrentUser(updatedUserProfile.data));
            }
        };

        if (fullToken) {
            void fetchInitialUserProfile(); // refetch user profile on page refresh
        }
    }, [fullToken?.id, refetchUserProfile]);

    useEffect(() => {
        if (activeModals === 0) {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
        }
    }, [activeModals]);

    useEffect(() => {
        void dispatch(resetModalCount()); // if the pathname changes, then reset modal count to 0
        sendMessageToOW({
            type: "de:path-changed",
            payload: location.pathname,
        });
    }, [location.pathname]);

    useEffect(() => {
        const originalTheme = store.getState().metaStore.themePreference;

        if (forceDarkTheme) {
            setTheme(ThemePreference.DARK);
        }

        return () => {
            if (forceDarkTheme && originalTheme) {
                setTheme(originalTheme);
            }
        };
    }, [forceDarkTheme]);

    function handleProfileDialogClose() {
        setIsProfileDialogOpen(false);
        const storeToken = store.getState().authStore.fullToken;
        const storeUser = store.getState().metaStore.currentUser;
        if (storeToken || storeUser) {
            sendMessageToOW({
                type: "de:data",
                payload: {
                    token: storeToken,
                    userData: storeUser,
                },
            });
        }
    }

    function getShowMobileStickyFooter(): boolean {
        if (isOnOverwolf) {
            return false; // Don't ever show the mobile sticky footer on DevourPlay
        }
        if (controlMobileStickyFooterWithRedux) {
            return isShowStickyFooter;
        }
        return showMobileStickyFooter;
    }

    return (
        <main
            className={classNames("app-main", {
                modal_active: activeModals > 0,
            })}
        >
            <Toaster
                containerClassName="toast-container"
                position="bottom-center"
                toastOptions={{
                    style: {
                        maxWidth: "fit-content",
                    },
                }}
            />
            <LoginModal
                isOpen={isLoginOpen}
                onClose={() => {
                    dispatch(toggleLoginOpen(false));
                }}
            />
            <ThirdwebReauthenticateModal />
            <NavigationTracking />
            <ScrollRestoration />
            <ErrorModalManager />
            <BrandChallengesPrizeNotifier/>
            {!hideLoader && <LoadingManager />}
            <ReferralHandler/>
            {!isOnOverwolf && <LevelUpHandler/>}
            <SEOHandler/>
            <TokenManager/>
            {!isOnOverwolf && <IndustryPassPromoHandler/>}

            <FrameOneSidebar dockingEnabled={!isOnOverwolf && sidebarDockingEnabled}/>

            <div
                className={classNames("page-padding-adjuster", pagePaddingAdjusterClassName, {
                    "page-padding-adjuster_active": !isOnOverwolf && sidebarDockingEnabled,
                })}
            >
                {authRequired ? <AuthenticatedRoute>{page}</AuthenticatedRoute> : page}

                {showFooter && <DevourFooter/>}
            </div>

            {getShowMobileStickyFooter() && <DevourStickyFooter/>}
            {fullToken && isOnOverwolf &&
								<EditProfilePictureModal
								    isOpen={isProfileDialogOpen}
								    onClose={handleProfileDialogClose}
								    onProfilePictureUpdate={() => {
								    }}
								/>
            }
            <AtlasChatWidget/>
        </main>
    );
}

export default PageWrapper;
