import {ReactElement} from "react";
import {Guild, GuildQuest, GuildsApi, OWGame, OWRewardType} from "@devour/client";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GuildJoinButton from "@/components/guilds/GuildJoinButton";
import GuildLeaveButton from "@/components/guilds/GuildLeaveButton";
import GuildQuestClaimButton from "@/components/guilds/GuildQuestCard";
import GuildQualifierCard from "@/pages/guilds/GuildQualifierCard";
import FrameButton from "@/components/buttons/FrameButton";
import {
    addError,
    decrementLoading,
    incrementLoading,
} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import useRefreshRewardData from "@/hooks/useRefreshRewardData";

interface Props {
    guild: Guild;
    game: OWGame;
}

function GuildCard(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: guildProgress, refetch: refetchGuildProgress } = useGetGuildProgress(props.guild?.id, fullToken);
    const guildSpecificQuests = guildProgress?.quests.filter(quest => !quest.isGuildQualifier) || [];
    const {refetch: refreshOWRewardData} = useRefreshRewardData();

    async function claimQuest(quest: GuildQuest, target?: number, rewardType?: OWRewardType): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new GuildsApi(getConfig(fullToken)).claimGuildQuest({
                id: quest.id,
                guildId: props.guild.id,
                gameId: props.game.id,
                claimedTarget: quest.milestones?.length > 0 ? target : undefined,
                rewardType: quest.milestones?.length > 0 ? rewardType : undefined,
            });
            await Promise.all([
                refetchGuildProgress(),
                res.rewards.map(async (reward) => await refreshOWRewardData(reward.rewardType, true)),
            ]);
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    return (
        <div className="guild-card">
            <h3>{props.guild.name}</h3>
            <h5>Qualifier Quests</h5>
            {guildProgress && <GuildQualifierCard
                quests={guildProgress.quests}
                guild={guildProgress.guild}
            />}
            <h5>Guild-specific Quests</h5>
            <ul>
                {guildSpecificQuests.map((quest) =>
                    <li key={quest.id}>
                        <strong>{quest.name}</strong>
                        <div>{quest.description}</div>
                        <div>Rewards: {quest.questRewards.map(reward => `${reward.amount} ${reward.rewardType}`).join(", ")}</div>
                        <div>Current Progress: {quest.userStat?.progress || 0} / {quest.target}</div>
                        {quest.milestones.length > 0
                            ? <>
                                <div>Milestones:</div>
                                <ul>
                                    {quest.milestones.map((milestone, i) => {
                                        const isClaimed = quest.userStat?.claimLogs?.some(log => log.target === milestone.target);
                                        return (
                                            <li
                                                key={`${quest.id}_milestone_${i}`}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    marginTop: "0.5rem",
                                                }}>
                                                <div>
                                                    <div>{milestone.name}</div>
                                                    <div>Target: {milestone.target}</div>
                                                    <div>Reward: {milestone.amount} {milestone.rewardType}</div>
                                                    <div>Claimed: {isClaimed ? "Yes" : "No"}</div>
                                                </div>

                                                {!isClaimed && <FrameButton
                                                    size="narrow"
                                                    onClick={() => claimQuest(quest, milestone.target, milestone.rewardType)}
                                                >Claim</FrameButton>}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                            : <FrameButton
                                size="narrow"
                                onClick={() => claimQuest(quest)}
                            >Claim</FrameButton>}
                        <div>Completed? {quest.userStat?.isCompleted ? "Yes" : "No"}</div>
                        <GuildQuestClaimButton quest={quest} />
                    </li>)}
            </ul>

            <GuildJoinButton guild={props.guild} />
            <GuildLeaveButton guild={props.guild} />
        </div>
    );
}

export default GuildCard;
