/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WalletType {
    WALLETCONNECT = 'WALLET_CONNECT',
    MAGIC = 'MAGIC',
    SIGNEDURL = 'SIGNED_URL',
    SOLANA = 'SOLANA',
    THIRDWEB = 'THIRDWEB'
}

export function WalletTypeFromJSON(json: any): WalletType {
    return WalletTypeFromJSONTyped(json, false);
}

export function WalletTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletType {
    return json as WalletType;
}

export function WalletTypeToJSON(value?: WalletType | null): any {
    return value as any;
}

