/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PrizePoolType,
    PrizePoolTypeFromJSON,
    PrizePoolTypeFromJSONTyped,
    PrizePoolTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChallengePrizeBody
 */
export interface ChallengePrizeBody {
    /**
     * 
     * @type {string}
     * @memberof ChallengePrizeBody
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ChallengePrizeBody
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ChallengePrizeBody
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengePrizeBody
     */
    image?: string;
    /**
     * 
     * @type {PrizePoolType}
     * @memberof ChallengePrizeBody
     */
    type?: PrizePoolType;
    /**
     * 
     * @type {number}
     * @memberof ChallengePrizeBody
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ChallengePrizeBody
     */
    nftTracker?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengePrizeBody
     */
    winnerMessage?: string;
}

export function ChallengePrizeBodyFromJSON(json: any): ChallengePrizeBody {
    return ChallengePrizeBodyFromJSONTyped(json, false);
}

export function ChallengePrizeBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengePrizeBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'type': !exists(json, 'type') ? undefined : PrizePoolTypeFromJSON(json['type']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'nftTracker': !exists(json, 'nftTracker') ? undefined : json['nftTracker'],
        'winnerMessage': !exists(json, 'winnerMessage') ? undefined : json['winnerMessage'],
    };
}

export function ChallengePrizeBodyToJSON(value?: ChallengePrizeBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'quantity': value.quantity,
        'description': value.description,
        'image': value.image,
        'type': PrizePoolTypeToJSON(value.type),
        'amount': value.amount,
        'nftTracker': value.nftTracker,
        'winnerMessage': value.winnerMessage,
    };
}


