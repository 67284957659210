import {
    ButtonHTMLAttributes,
    ChangeEvent,
    ClipboardEvent,
    FormEvent,
    KeyboardEvent,
    ReactElement,
    useEffect, useRef,
    useState,
} from "react";
import FrameButton from "../buttons/FrameButton";

interface Code {
    i1: string;
    i2: string;
    i3: string;
    i4: string;
    i5: string;
    i6: string;
}

const defaultCode: Code = {
    i1: "",
    i2: "",
    i3: "",
    i4: "",
    i5: "",
    i6: "",
};

interface Props {
    email: string;
    hasError: boolean;
    onRequestNewCode: () => void;
    onSubmit: (code: string) => void;
}

function LoginVerificationCode(props: Props): ReactElement {
    const [code, setCode] = useState<Code>(defaultCode);
    const [timer, setTimer] = useState<number>(60);
    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const intervalId = useRef(null);

    useEffect(() => {
        inputsRef.current[0]?.focus();
        resetInterval();
        return () => clearInterval(intervalId.current);
    }, []);

    function codeOnChange(input: ChangeEvent<HTMLInputElement>, index: number, position: keyof Code): void {
        const regex = /^\d+$/;
        if (input.target.value && !regex.test(input.target.value)) {
            // Attempted to input non-numeric digits
            return;
        }

        setCode((prev) => {
            return {
                ...prev,
                [position]: input.target.value,
            };
        });

        // Focus on the next input after entering a value. Don't do this on a backspace delete.
        if (input.target.value) {
            const nextInput = inputsRef.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        }
    }

    function verifyWithCode(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        const mergedCode: string = Object.values(code).join("");
        props.onSubmit(mergedCode);
    }

    function onKeyDown(e: KeyboardEvent<HTMLInputElement>, index: number, position: keyof Code): void {
        // Handle the backspace to move the focus back to the previous input
        if (e.key === "Backspace" && !code[position]) {
            const prevInput = inputsRef.current[index - 1];
            if (prevInput) {
                const prevCodePosition = Object.keys(code)[index - 1];
                setCode((code) => {
                    return {
                        ...code,
                        [prevCodePosition]: "",
                    };
                });
                prevInput.focus();
            }
        }
    }

    function onPaste(e: ClipboardEvent<HTMLInputElement>): void {
        // Handle paste event to distribute the pasted values across the inputs
        const pastedData = e.clipboardData.getData("text"); // Get the text from the clipboard
        const theNumber = pastedData.replace(/^\D+/g, ""); // Replace all leading non-digits with nothing
        if (theNumber.length === 6) {
            e.preventDefault(); // Prevent the default paste behavior
            const newCode: Code = {
                i1: theNumber[0],
                i2: theNumber[1],
                i3: theNumber[2],
                i4: theNumber[3],
                i5: theNumber[4],
                i6: theNumber[5],
            };
            setCode(newCode);
            inputsRef.current[theNumber.length - 1].focus();
        }
    }

    function resetInterval() {
        setTimer(60);
        clearInterval(intervalId.current);
        intervalId.current = setInterval(() => {
            setTimer((time) => {
                if (time === 0) {
                    clearInterval(intervalId.current);
                    return 0;
                }
                return time - 1;
            });
        }, 1000);
    }

    function onRequestNewCode() {
        resetInterval();
        props.onRequestNewCode();
    }

    return (
        <>
            <form onSubmit={verifyWithCode}>
                <p className="color-medium-gray text-center">
										Enter the verification code sent to
                </p>
                <p className="text-center">
                    {props.email}
                </p>
                <div className="login-page_content_verification-code-container">
                    {Object.keys(code).map((position, index) =>
                        <input
                            key={`code-input-${position}`}
                            id={`code-input-${position}`}
                            value={code[position]}
                            type="tel"
                            ref={(input: HTMLInputElement) => {
                                inputsRef.current[index] = input;
                            }}
                            maxLength={1}
                            onChange={(e) => codeOnChange(e, index, position as unknown as keyof Code)}
                            required={true}
                            onKeyDown={(e) => onKeyDown(e, index, position as unknown as keyof Code)}
                            onPaste={onPaste}
                        />)}
                </div>
                {props.hasError &&
										<div className="login-page_content_verification-code-error">
												Verification code failed.<br/>
												Please try again or request a new code.
										</div>
                }
                <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="normal"
                    className="login-page_content_login-button"
                    forwardProps={{
                        type: "submit",
                        disabled: !Object.values(code).every(Boolean), // Verify all 6 input digits have value
                    }}
                >
										Verify
                </FrameButton>
            </form>
            <div className="login-page_content_request-code">
                {timer > 0
                    ? <div className="login-page_content_request-code_timer">
												Resend code in {timer} seconds
                    </div>
                    : <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                        color="ghost"
                        size="normal"
                        className="login-page_content_request-code_button"
                        forwardProps={{
                            type: "button",
                        }}
                        onClick={onRequestNewCode}
                    >
												Request New Code
                    </FrameButton>
                }
            </div>
        </>
    );
}

export default LoginVerificationCode;