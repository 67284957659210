import {IStore} from "@/redux/defaultStore";
import {ChallengesApi, PrizePool} from "@devour/client";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {BrandChallengePrizeWonModal} from "./BrandChallengePrizeWonModal";
import getConfig from "@/utils/getConfig";
import useRefreshRewardData from "@/hooks/useRefreshRewardData";

export const BrandChallengesPrizeNotifier = () => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [prizes, setPrizes] = useState<PrizePool[]>([]);
    const {refetch: refreshOWRewardData} = useRefreshRewardData();

    const fetchPrizes = async () => {
        if (!fullToken) {
            return;
        }

        try {
            const res = await new ChallengesApi(getConfig(fullToken)).getAllChallengePrizesWon({
                newPrizesOnly: true,
            });

            const prizeTypes = new Set(res.map(prize => prize.type));

            prizeTypes.forEach(type => {
                void refreshOWRewardData(type);
            });
            setPrizes(res);
        } catch (e) {
            console.error("Error fetching prizes:", e);
        }
    };

    useEffect(() => {
        if (!fullToken) {
            return;
        }
        void fetchPrizes();
        const intervalKey = setInterval(() => {
            void fetchPrizes();
        }, 1000 * 60);
        return () => {
            clearInterval(intervalKey);
        };
    }, [fullToken?.id]);

    return (
        <BrandChallengePrizeWonModal
            isOpen={!!prizes[0] && !prizes[0].notifiedWinner}
            prize={prizes[0]}
            onAccepted={fetchPrizes}
        />
    );
};
