import { IStore } from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";
import { GetUserSummaryResponse, OverwolfApi, Token } from "@devour/client";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

export const OVERWOLF_SUMMARY_QUERY_KEY = "overwolf-summary";

async function fetchOverwolfSummary(fullToken: Token) {
    return await new OverwolfApi(getConfig(fullToken)).getOverwolfUserSummary();
}

export const useGetOverwolfSummary = () => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data, isLoading, refetch } = useQuery<GetUserSummaryResponse>({
        queryKey: [
            OVERWOLF_SUMMARY_QUERY_KEY,
            fullToken?.id,
        ],
        queryFn: () => fetchOverwolfSummary(fullToken),
        staleTime: 60000,
        enabled: !!fullToken?.token,
    });

    return {
        data,
        isLoading,
        refetch,
    };
};
