import {ReactElement} from "react";
import FrameButton from "@/components/buttons/FrameButton";
import {useNavigate} from "react-router";
import {isDesktop, isTablet} from "react-device-detect";

function IndustryPassPromoBanner(): ReactElement {
    const history = useNavigate();

    return (
        <>
            <div className="industry-pass-promo-banner">
                <div className="industry-pass-promo-banner_left">
                    <p className="industry-pass-promo-banner_left_header">$0 Delivery Fee</p>
                    <p className="industry-pass-promo-banner_left_description">
                        <span className="industry-pass-promo-banner_left_opacity-adjust">Start saving more by getting an </span>
                        <strong>Industry Pass!</strong>
                    </p>
                </div>
                <div className="industry-pass-promo-banner_right">
                    <img
                        src={import.meta.env.VITE_CDN_URL + "/images/industry-banner-graphic-mobile.webp"}
                        alt="industry pass"
                        className="industry-pass-promo-banner_right_graphic"
                    />
                    <FrameButton
                        color="white-drop-shadow-devour"
                        size={isDesktop || isTablet
                            ? "narrow"
                            : "pill"}
                        className="industry-pass-promo-banner_right_button"
                        onClick={() => history("/the-industry-pass")}
                    >
                        Start Saving!
                    </FrameButton>
                </div>
            </div>
        </>
    );
}

export default IndustryPassPromoBanner;
