import {ReactElement, useEffect, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
    ReferralLinkTextBody,
    ReferralsApi,
    UsersApi,
    ErrorType,
    OWUserSessionEventType,
} from "@devour/client";
import {IStore} from "../redux/defaultStore";
import AuthHeaderLogo from "../components/auth/AuthHeaderLogo";
import {
    addError,
    decrementLoading,
    toggleLoginOpen,
} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import {AiFillCheckCircle} from "react-icons/ai";
import classNames from "classnames";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import LoginEmail from "@/components/login/LoginEmail";
import LoginRegistration from "@/components/login/LoginRegistration";
import LoginVerificationCode from "@/components/login/LoginVerificationCode";
import {emitLoginEvent, emitRegisterEvent} from "@/hooks/useOnLogin";
import {
    inAppWallet,
    preAuthenticate,
} from "thirdweb/wallets/in-app";
import {Wallet} from "thirdweb/wallets";
import useOnAuthThirdweb from "@/hooks/useOnAuthThirdweb";
import {useConnect} from "thirdweb/react";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import {logOverwolfUserEvent} from "@/utils/logOverwolfUserEvent";
import { useGetGuild } from "@/hooks/useGetGuild";
import { useAsset } from "@/hooks/useAsset";

enum LoginState {
    EMAIL,
    REGISTRATION,
    VERIFY_CODE,
}

interface Props {
    isOnDialog?: boolean;
}

function ThirdwebLogin(props: Props): ReactElement {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const referralCodeQuery = searchParams.get("ref");
    const [loginState, setLoginState] = useState<LoginState>(LoginState.EMAIL);
    const [referralLinkText, setReferralLinkText] = useState<ReferralLinkTextBody | undefined>(undefined);
    const [email, setEmail] = useState<string | null>(null);
    const [hasVerificationCodeError, setHasVerificationCodeError] = useState<boolean>(false);
    const reduxReferralCode = useSelector((store: IStore) => store.affiliateStore.referralCode);
    const reduxReferralExpiry = useSelector((store: IStore) => store.affiliateStore.referralExpiry);
    const reduxReferralValid = reduxReferralCode && Date.now() < reduxReferralExpiry ? reduxReferralCode : "";
    const [referralCode, setReferralCode] = useState<string>(reduxReferralValid ?? referralCodeQuery ?? "");
    const {isOnOverwolf} = useOverwolfInterop();
    const {connect} = useConnect();
    const {onAuthComplete} = useOnAuthThirdweb();
    const navigate = useNavigate();
    const isSubmittingRef = useRef(false);
    const guildInviteId = searchParams.get("guildId");
    const {data: guildInfo} = useGetGuild(guildInviteId);
    const isGuildInvite = referralCode && guildInviteId;

    useEffect(() => {
        /**
         * Get the current referral link text.
         *
         */
        async function getReferralLinkText() {
            try {
                const formData = await new ReferralsApi(getConfig()).getReferralLinkText({
                    referralCode: reduxReferralCode,
                });

                setReferralLinkText({
                    header: formData.header,
                    description: formData.description,
                    relationshipType: formData.relationshipType,
                });
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

        // only render if there was a referral code stored
        if (reduxReferralCode) {
            void getReferralLinkText();
        }
    }, [reduxReferralCode]);


    async function onSubmitEmail(email: string): Promise<void> {
        if (isSubmittingRef.current) {
            return;
        }
        try {
            isSubmittingRef.current = true;
            const res = await new UsersApi(getConfig()).validateUserExistence({
                email,
            });
            if (res.disabled) {
                throw new Error("Your account has been disabled.");
            }
            setEmail(email);
            if (res._exists) {
                // send email verification code
                await preAuthenticate({
                    client: thirdwebAuthClient,
                    strategy: "email",
                    email, // ex: user@example.com
                });
                setLoginState(LoginState.VERIFY_CODE);
            } else {
                setLoginState(LoginState.REGISTRATION);
            }
        } catch (e) {
            let errorMessage = e.message || "Unable to process registration";
            if (e?.message === "Failed to send verification code") {
                errorMessage = "Unable to send verification code. You may have requested a code too recently. Please wait one minute and try again.";
            }
            dispatch(await addError({
                type: ErrorType.APP,
                message: errorMessage,
            }));
        } finally {
            isSubmittingRef.current = false;
        }
    }

    async function onSubmitRegistration(referralCode?: string): Promise<void> {
        if (isSubmittingRef.current) {
            return;
        }
        try {
            isSubmittingRef.current = true;
            // send email verification code
            await preAuthenticate({
                client: thirdwebAuthClient,
                strategy: "email",
                email, // ex: user@example.com
            });
            if (referralCode) {
                setReferralCode(referralCode);
            }
            setLoginState(LoginState.VERIFY_CODE);
        } catch (e) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: e.message || "Unable to submit registration code",
            }));
        } finally {
            isSubmittingRef.current = false;
        }
    }

    async function onSubmitVerificationCode(code: string): Promise<void> {
        if (isSubmittingRef.current) {
            return;
        }
        isSubmittingRef.current = true;
        await connect(async () => {
            try {
                const wallet = inAppWallet();
                await wallet.connect({
                    client: thirdwebAuthClient,
                    strategy: "email",
                    email,
                    verificationCode: code,
                });
                void onNewLoginComplete(wallet);
                return wallet;
            } catch (e) {
                if (e?.message === "Failed to verify verification code") {
                    setHasVerificationCodeError(true);
                } else {
                    dispatch(await addError({
                        type: ErrorType.APP,
                        message: e.message || "Unable to verify registration code",
                    }));
                }
            } finally {
                isSubmittingRef.current = false;
            }
        });
    }

    async function onRequestNewCode(): Promise<void> {
        setHasVerificationCodeError(false);
        await onSubmitRegistration();
    }

    async function onNewLoginComplete(wallet: Wallet<"inApp">) {
        if (!wallet) {
            return;
        }
        const loginRes = await onAuthComplete(wallet, referralCode);

        dispatch(toggleLoginOpen(false));

        if (loginRes) {

            if (loginRes.isExistingUser) {
                emitLoginEvent();
                if (isOnOverwolf) {
                    void logOverwolfUserEvent(OWUserSessionEventType.LOGIN);
                }
            } else {
                emitRegisterEvent();
                if (isOnOverwolf) {
                    void logOverwolfUserEvent(OWUserSessionEventType.SIGNUP);
                }
            }
            if (!props.isOnDialog) {
                navigate("/");
            }
        }
    }

    function renderIntro() {
        if (loginState === LoginState.VERIFY_CODE) {
            return null;
        }
        if (isGuildInvite) {
            return (
                <>
                    <h3 className="login-page_content_title">You Are Invited!</h3>
                    <p className="login-page_content_subtitle">
                        Your friend invited you to join {guildInfo?.name} guild in DevourGO. Please sign up to get started!
                    </p>
                </>
            );
        }
        return (
            <>
                {!isOnOverwolf &&
                    <>
                        <h3 className="login-page_content_title">Craving something different?</h3>
                        <p className="login-page_content_subtitle">
                            DevourGO delivers more than just food — serving up a whole new way to
                            eat, play, and earn.
                        </p>
                    </>
                }

                {reduxReferralValid && referralLinkText && !isGuildInvite &&
                    <div className="sign-up_content_referral-link-container">
                        <div className="sign-up_content_referral-link-container_header">
                            <AiFillCheckCircle
                                className="sign-up_content_referral-link-container_header_icon"/>
                            <p className="sign-up_content_referral-link-container_text">{referralLinkText.header}</p>
                        </div>
                        {referralLinkText.description && referralLinkText.description !== "" &&
                            <p className="sign-up_content_referral-link-container_text">
                                {referralLinkText.description}
                            </p>
                        }
                    </div>
                }

                <h4 className="login-page_content_login-text">
                    {!isOnOverwolf ? "Login or Sign Up 🚀" : "Login or Sign Up"}
                </h4>
                {isOnOverwolf &&
                    <p className="login-page_content_login-description">
                        DevourGO delivers more than just food — serving up a new way to eat, play,
                        and earn!
                    </p>
                }
            </>
        );
    }

    function renderForm() {
        switch (loginState) {
            case LoginState.REGISTRATION:
                return (
                    <LoginRegistration
                        initialReferralCode={referralCode}
                        email={email}
                        onSubmit={onSubmitRegistration}
                        disableReferralField={!!(referralCodeQuery && reduxReferralValid)}
                    />
                );
            case LoginState.VERIFY_CODE:
                return (
                    <LoginVerificationCode
                        email={email}
                        hasError={hasVerificationCodeError}
                        onRequestNewCode={onRequestNewCode}
                        onSubmit={onSubmitVerificationCode}
                    />
                );
            case LoginState.EMAIL:
            default:
                return (
                    <LoginEmail
                        initialEmail={email}
                        onSubmit={onSubmitEmail}
                    />
                );
        }
    }

    return (
        <div
            className={classNames("login-page", {
                "is-on-dialog": !!props.isOnDialog,
            })}
        >
            {!props.isOnDialog &&
                <div className="login-page_spacer-top">
                    <AuthHeaderLogo/>
                </div>
            }

            <div className="login-page_content">

                {renderIntro()}
                {renderForm()}

                {!isOnOverwolf && loginState !== LoginState.VERIFY_CODE && !isGuildInvite &&
                    <div className="login-page_content_other-actions">
                        <p className="login-page_content_other-actions_owner">
                            Are you a restaurant owner?{" "}
                            <a href={import.meta.env.VITE_MERCHANT_URL} rel="noopener noreferrer">
                                Click here
                            </a>
                        </p>
                    </div>
                }
            </div>

            <div className="login-page_spacer-bottom"/>
        </div>
    );
}

export default ThirdwebLogin;