import {useQuery} from "@tanstack/react-query";
import {
    Guild,
    GuildsApi,
} from "@devour/client";

async function fetchGuild(guildId: string): Promise<Guild> {
    return await new GuildsApi().getGuild({
        id: guildId,
    });
}

function getGuildQuery(guildId: string) {
    return {
        queryKey: [
            "get-guild", guildId,
        ],
        queryFn: () => fetchGuild(guildId),
        staleTime: 60000,
        enabled: !!guildId,
    } as const;
}

export function useGetGuild(guildId: string) {
    return useQuery(getGuildQuery(guildId));
}
