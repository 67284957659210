import AssetImage from "@/components/AssetImage";
import FrameButton from "@/components/buttons/FrameButton";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import FrameModalNoHeaderToggle from "@/components/modals/modalComponents/FrameModalNoHeaderToggle";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import { ChallengeReward, OWRewardType, RewardResponseObject } from "@devour/client";
import { FC, useState } from "react";
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";

interface props {
    rewardResponse?: RewardResponseObject;
    reward: ChallengeReward;
}

const descriptionByType = {
    [OWRewardType.FUEL]: "Fuel has been awarded to your account.",
    [OWRewardType.NFT]: "Promotion will be automatically applied upon checkout. Redeem it now!",
    [OWRewardType.XP]: "XP has been awarded to your account.",
};

export const BrandChallengeInstantRewardClaimedModal: FC<props> = ({ reward, rewardResponse }) => {
    const [isOpen, setIsOpen] = useState(true);
    // TODO: add claim date
    const dateMintedString = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
    const handleToggle = () => {
        setIsOpen(false);
    };
    const getTitle = () => {
        switch (reward.rewardType) {
            case OWRewardType.FUEL:
                return `${reward.amount} Fuel`;
            case OWRewardType.NFT:
                return `${reward.name}`;
            case OWRewardType.XP:
                return `${reward.amount} XP`;
            default:
                return "";
        }
    };

    const getImage = () => {
        if (reward.rewardType === OWRewardType.NFT) {
            return <AssetImage assetId={reward.image} alt="Reward image" />;
        }
        return <img src={`${import.meta.env.VITE_CDN_URL}/images/quest-notification-reward-${reward.rewardType.toLowerCase()}.webp`} alt="Reward image"/>;
    };

    const getDescription = () => {
        switch (reward.rewardType) {
            case OWRewardType.FUEL:
                return `${reward.amount} Fuel! Use your Fuel to order food or items in the Loot Shop`;
            case OWRewardType.NFT:
                return `${reward.name}!`;
            case OWRewardType.XP:
                return `${reward.amount} XP! Stack more XP to level up and unlock level rewards!`;
            default:
                return "";
        }
    };

    const renderPromoDescriptions = () => {
        if (!rewardResponse?.promoDescriptions || rewardResponse?.promoDescriptions?.length === 0) {
            return null;
        }
        if (rewardResponse?.promoDescriptions?.length === 1) {
            return rewardResponse?.promoDescriptions[0];
        }
        return rewardResponse?.promoDescriptions.map((desc) => <><br/>-{desc}</>);
    };

    return (
        <FrameOneModal
            contentClassName="brand-challenge-prize-won-modal"
            size="xs2"
            isOpen={isOpen}
            disableOverlayDismiss={false}
            toggle={handleToggle}
        >

            <FrameModalBody className="brand-challenge-prize-won-modal_body">
                <FrameModalNoHeaderToggle toggle={handleToggle} />
                {getImage()}
                <div className="brand-challenge-prize-won-modal_body_info">
                    <h6>
                        {getTitle()} <br/> <FaCheckCircle /> Claimed
                    </h6>
                    <span>Claimed on {dateMintedString}</span>
                    <p>You earned {getDescription()}
                        {reward.rewardType === OWRewardType.NFT && renderPromoDescriptions()}</p>
                </div>
                <FrameButton onClick={handleToggle} size="normal" color="purple">
                    Confirm
                </FrameButton>
            </FrameModalBody>
        </FrameOneModal>
    );
};
