import {ReactElement, useState} from "react";
import {NftGrouping, NftTracker} from "@devour/client";
import Ellipse from "../../svgs/ellipse15.svg?react";
import classNames from "classnames";
import GoFrensCommunityInfoDrawer from "@/components/goFrens/GoFrensCommunityInfoDrawer";
import DrawerModalView from "@/components/goFrens/communityInfoDrawer/DrawerModalView";
import {isTablet} from "react-device-detect";

interface Props {
    grouping: NftGrouping;
    trackers: Array<NftTracker>;
    owned: number;
    onlyShowOwned?: boolean;
    className?: string;
    forceWidth?: string;
}

function GoFrensCommunityCard(props: Props): ReactElement {
    const [
        showDrawer,
        setShowDrawer,
    ] = useState<boolean>(false);
    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false);

    function toggleDrawer(): void {
        setShowDrawer(s => !s);
    }
    function toggleModal(): void {
        setShowModal(s => !s);
    }

    function isCommunityCardShown() {
        if (props.onlyShowOwned) {
            return props.owned > 0;
        }
        return true;
    }

    return (
        <>
            {isCommunityCardShown() &&
            <>
                <GoFrensCommunityInfoDrawer
                    show={showDrawer}
                    toggle={toggleDrawer}
                    grouping={props.grouping}
                    trackers={props.trackers}
                    owned={props.owned}
                />
                <DrawerModalView
                    show={showModal}
                    toggle={toggleModal}
                    grouping={props.grouping}
                    trackers={props.trackers}
                    owned={props.owned}
                />
                <div
                    className={classNames("gofrens-community-card", props?.className)}
                    style={{width: props?.forceWidth
                        ? props.forceWidth
                        : ""}}
                    onClick={() => isTablet
                        ? toggleModal()
                        : toggleDrawer()}
                >
                    <div className="gofrens-community-card_graphics">
                        <div className={classNames(
                            "gofrens-community-card_graphics_hero",
                            {"no-hero": !props.grouping?.hero},
                        )}>
                            {props.grouping?.hero &&
                                <img
                                    src={props.grouping.hero?.url}
                                    alt={`${props.grouping?.name} hero`}
                                    className="gofrens-community-card_graphics_hero_img"
                                />
                            }
                        </div>
                        <div className="gofrens-community-card_graphics_icon">
                            {props.grouping?.icon &&
                            <img
                                src={props.grouping.icon?.url}
                                alt={`${props.grouping?.name} icon`}
                            />
                            }
                        </div>
                    </div>

                    <div className="gofrens-community-card_body" style={{width: props?.forceWidth
                        ? props.forceWidth
                        : ""}}>
                        <div className="gofrens-community-card_body_description">
                            <p className="gofrens-community-card_body_description_name">{props.grouping.name}</p>
                            <div className="gofrens-community-card_body_description_community-info">
                                {props.owned > 0 &&
                                <div className="gofrens-community-card_body_description_community-info_nfts-owned">
                                    <span>{props.owned} owned NFTs</span>
                                    <span><Ellipse /></span>
                                </div>
                                }
                                <div className="gofrens-community-card_body_description_community-info_others">
                                    {props.grouping.numOfMembers != null && <span>{props.grouping.numOfMembers} members</span>}
                                    <span><Ellipse /></span>
                                    <span>
                                        {props.grouping.totalPoints === 0
                                            ? "Unranked"
                                            : `rank ${props.grouping.rank}`}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
            }
        </>
    );
}

export default GoFrensCommunityCard;
