import getConfig from "@/utils/getConfig";
import {ChallengeInfoResponse, ChallengesApi, Token} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useQuery} from "@tanstack/react-query";


async function fetchChallenge(challengeId: string, fullToken?: Token): Promise<ChallengeInfoResponse> {
    return await new ChallengesApi(getConfig(fullToken)).getChallengeInfo({
        id: challengeId,
    });
}

function getChallengeQuery(challengeId: string) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    return {
        queryKey: [
            "challenge",
            challengeId,
            fullToken?.id ?? "unauthenticated",
        ],
        queryFn: () => fetchChallenge(challengeId),
        staleTime: 30 * 60000,
        enabled: !!challengeId,
    } as const;
}

export function useGetChallenge(challengeId: string) {
    return useQuery(getChallengeQuery(challengeId));
}