import {ButtonHTMLAttributes, Dispatch, ReactElement, SetStateAction} from "react";
import FrameButton from "../../buttons/FrameButton";
import {NftPurchaseUpsert} from "@devour/client";
import {FiArrowRight, FiChevronLeft} from "react-icons/fi";
import Spacer from "@/components/Spacer";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {formatPriceAddCommaPreserveDecimals} from "@/utils/currencyFormatters";
import {PaymentStates} from "@/components/modals/mintIndustryPassModal/PaymentStates";

interface Props {
    onSubmit: () => void;
    nftPurchase: NftPurchaseUpsert;
    setPaymentState: Dispatch<SetStateAction<PaymentStates>>;
}

function MintIndustryPassModalDpayPayment(props: Props): ReactElement {
    const dpayFullAmount = Math.ceil(props.nftPurchase?.total / props.nftPurchase?.dPayPricePerUsd);
    const maxDpayAllowed = Math.min(dpayFullAmount, props.nftPurchase?.dpayBalance);

    function renderBalanceData(): ReactElement {
        return (
            <>
                <img
                    className="mint-industry-credit-card_body_payment-options_row_img"
                    src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                    alt="DPAY Token Icon"
                />
                <p>
                    <strong className="mint-industry-credit-card_body_dpay_balance-card_my-label">
                        My {import.meta.env.VITE_TOKEN_NAME} Balance
                    </strong>
                </p>
                <Spacer/>
                <div className="mint-industry-credit-card_body_dpay_balance-card_usd">
                    <p>${(props.nftPurchase?.dpayBalance * props.nftPurchase?.dPayPricePerUsd).toFixed(2)} USD</p>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="mint-industry-credit-card_body_dpay">
                <div
                    className="mint-industry-credit-card_body_payment-options_header"
                    onClick={() => props.setPaymentState(PaymentStates.SELECT_PAYMENT)}
                >
                    <FiChevronLeft className="mint-industry-credit-card_body_payment-options_header_back"/>
                    <h4>Pay with {import.meta.env.VITE_TOKEN_NAME}</h4>
                </div>
                {isMobile && !isTablet &&
                <div className="mint-industry-credit-card_body_dpay_mobile-balance">
                    {renderBalanceData()}
                </div>
                }
                <div className="mint-industry-credit-card_body_dpay_balance-card">
                    {isDesktop || isTablet
                        ? <>
                            {renderBalanceData()}
                            <p>
                                <strong>{formatPriceAddCommaPreserveDecimals(props.nftPurchase?.dpayBalance, 0)} DPAY</strong>
                            </p>
                        </>
                        : <>
                            <Spacer/>
                            <p>
                                <strong>{formatPriceAddCommaPreserveDecimals(props.nftPurchase?.dpayBalance, 0)} {import.meta.env.VITE_TOKEN_NAME}</strong>
                            </p>
                        </>
                    }
                </div>

                <p className="mint-industry-credit-card_body_dpay_remaining-balance">
                    Remaining
                    Balance: <strong>{formatPriceAddCommaPreserveDecimals(props.nftPurchase.dpayBalance - maxDpayAllowed, 0)} {import.meta.env.VITE_TOKEN_NAME}</strong>
                </p>
            </div>
            <div className="mint-industry-credit-card_body_credit_footer">
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="purple"
                    size="large"
                    className="mint-industry-credit-card_body_credit_footer_button"
                    rightIcon={FiArrowRight}
                    showSpinner={true}
                    onClick={props.onSubmit}
                >
                    Purchase Now
                </FrameButton>
            </div>
        </>
    );
}

export default MintIndustryPassModalDpayPayment;
