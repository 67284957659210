/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NftRewardPrizeType {
    XP = 'XP',
    FUEL = 'FUEL',
    NFT = 'NFT',
    PROMO = 'PROMO',
    MERCH = 'MERCH',
    GIFTCARD = 'GIFT_CARD',
    OTHER = 'OTHER'
}

export function NftRewardPrizeTypeFromJSON(json: any): NftRewardPrizeType {
    return NftRewardPrizeTypeFromJSONTyped(json, false);
}

export function NftRewardPrizeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftRewardPrizeType {
    return json as NftRewardPrizeType;
}

export function NftRewardPrizeTypeToJSON(value?: NftRewardPrizeType | null): any {
    return value as any;
}

