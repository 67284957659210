/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    ChallengeUser,
    ChallengeUserFromJSON,
    ChallengeUserFromJSONTyped,
    ChallengeUserToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection tracks which users are participating in which challenges. User must accept a challenge to participate.
 * @export
 * @interface ChallengeMembership
 */
export interface ChallengeMembership {
    /**
     * The ID of the user participating in the challenge.
     * @type {string}
     * @memberof ChallengeMembership
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeMembership
     */
    challenge: string;
    /**
     * The prize user has won from the raffle for this challenge.
     * @type {string}
     * @memberof ChallengeMembership
     */
    prizeWon?: string;
    /**
     * The timestamp when the user claimed the rewards for this challenge.
     * @type {number}
     * @memberof ChallengeMembership
     */
    rewardClaimDate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChallengeMembership
     */
    notifiedWinner?: boolean;
    /**
     * 
     * @type {ChallengeUser}
     * @memberof ChallengeMembership
     */
    user?: ChallengeUser;
    /**
     * 
     * @type {string}
     * @memberof ChallengeMembership
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ChallengeMembership
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof ChallengeMembership
     */
    updatedAt: number;
}

export function ChallengeMembershipFromJSON(json: any): ChallengeMembership {
    return ChallengeMembershipFromJSONTyped(json, false);
}

export function ChallengeMembershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeMembership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'challenge': json['challenge'],
        'prizeWon': !exists(json, 'prizeWon') ? undefined : json['prizeWon'],
        'rewardClaimDate': !exists(json, 'rewardClaimDate') ? undefined : json['rewardClaimDate'],
        'notifiedWinner': !exists(json, 'notifiedWinner') ? undefined : json['notifiedWinner'],
        'user': !exists(json, 'user') ? undefined : ChallengeUserFromJSON(json['user']),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function ChallengeMembershipToJSON(value?: ChallengeMembership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'challenge': value.challenge,
        'prizeWon': value.prizeWon,
        'rewardClaimDate': value.rewardClaimDate,
        'notifiedWinner': value.notifiedWinner,
        'user': ChallengeUserToJSON(value.user),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


