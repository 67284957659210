/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NftPurchaseRequestBody
 */
export interface NftPurchaseRequestBody {
    /**
     * 
     * @type {number}
     * @memberof NftPurchaseRequestBody
     */
    fiatInDpay?: number;
    /**
     * 
     * @type {string}
     * @memberof NftPurchaseRequestBody
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {number}
     * @memberof NftPurchaseRequestBody
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NftPurchaseRequestBody
     */
    isCoinbase?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NftPurchaseRequestBody
     */
    isLootBox?: boolean;
}

export function NftPurchaseRequestBodyFromJSON(json: any): NftPurchaseRequestBody {
    return NftPurchaseRequestBodyFromJSONTyped(json, false);
}

export function NftPurchaseRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftPurchaseRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fiatInDpay': !exists(json, 'fiatInDpay') ? undefined : json['fiatInDpay'],
        'paymentMethodId': !exists(json, 'paymentMethodId') ? undefined : json['paymentMethodId'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'isCoinbase': !exists(json, 'isCoinbase') ? undefined : json['isCoinbase'],
        'isLootBox': !exists(json, 'isLootBox') ? undefined : json['isLootBox'],
    };
}

export function NftPurchaseRequestBodyToJSON(value?: NftPurchaseRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fiatInDpay': value.fiatInDpay,
        'paymentMethodId': value.paymentMethodId,
        'quantity': value.quantity,
        'isCoinbase': value.isCoinbase,
        'isLootBox': value.isLootBox,
    };
}


