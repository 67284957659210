import {Challenge} from "@devour/client";
import {IoArrowForwardOutline, IoClose, IoGameControllerOutline, IoGiftOutline} from "react-icons/io5";
import {cloneElement, ReactElement, useState, Fragment} from "react";
import classNames from "classnames";
import {LuDownload} from "react-icons/lu";
import {RiCheckboxLine} from "react-icons/ri";
import ChallengePrizePoolIcon from "../../svgs/challenge-prize-pool.svg?react";
import {hexToRgb} from "@/utils/convertColor";
import useWindowSize from "@/hooks/useWindowSize";
import {useDispatch, useSelector} from "react-redux";
import {updateChallengeOnboardingDismissed} from "@/redux/meta/metaActions";
import {IStore} from "@/redux/defaultStore";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";


interface Props {
    challenge: Challenge;
}

enum OnboardingSection {
    GET_STARTED,
    HOW_IT_WORKS,
}

const getStartedIcons = [
    <LuDownload size="1.25rem"/>,
    <RiCheckboxLine size="1.25rem"/>,
    <IoGameControllerOutline size="1.25rem"/>,
];

const howItWorksIcons = {
    instantReward: <IoGiftOutline size="1.25rem"/>,
    prizePool: <ChallengePrizePoolIcon size="1.25rem"/>,
};

export default function BrandChallengeOnboarding({challenge}: Props) {
    const dispatch = useDispatch();
    const dismissedBrandChallengeOnboardings = useSelector((store: IStore) => store.metaStore.dismissedBrandChallengeOnboardings ?? []);
    const isChallengeOnboardingDismissed = dismissedBrandChallengeOnboardings.includes(challenge.id);
    const {onboardingSection} = challenge;
    const { isOnOverwolf } = useOverwolfInterop();
    const [viewState, setViewState] = useState<OnboardingSection>(isOnOverwolf ? OnboardingSection.HOW_IT_WORKS : OnboardingSection.GET_STARTED);
    const windowSize = useWindowSize()[0];

    if (!challenge.onboardingSection || isChallengeOnboardingDismissed) {
        return null;
    }

    const { r = 0, g = 0, b = 0 } = hexToRgb(challenge.brandColor) ?? {};
    const isOnSmallScreen = windowSize < 992;
    const brandColorInRgb = `${r}, ${g}, ${b}`;

    function getIcon(icon: ReactElement) {
        return cloneElement(icon, {
            width: "1.25rem",
            height: "1.25rem",
            style: { filter: `drop-shadow(0 0 0.5rem ${challenge.brandColor}) drop-shadow(0 0 1.5rem ${challenge.brandColor})`},
        });
    }

    return (
        <div
            style={{
                border: `1px solid ${challenge.brandColor}`,
                background: isOnSmallScreen
                    ? `linear-gradient(0deg, rgba(${brandColorInRgb}, 0.00) 50%, rgba(${brandColorInRgb}, 0.25) 100%), #212123`
                    : `linear-gradient(270deg, rgba(${brandColorInRgb}, 0.00) 50%, rgba(${brandColorInRgb}, 0.25) 100%), #212123`
                ,
            }}
            className="brand-challenge_onboarding">
            <button
                onClick={() => dispatch(updateChallengeOnboardingDismissed(challenge.id))}
                className="reset-button brand-challenge_onboarding_close">
                <IoClose size="1.75rem"/>
            </button>
            <div className="brand-challenge_onboarding_left">
                <div>
                    <h5>Welcome</h5>
                    <h4 style={{textShadow: `0 0 1.5rem ${challenge.brandColor}, 0 0 0.5rem ${challenge.brandColor}`}}>{onboardingSection.title}</h4>
                </div>
                <p>{onboardingSection.description}</p>
            </div>

            {!isOnSmallScreen && <div className="brand-challenge_onboarding_divider" />}

            <div className="brand-challenge_onboarding_right">
                <div className="brand-challenge_onboarding_right_header">
                    <button
                        className={classNames("reset-button", {"active": viewState === OnboardingSection.GET_STARTED})}
                        onClick={() => setViewState(OnboardingSection.GET_STARTED)}
                    >
                        Get Started
                    </button>
                    {" "}|{" "}
                    <button
                        className={classNames("reset-button", {"active": viewState === OnboardingSection.HOW_IT_WORKS})}
                        onClick={() => setViewState(OnboardingSection.HOW_IT_WORKS)}
                    >
                        How It Works
                    </button>
                </div>
                <div className="brand-challenge_onboarding_right_content">
                    {viewState === OnboardingSection.GET_STARTED && onboardingSection.getStartedSteps.map((step, i) => {
                        if (step.title && step.description) {
                            return <Fragment key={step.title}>
                                <div className="brand-challenge_onboarding_right_content_step">
                                    {getIcon(getStartedIcons[i])}
                                    <div>
                                        <h5>{step.title}</h5>
                                        <p>{step.description}</p>
                                    </div>
                                </div>
                                {i !== onboardingSection.getStartedSteps.length - 1 && !isOnSmallScreen && <IoArrowForwardOutline
                                    className="brand-challenge_onboarding_right_content_arrow"
                                    size="1.5rem"
                                />}
                            </Fragment>;
                        }
                    })}
                    {viewState === OnboardingSection.HOW_IT_WORKS && <>
                        {onboardingSection.instantReward && <div className="brand-challenge_onboarding_right_content_step">
                            {getIcon(howItWorksIcons.instantReward)}
                            <div>
                                <h5>{onboardingSection.instantReward.title}</h5>
                                <p>{onboardingSection.instantReward.description}</p>
                            </div>
                        </div>}
                        {onboardingSection.prizePool && <div className="brand-challenge_onboarding_right_content_step">
                            {getIcon(howItWorksIcons.prizePool)}
                            <div>
                                <h5>{onboardingSection.prizePool.title}</h5>
                                <p>{onboardingSection.prizePool.description}</p>
                            </div>
                        </div>}
                    </>}
                </div>
            </div>
        </div>
    );
}