import {ReactElement, useContext, useEffect, useState} from "react";
import FrameButton from "../../../components/buttons/FrameButton";
import {useParams} from "react-router";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {LinkProps, useNavigate} from "react-router";
import {BsFillArrowRightCircleFill} from "react-icons/bs";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";

interface Props {
    onToggle: () => void;
    display: boolean;
}

function MenuUnavailableModal(props: Props): ReactElement {
    const {placeId} = useParams<{ placeId?: string; }>();
    const history = useNavigate();
    const {brandMapState, setBrandMapState, setSelectedRestaurant} = useContext(BrandMapContext);

    // To make sure modal is closed before going back to the previous step and avoid the modal unmounting before F1 modal is closed properly
    const [goBackOnBrandMap, setGoBackOnBrandMap] = useState<boolean>(false);

    useEffect(() => {
        if (goBackOnBrandMap && !props.display) {
            setBrandMapState(BrandMapStates.SELECT_LOCATION);
            setSelectedRestaurant(null);
            setGoBackOnBrandMap(false);
        }
    }, [goBackOnBrandMap, props.display]);

    function handleSearchRestaurantButton() {
        if (brandMapState) {
            props.onToggle();
            setGoBackOnBrandMap(true);
            return;
        }

        history(placeId
            ? `/restaurants/search/${placeId}`
            : "/restaurants");
    }

    return (
        <FrameOneAutoPanel
            contentClassName="menu-unavailable-modal"
            isOpen={props.display}
            toggle={props.onToggle}
            size="xs"
        >
            <FrameAutoPanelBody>
                <h3>
					This location is currently unavailable for ordering.
                </h3>
                <p>
					To find an alternative location to order from, click the button below:
                </p>
                <div className="menu-unavailable-modal_cta">
                    <FrameButton
                        color="purple"
                        size="normal"
                        onClick={handleSearchRestaurantButton}
                        rightIcon={BsFillArrowRightCircleFill}
                    >
						Search for Restaurant
                    </FrameButton>
                </div>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default MenuUnavailableModal;
