import {FC, useState} from "react";
import {PhoneNumberBody, UpdateProfileBody, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameOnePhoneNumberInput from "./inputs/FrameOnePhoneNumberInput";
import FrameButton from "./buttons/FrameButton";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateCurrentUser,
} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import {
    formatOptionalPhoneNumberForApiSubmission,
} from "../utils/formatOptionalPhoneNumberForApiSubmission";
import {genericOnChangeHelper} from "../utils/genericOnChangeHelper";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {sendMessageToOW} from "@/hooks/useOverwolfInterop";
import {useStreamerMode} from "@/hooks/useStreamerMode";

const UserEditProfile: FC = () => {
    const dispatch = useDispatch();
    const {hideInputText} = useStreamerMode();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const user = userProfileData?.user;

    const [
        updateProfileForm,
        setUpdateProfileForm,
    ] = useState<UpdateProfileBody>({
        phoneNumber: {
            countryCode: user?.phoneNumber?.countryCode || "US",
            nationalNumber: user?.phoneNumber?.nationalNumber || "",
        },
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
    });
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken);

    function onPhoneNumberChange(phoneNumber: PhoneNumberBody) {
        setUpdateProfileForm({
            ...updateProfileForm,
            phoneNumber: phoneNumber,
        });
    }

    async function submitHandler(e) {
        e.preventDefault();
        dispatch(incrementLoading());
        try {
            await new UsersApi(getConfig(fullToken)).updateProfile({
                updateProfileBody: {
                    phoneNumber: formatOptionalPhoneNumberForApiSubmission(updateProfileForm.phoneNumber),
                    firstName: updateProfileForm.firstName,
                    lastName: updateProfileForm.lastName,
                },
            });

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
            // Tell Overwolf to update the user data
            sendMessageToOW({
                type: "de:update-user",
                payload: {userData: userRes},
            });
        } catch (err) {
            dispatch(await addError(err));
        } finally {
            await refetchUserProfile();
            dispatch(decrementLoading());
        }
    }

    return (
        <form
            className="user-edit_user-form"
            onSubmit={submitHandler}
        >
            <div className="user-edit_user-form_element">
                <div>
                    <label>First Name</label>
                    <input
                        placeholder="First name..."
                        value={updateProfileForm.firstName}
                        onChange={genericOnChangeHelper(updateProfileForm, setUpdateProfileForm, "firstName")}
                    />
                </div>

                <div>
                    <label>Last Name</label>
                    <input
                        {...hideInputText()}
                        placeholder="Last name..."
                        value={updateProfileForm.lastName}
                        onChange={genericOnChangeHelper(updateProfileForm, setUpdateProfileForm, "lastName")}
                    />
                </div>
            </div>

            <div className="user-edit_user-form_element">
                <div>
                    <FrameOnePhoneNumberInput
                        value={updateProfileForm.phoneNumber}
                        onChange={onPhoneNumberChange}
                    />
                </div>
                <div className="user-edit_user-form_element_spacer"/>
            </div>

            <div className="user-edit_user-form_submit">
                <FrameButton
                    color="purple"
                    size="normal"
                    forwardProps={{type: "submit"}}
                >
                    Save Changes
                </FrameButton>
            </div>
        </form>


    );
};

export default UserEditProfile;