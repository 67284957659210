import {PropsWithChildren, ReactElement, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router";
import {IStore} from "@/redux/defaultStore.ts";

function AuthenticatedRoute(props: PropsWithChildren): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const history = useNavigate();
    const location = useLocation();

    /**
     * Async function added, so we can leverage "await" on addError passed to the Redux dispatch action.
     *
     */
    /*
     * const handleErrorDispatch = useCallback(async () => {
     * 	props.dispatch(await addError({
     * 		type: ErrorType.APP,
     * 		message: "You must be logged in to access that page. If you were previously logged in, your session may have expired, and you must log in again.",
     * 	}));
     * }, [props]);
     */

    useEffect(() => {
        if (!fullToken?.token) {
            // handleErrorDispatch().then().catch();
            history(`/log-in?redirect=${location.pathname.slice(1)}`, {replace: true});
        }
    }, [
        fullToken,
        history,
    ]);

    if (!fullToken?.token || !currentUser) {
        return null;
    }

    return (
        <>
            {props.children}
        </>
    );
}

export default AuthenticatedRoute;
