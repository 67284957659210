/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChallengeFaqDetails
 */
export interface ChallengeFaqDetails {
    /**
     * 
     * @type {string}
     * @memberof ChallengeFaqDetails
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeFaqDetails
     */
    answer: string;
}

export function ChallengeFaqDetailsFromJSON(json: any): ChallengeFaqDetails {
    return ChallengeFaqDetailsFromJSONTyped(json, false);
}

export function ChallengeFaqDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeFaqDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'question': json['question'],
        'answer': json['answer'],
    };
}

export function ChallengeFaqDetailsToJSON(value?: ChallengeFaqDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'question': value.question,
        'answer': value.answer,
    };
}


