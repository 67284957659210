/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThirdwebRegisterBody
 */
export interface ThirdwebRegisterBody {
    /**
     * 
     * @type {number}
     * @memberof ThirdwebRegisterBody
     */
    anonymousSpinValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ThirdwebRegisterBody
     */
    referralCode?: string;
    /**
     * See https://portal.thirdweb.com/references/typescript/v5/VerifyLoginPayloadParams
     * @type {object}
     * @memberof ThirdwebRegisterBody
     */
    signedPayload: object;
}

export function ThirdwebRegisterBodyFromJSON(json: any): ThirdwebRegisterBody {
    return ThirdwebRegisterBodyFromJSONTyped(json, false);
}

export function ThirdwebRegisterBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThirdwebRegisterBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anonymousSpinValue': !exists(json, 'anonymousSpinValue') ? undefined : json['anonymousSpinValue'],
        'referralCode': !exists(json, 'referralCode') ? undefined : json['referralCode'],
        'signedPayload': json['signedPayload'],
    };
}

export function ThirdwebRegisterBodyToJSON(value?: ThirdwebRegisterBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anonymousSpinValue': value.anonymousSpinValue,
        'referralCode': value.referralCode,
        'signedPayload': value.signedPayload,
    };
}


