import {useRef} from "react";
import {useSearchParams} from "react-router";
import {useDispatch} from "react-redux";
import {
    OnboardingApi,
    UsersApi,
    ThirdwebApi,
    Token,
} from "@devour/client";
import {login} from "@/redux/auth/authActions";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateAnonymousSpin,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {removeReferralCode} from "@/redux/affiliateReferrals/affiliateActions";
import * as Sentry from "@sentry/react";
import getAnonymousSpin from "@/utils/getAnonymousSpin";
import {AnonymousSpinModalShownKey} from "@/components/modals/GoVipSpin2WinModal";
import {Account, Wallet} from "thirdweb/wallets";
import {LoginPayload, signLoginPayload} from "thirdweb/auth";
import { getUserEmail } from "thirdweb/wallets/in-app";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {sleep} from "@/utils/sleep";

interface OnLoginComplete {
    isExistingUser: boolean;
}

/**
 * Thirdweb signing sometimes returns a 400.
 * Seems to be some sort of race condition. Maybe they're still setting the cookie?
 * Reattempt if initial signing fails.
 */
async function doSignLoginPayload(walletAccount: Account): Promise<{ signature: `0x${string}`; payload: LoginPayload; }> {
    const loginPayload = await new ThirdwebApi().getThirdwebLoginPayload({
        address: walletAccount.address,
        // chainId: sepolia.id.toString(),
    });
    let signedPayload: { signature: `0x${string}`; payload: LoginPayload; };
    let attempt = 0;
    while (!signedPayload && attempt <= 2) {
        try {
            signedPayload = await signLoginPayload({
                payload: loginPayload as unknown as LoginPayload,
                account: walletAccount,
            });
        } catch (err) {
            console.error(`signLoginPayload err attempt ${attempt}`, err);
            attempt++;
            await sleep(1000);
        }
    }
    // If still can't be signed then just reset.
    if (!signedPayload) {
        throw new Error("Failed to verify your user. Please try to login again.");
    }
    return signedPayload;
}

async function submitSignUp(walletAccount: Account, referralCode?: string) {
    const signedPayload = await doSignLoginPayload(walletAccount);
    const anonymousSpin = getAnonymousSpin();
    await new ThirdwebApi().thirdwebRegister({
        thirdwebRegisterBody: {
            anonymousSpinValue: anonymousSpin?.spinValue,
            referralCode: referralCode,
            signedPayload,
        },
    });
}

/**
 * Hook to handle post-login functionality
 */
export default function useOnAuthThirdweb() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const signedUrlKey = searchParams.get("suk");
    const {sendMessageToOW} = useOverwolfInterop();

    // Define a ref to track if a request is currently in flight
    const requestInFlight = useRef(false);

    /**
     * Reapply the user profile to redux
     * @param token
     * @param isFreshLogin
     */
    async function onReauthorize(token: Token, isFreshLogin: boolean) {
        if (!token) {
            return;
        }

        // grab the profile to save into redux
        const userRes = await new UsersApi(getConfig(token)).getProfile();

        // Send user data to overwolf
        sendMessageToOW({
            type: "de:data",
            isLogin: isFreshLogin,
            payload: {
                token: token,
                userData: userRes,
            },
        });

        //  save the token and profile into redux
        dispatch(login(token));
        dispatch(updateCurrentUser(userRes));
        dispatch(removeReferralCode());

        Sentry.setUser({
            email: userRes.user.email,
        });
        Sentry.setContext("userInfo", userRes);

        if (signedUrlKey) {
            await new OnboardingApi(getConfig(token)).mergeUserWithSignedUrlKey({
                signedUrlKey: signedUrlKey,
            });
        }

        const anonymousSpin = getAnonymousSpin();
        if (anonymousSpin) {
            dispatch(updateAnonymousSpin(undefined));
            window[AnonymousSpinModalShownKey] = false;
        }
    }

    async function onAuthComplete(wallet: Wallet, referralCode?: string): Promise<OnLoginComplete> {
        if (!wallet || requestInFlight.current) {
            return;
        }
        const walletAccount = wallet?.getAccount();

        // Set the request in flight ref to true
        requestInFlight.current = true;

        dispatch(incrementLoading());

        try {

            const email = await getUserEmail({
                client: thirdwebAuthClient,
            });
            let isExistingUser: boolean = true;
            if (email) {
                const existingRes = await new UsersApi(getConfig()).validateUserExistence({
                    email: email,
                });
                isExistingUser = existingRes._exists;
                if (!isExistingUser) {
                    await submitSignUp(walletAccount, referralCode);
                }
            }

            const signedPayload = await doSignLoginPayload(walletAccount);
            const loginPayloadResult = await new ThirdwebApi(getConfig()).postThirdwebLoginPayload({
                thirdwebLoginBody: {
                    signedPayload,
                },
            });

            await onReauthorize(loginPayloadResult.token, true);

            return {
                isExistingUser,
            };
        } catch (err) {
            dispatch(await addError(err));
        } finally {
            // After the request is finished, set the request in flight ref to false
            requestInFlight.current = false;

            dispatch(decrementLoading());
        }

    }

    return {
        onAuthComplete,
        onReauthorize,
    };
}
