/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents Overwolf game mapping information.
 * @export
 * @interface OWGameEventMap
 */
export interface OWGameEventMap {
    /**
     * 
     * @type {string}
     * @memberof OWGameEventMap
     */
    gameId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OWGameEventMap
     */
    mappings: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OWGameEventMap
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OWGameEventMap
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OWGameEventMap
     */
    updatedAt: number;
}

export function OWGameEventMapFromJSON(json: any): OWGameEventMap {
    return OWGameEventMapFromJSONTyped(json, false);
}

export function OWGameEventMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWGameEventMap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'mappings': json['mappings'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function OWGameEventMapToJSON(value?: OWGameEventMap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameId': value.gameId,
        'mappings': value.mappings,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


