import {ReactElement, ReactNode} from "react";
import {useSelector} from "react-redux";
import {ApiError} from "@devour/client";
import {IStore} from "../../../redux/defaultStore";
import ErrorModal from "./ErrorModal";

function ErrorModalManager(): ReactElement {
    const errors = useSelector((store: IStore) => store.metaStore.errors);

    /**
     * Render a modal for each error found in Redux.
     *
     * @param e
     * @param i
     */
    function renderModal(e: ApiError, i: number): ReactNode {
        if (e?.message === "There was an unexpected error.") return null;
        if (e?.message === "There was an error checking the current price of DPAY.") return null;
        if (e?.message === "This order does not belong to you.") return null;
        if (e?.message === "FUEL is unavailable. Please contact customer support.") return null;
        if (e?.message === "You are not logged in. Please log in to continue.") return null;
        return (
            <ErrorModal
                key={`error-modal_${i}`}
                index={i}
                apiError={e}
            />
        );
    }


    return (
        <>
            {errors?.map(renderModal)}
        </>
    );
}

export default ErrorModalManager;
