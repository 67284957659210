/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    EVMChain,
    EVMChainFromJSON,
    EVMChainFromJSONTyped,
    EVMChainToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents an NFT collection being tracked in the system
 * @export
 * @interface NftTracker
 */
export interface NftTracker {
    /**
     * 
     * @type {string}
     * @memberof NftTracker
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftTracker
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftTracker
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof NftTracker
     */
    grouping: string;
    /**
     * 
     * @type {string}
     * @memberof NftTracker
     */
    collectionId: string;
    /**
     * 
     * @type {string}
     * @memberof NftTracker
     */
    contractAddress: string;
    /**
     * 
     * @type {EVMChain}
     * @memberof NftTracker
     */
    evmChain: EVMChain;
    /**
     * 
     * @type {string}
     * @memberof NftTracker
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NftTracker
     */
    openSea: string;
    /**
     * 
     * @type {boolean}
     * @memberof NftTracker
     */
    overrideSupportsInterface?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NftTracker
     */
    isIneligibleForLeaderboards?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NftTracker
     */
    maxLimitMint?: number;
}

export function NftTrackerFromJSON(json: any): NftTracker {
    return NftTrackerFromJSONTyped(json, false);
}

export function NftTrackerFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftTracker {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'grouping': json['grouping'],
        'collectionId': json['collectionId'],
        'contractAddress': json['contractAddress'],
        'evmChain': EVMChainFromJSON(json['evmChain']),
        'name': json['name'],
        'openSea': json['openSea'],
        'overrideSupportsInterface': !exists(json, 'overrideSupportsInterface') ? undefined : json['overrideSupportsInterface'],
        'isIneligibleForLeaderboards': !exists(json, 'isIneligibleForLeaderboards') ? undefined : json['isIneligibleForLeaderboards'],
        'maxLimitMint': !exists(json, 'maxLimitMint') ? undefined : json['maxLimitMint'],
    };
}

export function NftTrackerToJSON(value?: NftTracker | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'grouping': value.grouping,
        'collectionId': value.collectionId,
        'contractAddress': value.contractAddress,
        'evmChain': EVMChainToJSON(value.evmChain),
        'name': value.name,
        'openSea': value.openSea,
        'overrideSupportsInterface': value.overrideSupportsInterface,
        'isIneligibleForLeaderboards': value.isIneligibleForLeaderboards,
        'maxLimitMint': value.maxLimitMint,
    };
}


