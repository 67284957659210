/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    GuildQuestMode,
    GuildQuestModeFromJSON,
    GuildQuestModeFromJSONTyped,
    GuildQuestModeToJSON,
    GuildQuestUserStat,
    GuildQuestUserStatFromJSON,
    GuildQuestUserStatFromJSONTyped,
    GuildQuestUserStatToJSON,
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    OWQuestReward,
    OWQuestRewardFromJSON,
    OWQuestRewardFromJSONTyped,
    OWQuestRewardToJSON,
    OWRewardMilestone,
    OWRewardMilestoneFromJSON,
    OWRewardMilestoneFromJSONTyped,
    OWRewardMilestoneToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the quests that can be completed.
 * @export
 * @interface GuildQuest
 */
export interface GuildQuest {
    /**
     * 
     * @type {string}
     * @memberof GuildQuest
     */
    guild?: string;
    /**
     * The game that the quest is for if quest is event based. Required for event based quests.
     * @type {string}
     * @memberof GuildQuest
     */
    game?: string;
    /**
     * The title of the quest.
     * @type {string}
     * @memberof GuildQuest
     */
    name: string;
    /**
     * The description of the quest.
     * @type {string}
     * @memberof GuildQuest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GuildQuest
     */
    isGuildQualifier?: boolean;
    /**
     * 
     * @type {Array<OWQuestReward>}
     * @memberof GuildQuest
     */
    questRewards?: Array<OWQuestReward>;
    /**
     * 
     * @type {OWGameEventType}
     * @memberof GuildQuest
     */
    eventType: OWGameEventType;
    /**
     * The target amount of progress (in minutes) to reach to claim the reward. Should be in days if mode is DIFF_DAYS.
     * @type {number}
     * @memberof GuildQuest
     */
    target: number;
    /**
     * 
     * @type {Array<OWRewardMilestone>}
     * @memberof GuildQuest
     */
    milestones?: Array<OWRewardMilestone>;
    /**
     * 
     * @type {boolean}
     * @memberof GuildQuest
     */
    isActive?: boolean;
    /**
     * 
     * @type {GuildQuestMode}
     * @memberof GuildQuest
     */
    mode?: GuildQuestMode;
    /**
     * The target amount of progress (in minutes) (Only relevant for DIFF_DAYS mode)
     * @type {number}
     * @memberof GuildQuest
     */
    dailyTarget?: number;
    /**
     * 
     * @type {GuildQuestUserStat}
     * @memberof GuildQuest
     */
    userStat?: GuildQuestUserStat;
    /**
     * 
     * @type {string}
     * @memberof GuildQuest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GuildQuest
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof GuildQuest
     */
    updatedAt: number;
}

export function GuildQuestFromJSON(json: any): GuildQuest {
    return GuildQuestFromJSONTyped(json, false);
}

export function GuildQuestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuildQuest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guild': !exists(json, 'guild') ? undefined : json['guild'],
        'game': !exists(json, 'game') ? undefined : json['game'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isGuildQualifier': !exists(json, 'isGuildQualifier') ? undefined : json['isGuildQualifier'],
        'questRewards': !exists(json, 'questRewards') ? undefined : ((json['questRewards'] as Array<any>).map(OWQuestRewardFromJSON)),
        'eventType': OWGameEventTypeFromJSON(json['eventType']),
        'target': json['target'],
        'milestones': !exists(json, 'milestones') ? undefined : ((json['milestones'] as Array<any>).map(OWRewardMilestoneFromJSON)),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'mode': !exists(json, 'mode') ? undefined : GuildQuestModeFromJSON(json['mode']),
        'dailyTarget': !exists(json, 'dailyTarget') ? undefined : json['dailyTarget'],
        'userStat': !exists(json, 'userStat') ? undefined : GuildQuestUserStatFromJSON(json['userStat']),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function GuildQuestToJSON(value?: GuildQuest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guild': value.guild,
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'isGuildQualifier': value.isGuildQualifier,
        'questRewards': value.questRewards === undefined ? undefined : ((value.questRewards as Array<any>).map(OWQuestRewardToJSON)),
        'eventType': OWGameEventTypeToJSON(value.eventType),
        'target': value.target,
        'milestones': value.milestones === undefined ? undefined : ((value.milestones as Array<any>).map(OWRewardMilestoneToJSON)),
        'isActive': value.isActive,
        'mode': GuildQuestModeToJSON(value.mode),
        'dailyTarget': value.dailyTarget,
        'userStat': GuildQuestUserStatToJSON(value.userStat),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


