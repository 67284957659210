/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NftRewardPrizeType,
    NftRewardPrizeTypeFromJSON,
    NftRewardPrizeTypeFromJSONTyped,
    NftRewardPrizeTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateNftRewardPrizeBody
 */
export interface UpdateNftRewardPrizeBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateNftRewardPrizeBody
     */
    name: string;
    /**
     * 
     * @type {NftRewardPrizeType}
     * @memberof UpdateNftRewardPrizeBody
     */
    type: NftRewardPrizeType;
    /**
     * 
     * @type {string}
     * @memberof UpdateNftRewardPrizeBody
     */
    prizeRevealName: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNftRewardPrizeBody
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNftRewardPrizeBody
     */
    emailOnWin: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateNftRewardPrizeBody
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateNftRewardPrizeBody
     */
    winningImage?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNftRewardPrizeBody
     */
    sortOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNftRewardPrizeBody
     */
    onlyAvailableAfterWins?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateNftRewardPrizeBody
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNftRewardPrizeBody
     */
    nftTrackerId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNftRewardPrizeBody
     */
    claimFormUrl?: string;
}

export function UpdateNftRewardPrizeBodyFromJSON(json: any): UpdateNftRewardPrizeBody {
    return UpdateNftRewardPrizeBodyFromJSONTyped(json, false);
}

export function UpdateNftRewardPrizeBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNftRewardPrizeBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': NftRewardPrizeTypeFromJSON(json['type']),
        'prizeRevealName': json['prizeRevealName'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'emailOnWin': json['emailOnWin'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'winningImage': !exists(json, 'winningImage') ? undefined : json['winningImage'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'onlyAvailableAfterWins': !exists(json, 'onlyAvailableAfterWins') ? undefined : json['onlyAvailableAfterWins'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'nftTrackerId': !exists(json, 'nftTrackerId') ? undefined : json['nftTrackerId'],
        'claimFormUrl': !exists(json, 'claimFormUrl') ? undefined : json['claimFormUrl'],
    };
}

export function UpdateNftRewardPrizeBodyToJSON(value?: UpdateNftRewardPrizeBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': NftRewardPrizeTypeToJSON(value.type),
        'prizeRevealName': value.prizeRevealName,
        'quantity': value.quantity,
        'emailOnWin': value.emailOnWin,
        'amount': value.amount,
        'winningImage': value.winningImage,
        'sortOrder': value.sortOrder,
        'onlyAvailableAfterWins': value.onlyAvailableAfterWins,
        'description': value.description,
        'nftTrackerId': value.nftTrackerId,
        'claimFormUrl': value.claimFormUrl,
    };
}


