import { ApiError, ErrorType, HandoffOptions, MenuOrder, MenuOrderStatus } from "@devour/client";
import MenuSuccessTrackerProgressBar from "./MenuSuccessTrackerProgressBar";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { menuOrderTrackerQueryKey, useMenuOrderTracker } from "@/hooks/menuOrder/useMenuOrderTracker";
import { OrderTrackerDataFullfillmentStatus } from "@/types/OrderTrackerData";
import { useNavigate, useSearchParams } from "react-router";
import MenuOrderSuccessTrackerMap from "./MenuOrderSuccessTrackerMap";
import FrameButton from "../buttons/FrameButton";
import classNames from "classnames";
import { MdImage, MdMap, MdOpenInFull } from "react-icons/md";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useRestaurant } from "@/hooks/useRestaurant";
import { getScheduledOrderData } from "@/utils/CheckoutDetailsScheduleTime/createScheduleString";
import {
    cancelledOnlyList,
    completedList,
    erroredList,
    menuOrderFinalizedStatuses,
    nonReceivedList,
    notSubmittedList,
    ongoingStatusesList,
} from "@/pages/menu-orders/MenuOrderListPage";
import { FiAlertCircle } from "react-icons/fi";
import { addError, toggleChatOpen } from "@/redux/meta/metaActions";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import useThemePreference from "@/hooks/useThemePreference";
import { useCreateMenuOrderItems } from "@/hooks/menuOrder/useCreateMenuOrderItems";
import { useQueryClient } from "@tanstack/react-query";
import Skeleton from "../skeletons/Skeleton";
import { useStreamerMode } from "@/hooks/useStreamerMode";

interface props {
    order: MenuOrder;
}

interface statusData {
    title: string;
    subtitle: string;
    stepNumber?: number;
    maxSteps?: number;
}
const defaultPreparationTime = 10;
const MenuOrderSuccessTracker: FC<props> = ({ order }) => {
    const isDigital = order.handoff === HandoffOptions.DIGITAL;
    const isDelivery = order.handoff === HandoffOptions.DELIVERY;
    const isOrderFinalized = menuOrderFinalizedStatuses.includes(order.status);
    const isChatOpen = useSelector((store: IStore) => store.metaStore.isChatOpen);
    const { streamerModeEnabled } = useStreamerMode();
    const { isOnDarkMode } = useThemePreference();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const client = useQueryClient();
    const hasError = erroredList.includes(order.status);
    const [isShowingDropoutImage, setIsShowingDropoutImage] = useState(false);
    const [displayDropoutImage, setDisplayDropoutImage] = useState(false);
    const [searchParams] = useSearchParams();
    const { data: restaurant } = useRestaurant(order.business);
    const restaurantTimeZone = restaurant?.timeZone || moment.tz.guess();
    const { data, isLoading, refetch, isFetched } = useMenuOrderTracker(
        order.deliveryId,
        searchParams.get("debug")
            ? {
                order: order,
                restaurant: restaurant,
                status: searchParams.get("debug") as OrderTrackerDataFullfillmentStatus,
            }
            : undefined,
    );
    const { mutateAsync: createMenuOrderItems } = useCreateMenuOrderItems({
        skipOnError: true,
        restaurantId: order.business,
    });
    const [currentDate, setCurrentDate] = useState(new Date());
    const trackerFetchIntervalKey = useRef(null);
    const currentTimeIntervalKey = useRef(null);
    const readyByOffsetTime = defaultPreparationTime;
    const isDeliveryCompleted = completedList.includes(order.status);

    const canShowDropoutImage = data?.order?.dropoff_photo;
    useEffect(() => {
        if (isDelivery) {
            trackerFetchIntervalKey.current = setInterval(() => {
                if (!isLoading) {
                    refetch();
                }
                const trackerData = client.getQueryData([menuOrderTrackerQueryKey, order.deliveryId]);
                if (trackerData && isOrderFinalized) {
                    clearInterval(trackerFetchIntervalKey.current);
                }
            }, 5000);
        }
        return () => {
            if (trackerFetchIntervalKey.current) {
                clearInterval(trackerFetchIntervalKey.current);
            }
        };
    }, [isLoading, isDelivery, isOrderFinalized]);
    useEffect(() => {
        if (!isDelivery) {
            currentTimeIntervalKey.current = setInterval(() => {
                setCurrentDate(new Date());
            }, 1000);
        }
        return () => {
            if (currentTimeIntervalKey.current) {
                clearInterval(currentTimeIntervalKey.current);
            }
        };
    }, [isDelivery]);

    const getEstimatedLabel = () => {
        if (isDigital) {
            return "Processing";
        }
        return isDelivery ? "Delivery" : "Pickup";
    };

    const getOrderReadyBy = () => {
        if (order.scheduledTime) {
            return moment(getScheduledOrderData(order.scheduledTime, restaurantTimeZone)?.startTime);
        }

        if (isDelivery) {
            if ([...ongoingStatusesList, MenuOrderStatus.DELIVERED].includes(order.status)) {
                if (!isFetched) {
                    return null;
                }
                if (data?.fulfillment?.runner_estimated_dropoff) {
                    const estimatedDropoff = moment(data?.fulfillment?.runner_estimated_dropoff);
                    return moment(getScheduledOrderData(estimatedDropoff.unix(), restaurantTimeZone)?.startTime);
                }
            }
        }

        return order.readyBy ? moment(getScheduledOrderData(order.readyBy, restaurantTimeZone)?.startTime) : null;
    };

    const shouldShowOrderData = () => {
        if (isDigital) {
            return true;
        }
        const noOrderDataStatuses = !isDelivery ? nonReceivedList : [...nonReceivedList, MenuOrderStatus.RECEIVED];
        return !noOrderDataStatuses.includes(order.status);
    };

    const orderStatusData = useMemo((): statusData => {
        const receivingStatus = {
            title: "Receiving Your Order",
            subtitle: "Hang tight! Grabbing your order details — give us just a few minutes!",
        };

        if (cancelledOnlyList.includes(order.status)) {
            return {
                title: "Order Cancelled",
                subtitle:
                    "There has been a problem with delivering your order. Your order has been cancelled and refunded. We apologize for the inconvenience.",
            };
        }

        if (notSubmittedList.includes(order.status)) {
            return {
                title: "Order Not Submitted",
                subtitle:
                    "We couldn’t process your order, and your payment has been refunded. We apologize for the inconvenience.",
            };
        }

        if (isDigital) {
            if (order.status === MenuOrderStatus.PROCESSING) {
                return {
                    title: "Processing your order...",
                    subtitle: "Your order will be sent to your email within 48-hours.",
                    maxSteps: 2,
                    stepNumber: 1,
                };
            }
            if (order.status === MenuOrderStatus.DELIVERED) {
                return {
                    title: "Your order has been delivered",
                    subtitle: "Please check your email for information on your digital order.",
                    maxSteps: 2,
                    stepNumber: 2,
                };
            }
        }


        if (nonReceivedList.includes(order.status)) {
            return receivingStatus;
        }
        if (isDelivery) {
            if (order.status === MenuOrderStatus.RECEIVED) {
                return {
                    title: "Order Received! 🎉",
                    subtitle: "Got it! We’ve received your order and are getting it ready for confirmation.",
                };
            }
            if (order.status === MenuOrderStatus.DELIVERYSERVICE) {
                return {
                    title: "Order Confirmed",
                    subtitle: "All set! Your order is confirmed and will be on its way soon.",
                    stepNumber: 1,
                    maxSteps: 4,
                };
            }

            if (order.status === MenuOrderStatus.DRIVERASSIGNED) {
                return {
                    title: "Driver Is Assigned 🚙💨",
                    subtitle: "A driver’s been assigned to your order and is gearing up for pickup!",
                    stepNumber: 2,
                    maxSteps: 4,
                };
            }

            if (order.status === MenuOrderStatus.DRIVERPICKUP) {
                return {
                    title: "Order Is Being Picked Up!",
                    subtitle: "Almost there! Your driver is picking up your order as we speak.",
                    stepNumber: 2,
                    maxSteps: 4,
                };
            }

            if (order.status === MenuOrderStatus.OUTFORDELIVERY) {
                return {
                    title: "Your Order Is On Its Way",
                    subtitle: "Food’s on the move! Your driver is en route to you.",
                    stepNumber: 3,
                    maxSteps: 4,
                };
            }

            if (order.status === MenuOrderStatus.DRIVERATDESTINATION) {
                return {
                    title: "Driver Is Arriving With Your Order",
                    subtitle: "Heads up! Your driver is almost at your location.",
                    stepNumber: 3,
                    maxSteps: 4,
                };
            }
            if (completedList.includes(order.status)) {
                return {
                    title: "Your Order Was Delivered!",
                    subtitle: "Your order has arrived! Time to dig in and enjoy your delicious meal.",
                    stepNumber: 4,
                    maxSteps: 4,
                };
            }

            return receivingStatus;
        }
        /*
         * Pickup orders
         * Disabled the completed state for now
         * if (isDeliveryCompleted) {
         *     return {
         *         title: "Order Completed!",
         *         subtitle: "Your order will be ready for pickup soon! Make sure to arrive on time. Enjoy your meal!",
         *     };
         * }
         */

        return {
            title: `📣 Pickup Ready at ${getOrderReadyBy()
                .add(readyByOffsetTime, "minutes")
                .tz(restaurantTimeZone)
                .format("LT")}!`,
            subtitle:
                "Your order will be ready for pickup soon! Make sure to arrive on time. Be sure to check the accuracy of your order at the time of pick-up, we are unable to provide refunds for missing or incorrect items on pick-up orders. Enjoy your meal!",
        };
    }, [order.status, data, isDelivery]);

    const getFutureDateCompletition = (futureDate: Date) => {
        const startDate = moment(new Date(order.createdAt)).tz(restaurantTimeZone)
            .toDate();
        const elapsedTime = currentDate.getTime() - startDate.getTime();
        const totalTimeSpan = futureDate.getTime() - startDate.getTime();
        const percentageCompletion = elapsedTime / totalTimeSpan * 100;
        return Math.floor(Math.min(Math.max(percentageCompletion, 0), 100));
    };

    const getOrderProgress = () => {
        if (isDigital && isDeliveryCompleted) {
            return 100;
        }
        if (!isDelivery && getOrderReadyBy()) {
            if (nonReceivedList.includes(order.status)) {
                return null;
            }
            if (isDeliveryCompleted) {
                return 100;
            }
            const futureDate = getOrderReadyBy().add(readyByOffsetTime, "minutes")
                .toDate();
            return getFutureDateCompletition(futureDate);
        }
        return null;
    };

    function toggleHelpModal(): void {
        dispatch(toggleChatOpen(!isChatOpen));
    }

    async function handleReorder() {
        navigate(`${restaurant?.url}/${order.address?.placeId}`);
        const newMenuOrder = await createMenuOrderItems(order);
        if (newMenuOrder?.validationErrors?.length > 0) {
            const errors: Array<ApiError> = newMenuOrder.validationErrors.map((error) => {
                return {
                    type: ErrorType.APP,
                    message: error.errorMessage,
                };
            });

            // const errors = await Promise.all(newMenuOrder.validationErrors.map((error) => makeApiError(error.error)));
            dispatch(await addError({
                type: ErrorType.FORM,
                message:
                        "Some items in your previous order are not available anymore. They have been skipped and everything else has been added to your cart.",
                errors: errors,
            }));
        }
        console.log("newmenuOrder", newMenuOrder);
    }

    function renderReadyByTime() {
        if (isDigital) {
            return (
                <h4 className="menu-order-success-tracker_order-data_delivery_time">
                    24 - 48 hours
                </h4>
            );
        }
        return (
            <h4 className="menu-order-success-tracker_order-data_delivery_time">
                {" "}
                {getOrderReadyBy().tz(restaurantTimeZone)
                    .format("LT")} -{" "}
                {getOrderReadyBy()
                    .tz(restaurantTimeZone)
                    .add(readyByOffsetTime, "minutes")
                    .format("LT")}
            </h4>
        );
    }

    return (
        <div className="menu-order-success-tracker">
            {shouldShowOrderData() &&
                <div
                    className={classNames("menu-order-success-tracker_order-data", {
                        error: hasError,
                    })}
                >
                    <div className="menu-order-success-tracker_order-data_delivery">
                        {!hasError
                            ? <>
                                <h4 className="menu-order-success-tracker_order-data_delivery_title">
                                    Estimated {getEstimatedLabel()} Time
                                </h4>
                                {getOrderReadyBy()
                                    ? renderReadyByTime()
                                    : <Skeleton height={22} classes="" rows={1} width="190px"></Skeleton>
                                }
                            </>
                            : <FiAlertCircle className="menu-order-success-tracker_order-data_delivery_error" />
                        }
                    </div>
                    {!notSubmittedList.includes(order.status) &&
                        <span className="menu-order-success-tracker_order-data_order-chip">
                            <span>Order</span> #{order.pickupCode || order.id}
                        </span>
                    }
                </div>
            }
            {!hasError &&
                <MenuSuccessTrackerProgressBar
                    progress={getOrderProgress()}
                    currentStep={orderStatusData.stepNumber}
                    stepsCount={orderStatusData.maxSteps}
                />
            }
            <div className="menu-order-success-tracker_heading">
                <h2 className="menu-order-success-tracker_heading_title">{orderStatusData.title}</h2>
                <p className="menu-order-success-tracker_heading_subtitle">{orderStatusData.subtitle}</p>
            </div>

            {canShowDropoutImage && displayDropoutImage &&
                <Lightbox mainSrc={data.order.dropoff_photo} onCloseRequest={() => setDisplayDropoutImage(false)} />
            }

            {hasError &&
                <div className="menu-order-success-tracker_error-actions">
                    <FrameButton color="purple-outline" size="large" onClick={handleReorder}>
                        Reorder
                    </FrameButton>

                    <FrameButton
                        color={isOnDarkMode ? "purple" : "purple-text-no-border"}
                        size="large"
                        onClick={toggleHelpModal}
                    >
                        Get Help
                    </FrameButton>
                </div>
            }

            {shouldShowOrderData() && !isDigital && !notSubmittedList.includes(order.status) &&
                <>
                    {canShowDropoutImage &&
                        <div className="menu-order-success-tracker_dropout-switch">
                            <FrameButton
                                onClick={() => setIsShowingDropoutImage(false)}
                                color="purple"
                                className={classNames({
                                    active: !isShowingDropoutImage,
                                })}
                                size="narrow"
                                leftIcon={() => <MdMap />}
                            >
                                Map
                            </FrameButton>
                            <FrameButton
                                onClick={() => setIsShowingDropoutImage(true)}
                                color="purple"
                                className={classNames({
                                    active: isShowingDropoutImage,
                                })}
                                size="narrow"
                                leftIcon={() => <MdImage />}
                            >
                                Image
                            </FrameButton>
                        </div>
                    }
                    <div
                        className={classNames("menu-order-success-tracker_map", {
                            "show-dropout": isShowingDropoutImage,
                            "streamer-mode": streamerModeEnabled,
                        })}
                    >
                        {!isDigital && <MenuOrderSuccessTrackerMap order={order} trackerData={data} />}
                        {streamerModeEnabled &&
                                    <div className="menu-order-success-tracker_map_streamer-mode-message">
                                        <h4>Map unavailable on Streamer Mode.</h4>
                                        <p>
                                            To track your order, turn off Streamer
                                            mode, and click "Track" order on the Orders page. If you are streaming, you
                                            can do this on your mobile device, or on a separate hidden window
                                        </p>
                                    </div>
                        }
                        {canShowDropoutImage && !streamerModeEnabled &&
                            <>
                                <img
                                    src={data.order.dropoff_photo}
                                    className="menu-order-success-tracker_map_dropout-img"
                                    alt=""
                                />
                                {isShowingDropoutImage &&
                                    <FrameButton
                                        onClick={() => setDisplayDropoutImage(true)}
                                        color="dark"
                                        className="menu-order-success-tracker_map_expand-button"
                                        size="icon"
                                        leftIcon={() => <MdOpenInFull />}
                                    ></FrameButton>
                                }
                            </>
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default MenuOrderSuccessTracker;
