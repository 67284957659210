/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    OWQuestReward,
    OWQuestRewardFromJSON,
    OWQuestRewardFromJSONTyped,
    OWQuestRewardToJSON,
    OWRewardMaxType,
    OWRewardMaxTypeFromJSON,
    OWRewardMaxTypeFromJSONTyped,
    OWRewardMaxTypeToJSON,
    OWRewardMilestone,
    OWRewardMilestoneFromJSON,
    OWRewardMilestoneFromJSONTyped,
    OWRewardMilestoneToJSON,
    OWRewardMode,
    OWRewardModeFromJSON,
    OWRewardModeFromJSONTyped,
    OWRewardModeToJSON,
    OWUserStat,
    OWUserStatFromJSON,
    OWUserStatFromJSONTyped,
    OWUserStatToJSON,
    QuestReset,
    QuestResetFromJSON,
    QuestResetFromJSONTyped,
    QuestResetToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the rewards that can be claimed.
 * @export
 * @interface OWReward
 */
export interface OWReward {
    /**
     * 
     * @type {string}
     * @memberof OWReward
     */
    game?: string;
    /**
     * The title of the reward.
     * @type {string}
     * @memberof OWReward
     */
    name: string;
    /**
     * The description of the reward.
     * @type {string}
     * @memberof OWReward
     */
    description?: string;
    /**
     * If the quest is active (shows on FE and processes event logs in BE) or not.
     * @type {boolean}
     * @memberof OWReward
     */
    isActive: boolean;
    /**
     * If the quest complete notification should be shown.
     * @type {boolean}
     * @memberof OWReward
     */
    showQuestCompleteNotification?: boolean;
    /**
     * The message to show in the quest complete notification.
     * @type {string}
     * @memberof OWReward
     */
    questCompleteNotificationMessage?: string;
    /**
     * The maximum amount of XP that can be claimed for the 1hr time quest.
     * @type {number}
     * @memberof OWReward
     */
    rewardMax?: number;
    /**
     * 
     * @type {OWRewardMaxType}
     * @memberof OWReward
     */
    rewardMaxType?: OWRewardMaxType;
    /**
     * 
     * @type {QuestReset}
     * @memberof OWReward
     */
    questReset: QuestReset;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWReward
     */
    rewardCategory: OWLogCategory;
    /**
     * 
     * @type {string}
     * @memberof OWReward
     */
    eventType: string;
    /**
     * The target amount of progress to reach to claim the reward.
     * @type {number}
     * @memberof OWReward
     */
    target: number;
    /**
     * 
     * @type {Array<OWRewardMilestone>}
     * @memberof OWReward
     */
    milestones?: Array<OWRewardMilestone>;
    /**
     * The timestamp when the event was created.
     * @type {Date}
     * @memberof OWReward
     */
    createdAt?: Date;
    /**
     * The timestamp when the event was last updated.
     * @type {Date}
     * @memberof OWReward
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<OWQuestReward>}
     * @memberof OWReward
     */
    questRewards?: Array<OWQuestReward>;
    /**
     * Ref to the challenge of this quest (only for challenge quests).
     * @type {string}
     * @memberof OWReward
     */
    challenge?: string;
    /**
     * 
     * @type {OWUserStat}
     * @memberof OWReward
     */
    userStat?: OWUserStat;
    /**
     * If the TIME quest is available for all supported games that are not in our database (OWGames).
     * @type {boolean}
     * @memberof OWReward
     */
    onAllSupportedGames?: boolean;
    /**
     * 
     * @type {OWRewardMode}
     * @memberof OWReward
     */
    mode?: OWRewardMode;
    /**
     * 
     * @type {string}
     * @memberof OWReward
     */
    id: string;
}

export function OWRewardFromJSON(json: any): OWReward {
    return OWRewardFromJSONTyped(json, false);
}

export function OWRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'game': !exists(json, 'game') ? undefined : json['game'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isActive': json['isActive'],
        'showQuestCompleteNotification': !exists(json, 'showQuestCompleteNotification') ? undefined : json['showQuestCompleteNotification'],
        'questCompleteNotificationMessage': !exists(json, 'questCompleteNotificationMessage') ? undefined : json['questCompleteNotificationMessage'],
        'rewardMax': !exists(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMaxType': !exists(json, 'rewardMaxType') ? undefined : OWRewardMaxTypeFromJSON(json['rewardMaxType']),
        'questReset': QuestResetFromJSON(json['questReset']),
        'rewardCategory': OWLogCategoryFromJSON(json['rewardCategory']),
        'eventType': json['eventType'],
        'target': json['target'],
        'milestones': !exists(json, 'milestones') ? undefined : ((json['milestones'] as Array<any>).map(OWRewardMilestoneFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'questRewards': !exists(json, 'questRewards') ? undefined : ((json['questRewards'] as Array<any>).map(OWQuestRewardFromJSON)),
        'challenge': !exists(json, 'challenge') ? undefined : json['challenge'],
        'userStat': !exists(json, 'userStat') ? undefined : OWUserStatFromJSON(json['userStat']),
        'onAllSupportedGames': !exists(json, 'onAllSupportedGames') ? undefined : json['onAllSupportedGames'],
        'mode': !exists(json, 'mode') ? undefined : OWRewardModeFromJSON(json['mode']),
        'id': json['id'],
    };
}

export function OWRewardToJSON(value?: OWReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'isActive': value.isActive,
        'showQuestCompleteNotification': value.showQuestCompleteNotification,
        'questCompleteNotificationMessage': value.questCompleteNotificationMessage,
        'rewardMax': value.rewardMax,
        'rewardMaxType': OWRewardMaxTypeToJSON(value.rewardMaxType),
        'questReset': QuestResetToJSON(value.questReset),
        'rewardCategory': OWLogCategoryToJSON(value.rewardCategory),
        'eventType': value.eventType,
        'target': value.target,
        'milestones': value.milestones === undefined ? undefined : ((value.milestones as Array<any>).map(OWRewardMilestoneToJSON)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'questRewards': value.questRewards === undefined ? undefined : ((value.questRewards as Array<any>).map(OWQuestRewardToJSON)),
        'challenge': value.challenge,
        'userStat': OWUserStatToJSON(value.userStat),
        'onAllSupportedGames': value.onAllSupportedGames,
        'mode': OWRewardModeToJSON(value.mode),
        'id': value.id,
    };
}


