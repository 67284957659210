/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Business,
    BusinessFromJSON,
    BusinessFromJSONTyped,
    BusinessToJSON,
    BusinessLabel,
    BusinessLabelFromJSON,
    BusinessLabelFromJSONTyped,
    BusinessLabelToJSON,
    BusinessServiceAvailability,
    BusinessServiceAvailabilityFromJSON,
    BusinessServiceAvailabilityFromJSONTyped,
    BusinessServiceAvailabilityToJSON,
    BusinessServiceAvailabilityOverride,
    BusinessServiceAvailabilityOverrideFromJSON,
    BusinessServiceAvailabilityOverrideFromJSONTyped,
    BusinessServiceAvailabilityOverrideToJSON,
    BusinessType,
    BusinessTypeFromJSON,
    BusinessTypeFromJSONTyped,
    BusinessTypeToJSON,
    HandoffOptions,
    HandoffOptionsFromJSON,
    HandoffOptionsFromJSONTyped,
    HandoffOptionsToJSON,
    MenuCategorySearchEssential,
    MenuCategorySearchEssentialFromJSON,
    MenuCategorySearchEssentialFromJSONTyped,
    MenuCategorySearchEssentialToJSON,
    MenuItemSearchEssential,
    MenuItemSearchEssentialFromJSON,
    MenuItemSearchEssentialFromJSONTyped,
    MenuItemSearchEssentialToJSON,
    OrderDiscountSearchEssential,
    OrderDiscountSearchEssentialFromJSON,
    OrderDiscountSearchEssentialFromJSONTyped,
    OrderDiscountSearchEssentialToJSON,
    PhoneNumber,
    PhoneNumberFromJSON,
    PhoneNumberFromJSONTyped,
    PhoneNumberToJSON,
    Pos,
    PosFromJSON,
    PosFromJSONTyped,
    PosToJSON,
    RestaurantBrandSize,
    RestaurantBrandSizeFromJSON,
    RestaurantBrandSizeFromJSONTyped,
    RestaurantBrandSizeToJSON,
    RestaurantSubType,
    RestaurantSubTypeFromJSON,
    RestaurantSubTypeFromJSONTyped,
    RestaurantSubTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Restaurant
 */
export interface Restaurant extends Business {
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    prepTime?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    distanceDeliveryMax?: number;
    /**
     * Check whether the menu has successfully imported / loaded. Prevent customers from ordering if menu is not ready.
     * @type {boolean}
     * @memberof Restaurant
     */
    isMenuActive?: boolean;
    /**
     * Whether or not the restaurant only accepts Advance (a.k.a. later) orders. If so, minimum amount of time beforehand that an order can be placed.
     * @type {number}
     * @memberof Restaurant
     */
    minScheduledTime?: number;
    /**
     * Whether or not the restaurant supports manual fire orders. Manual Fire is an order time mode that allows orders to be fired at a precise desired moment.
     * @type {number}
     * @memberof Restaurant
     */
    supportManualFire?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    pickupMinAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    supportGuestOrdering?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    supportTip?: boolean;
    /**
     * Always show menu even if the restaurant is closed.
     * @type {boolean}
     * @memberof Restaurant
     */
    alwaysShowMenu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    productRecipientNameLabel?: string;
    /**
     * Whether or not the restaurant supports basket transfers to another restaurant.
     * @type {boolean}
     * @memberof Restaurant
     */
    supportBasketTransfer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    showCalories?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    acceptOrdersUntilClosing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    acceptOrdersBeforeOpening?: boolean;
    /**
     * Dynamic boolean check whether the restaurant is open.
     * @type {boolean}
     * @memberof Restaurant
     */
    isOpen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    specialInstructions?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    specialInstructionsMaxLength?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    deliveryCharge?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    menuImportStart?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    menuImportEnd?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    isDeliveryAvailable?: boolean;
    /**
     * Boolean to determine if Chowly is enabled.
     * @type {boolean}
     * @memberof Restaurant
     */
    isChowly?: boolean;
    /**
     * Boolean to determine if ItsaCheckmate is enabled.
     * @type {boolean}
     * @memberof Restaurant
     */
    isItsaCheckmate?: boolean;
    /**
     * 
     * @type {Array<BusinessServiceAvailability>}
     * @memberof Restaurant
     */
    serviceAvailabilities?: Array<BusinessServiceAvailability>;
    /**
     * 
     * @type {Array<BusinessServiceAvailabilityOverride>}
     * @memberof Restaurant
     */
    serviceAvailabilitiesOverride?: Array<BusinessServiceAvailabilityOverride>;
    /**
     * 
     * @type {Array<HandoffOptions>}
     * @memberof Restaurant
     */
    handoffOptions?: Array<HandoffOptions>;
    /**
     * 
     * @type {Array<MenuCategorySearchEssential>}
     * @memberof Restaurant
     */
    menus?: Array<MenuCategorySearchEssential>;
    /**
     * 
     * @type {Array<OrderDiscountSearchEssential>}
     * @memberof Restaurant
     */
    promos?: Array<OrderDiscountSearchEssential>;
    /**
     * 
     * @type {Array<MenuItemSearchEssential>}
     * @memberof Restaurant
     */
    secretMenuItems?: Array<MenuItemSearchEssential>;
    /**
     * 
     * @type {RestaurantSubType}
     * @memberof Restaurant
     */
    subtype?: RestaurantSubType;
    /**
     * 
     * @type {RestaurantBrandSize}
     * @memberof Restaurant
     */
    brandSize?: RestaurantBrandSize;
    /**
     * 
     * @type {Pos}
     * @memberof Restaurant
     */
    menuSource?: Pos;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    oloVendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    uberEatsUUID?: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    uberEatsUrl?: string;
    /**
     * Whether this location was imported directly through DevourIQ, or manually through the admin portal.
     * @type {boolean}
     * @memberof Restaurant
     */
    isDevourIQManaged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    isDevourIQ?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    isDevourIQMenuFresh?: boolean;
}

export function RestaurantFromJSON(json: any): Restaurant {
    return RestaurantFromJSONTyped(json, false);
}

export function RestaurantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Restaurant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BusinessFromJSONTyped(json, ignoreDiscriminator),
        'prepTime': !exists(json, 'prepTime') ? undefined : json['prepTime'],
        'distanceDeliveryMax': !exists(json, 'distanceDeliveryMax') ? undefined : json['distanceDeliveryMax'],
        'isMenuActive': !exists(json, 'isMenuActive') ? undefined : json['isMenuActive'],
        'minScheduledTime': !exists(json, 'minScheduledTime') ? undefined : json['minScheduledTime'],
        'supportManualFire': !exists(json, 'supportManualFire') ? undefined : json['supportManualFire'],
        'pickupMinAmount': !exists(json, 'pickupMinAmount') ? undefined : json['pickupMinAmount'],
        'supportGuestOrdering': !exists(json, 'supportGuestOrdering') ? undefined : json['supportGuestOrdering'],
        'supportTip': !exists(json, 'supportTip') ? undefined : json['supportTip'],
        'alwaysShowMenu': !exists(json, 'alwaysShowMenu') ? undefined : json['alwaysShowMenu'],
        'productRecipientNameLabel': !exists(json, 'productRecipientNameLabel') ? undefined : json['productRecipientNameLabel'],
        'supportBasketTransfer': !exists(json, 'supportBasketTransfer') ? undefined : json['supportBasketTransfer'],
        'showCalories': !exists(json, 'showCalories') ? undefined : json['showCalories'],
        'acceptOrdersUntilClosing': !exists(json, 'acceptOrdersUntilClosing') ? undefined : json['acceptOrdersUntilClosing'],
        'acceptOrdersBeforeOpening': !exists(json, 'acceptOrdersBeforeOpening') ? undefined : json['acceptOrdersBeforeOpening'],
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
        'specialInstructions': !exists(json, 'specialInstructions') ? undefined : json['specialInstructions'],
        'specialInstructionsMaxLength': !exists(json, 'specialInstructionsMaxLength') ? undefined : json['specialInstructionsMaxLength'],
        'deliveryCharge': !exists(json, 'deliveryCharge') ? undefined : json['deliveryCharge'],
        'menuImportStart': !exists(json, 'menuImportStart') ? undefined : json['menuImportStart'],
        'menuImportEnd': !exists(json, 'menuImportEnd') ? undefined : json['menuImportEnd'],
        'isDeliveryAvailable': !exists(json, 'isDeliveryAvailable') ? undefined : json['isDeliveryAvailable'],
        'isChowly': !exists(json, 'isChowly') ? undefined : json['isChowly'],
        'isItsaCheckmate': !exists(json, 'isItsaCheckmate') ? undefined : json['isItsaCheckmate'],
        'serviceAvailabilities': !exists(json, 'serviceAvailabilities') ? undefined : ((json['serviceAvailabilities'] as Array<any>).map(BusinessServiceAvailabilityFromJSON)),
        'serviceAvailabilitiesOverride': !exists(json, 'serviceAvailabilitiesOverride') ? undefined : ((json['serviceAvailabilitiesOverride'] as Array<any>).map(BusinessServiceAvailabilityOverrideFromJSON)),
        'handoffOptions': !exists(json, 'handoffOptions') ? undefined : ((json['handoffOptions'] as Array<any>).map(HandoffOptionsFromJSON)),
        'menus': !exists(json, 'menus') ? undefined : ((json['menus'] as Array<any>).map(MenuCategorySearchEssentialFromJSON)),
        'promos': !exists(json, 'promos') ? undefined : ((json['promos'] as Array<any>).map(OrderDiscountSearchEssentialFromJSON)),
        'secretMenuItems': !exists(json, 'secretMenuItems') ? undefined : ((json['secretMenuItems'] as Array<any>).map(MenuItemSearchEssentialFromJSON)),
        'subtype': !exists(json, 'subtype') ? undefined : RestaurantSubTypeFromJSON(json['subtype']),
        'brandSize': !exists(json, 'brandSize') ? undefined : RestaurantBrandSizeFromJSON(json['brandSize']),
        'menuSource': !exists(json, 'menuSource') ? undefined : PosFromJSON(json['menuSource']),
        'oloVendorId': !exists(json, 'oloVendorId') ? undefined : json['oloVendorId'],
        'uberEatsUUID': !exists(json, 'uberEatsUUID') ? undefined : json['uberEatsUUID'],
        'uberEatsUrl': !exists(json, 'uberEatsUrl') ? undefined : json['uberEatsUrl'],
        'isDevourIQManaged': !exists(json, 'isDevourIQManaged') ? undefined : json['isDevourIQManaged'],
        'isDevourIQ': !exists(json, 'isDevourIQ') ? undefined : json['isDevourIQ'],
        'isDevourIQMenuFresh': !exists(json, 'isDevourIQMenuFresh') ? undefined : json['isDevourIQMenuFresh'],
    };
}

export function RestaurantToJSON(value?: Restaurant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BusinessToJSON(value),
        'prepTime': value.prepTime,
        'distanceDeliveryMax': value.distanceDeliveryMax,
        'isMenuActive': value.isMenuActive,
        'minScheduledTime': value.minScheduledTime,
        'supportManualFire': value.supportManualFire,
        'pickupMinAmount': value.pickupMinAmount,
        'supportGuestOrdering': value.supportGuestOrdering,
        'supportTip': value.supportTip,
        'alwaysShowMenu': value.alwaysShowMenu,
        'productRecipientNameLabel': value.productRecipientNameLabel,
        'supportBasketTransfer': value.supportBasketTransfer,
        'showCalories': value.showCalories,
        'acceptOrdersUntilClosing': value.acceptOrdersUntilClosing,
        'acceptOrdersBeforeOpening': value.acceptOrdersBeforeOpening,
        'isOpen': value.isOpen,
        'specialInstructions': value.specialInstructions,
        'specialInstructionsMaxLength': value.specialInstructionsMaxLength,
        'deliveryCharge': value.deliveryCharge,
        'menuImportStart': value.menuImportStart,
        'menuImportEnd': value.menuImportEnd,
        'isDeliveryAvailable': value.isDeliveryAvailable,
        'isChowly': value.isChowly,
        'isItsaCheckmate': value.isItsaCheckmate,
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : ((value.serviceAvailabilities as Array<any>).map(BusinessServiceAvailabilityToJSON)),
        'serviceAvailabilitiesOverride': value.serviceAvailabilitiesOverride === undefined ? undefined : ((value.serviceAvailabilitiesOverride as Array<any>).map(BusinessServiceAvailabilityOverrideToJSON)),
        'handoffOptions': value.handoffOptions === undefined ? undefined : ((value.handoffOptions as Array<any>).map(HandoffOptionsToJSON)),
        'menus': value.menus === undefined ? undefined : ((value.menus as Array<any>).map(MenuCategorySearchEssentialToJSON)),
        'promos': value.promos === undefined ? undefined : ((value.promos as Array<any>).map(OrderDiscountSearchEssentialToJSON)),
        'secretMenuItems': value.secretMenuItems === undefined ? undefined : ((value.secretMenuItems as Array<any>).map(MenuItemSearchEssentialToJSON)),
        'subtype': RestaurantSubTypeToJSON(value.subtype),
        'brandSize': RestaurantBrandSizeToJSON(value.brandSize),
        'menuSource': PosToJSON(value.menuSource),
        'oloVendorId': value.oloVendorId,
        'uberEatsUUID': value.uberEatsUUID,
        'uberEatsUrl': value.uberEatsUrl,
        'isDevourIQManaged': value.isDevourIQManaged,
        'isDevourIQ': value.isDevourIQ,
        'isDevourIQMenuFresh': value.isDevourIQMenuFresh,
    };
}


