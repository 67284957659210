import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ActiveIndicator from "../../../svgs/ow-discover-chevron-active.svg?react";
import DisabledIndicator from "../../../svgs/ow-discover-chevron-disabled.svg?react";
import { useGate } from "statsig-react";
import { getGoVipLevelsText } from "@/components/modals/GoVipLevelsModal";
import Tooltip from "@/components/Tooltip";
import {getGovipLevelImage, getGoVipLevelTokenReward} from "@/components/goVip/GoVipLevelCard";

export const OverwolfLevelRewardsCarousel = () => {
    const { value: fuelStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const levelTexts = getGoVipLevelsText(fuelStatus);
    const levels = Array.from({ length: levelTexts.length }, (_, i) => i + 1);
    const levelsContentRef = useRef<HTMLDivElement>(null);
    const [canScrollRight, setCanScrollRight] = useState(true);
    const [canScrollLeft, setCanScrollLeft] = useState(false);

    function handleLevelsScroll(event: React.UIEvent<HTMLDivElement>) {
        const { scrollWidth, scrollLeft, clientWidth } = event.currentTarget;
        setCanScrollRight(scrollWidth > scrollLeft + clientWidth);
        setCanScrollLeft(scrollLeft > 0);
    }

    function slideLevels(isLeft: boolean) {
        return () => {
            if (!levelsContentRef.current) return;
            const { clientWidth, scrollWidth, scrollLeft } = levelsContentRef.current;

            // Calculate the scroll amount (50% of the client width, minimum 100px)
            const scrollAmount = isMobile ? 152 : Math.max(clientWidth, 152);

            let newScrollLeft = scrollLeft;

            if (isLeft) {
                // Scroll left: Ensure we don't scroll past the start
                newScrollLeft = Math.max(scrollLeft - scrollAmount, 0);
            } else {
                // Scroll right: Ensure we don't scroll past the end
                newScrollLeft = Math.min(scrollLeft + scrollAmount, scrollWidth);
            }
            levelsContentRef.current?.scroll({
                left: newScrollLeft,
                behavior: "smooth",
            });
        };
    }

    function renderIndicator(left: boolean) {
        const handleClick = slideLevels(left);
        const active =
            <ActiveIndicator
                onClick={handleClick}
                style={{
                    rotate: left ? "180deg" : "0deg",
                }}
            />;
        const disabled =
            <DisabledIndicator
                style={{
                    rotate: left ? "180deg" : "0deg",
                }}
                onClick={handleClick}
            />;
        if (left && canScrollLeft) {
            return active;
        }
        if (!left && canScrollRight) {
            return active;
        }
        return disabled;
    }

    const handleOnMouseEnter: React.MouseEventHandler<HTMLDivElement> = (ev) => {
        const tooltip = ev.currentTarget;
        setTimeout(() => {
            const tooltipContent: HTMLDivElement = document.querySelector(".tooltip.is-hover .tooltip_content");
            if (!tooltipContent) return;
            const rect = tooltip.getBoundingClientRect();
            const tooltipOffset = isMobile ? 0 : 40;
            const tooltipWidth = tooltipContent.offsetWidth;
            const targetCenterX = rect.left + rect.width / 2;
            const tooltipLeft = targetCenterX - tooltipWidth / 2;
            tooltipContent.style.display = "block";
            tooltipContent.style.left = `${tooltipLeft - tooltipOffset}px`;
        }, 2);
    };

    return (
        <div className="level-rewards-carousel">
            <div className="level-rewards-carousel_indicator">{renderIndicator(true)}</div>
            <div className="level-rewards-carousel_scroller" onScroll={handleLevelsScroll} ref={levelsContentRef}>
                <div className="level-rewards-carousel_content">
                    <div className="level-rewards-carousel_content_levels">
                        {levels.map((level) => {
                            return (
                                <>
                                    <div
                                        key={`level-${level}`}
                                        className="level-rewards-carousel_content_levels_number"
                                    >
                                        <span>{level}</span>
                                    </div>
                                    <div
                                        key={`level-stripe-${level}`}
                                        className="level-rewards-carousel_content_levels_stripe"
                                    ></div>
                                </>
                            );
                        })}
                    </div>
                    <div className="level-rewards-carousel_content_rewards">
                        {levels.map((level) => {
                            return (
                                <Tooltip
                                    disablePositioning
                                    outsideContent={
                                        <div
                                            key={level}
                                            className="level-rewards-carousel_content_rewards_reward"
                                            onMouseEnter={handleOnMouseEnter}
                                        >
                                            <img src={getGovipLevelImage(level)} alt="level reward" />
                                        </div>
                                    }
                                >
                                    {levelTexts[level - 1] && <>
                                        <h5 className="tooltip_content_title">
                                            {levelTexts[level - 1].prize} and{" "}
                                            <img
                                                src={`${
                                                    import.meta.env.VITE_CDN_URL
                                                }/images/govip-reward-fuel-mini-Icon.webp`}
                                                alt="fuel-bonus-icon"
                                            />{" "}
                                        ${getGoVipLevelTokenReward(level)} {import.meta.env.VITE_TOKEN_NAME} Tokens
                                        </h5>
                                        <p className="tooltip_content_description">{levelTexts[level - 1].description}</p>
                                    </>}
                                </Tooltip>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="level-rewards-carousel_indicator">{renderIndicator(false)}</div>
        </div>
    );
};
