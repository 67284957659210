import {ReactElement, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GoVipLevelsModal from "@/components/modals/GoVipLevelsModal";
import GoVipExperienceBar from "@/components/goVip/GoVipExperienceBar";
import {useGetUserLeaderboardRank} from "@/hooks/useGetUserLeaderboardRank";
import {formatNumberWithCommas} from "@/utils/formatNumberWithCommas";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import FrameButton from "@/components/buttons/FrameButton";
import {useNavigate} from "react-router";
import {isMobile, isTablet} from "react-device-detect";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import classNames from "classnames";
import Toast from "@/components/Toast";
import {getRankSuffix} from "@/utils/getRankSuffix";
import {addError, toggleLoginOpen, updateAccountLevel} from "@/redux/meta/metaActions";
import Skeleton from "react-loading-skeleton";
import {useGate} from "statsig-react";
import useWindowSize, {getScreenWidthStr, WindowBreakpointSizes} from "@/hooks/useWindowSize";
import useThemePreference from "@/hooks/useThemePreference";
import {FaChevronRight, FaChevronUp} from "react-icons/fa";
import Spacer from "@/components/Spacer";
import AnimateHeight from "react-animate-height";

function getBackgroungImageUrl(width: WindowBreakpointSizes, darkMode: boolean, isCollapsed = false): string {
    const modePrefix = darkMode ? "dm-" : "";
    const collapseSuffix = isCollapsed ? "-collapsed" : "";
    return `${import.meta.env.VITE_CDN_URL}/images/${modePrefix}restaurant-search-govip-bnr-${width}${collapseSuffix}.webp`;
}

function GoVipRestaurantSearchBanner(): ReactElement {
    const history = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const accountLevel = useSelector((store: IStore) => store.metaStore.accountLevel);
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);

    const windowSize = useWindowSize();
    const windowWidth = getScreenWidthStr(windowSize[0]);
    const {isOnDarkMode} = useThemePreference();

    const [showGoVipLevelsModal, setShowGoVipLevelsModal] = useState<boolean>(false);
    const [showDpayErrorToast, setShowDpayErrorToast] = useState<boolean>(false);
    const isOnMobile: boolean = isMobile && !isTablet;
    const [isCollapsed, setIsCollapsed] = useState<boolean>(isOnMobile);

    const {data: userLeaderboardRankData} = useGetUserLeaderboardRank(fullToken);
    const leaderboardRank: number = userLeaderboardRankData?.rank;
    const {data: transactionsData, isError: isTransactionsDataError} = useGetTransactions(fullToken, currentUser?.user?.id);
    const {data: userLevelData} = useGetUserLevel(fullToken, currentUser?.user?.id);
    const dpayPricesError = fullToken && !transactionsData && isTransactionsDataError;
    const collapsibleElementHeight = dpayPricesError ? 45 : 33;

    useEffect(() => {
        if (userLevelData) {
            void updateLevelData();
        }
    }, [userLevelData]);

    async function updateLevelData() {
        try {
            dispatch(updateAccountLevel(userLevelData));
        } catch (err) {
            dispatch(await addError(err));
        }
    }

    function toggleGoVipPrizesModal(): void {
        setShowGoVipLevelsModal(!showGoVipLevelsModal);
    }

    function renderDpayCard(): ReactElement {
        return (
            <div className="go-vip-restaurant-search_right">
                <div className="go-vip-restaurant-search_right_info">
                    <img
                        className="go-vip-restaurant-search_right_info_icon"
                        src={import.meta.env.VITE_CDN_URL + "/images/FUEL.webp"}
                        alt="DevourGO Logo"
                    />

                    {dpayPricesError
                        ? <div className="go-vip-restaurant-search_right_info_error">
                            {import.meta.env.VITE_TOKEN_NAME} prices are unavailable at the moment.
                        </div>
                        : <div>
                            <div className="go-vip-restaurant-search_right_info_dpay">
                                {!fullToken
                                    ? `0 ${import.meta.env.VITE_TOKEN_NAME}`
                                    : !transactionsData
                                        ? <Skeleton width={130}/>
                                        : `${formatNumberWithCommas(transactionsData.balance)} ${import.meta.env.VITE_TOKEN_NAME}`}
                            </div>
                            <div className="go-vip-restaurant-search_right_info_fiat">
                                {!fullToken
                                    ? "$0 USD"
                                    : !transactionsData
                                        ? <Skeleton width={130}/>
                                        : `$${Intl.NumberFormat("en-us", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(transactionsData.balanceInUsd)} USD`
                                }
                            </div>
                        </div>
                    }

                    {isOnMobile && <>
                        <Spacer/>
                        <FaChevronUp
                            className={classNames("menu-item-customization-form_group_details_hide-icon", {
                                "menu-item-customization-form_group_details_hide-icon_flipped": isCollapsed,
                            })}
                            onClick={() => setIsCollapsed(!isCollapsed)}
                        />
                    </>}
                </div>

                <FrameButton
                    size="pill"
                    rightIcon={!isOnMobile && FaChevronRight}
                    className="go-vip-restaurant-search_right_load-btn"
                    onClick={() => !transactionsData && isTransactionsDataError
                        ? setShowDpayErrorToast(true)
                        : fullToken
                            ? history("/load-fuel")
                            : dispatch(toggleLoginOpen(true))}
                >
                    Load {!isTablet && import.meta.env.VITE_TOKEN_NAME}
                </FrameButton>
            </div>
        );
    }


    function handleToastDismissal() {
        setShowDpayErrorToast(false);
    }

    function renderContent(): ReactElement {
        return (
            <div className="go-vip-restaurant-search_container_content">
                <div className="go-vip-restaurant-search_left">
                    <div className="go-vip-restaurant-search_left_info">
                        <div className="go-vip-restaurant-search_left_info_xp">
                            {fullToken ? accountLevel?.experienceGainedAtThisLevel : 0}
                            <span className="go-vip-restaurant-search_left_info_xp_suffix">XP</span>
                        </div>

                        <div className="go-vip-restaurant-search_left_info_others">
                            <div className="go-vip-restaurant-search_left_info_item">
                                <span className="go-vip-restaurant-search_left_info_item_label">
                                    {isOnMobile ? "Lvl" : "Level"}
                                </span>
                                <span className="go-vip-restaurant-search_left_info_item_number">
                                    {accountLevel?.level || 1}
                                </span>
                            </div>

                            <div className="go-vip-restaurant-search_left_info_item">
                                <span className="go-vip-restaurant-search_left_info_item_label">Rank</span>
                                <span className="go-vip-restaurant-search_left_info_item_number">
                                    {accountLevel?.level > 1 ? `${leaderboardRank}${getRankSuffix(leaderboardRank)}` : "NEW"}
                                </span>
                            </div>
                        </div>
                    </div>
                    {!isOnMobile && <div className="go-vip-restaurant-search_left_exp-container">
                        <GoVipExperienceBar/>
                    </div>}
                </div>
                <div className="go-vip-restaurant-search_container_divider"/>
                {renderDpayCard()}
            </div>
        );
    }

    return (
        <>
            <Toast
                duration={3000}
                message={`Uh oh... it looks like ${import.meta.env.VITE_TOKEN_NAME} prices are unavailable right now. Please check back in a bit!`}
                isOpen={showDpayErrorToast}
                variant="error"
                onDismiss={handleToastDismissal}
            />
            <GoVipLevelsModal
                isOpen={showGoVipLevelsModal}
                toggle={toggleGoVipPrizesModal}
            />
            <div className={classNames("go-vip-restaurant-search", {
                "is-mobile": isOnMobile,
            })}>
                {fullToken && !leaderboardRank
                    ? <div className="react-loading-skeleton go-vip-restaurant-search_skeleton"/>
                    : <div
                        className={classNames("go-vip-restaurant-search_container", {
                            "is-mobile": isOnMobile,
                        })}
                        style={{
                            backgroundImage: `url(${getBackgroungImageUrl(windowWidth, isOnDarkMode, isCollapsed)})`,
                        }}
                    >
                        {isOnMobile
                            ? <AnimateHeight
                                duration={400}
                                height={isCollapsed ? collapsibleElementHeight : "auto"}
                            >
                                {renderContent()}
                            </AnimateHeight>
                            : renderContent()
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default GoVipRestaurantSearchBanner;
