import {ButtonHTMLAttributes, ReactElement, useContext, useState} from "react";
import {BrandMap, LoginAccessPoint} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {isTablet} from "react-device-detect";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {BsArrowRight} from "react-icons/bs";
import { isColorDarkOrLight } from "@/utils/isColorDarkOrLight";
import ExpandableText from "../ExpandableText";
import { useGetChallenge } from "@/hooks/challenges/useGetChallenge";
import AssetImage from "../AssetImage";
import { FaMinus, FaPlus } from "react-icons/fa";

interface Props {
    brandMap: BrandMap;
    toggleEmailModal?: (showModal: boolean) => void;
}

function BrandLandingChallengeMainPromo(props: Props): ReactElement {
    const {brandMap, toggleEmailModal} = props;
    const {data: challenge} = useGetChallenge(brandMap?.challenge);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const color = challenge?.brandColor || brandMap?.colorBackground;
    const {
        brandMapState,
        setBrandMapState,
        selectedPromo,
        setSelectedPromo,
    } = useContext(BrandMapContext);
    const promoSelectionStep = brandMapState === BrandMapStates.SELECT_PROMOTION;

    if (!brandMap?.mainPromo) {
        return null;
    }


    function redeemButtonOnClick() {
        if (promoSelectionStep) {
            return;
        }

        if (brandMap.loginAccessPoint === LoginAccessPoint.PAGE && !fullToken) {
            toggleEmailModal?.(true);
        } else {
            setBrandMapState(BrandMapStates.SELECT_PROMOTION);
        }
    }

    function renderRedeemButton() {
        if (promoSelectionStep) {
            return;
        }

        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color={"gray-light"}
                size="normal"
                className={classNames("brand-map-promotions_challenge-main-promo_details_redeem", isColorDarkOrLight(color))}
                forwardProps={{
                    style: {
                        backgroundColor: color,
                    },
                }}
                onClick={redeemButtonOnClick}
            >
                Redeem Now <BsArrowRight />
            </FrameButton>
        );
    }

    function renderSelectPromoButton(): ReactElement {
        const isPromoSelected = selectedPromo === brandMap.mainPromo.id;

        if (brandMap.mainPromo.isBrandMapAutoApplied) {
            return <span className="brand-map-promotions_others_card_auto-applied-tag">Auto-applied</span>;
        }

        return (
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color="gray"
                size="icon"
                rightIcon={isPromoSelected ? FaMinus : FaPlus}
                className={`brand-map-promotions_icon-btn ${isPromoSelected ? "deselect" : "select"}`}
                onClick={() => setSelectedPromo(isPromoSelected ? "" : brandMap.mainPromo.id)}
            />
        );
    }


    return (
        <div className={classNames(
            "brand-map-promotions_challenge-main-promo",
            {
                "no-bg": !brandMap?.promoBackground,
                "is-selected": selectedPromo === brandMap.mainPromo.id || brandMap.mainPromo.isBrandMapAutoApplied,
            },
        )}
        style={{
            backgroundImage: `url(${brandMap.promoBackground?.url})`,
        }}>
            <div className="brand-map-promotions_challenge-main-promo_shader"></div>
            {promoSelectionStep && renderSelectPromoButton()}

            <div className="brand-map-promotions_challenge-main-promo_hero">
                <img
                    src={brandMap.promoHero?.url || `${import.meta.env.VITE_CDN_URL}/images/landing-background.webp`}
                    alt={brandMap.promoTitle}
                />
            </div>
            <div className={classNames(
                "brand-map-promotions_challenge-main-promo_details",
                {"is-tablet": isTablet},
            )}>
                <div className="brand-map-promotions_challenge-main-promo_details_heading">
                    <AssetImage assetId={challenge?.brandLogo} alt="brand logo"/>
                    <span>Instant Reward</span>
                </div>

                <h6 className="brand-map-promotions_challenge-main-promo_details_title">{brandMap.promoTitle}</h6>

                <div className="brand-map-promotions_challenge-main-promo_details_description">
                    <ExpandableText showMoreColor={color} maxLines={3} text={brandMap.promoTagline} />
                </div>
                {renderRedeemButton()}
            </div>

        </div>
    );
}

export default BrandLandingChallengeMainPromo;
