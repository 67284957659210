/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChallengeFaqDetails,
    ChallengeFaqDetailsFromJSON,
    ChallengeFaqDetailsFromJSONTyped,
    ChallengeFaqDetailsToJSON,
    ChallengeOnboarding,
    ChallengeOnboardingFromJSON,
    ChallengeOnboardingFromJSONTyped,
    ChallengeOnboardingToJSON,
    ChallengeResponse,
    ChallengeResponseFromJSON,
    ChallengeResponseFromJSONTyped,
    ChallengeResponseToJSON,
    ChallengeResponseWithAcceptanceAllOf,
    ChallengeResponseWithAcceptanceAllOfFromJSON,
    ChallengeResponseWithAcceptanceAllOfFromJSONTyped,
    ChallengeResponseWithAcceptanceAllOfToJSON,
    ChallengeReward,
    ChallengeRewardFromJSON,
    ChallengeRewardFromJSONTyped,
    ChallengeRewardToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChallengeResponseWithAcceptance
 */
export interface ChallengeResponseWithAcceptance extends ChallengeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChallengeResponseWithAcceptance
     */
    isAccepted?: boolean;
}

export function ChallengeResponseWithAcceptanceFromJSON(json: any): ChallengeResponseWithAcceptance {
    return ChallengeResponseWithAcceptanceFromJSONTyped(json, false);
}

export function ChallengeResponseWithAcceptanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeResponseWithAcceptance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...ChallengeResponseFromJSONTyped(json, ignoreDiscriminator),
        'isAccepted': !exists(json, 'isAccepted') ? undefined : json['isAccepted'],
    };
}

export function ChallengeResponseWithAcceptanceToJSON(value?: ChallengeResponseWithAcceptance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ChallengeResponseToJSON(value),
        'isAccepted': value.isAccepted,
    };
}


