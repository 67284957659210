import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {useParams} from "react-router";
import BrandLandingMainPromo from "@/components/brands/BrandLandingMainPromo";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {ReactElement, useContext} from "react";
import {BrandMapOtherPromoCard} from "@/components/brands/BrandMapOtherPromoCard";
import classNames from "classnames";
import {isDesktop, isMobile} from "react-device-detect";
import Spacer from "@/components/Spacer";
import {IoCloseOutline} from "react-icons/io5";
import FrameButton from "@/components/buttons/FrameButton";
import {BrandMapColorTheme, OrderDiscount} from "@devour/client";
import {BiChevronLeft, BiSolidDiscount} from "react-icons/bi";
import DragScroll from "@/components/DragScroll";
import BrandLandingChallengeMainPromo from "./BrandLandingChallengeMainPromo";
import BrandChallengePageSecondaryPromos from "@/components/brands/BrandChallengePageSecondaryPromos";

interface Props {
    toggleEmailModal: (showModal: boolean) => void;
}

export default function BrandMapPromotions(props: Props) {
    const {slug} = useParams<{ slug: string }>();
    const {data: brandMap} = useGetBrandMap(slug);
    const {
        brandMapState,
        setBrandMapState,
        selectedPromo: selectedPromoId,
        setSelectedPromo,
    } = useContext(BrandMapContext);

    // only show secondary promos that are available to the user
    const availableSecondaryPromos = brandMap?.promos?.filter(promo => promo.isBrandMapValid) ?? [];
    const numberOfAvailablePromotions = availableSecondaryPromos.length + (brandMap?.mainPromo ? 1 : 0);
    const promoSelectionStep = brandMapState === BrandMapStates.SELECT_PROMOTION;
    const isTextDark = brandMap?.colorTheme === BrandMapColorTheme.DARK;

    const selectedPromos = availableSecondaryPromos.concat(brandMap.mainPromo ?? [])
        .filter(promo => promo.id === selectedPromoId || promo.isBrandMapAutoApplied)
        .sort((a, _b) => a.isBrandMapAutoApplied ? -1 : 1);

    if (!numberOfAvailablePromotions) {
        return null;
    }

    function renderHeader() {
        if (brandMapState === BrandMapStates.NOT_INITIATED) {
            return (
                <div className="brand-map-promotions_header">
                    <span className="brand-map-promotions_header_pill">Promotions</span>
                    <div className="brand-map-promotions_header_title">
                        <h3 className="brand-map-promotions_header_title_text">{brandMap.promoHeader?.title || "Promotions"}</h3>
                    </div>
                    {brandMap.promoHeader?.description &&
                        <div className="brand-map-promotions_header_description">
                            {brandMap.promoHeader.description}
                        </div>
                    }
                </div>
            );
        }
        if (brandMap.challenge) {
            return (
                <div className={"brand-map-promotions_header has-challenge"}>
                    {brandMap.challenge && <BiChevronLeft className="brand-map-promotions_header_title_back" onClick={() => setBrandMapState(BrandMapStates.NOT_INITIATED)}/>}
                    <div className="brand-map-promotions_header_content">
                        <div className="brand-map-promotions_header_title">
                            <h3 className="brand-map-promotions_header_title_text">{isMobile ? "Promotions" : "Redeem Promotions"}</h3>
                            <div className="brand-map-promotions_header_title_number">
                                {numberOfAvailablePromotions}
                            </div>
                            <Spacer/>
                            <span
                                onClick={() => setBrandMapState(BrandMapStates.SELECT_LOCATION)}
                                className="brand-map-promotions_header_skip-btn"
                            >
                        Skip
                            </span>
                        </div>
                        <div className="brand-map-promotions_header_description">
                                Select 1 or more promotions to use for your order.
                        </div>
                    </div>
                </div>
            );
        }


        return (
            <div className={"brand-map-promotions_header"}>
                <div className="brand-map-promotions_header_title">
                    <h3 className="brand-map-promotions_header_title_text">PROMOTIONS</h3>
                    <div className="brand-map-promotions_header_title_number">
                        {numberOfAvailablePromotions}
                    </div>
                    <Spacer/>
                    <span
                        onClick={() => setBrandMapState(BrandMapStates.SELECT_LOCATION)}
                        className="brand-map-promotions_header_skip-btn"
                    >
                        Skip
                    </span>
                </div>
                <div className="brand-map-promotions_header_description">
                Select a promotion to redeem for your order
                </div>
            </div>
        );


    }


    function renderSelectedPromotion(promo: OrderDiscount): ReactElement {
        return (
            <span
                className="brand-map-promotions_bottom_selected brand-map-promotions_pill-color"
                key={`selected-promo-${promo.id}`}
            >
                <span>{promo.label}</span>
                {!promo.isBrandMapAutoApplied && <IoCloseOutline size="1.25rem" onClick={() => setSelectedPromo("")}/>}
            </span>
        );
    }

    return (
        <div className={classNames(
            "brand-map-promotions",
            `theme-${brandMap.colorTheme}`, {
                "restaurant-map-landing_ordering_step": promoSelectionStep,
                "has-challenge": !!brandMap?.challenge,
            },
        )}>
            {(!brandMap.challenge || brandMapState === BrandMapStates.SELECT_PROMOTION) && renderHeader()}

            {brandMap.challenge
                ? <BrandLandingChallengeMainPromo brandMap={brandMap} toggleEmailModal={props.toggleEmailModal}/>
                : <BrandLandingMainPromo brandMap={brandMap} toggleEmailModal={props.toggleEmailModal}/>}

            {availableSecondaryPromos.length > 0 && brandMap.challenge && <BrandChallengePageSecondaryPromos
                brandMap={brandMap}
                isPromoSelectionStep={promoSelectionStep}
                promos={availableSecondaryPromos}
                toggleEmailModal={props.toggleEmailModal}
            />}

            {availableSecondaryPromos.length > 0 && !brandMap.challenge &&
                <div className="brand-map-promotions_others no-margin-adjust">
                    {brandMap.mainPromo && !promoSelectionStep &&
                        <div className="brand-map-promotions_others_header">
                            <h4>DISCOVER MORE</h4>
                            <div className="brand-map-promotions_header_description">
                                {availableSecondaryPromos.length} promotions available
                            </div>
                        </div>
                    }
                    <div className="brand-map-promotions_others_cards">
                        <DragScroll className={classNames(
                            "brand-map-promotions_others_list",
                            {"is-desktop": isDesktop},
                        )}>
                            {availableSecondaryPromos.map(promo =>
                                <BrandMapOtherPromoCard
                                    brandMap={brandMap}
                                    promo={promo}
                                    toggleEmailModal={props.toggleEmailModal}
                                    key={promo.id}/>)}
                        </DragScroll>
                    </div>
                </div>
            }

            {promoSelectionStep &&
                <div className="brand-map-promotions_bottom">
                    <div>
                        <div className="brand-map-promotions_bottom_label">
                            <BiSolidDiscount />
                            <h5>{selectedPromos.length} Promotion(s) Added</h5>
                        </div>
                        {selectedPromos.map(promo => renderSelectedPromotion(promo))}
                    </div>

                    {brandMap.challenge
                        ? <FrameButton
                            color={"purple-blue-gradient"}
                            size="normal"
                            className="brand-map-promotions_bottom_continue-btn"
                            onClick={() => setBrandMapState(BrandMapStates.SELECT_LOCATION)}
                        >
                      Continue
                        </FrameButton>
                        : <FrameButton
                            color={isTextDark ? "gray-light" : "ghost"}
                            size="large"
                            className="brand-map-promotions_bottom_continue-btn"
                            onClick={() => setBrandMapState(BrandMapStates.SELECT_LOCATION)}
                        >
                        Continue
                        </FrameButton>}
                </div>
            }
        </div>
    );
}