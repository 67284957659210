/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The method at which we process time logs for time quests
 * @export
 * @enum {string}
 */
export enum OWRewardMode {
    ALLTIME = 'ALLTIME'
}

export function OWRewardModeFromJSON(json: any): OWRewardMode {
    return OWRewardModeFromJSONTyped(json, false);
}

export function OWRewardModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWRewardMode {
    return json as OWRewardMode;
}

export function OWRewardModeToJSON(value?: OWRewardMode | null): any {
    return value as any;
}

