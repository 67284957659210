import { ChallengeReward, OWLogCategory, OWReward, OWUserStat, QuestReset } from "@devour/client";

export const convertChallengeRewardToReward = (challengeReward: ChallengeReward, challengeQuests: OWReward[]): OWReward => {
    return {
        eventType: "",
        id: "",
        game: "",
        target: challengeQuests.length,
        title: "Complete All Quests",
        userStat: {
            progress: challengeQuests.filter(quest => quest.userStat?.progress >= quest.target).length,
            completed: false,
            createdAt: new Date(),
            id: "",
            target: challengeQuests.length,
            reward: {} as any,
            updatedAt: new Date(),
            userId: "",
            rewardCategory: OWLogCategory.EVENT,
        } as OWUserStat,

        isActive: true,
        name: "Complete All Quests",
        description: `Redeem your instant reward after completing all quests below. ${challengeReward.description}`,
        questReset: QuestReset.NONE,
        rewardCategory: "EVENT",

    } as OWReward;
};