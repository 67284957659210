/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GuildQuestMode,
    GuildQuestModeFromJSON,
    GuildQuestModeFromJSONTyped,
    GuildQuestModeToJSON,
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    OWQuestReward,
    OWQuestRewardFromJSON,
    OWQuestRewardFromJSONTyped,
    OWQuestRewardToJSON,
    OWRewardMilestone,
    OWRewardMilestoneFromJSON,
    OWRewardMilestoneFromJSONTyped,
    OWRewardMilestoneToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddGuildQuestBody
 */
export interface AddGuildQuestBody {
    /**
     * 
     * @type {string}
     * @memberof AddGuildQuestBody
     */
    guild?: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuildQuestBody
     */
    game?: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuildQuestBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddGuildQuestBody
     */
    description?: string;
    /**
     * 
     * @type {OWGameEventType}
     * @memberof AddGuildQuestBody
     */
    eventType: OWGameEventType;
    /**
     * 
     * @type {number}
     * @memberof AddGuildQuestBody
     */
    target: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddGuildQuestBody
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<OWRewardMilestone>}
     * @memberof AddGuildQuestBody
     */
    milestones?: Array<OWRewardMilestone>;
    /**
     * 
     * @type {Array<OWQuestReward>}
     * @memberof AddGuildQuestBody
     */
    questRewards?: Array<OWQuestReward>;
    /**
     * 
     * @type {boolean}
     * @memberof AddGuildQuestBody
     */
    isGuildQualifier?: boolean;
    /**
     * 
     * @type {GuildQuestMode}
     * @memberof AddGuildQuestBody
     */
    mode?: GuildQuestMode;
    /**
     * 
     * @type {number}
     * @memberof AddGuildQuestBody
     */
    dailyTarget?: number;
}

export function AddGuildQuestBodyFromJSON(json: any): AddGuildQuestBody {
    return AddGuildQuestBodyFromJSONTyped(json, false);
}

export function AddGuildQuestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddGuildQuestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guild': !exists(json, 'guild') ? undefined : json['guild'],
        'game': !exists(json, 'game') ? undefined : json['game'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'eventType': OWGameEventTypeFromJSON(json['eventType']),
        'target': json['target'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'milestones': !exists(json, 'milestones') ? undefined : ((json['milestones'] as Array<any>).map(OWRewardMilestoneFromJSON)),
        'questRewards': !exists(json, 'questRewards') ? undefined : ((json['questRewards'] as Array<any>).map(OWQuestRewardFromJSON)),
        'isGuildQualifier': !exists(json, 'isGuildQualifier') ? undefined : json['isGuildQualifier'],
        'mode': !exists(json, 'mode') ? undefined : GuildQuestModeFromJSON(json['mode']),
        'dailyTarget': !exists(json, 'dailyTarget') ? undefined : json['dailyTarget'],
    };
}

export function AddGuildQuestBodyToJSON(value?: AddGuildQuestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guild': value.guild,
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'eventType': OWGameEventTypeToJSON(value.eventType),
        'target': value.target,
        'isActive': value.isActive,
        'milestones': value.milestones === undefined ? undefined : ((value.milestones as Array<any>).map(OWRewardMilestoneToJSON)),
        'questRewards': value.questRewards === undefined ? undefined : ((value.questRewards as Array<any>).map(OWQuestRewardToJSON)),
        'isGuildQualifier': value.isGuildQualifier,
        'mode': GuildQuestModeToJSON(value.mode),
        'dailyTarget': value.dailyTarget,
    };
}


