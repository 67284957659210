import {Dispatch, ReactElement, SetStateAction, useMemo} from "react";
import {
    GetGlobalOrderDiscountsResponse,
    OrderDiscount,
    OrderDiscountSearchEssential,
    RestaurantSearchResult,
} from "@devour/client";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {ViewStates} from "@/pages/restaurants/RestaurantSearchResultsPage";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import Skeleton from "react-loading-skeleton";
import DragScroll from "@/components/DragScroll";
import { RestaurantPromoCard } from "./RestaurantPromoCard";
import useWindowSize from "@/hooks/useWindowSize";
import { HiArrowRight } from "react-icons/hi";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";

interface Props {
    isLoading: boolean;
    globalOrderDiscounts: GetGlobalOrderDiscountsResponse;
    goFrensRestaurants: Array<RestaurantSearchResult>;
    qualifiedPromos: OrderDiscountSearchEssential[];
    setViewState: Dispatch<SetStateAction<ViewStates>>;
    setRestaurantIds?: Dispatch<SetStateAction<string[]>>;
}


function RestaurantSearchGoFrensPromotions(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { isOnOverwolf } = useOverwolfInterop();
    const {data: nftOwnershipResponse, isFetching: isNftOwnershipDataFetching} = useGetNftOwnershipsForUser(fullToken);
    const windowSize = useWindowSize()[0];
    function renderGlobalPromo(promo: OrderDiscount): ReactElement {
        return (
            <RestaurantPromoCard
                key={promo.id}
                promo={promo}
                nftOwnershipResponse={nftOwnershipResponse}
                onOrderNow={() => {
                    props.setViewState(ViewStates.PROMO_RESTAURANTS);
                    props.setRestaurantIds?.(promo.businesses);
                }}
            />
        );
    }
    const getPromotionCount = () => {
        if (windowSize >= 1920 && isDesktop) {
            return 4;
        }
        if (windowSize >= 1000 && isOnOverwolf) {
            return 3;
        }
        if (windowSize > 1280 && windowSize < 1475 && isDesktop) {
            return 3;
        }
        return 4;
    };

    const uniqueQualifiedPromos = useMemo(() => {
        return props.qualifiedPromos.filter((promo, index, self) => self.findIndex(p => p.id === promo.id) === index);
    }, [props.qualifiedPromos]);

    const promotions = useMemo(() => {
        let allPromos = uniqueQualifiedPromos;
        if (props.globalOrderDiscounts?.orderDiscounts?.length > 0) {
            allPromos = [...allPromos, ...props.globalOrderDiscounts.orderDiscounts];
        }
        return allPromos.sort((a, b) => {
            if (a.priority !== b.priority) {
                return b.priority - a.priority;
            }
            return b.amount - a.amount;
        });

    }, [props.globalOrderDiscounts, uniqueQualifiedPromos]);

    if (isNftOwnershipDataFetching || props.isLoading) {
        let numOfSkeletons = 4; // desktop
        if (isTablet) {
            numOfSkeletons = 2; // tablet
        } else if (isMobile) {
            numOfSkeletons = 1; // mobile
        }

        return (
            <div className="restaurants-search-gofrens-promotions">
                <div className="restaurants-search-results-page_list">
                    {Array.from({length: numOfSkeletons}, (_, i) => <div className="restaurants-search-results_list_item" key={i}>
                        <div className="restaurant_search_item_card ">
                            <Skeleton height={100}/>
                        </div>
                    </div>)}
                </div>
            </div>
        );
    }

    if (!props.goFrensRestaurants.length && !props.globalOrderDiscounts?.orderDiscounts?.length || !promotions.length) {
        return null;
    }

    return (
        <div className="restaurants-search-gofrens-promotions">
            <DragScroll className="restaurants-search-results-page_list">
                <>
                    {promotions.slice(0, getPromotionCount()).map(renderGlobalPromo)}
                    {isMobile && <div className="restaurant-promo-card view-all-card" onClick={() => props.setViewState(ViewStates.PROMOTIONS)}>
                        <div className="view-all-card_content">
                            <HiArrowRight/>
                            <span>View All</span>
                        </div>
                    </div>}
                    {isDesktop && !isTablet && <div className="restaurants-search-results-page_list_view-all">
                        <div className="restaurants-search-results-page_list_view-all_content" onClick={() => props.setViewState(ViewStates.PROMOTIONS)}>
                            <HiArrowRight/>
                            <span>View All</span>
                        </div>
                    </div>}
                </>
            </DragScroll>
        </div>
    );
}


export default RestaurantSearchGoFrensPromotions;
