import useWindowSize from "@/hooks/useWindowSize";
import { useState, useRef, useEffect, FC } from "react";

interface props {
    text: string;
    maxLines?: number;
    showMoreColor?: string;
}

const ExpandableText: FC<props> = ({ text, maxLines, showMoreColor }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight;
                setIsOverflowing(isOverflowing);
                if (!isOverflowing) {
                    setIsExpanded(false);
                }
            }
        };
        checkOverflow();
        // Check overflow again when window resizes
        window.addEventListener("resize", checkOverflow);
        return () => window.removeEventListener("resize", checkOverflow);
    }, [text]);

    return (
        <p className="expandable-text">
            <span
                ref={textRef}
                className={`expandable-text_content ${isExpanded ? "expanded" : ""}`}
                style={isExpanded ? { WebkitLineClamp: "unset", lineClamp: "unset" } : {
                    WebkitLineClamp: maxLines,
                    lineClamp: maxLines,
                }}
            >
                {text}{isOverflowing ? " " : ""}
            </span>
            {isOverflowing &&
                <span className="expandable-text_show-more" style={{
                    color: showMoreColor,
                }} onClick={() => setIsExpanded(!isExpanded)}>
                    &nbsp;{isExpanded ? "Read Less" : "Read More"}
                </span>
            }
        </p>
    );
};

export default ExpandableText;