import {ButtonHTMLAttributes, FormEvent, ReactElement, useState} from "react";
import {CommerceApi, UsersApi} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {PatternFormat} from "react-number-format";
import {NumberFormatValues} from "react-number-format/types/types";
import {IStore} from "@/redux/defaultStore";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import FrameButton from "@/components/buttons/FrameButton";
import {useGetStripePaymentMethodList} from "@/hooks/useGetStripePaymentMethodList";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import {CreditCardFormValues, isCreditCardValid} from "@/utils/isCreditCardValid";

const defaultValues: CreditCardFormValues = {
    cardNumber: "",
    expDate: {
        floatValue: undefined,
        formattedValue: "",
        value: "",
    },
    securityCode: "",
    postalCode: "",
    country: "US",
};

function CardPaymentsInput(): ReactElement {
    const [
        formValues,
        setFormValues,
    ] = useState<CreditCardFormValues>(defaultValues);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const {refetch: refetchStripePaymentMethodListData} = useGetStripePaymentMethodList(fullToken);

    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        dispatch(incrementLoading());
        try {
            const paymentMethod = await new CommerceApi(getConfig()).stripePaymentMethodAdd({
                createPaymentMethodBody: {
                    cardNumber: formValues.cardNumber,
                    expMonth: parseInt(formValues.expDate.formattedValue.split("/")[0]),
                    expYear: 2000 + parseInt(formValues.expDate.formattedValue.split("/")[1]),
                    cvc: formValues.securityCode,
                    postalCode: formValues.postalCode,
                    country: "US",
                },
            });
            setFormValues(defaultValues);

            await refetchStripePaymentMethodListData();

            await new UsersApi(getConfig(fullToken)).updateDefaultPayment({
                defaultPaymentMethodId: paymentMethod.paymentMethodId,
            });
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            await dispatch(updateCurrentUser(userRes));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    /**
     * Handle the card number input onChange.
     *
     * @param cardNumber
     */
    function cardNumberOnChange(cardNumber: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            cardNumber: cardNumber.value,
        });
    }

    /**
     * Handle the card expiry input onChange.
     *
     * @param expDate
     */
    function expiryOnChange(expDate: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            expDate,
        });
    }

    /**
     * Handle the card security input onChange.
     *
     * @param security
     */
    function securityOnChange(security: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            securityCode: security.value,
        });
    }

    /**
     * Handle the postal code input onChange.
     *
     * @param postalCode
     */
    function postalCodeOnChange(postalCode: NumberFormatValues): void {
        setFormValues({
            ...formValues,
            postalCode: postalCode.value,
        });
    }

    const postalCodeInput =
        <div className="card-payment-add-modal_body_postal-code">
            <label>ZIP Code</label>
            <PatternFormat
                value={formValues.postalCode}
                valueIsNumericString={true}
                type="tel"
                format="#####"
                placeholder="00000"
                onValueChange={postalCodeOnChange}
            />
        </div>;
    return (
        <form onSubmit={onFormSubmit}>
            <div className="mint-industry-credit-card_body_credit">
                <div className="card-payment-add-modal_body_card-number">
                    <label>Card Number</label>
                    <PatternFormat
                        value={formValues.cardNumber}
                        valueIsNumericString={true}
                        type="tel"
                        format="#### #### #### ####"
                        placeholder="0000 0000 0000 0000"
                        className="card-payment-add-modal_body_card-number_text"
                        onValueChange={cardNumberOnChange}
                    />
                </div>

                <div className="card-payment-add-modal_body_row">
                    <div className="card-payment-add-modal_body_expiry">
                        <label>Expiry date</label>
                        <PatternFormat
                            value={formValues.expDate.value}
                            valueIsNumericString={true}
                            type="tel"
                            format="## / ##"
                            placeholder="MM / YY"
                            onValueChange={expiryOnChange}
                        />
                    </div>

                    <div className="card-payment-add-modal_body_security">
                        <label>Security Code</label>
                        <PatternFormat
                            value={formValues.securityCode}
                            valueIsNumericString={true}
                            type="tel"
                            format="###"
                            placeholder="000"
                            onValueChange={securityOnChange}
                        />
                    </div>

                    {(isDesktop || isTablet) && postalCodeInput}

                </div>
                {isMobile && !isDesktop && postalCodeInput}
            </div>

            <div className="card-payment-add-modal_body_actions">

                <button
                    className="reset-button"
                    onClick={() => setFormValues(defaultValues)}
                    type="button"
                >
                    Reset
                </button>

                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color={isCreditCardValid(formValues)
                        ? "purple"
                        : "gray"}
                    size="narrow"
                    className="card-payment-add-modal_body_actions_add"
                    forwardProps={{
                        type: "submit",
                        disabled: !isCreditCardValid(formValues),
                    }}
                >
                    Add Card
                </FrameButton>
            </div>
        </form>
    );
}

export default CardPaymentsInput;
