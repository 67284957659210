import {ReactElement} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameAutoPanelFooter from "@/components/modals/autoPanelComponents/FrameAutoPanelFooter";
import {removeMenuOrderError} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";
import {MenuOrderItem} from "@devour/client";

interface Props {
    updateMenuOrderItemManager?: (key: MenuOrderItem[], clear?: boolean) => void;
    forceDarkMode?: boolean;
}

function MenuOrderErrorModal(props: Props): ReactElement {
    const dispatch = useDispatch();
    const menuOrderError = useSelector((store: IStore) => store.metaStore.menuOrderError);
    const forceDarkMode = props.forceDarkMode;

    async function onClearCart() {
        props.updateMenuOrderItemManager(null, true);
        onCloseModal();
    }

    function onCloseModal() {
        dispatch(removeMenuOrderError());
    }

    return (
        <FrameOneAutoPanel
            isOpen={Boolean(menuOrderError?.errorMessage)}
            toggle={onCloseModal}
            size="xs"
        >
            <FrameAutoPanelHeader
                title="Cart Error"
                toggle={onCloseModal}
            />
            <FrameAutoPanelBody className={forceDarkMode ? "menu-order-error-modal_dark-body" : undefined}>
                <p>
                    {menuOrderError?.errorMessage}
                </p>
            </FrameAutoPanelBody>
            <FrameAutoPanelFooter>
                <FrameButton
                    color="danger"
                    size="normal"
                    onClick={onClearCart}
                >
                    Clear Cart
                </FrameButton>

                <FrameButton
                    color="purple"
                    size="normal"
                    onClick={onCloseModal}
                >
                    Dismiss
                </FrameButton>
            </FrameAutoPanelFooter>

        </FrameOneAutoPanel>
    );
}

export default MenuOrderErrorModal;
