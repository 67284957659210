/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RestaurantSubType {
    RESTAURANT = 'RESTAURANT',
    GROCERY = 'GROCERY',
    CONVENIENCE = 'CONVENIENCE'
}

export function RestaurantSubTypeFromJSON(json: any): RestaurantSubType {
    return RestaurantSubTypeFromJSONTyped(json, false);
}

export function RestaurantSubTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestaurantSubType {
    return json as RestaurantSubType;
}

export function RestaurantSubTypeToJSON(value?: RestaurantSubType | null): any {
    return value as any;
}

