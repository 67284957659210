/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    Challenge,
    ChallengeFromJSON,
    ChallengeFromJSONTyped,
    ChallengeToJSON,
    PrizePoolType,
    PrizePoolTypeFromJSON,
    PrizePoolTypeFromJSONTyped,
    PrizePoolTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains prize pools that can be won by users in a challenge
 * @export
 * @interface PrizePool
 */
export interface PrizePool {
    /**
     * Name of the prize
     * @type {string}
     * @memberof PrizePool
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PrizePool
     */
    quantity: number;
    /**
     * 
     * @type {Challenge}
     * @memberof PrizePool
     */
    challenge: Challenge;
    /**
     * Description of the prize
     * @type {string}
     * @memberof PrizePool
     */
    description?: string;
    /**
     * 
     * @type {Asset}
     * @memberof PrizePool
     */
    image: Asset;
    /**
     * 
     * @type {PrizePoolType}
     * @memberof PrizePool
     */
    type: PrizePoolType;
    /**
     * Number of prizes that have been rewarded
     * @type {number}
     * @memberof PrizePool
     */
    quantityRewarded?: number;
    /**
     * The amount of the prize
     * @type {number}
     * @memberof PrizePool
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PrizePool
     */
    nftTracker?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrizePool
     */
    notifiedWinner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrizePool
     */
    winnerMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof PrizePool
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PrizePool
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof PrizePool
     */
    updatedAt: number;
}

export function PrizePoolFromJSON(json: any): PrizePool {
    return PrizePoolFromJSONTyped(json, false);
}

export function PrizePoolFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrizePool {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'quantity': json['quantity'],
        'challenge': ChallengeFromJSON(json['challenge']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'image': AssetFromJSON(json['image']),
        'type': PrizePoolTypeFromJSON(json['type']),
        'quantityRewarded': !exists(json, 'quantityRewarded') ? undefined : json['quantityRewarded'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'nftTracker': !exists(json, 'nftTracker') ? undefined : json['nftTracker'],
        'notifiedWinner': !exists(json, 'notifiedWinner') ? undefined : json['notifiedWinner'],
        'winnerMessage': !exists(json, 'winnerMessage') ? undefined : json['winnerMessage'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function PrizePoolToJSON(value?: PrizePool | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'quantity': value.quantity,
        'challenge': ChallengeToJSON(value.challenge),
        'description': value.description,
        'image': AssetToJSON(value.image),
        'type': PrizePoolTypeToJSON(value.type),
        'quantityRewarded': value.quantityRewarded,
        'amount': value.amount,
        'nftTracker': value.nftTracker,
        'notifiedWinner': value.notifiedWinner,
        'winnerMessage': value.winnerMessage,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


