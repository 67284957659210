import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {
    completedList,
    getOrderStatusTag,
    processingList,
} from "../../pages/menu-orders/MenuOrderListPage";
import {ApiError, ErrorType, MenuOrder} from "@devour/client";
import moment from "moment/moment";
import OrderReceiptModal from "../modals/OrderReceiptModal";
import classNames from "classnames";
import {useRestaurant} from "@/hooks/useRestaurant";
import {LinkProps, useNavigate} from "react-router";
import {useCreateMenuOrderItems} from "@/hooks/menuOrder/useCreateMenuOrderItems";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";

interface Props {
    order: MenuOrder;
}

function MenuOrderListCard(props: Props): ReactElement {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [
        receiptIsOpen,
        setReceiptIsOpen,
    ] = useState<boolean>(false);
    const {data: restaurant} = useRestaurant(props.order.business);

    const {
        mutateAsync: createMenuOrderItems,
    } = useCreateMenuOrderItems({
        skipOnError: true,
        restaurantId: props.order.business,
    });
    const sumQuantity: number = props.order?.orderItems.reduce((accumulator, object) => {
        return accumulator + object.quantity;
    }, 0);

    function toggleOrderReceiptModal(): void {
        setReceiptIsOpen(!receiptIsOpen);
    }

    async function handleReorder() {
        navigate(`${restaurant?.url}/${props.order.address?.placeId}`);
        const newMenuOrder = await createMenuOrderItems(props.order);
        if (newMenuOrder?.validationErrors?.length > 0) {
            const errors: Array<ApiError> = newMenuOrder.validationErrors.map((error) => {
                return {
                    type: ErrorType.APP,
                    message: error.errorMessage,
                };
            });

            // const errors = await Promise.all(newMenuOrder.validationErrors.map((error) => makeApiError(error.error)));
            dispatch(await addError({
                type: ErrorType.FORM,
                message: "Some items in your previous order are not available anymore. They have been skipped and everything else has been added to your cart.",
                errors: errors,
            }));
        }
        console.log("newmenuOrder", newMenuOrder);
    }

    const getSubtitle = () => {
        let statusLabel = getOrderStatusTag(props.order).name;
        const date = moment(props.order.updatedAt).format("LL");
        const itemsTotal = props.order.subtotal;
        const isInProgress = statusLabel === "Processing";
        if (isInProgress) {
            statusLabel = "In Progress";
        }

        return (
            <span>
                <span className={classNames("progress-label", {"processing": isInProgress})}>
                    {statusLabel} {!isInProgress ? `on ${date}` : ""}
                    <span className="progress-label_dot">   •   </span>
                </span>
                {sumQuantity} item{sumQuantity !== 1 ? "s" : ""} for ${itemsTotal}
            </span>
        );

    };

    if (!props.order) {
        return null;
    }

    return (
        <>
            <OrderReceiptModal
                isOpen={receiptIsOpen}
                order={props.order}
                onClose={toggleOrderReceiptModal}
                track={true}
            />

            <div className="menu-order-list-component" onClick={() => setReceiptIsOpen(true)}>
                <div className="menu-order-list-component_header">
                    <div className="menu-order-list-component_header_business-link">
                        <div className="menu-order-list-component_header_business-link_image">
                            <img
                                src={restaurant?.icon?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                                alt={"business logo"}
                            />
                        </div>
                        <div className="menu-order-list-component_header_business-link_info">
                            <strong>{restaurant?.name}</strong>
                            <p>{getSubtitle()}</p>
                        </div>
                    </div>

                    <div className="menu-order-list-component_header_buttons">
                        {processingList?.includes(props.order.status) &&
														<FrameButton
														    <LinkProps>
														    color="purple"
														    size="narrow"
														    to={`/menu-orders/${props.order.id}/success`}
														>
																Track Order
														</FrameButton>
                        }

                        {completedList?.includes(props.order.status) &&
														<FrameButton
														    <ButtonHTMLAttributes<HTMLButtonElement>>
														    color="purple"
														    size="narrow"
														    onClick={handleReorder}
														>
																Reorder
														</FrameButton>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default MenuOrderListCard;
