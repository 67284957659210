import AssetImage from "@/components/AssetImage";
import { useAsset } from "@/hooks/useAsset";
import { formatChallengeDateRange } from "@/utils/formatChallengeDateRange";
import { ChallengeResponseWithAcceptance } from "@devour/client";
import {FC, useEffect, useState} from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import FrameButton from "@/components/buttons/FrameButton";
import {isColorDarkOrLight} from "@/utils/isColorDarkOrLight";
import classNames from "classnames";
import {DateTime} from "luxon";

interface Props {
    challenge: ChallengeResponseWithAcceptance;
}

export function getCurrentEstTime() {
    return DateTime.now().setZone("America/New_York")
        .toMillis();
}

export const OverwolfGoVipChallengeCard: FC<Props> = ({ challenge }) => {
    const { data } = useAsset((isMobile ? challenge.backgroundImageSmall : challenge.backgroundImageLarge) as any);
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(getCurrentEstTime());

    useEffect(() => {
        const currentTimeIntervalKey = setInterval(() => setCurrentDate(getCurrentEstTime()), 1000 * 10);
        return () => clearInterval(currentTimeIntervalKey);
    }, []);

    const handleChallengeClick = () => {
        navigate(challenge.brandMapSlug ? `/brands/${challenge.brandMapSlug}` : `/challenges/${challenge.id}`);
    };

    const getChallengeLabel = () => {
        if (challenge.end <= currentDate) {
            return "View";
        }
        if (challenge.isAccepted) {
            return "Track";
        }
        return "Explore";
    };

    return (
        <div
            className="overwolf-go-vip-challenges_card"
            style={{backgroundImage: `url(${data?.cdnUrl ?? ""})`}}
        >
            <div className="overwolf-go-vip-challenges_card_shader" />
            <div className="overwolf-go-vip-challenges_card_heading">
                <div className="overwolf-go-vip-challenges_card_heading_info">
                    <AssetImage assetId={challenge.brandLogo as any} alt="brand-logo"/>
                    <h5>CHALLENGE</h5>
                    <span>{formatChallengeDateRange(challenge.start, challenge.end)}</span>
                </div>
                <FrameButton
                    size="narrow"
                    color="purple"
                    onClick={handleChallengeClick}
                    forwardProps={{style: {backgroundColor: challenge.brandColor}}}
                    className={classNames("overwolf-go-vip-challenges_card_heading_action", isColorDarkOrLight(challenge.brandColor))}
                >
                    {getChallengeLabel()}
                </FrameButton>
            </div>

            <div className="overwolf-go-vip-challenges_card_main">
                <h5>{challenge.cardTitle || challenge.name}</h5>
                <span>{challenge.description}</span>
            </div>
        </div>
    );
};
