import {useEffect, useState} from "react";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import {IoClose} from "react-icons/io5";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import Spacer from "@/components/Spacer";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {updateIsFuelUpModalDismissed} from "@/redux/meta/metaActions";

interface Props {
    loadWithXp: () => Promise<void>;
}

export default function FuelUpWithXp(props: Props) {
    const dispatch = useDispatch();
    const [learnMoreView, setLearnMoreView] = useState<boolean>(false);
    const isFuelUpModalDismissed = useSelector((store: IStore) => store.metaStore.isFuelUpModalDismissed);
    const [showModal, setShowModal] = useState<boolean>(!isFuelUpModalDismissed);

    useEffect(() => {
        setShowModal(!isFuelUpModalDismissed);
    }, [isFuelUpModalDismissed]);

    async function handleLoadWithXp() {
        await props.loadWithXp();
        toggleModal();
    }

    function toggleModal(): void {
        setShowModal(false);
    }

    function onModalDismiss() {
        dispatch(updateIsFuelUpModalDismissed(true));
    }

    return (
        <FrameOneModal
            isOpen={showModal}
            toggle={toggleModal}
            contentClassName="fuel-xp-modal"
            size="xs"
        >
            <div
                className={classNames("fuel-xp-modal_container", {
                    "fuel-xp-modal_container_announcement": !learnMoreView,
                })}
                style={{backgroundImage: `url(${import.meta.env.VITE_CDN_URL}/images/fuel-up-with-xp-modal.webp)`}}
            >
                <div className="fuel-xp-modal_header">
                    <IoClose size="1.88rem" onClick={toggleModal}/>
                </div>

                <div className="fuel-xp-modal_body">
                    {learnMoreView
                        ? <>
                            <h4 className="fuel-xp-modal_body_title">How does loading Fuel with my level XP work?</h4>
                            <div>
                                <div className="fuel-xp-modal_body_section">
                                    <img
                                        src={`${import.meta.env.VITE_CDN_URL}/images/fuel-up-with-xp-icon-1.svg`}
                                        className="fuel-xp-modal_body_section_icon"
                                        alt="fuel-up-icon"/>

                                    <div className="fuel-xp-modal_body_section_text">
                                        Only the current level’s XP can be converted
                                    </div>

                                    <div className="fuel-xp-modal_body_section_img-container">
                                        <img
                                            src={`${import.meta.env.VITE_CDN_URL}/images/fuel-up-with-xp-1.webp`}
                                            className="fuel-xp-modal_body_section_img"
                                            alt="fuel-up"
                                        />
                                    </div>
                                </div>

                                <div className="fuel-xp-modal_body_section">
                                    <img
                                        src={`${import.meta.env.VITE_CDN_URL}/images/fuel-up-with-xp-icon-2.svg`}
                                        className="fuel-xp-modal_body_section_icon"
                                        alt="fuel-up-icon"/>

                                    <div className="fuel-xp-modal_body_section_text">
                                        After conversion, XP decreases, but the level remains the same
                                    </div>

                                    <div className="fuel-xp-modal_body_section_img-container">
                                        <img
                                            src={`${import.meta.env.VITE_CDN_URL}/images/fuel-up-with-xp-2.webp`}
                                            className="fuel-xp-modal_body_section_img"
                                            alt="fuel-up"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        : <>
                            <div>
                                <span className="fuel-xp-modal_body_new-tag">New</span>
                                <h3 className="fuel-xp-modal_body_title">Load Fuel with XP</h3>
                                <div className="fuel-xp-modal_body_section_text">
                                    Now you can load FUEL with your XP, not just your cards!
                                </div>
                            </div>

                            <div>
                                <img
                                    src={`${import.meta.env.VITE_CDN_URL}/images/fuel-up-with-xp-announcement.webp`}
                                    alt="fuel-up"
                                />
                            </div>
                        </>
                    }
                </div>

                {learnMoreView
                    ? <div>
                        <FrameOneCheckbox
                            onToggle={onModalDismiss}
                            checked={false}
                            className="fuel-xp-modal_checkbox"
                        >
                            Do not show this again
                        </FrameOneCheckbox>
                        <FrameButton
                            size="large"
                            className="fuel-xp-modal_button"
                            onClick={handleLoadWithXp}
                        >
                            Load with XP Now
                        </FrameButton>
                    </div> : <>
                        <Spacer />
                        <FrameButton
                            size="large"
                            className="fuel-xp-modal_button"
                            onClick={() => setLearnMoreView(true)}
                        >
                            Learn More
                        </FrameButton>
                    </>}
            </div>
        </FrameOneModal>
    );

}