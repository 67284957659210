import {ReactElement} from "react";
import Style1 from "../svgs/level_up_graphic_style1.svg?react";
import Style2 from "../svgs/level_up_graphic_style2.svg?react";

interface Props {
    level: number;
}

function GoVipLevelUpAnimation(props: Props): ReactElement {
    function calculateXPosition(): number {
        if (props.level < 10) {
            return -18.620588;
        }

        const stringifiedLevel: string = props.level.toString();
        const length: number = stringifiedLevel.length;

        return -18.620588 - length * 8;
    }

    return (
        <svg
            id="eBzaEUyufM01" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 350 350" shapeRendering="geometricPrecision" textRendering="geometricPrecision"
        >
            <Style1/>
            <defs>
                <linearGradient id="eBzaEUyufM06-fill" x1="175" y1="262.05" x2="175" y2="75.95" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                    <stop id="eBzaEUyufM06-fill-0" offset="0%" stopColor="#c2b6f4"/>
                    <stop id="eBzaEUyufM06-fill-1" offset="100%" stopColor="#7859e6"/>
                </linearGradient>
                <linearGradient id="eBzaEUyufM08-fill" x1="175" y1="87.95" x2="175" y2="250.05" spreadMethod="pad"
                    gradientUnits="userSpaceOnUse" gradientTransform="translate(0 0)">
                    <stop id="eBzaEUyufM08-fill-0" offset="0%" stopColor="#c2b6f4"/>
                    <stop id="eBzaEUyufM08-fill-1" offset="100%" stopColor="#7859e6"/>
                </linearGradient>
            </defs>
            <rect width="350" height="350" rx="0" ry="0" fill="#fff" strokeWidth="0"/>
            <g id="eBzaEUyufM03_ts" transform="translate(175.000008,175.000008) scale(0.7,0.7)">
                <circle id="eBzaEUyufM03" r="119.39" transform="translate(-0.000008,-0.000008)" opacity="0" fill="none"
                    stroke="#dfd6fa" strokeWidth="15"/>
            </g>
            <g id="eBzaEUyufM04_ts" transform="translate(175.000015,169.00489) scale(0.5,0.5)">
                <g id="eBzaEUyufM04" transform="translate(-175.000015,-169.00489)" opacity="0">
                    <g>
                        <path
                            d="M270.73,145.5c-2.76-8.5-10.32-14.14-19.26-14.37l-42.64-1.09-14.02-39.68c-3.22-9.11-11.99-15.01-21.33-14.35-8.36.58-15.3,5.88-18.11,13.82l-14.2,40.21-42.63,1.09c-8.94.23-16.51,5.87-19.27,14.37-2.76,8.51.04,17.52,7.14,22.96l33.84,25.94-12.13,40.88c-1.9,6.37-.72,13.05,3.21,18.32c3.95,5.29,10.22,8.45,16.76,8.45c4.19,0,8.26-1.28,11.78-3.7L175,234.17l35.13,24.18c3.52,2.42,7.59,3.7,11.77,3.7c6.55,0,12.82-3.16,16.77-8.45c3.93-5.27,5.1-11.95,3.21-18.32L229.74,194.4l33.85-25.94c7.1-5.44,9.9-14.45,7.14-22.96Zm-10.79,18.2l-35.41,27.13c-1.03.78-1.47,2.14-1.1,3.37l12.7,42.79c1.34,4.54.52,9.28-2.27,13.02-2.83,3.78-7.29,6.04-11.96,6.04-2.96,0-5.86-.91-8.37-2.64l-36.77-25.3c-.53-.37-1.14-.55-1.76-.55s-1.24.18-1.77.55l-36.76,25.3c-2.52,1.73-5.41,2.64-8.38,2.64-4.66,0-9.13-2.26-11.95-6.04-2.79-3.74-3.62-8.48-2.27-13.02l12.7-42.78c.37-1.24-.07-2.6-1.09-3.37L90.06,163.7c-5.06-3.88-7.05-10.29-5.08-16.34c1.96-6.06,7.35-10.07,13.71-10.23l44.6-1.14c1.29-.03,2.44-.86,2.87-2.08l14.87-42.08c2-5.65,6.93-9.42,12.87-9.84c6.66-.46,12.93,3.8,15.25,10.37l14.69,41.55c.42,1.22,1.58,2.05,2.85,2.08l44.62,1.14c6.37.16,11.75,4.17,13.71,10.23c1.97,6.05-.02,12.46-5.08,16.34Z"
                            fill="url(#eBzaEUyufM06-fill)"/>
                        <path
                            d="M259.94,163.7l-35.41,27.13c-1.03.78-1.47,2.14-1.1,3.37l12.7,42.79c1.34,4.54.52,9.28-2.27,13.02-2.83,3.78-7.29,6.04-11.96,6.04-2.96,0-5.86-.91-8.37-2.64l-36.77-25.3c-.53-.37-1.14-.55-1.76-.55s-1.24.18-1.77.55l-36.76,25.3c-2.52,1.73-5.41,2.64-8.38,2.64-4.66,0-9.13-2.26-11.95-6.04-2.79-3.74-3.62-8.48-2.27-13.02l12.7-42.78c.37-1.24-.07-2.6-1.09-3.37L90.06,163.7c-5.06-3.88-7.05-10.29-5.08-16.34c1.96-6.06,7.35-10.07,13.71-10.23l44.6-1.14c1.29-.03,2.44-.86,2.87-2.08l14.87-42.08c2-5.65,6.93-9.42,12.87-9.84c6.66-.46,12.93,3.8,15.25,10.37l14.69,41.55c.42,1.22,1.58,2.05,2.85,2.08l44.62,1.14c6.37.16,11.75,4.17,13.71,10.23c1.97,6.05-.02,12.46-5.08,16.34Z"
                            fill="#fff"/>
                    </g>
                    <path
                        d="M256.3,158.93L220.7,186.2c-2.9,2.23-4.12,6.01-3.08,9.51l12.76,42.99c2.36,7.96-6.6,14.48-13.45,9.77L180,223.05c-3.01-2.07-6.99-2.07-10,0l-36.93,25.42c-6.85,4.71-15.82-1.81-13.45-9.77l12.76-42.99c1.04-3.5-.19-7.28-3.09-9.51L93.71,158.93c-6.6-5.05-3.18-15.59,5.13-15.81l44.82-1.14c3.66-.09,6.87-2.43,8.09-5.87l14.94-42.28c2.77-7.84,13.85-7.84,16.62,0l14.94,42.28c1.22,3.44,4.43,5.78,8.08,5.87l44.83,1.14c8.31.22,11.73,10.76,5.14,15.81Z"
                        fill="url(#eBzaEUyufM08-fill)" fillRule="evenodd"/>
                </g>
            </g>
            <path id="eBzaEUyufM09"
				  d="M98.84,143.12l39.00868-.981727c6.24664-.683226,12.933819-3.12332,15.56912-10.053273l7.456863-21.493198"
				  transform="translate(5.320176 7.878665)" fill="none" fillRule="evenodd" stroke="#fff" strokeWidth="5"
				  strokeLinecap="round" strokeMiterlimit="9" strokeDashoffset="81.349766" strokeDasharray="81.349766"/>
            <path id="eBzaEUyufM010" d="M164.172881,101.085195L166.69,93.83" transform="translate(5.320176 7.878665)"
				  opacity="0" fill="none" fillRule="evenodd" stroke="#fff" strokeWidth="5" strokeLinecap="round"
				  strokeMiterlimit="9" strokeDashoffset="7.679436" strokeDasharray="7.679436"/>
            <line id="eBzaEUyufM014" x1="175" y1="55.92" x2="175" y2="0" fill="none" stroke="#7859e6" strokeWidth="3"
				  strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <line id="eBzaEUyufM015" x1="104.82" y1="78.41" x2="72.14" y2="33.42" fill="none" stroke="#7859e6"
				  strokeWidth="3"
				  strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <line id="eBzaEUyufM016" x1="61.46" y1="138.11" x2="8.57" y2="120.92" fill="none" stroke="#7859e6"
				  strokeWidth="3"
				  strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <line id="eBzaEUyufM017" x1="61.44" y1="211.9" x2="8.57" y2="229.08" fill="none" stroke="#7859e6"
				  strokeWidth="3"
				  strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <line id="eBzaEUyufM018" x1="106.09" y1="269.85" x2="72.14" y2="316.58" fill="none" stroke="#7859e6"
				  strokeWidth="3" strokeMiterlimit="10" strokeDashoffset="57.760673" strokeDasharray="57.760673"/>
            <line id="eBzaEUyufM019" x1="175" y1="293.46" x2="175" y2="350" fill="none" stroke="#7859e6" strokeWidth="3"
				  strokeMiterlimit="10" strokeDashoffset="56.54" strokeDasharray="56.54"/>
            <line id="eBzaEUyufM020" x1="245.18" y1="271.59" x2="277.86" y2="316.58" fill="none" stroke="#7859e6"
				  strokeWidth="3" strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <line id="eBzaEUyufM021" x1="288.54" y1="211.89" x2="341.44" y2="229.08" fill="none" stroke="#7859e6"
				  strokeWidth="3" strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <line id="eBzaEUyufM022" x1="288.56" y1="138.1" x2="341.44" y2="120.92" fill="none" stroke="#7859e6"
				  strokeWidth="3" strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <line id="eBzaEUyufM023" x1="245.19" y1="78.39" x2="277.86" y2="33.42" fill="none" stroke="#7859e6"
				  strokeWidth="3"
				  strokeMiterlimit="10" strokeDashoffset="55.92" strokeDasharray="55.92"/>
            <g id="eBzaEUyufM024_ts" transform="translate(175.00132,177) scale(0.5,0.5)">
                <text id="eBzaEUyufM024" dx="0" dy="0" fontSize="64"
					  fontFamily="Poppins, sans-serif" // "&quot;eBzaEUyufM01:::Prompt&quot;"
					  fontWeight="700" transform={`translate(${calculateXPosition()},28.259384)`} opacity="0"
					  fill="#f6f3f3" stroke="#7859e6"
					  strokeWidth="0">
                    <tspan y="0" fontWeight="700" strokeWidth="0">
                        {props.level}
                    </tspan>
                </text>
            </g>
            <Style2/>
        </svg>
    );
}

export default GoVipLevelUpAnimation;
