import {ChangeEvent, ReactElement, useContext, useState} from "react";
import {MenuCategorySearchEssential, MenuItemSearchEssential} from "@devour/client";
import {RestaurantContext} from "../context/RestaurantContext";
import SearchInput from "@/components/inputs/SearchInput";
import {isDesktop} from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import {useRestaurant} from "@/hooks/useRestaurant";
import { CategoryCarousel } from "./CategoryCarousel/CategoryCarousel";
import ConvenienceMenuItemCardPreload from "./ConvenienceMenuItemCardPreload";
import classNames from "classnames";

function ConvenienceMenusPreload(): ReactElement {


    const {restaurantId} = useContext(RestaurantContext);
    const {data: restaurant} = useRestaurant(restaurantId);
    const menuCategoriesRoot = restaurant?.menus;
    console.log("items", menuCategoriesRoot);
    const [
        searchValue,
        setSearchValue,
    ] = useState("");

    function findLikelyTallestItem(items) {
        let maxEstimate = -1;
        let likelyTallest = null;
        items.forEach(item => {
            const estimated = item.name.length;
            if (estimated > maxEstimate) {
                maxEstimate = estimated;
                likelyTallest = item;
            }
        });
        return likelyTallest;
    }

    function renderConvencienceMenuItems(menuItems: Array<MenuItemSearchEssential> = []): Array<JSX.Element> {
        return menuItems
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .filter((menuItem) => filterItem(menuItem, searchValue))
            .map((menuItem) =>
                <ConvenienceMenuItemCardPreload
                    menuItem={menuItem}
                />);
    }

    function renderConvenienceCategory (category: MenuCategorySearchEssential) {
        const largestItem = findLikelyTallestItem(category.menuItems.filter((menuItem) => filterItem(menuItem, searchValue)));
        const menuItemsWithoutLargest = category.menuItems.filter((menuItem) => filterItem(menuItem, searchValue)).filter(item => item.name !== largestItem.name);
        return (
            <div className={classNames("restaurant-page_section-margin", "convenience-menu_carousel-margin")} id ={`menu-category-${category.id}`}>
                <CategoryCarousel categoryData={category} viewAllHref={`?categoryId=${category.id}`} items={[...renderConvencienceMenuItems([largestItem]), ...renderConvencienceMenuItems(menuItemsWithoutLargest)]}/>
            </div>
        );
    }

    /**
     * Get only categories & sub categories than have any items that match the user's search.
     *
     * @param _sortedRootCats
     */
    function getFilteredCategories(_sortedRootCats: Array<MenuCategorySearchEssential>): Array<MenuCategorySearchEssential> {
        if (!_sortedRootCats) {
            return [];
        }
        return _sortedRootCats.reduce((acc: Array<MenuCategorySearchEssential>, category) => {
            const foundMenuItems = category.menuItems?.filter(item => filterItem(item, searchValue));

            if (foundMenuItems.length > 0) {
                acc.push(category);
            }

            if (category.menuSubcategories.length > 0) {
                acc = acc.concat(getFilteredCategories(category.menuSubcategories));
            }

            return acc;
        }, []);
    }

    const filteredCategories = getFilteredCategories(menuCategoriesRoot);

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    function clearSearch(): void {
        setSearchValue("");
    }

    function RestaurantMenuCategoryJumperSkeleton(): ReactElement {
        return (
            <div className="category-jumper restaurant-menus-browse_jumper-search-container_preload-skeleton-adjust">
                {Array.from({length: 10}, (_, i) => <Skeleton width={100} height={32} key={i}/>)}
            </div>
        );
    }

    if (!menuCategoriesRoot) {
        return null;
    }

    return (
        <div className="restaurant-menus-preload">

            <div className="restaurant-menus-browse_jumper-search-container">
                {RestaurantMenuCategoryJumperSkeleton()}
                {isDesktop &&
                <div className="restaurant-menus-browse_jumper-search-container_preload-width-adjust">
                    <SearchInput
                        placeholder="Search restaurant menu"
                        value={searchValue}
                        onChange={handleSearchChange}
                        maxLength={15}
                        onClear={clearSearch}
                        className="restaurant-menus-browse_jumper-search-container_input"
                    />
                </div>
                }

            </div>

            {filteredCategories.length > 0 && filteredCategories
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((category) => renderConvenienceCategory(category))
            }

        </div>
    );
}

export default ConvenienceMenusPreload;

function filterItem(item: MenuItemSearchEssential, search: string): boolean {
    return item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        item.description?.toLowerCase()?.includes(search.toLowerCase());
}
