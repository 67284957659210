/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChallengeFaqDetails,
    ChallengeFaqDetailsFromJSON,
    ChallengeFaqDetailsFromJSONTyped,
    ChallengeFaqDetailsToJSON,
    ChallengeInfoResponseAllOf,
    ChallengeInfoResponseAllOfFromJSON,
    ChallengeInfoResponseAllOfFromJSONTyped,
    ChallengeInfoResponseAllOfToJSON,
    ChallengeMembership,
    ChallengeMembershipFromJSON,
    ChallengeMembershipFromJSONTyped,
    ChallengeMembershipToJSON,
    ChallengeOnboarding,
    ChallengeOnboardingFromJSON,
    ChallengeOnboardingFromJSONTyped,
    ChallengeOnboardingToJSON,
    ChallengeResponse,
    ChallengeResponseFromJSON,
    ChallengeResponseFromJSONTyped,
    ChallengeResponseToJSON,
    ChallengeReward,
    ChallengeRewardFromJSON,
    ChallengeRewardFromJSONTyped,
    ChallengeRewardToJSON,
    PrizePool,
    PrizePoolFromJSON,
    PrizePoolFromJSONTyped,
    PrizePoolToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChallengeInfoResponse
 */
export interface ChallengeInfoResponse extends ChallengeResponse {
    /**
     * Whether the challenge has been accepted by the user only if the user is logged in.
     * @type {boolean}
     * @memberof ChallengeInfoResponse
     */
    isAccepted?: boolean;
    /**
     * 
     * @type {Array<PrizePool>}
     * @memberof ChallengeInfoResponse
     */
    prizes: Array<PrizePool>;
    /**
     * 
     * @type {Array<ChallengeMembership>}
     * @memberof ChallengeInfoResponse
     */
    winners?: Array<ChallengeMembership>;
    /**
     * The slug of the brand map associated with this challenge.
     * @type {string}
     * @memberof ChallengeInfoResponse
     */
    brandMapSlug?: string;
    /**
     * Whether the challenge\'s instant reward was claimed by the user.
     * @type {boolean}
     * @memberof ChallengeInfoResponse
     */
    isInstantRewardClaimed?: boolean;
}

export function ChallengeInfoResponseFromJSON(json: any): ChallengeInfoResponse {
    return ChallengeInfoResponseFromJSONTyped(json, false);
}

export function ChallengeInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChallengeInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...ChallengeResponseFromJSONTyped(json, ignoreDiscriminator),
        'isAccepted': !exists(json, 'isAccepted') ? undefined : json['isAccepted'],
        'prizes': ((json['prizes'] as Array<any>).map(PrizePoolFromJSON)),
        'winners': !exists(json, 'winners') ? undefined : ((json['winners'] as Array<any>).map(ChallengeMembershipFromJSON)),
        'brandMapSlug': !exists(json, 'brandMapSlug') ? undefined : json['brandMapSlug'],
        'isInstantRewardClaimed': !exists(json, 'isInstantRewardClaimed') ? undefined : json['isInstantRewardClaimed'],
    };
}

export function ChallengeInfoResponseToJSON(value?: ChallengeInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ChallengeResponseToJSON(value),
        'isAccepted': value.isAccepted,
        'prizes': ((value.prizes as Array<any>).map(PrizePoolToJSON)),
        'winners': value.winners === undefined ? undefined : ((value.winners as Array<any>).map(ChallengeMembershipToJSON)),
        'brandMapSlug': value.brandMapSlug,
        'isInstantRewardClaimed': value.isInstantRewardClaimed,
    };
}


