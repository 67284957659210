import {useGetChallenge} from "@/hooks/challenges/useGetChallenge";
import {useGetChallengeQuests} from "@/hooks/challenges/useGetChallengeQuests";
import GameChallengeQuests from "@/pages/challenge/GameChallengeQuests";
import {useEffect, useMemo, useState} from "react";
import {OWReward} from "@devour/client";
import Skeleton from "react-loading-skeleton";
import {DateTime} from "luxon";
import { BrandChallengeInstantRewardCard } from "./BrandChallengeInstantRewardCard";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import ChallengePrizePool from "@/pages/challenge/ChallengePrizePool";
import { convertChallengeRewardToReward } from "@/utils/convertChallengeRewardToReward";
import Toast from "@/components/Toast";

const MAX_TIMEOUT = 2 ** 31 - 1; // setTimeout using a 32-bit signed integer to store the delay value
export default function ChallengeQuestsAndPrizes({challengeId}: { challengeId: string }) {
    const {data: challenge, refetch: refetchChallenge} = useGetChallenge(challengeId);
    const {data: quests, refetch: refetchQuests} = useGetChallengeQuests(challengeId);
    const [showToast, setShowToast] = useState(false);
    const instantRewardQuest = useMemo(() => {
        if (quests && challenge?.instantRewardQuest) {
            return quests.find((quest) => quest.id === challenge.instantRewardQuest);
        }
        if (quests && challenge?.rewards && challenge.rewards.length > 0) {
            const challengeReward = challenge.rewards[0];
            return convertChallengeRewardToReward(challengeReward, quests);
        }
        return null;
    }, [quests, challenge]);

    useOverwolfInterop((ev) => {
        if (ev.type === "ow:game-events-logged") {
            void refetchQuests();
        }
    });
    const challengeQuests = useMemo(() => quests?.filter(quest => quest.id !== challenge?.instantRewardQuest) ?? [], [quests, challenge]);
    const questsByGame = useMemo(() => quests?.filter(quest => quest.id !== challenge?.instantRewardQuest)
        .reduce((acc, quest) => {
            const gameKey = quest.game ?? "Unknown Game";
            if (!acc[gameKey]) {
                acc[gameKey] = [];
            }
            acc[gameKey].push(quest);
            return acc;
        }, {} as Record<string, Array<OWReward>>), [challengeQuests, challenge]);

    useEffect(() => {
        let endChallengeTimer: NodeJS.Timeout | undefined;
        if (challenge) {
            const now = DateTime.now().setZone("America/New_York");
            const endDate = DateTime.fromMillis(challenge.end).setZone("America/New_York");
            const differenceMs = endDate.diff(now).as("milliseconds");
            if (differenceMs > 0 && !challenge.hasEnded) {
                endChallengeTimer = setTimeout(() => {
                    void refetchChallenge().then((res) => {
                        if (!res.data?.hasEnded) {
                            setShowToast(true);
                        }
                    });
                }, Math.min(differenceMs + 6 * 1000, MAX_TIMEOUT)); // 6 seconds after the challenge ends 11:59:59pm EST to wait for the cron to select winners
            }
        }

        return () => {
            if (endChallengeTimer) {
                clearTimeout(endChallengeTimer);
            }
        };
    }, [challenge]);


    const renderSkeleton = () =>
        <div className="brand-challenge_main">
            <div className="brand-challenge_main_all-quests">
                {Array.from({ length: 3 }, (_, i) =>
                    <div className="brand-challenge_game" key={`brand-challenge_game_skeleton_${i}`}>
                        <div className="brand-challenge_game_header">
                            <div className="brand-challenge_game_header_content">
                                <Skeleton width={110} height={36}/>
                                <div>Quests</div>
                            </div>
                        </div>
                        <div className="brand-challenge_game_body">
                            <Skeleton height={100} />
                            <Skeleton height={100} />
                        </div>
                    </div>)}
            </div>

            <div className="brand-challenge_prize">
                <h4>Unlock Your Prize Pool Entry 🔥</h4>
                <Skeleton height={70} />

                <Skeleton height={70} />
                <div className="brand-challenge_prize_carousel react-loading-skeleton"></div>
            </div>
        </div>;

    if (!challenge || !quests) {
        return renderSkeleton();
    }

    return (
        <>

            <Toast
                message="Winner(s) will be announced soon! (ETA: in 30 min)"
                isOpen={showToast}
                showButton={true}
                variant="info"
                buttonMessage={"Got it!"}
                onDismiss={() => setShowToast(false)}
            />
            <div className="brand-challenge_main">
                <div className="brand-challenge_main_all-quests">
                    {instantRewardQuest && <BrandChallengeInstantRewardCard
                        onClaim={refetchChallenge}
                        challengeRes={challenge}
                        questReward={instantRewardQuest} />}

                    {/* regular quest  */}
                    {quests && Object.entries(questsByGame).map(([game, gameQuests]) =>
                        <GameChallengeQuests
                            key={game}
                            gameId={game !== "Unknown Game" ? game : undefined}
                            quests={gameQuests}/>)}
                </div>

                <ChallengePrizePool challenge={challenge} quests={challengeQuests} />
            </div>
        </>

    );
}