import { useAsset } from "@/hooks/useAsset";
import { useGetGuild } from "@/hooks/useGetGuild";
import { FC, useState } from "react";
import { IoMdClose } from "react-icons/io";
import FrameModalBody from "./modals/modalComponents/FrameModalBody";
import FrameOneModal from "./modals/modalComponents/FrameOneModal";
import FrameButton from "./buttons/FrameButton";
import { useGetOwnReferralCode } from "@/hooks/useGetOwnReferralCode";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import { FaCheck, FaFacebook, FaTwitter } from "react-icons/fa";
import { MdContentCopy, MdEmail } from "react-icons/md";
import { isGoNative } from "@/utils/isGoNative";
import { TbMessageCircleFilled } from "react-icons/tb";
import useThemePreference from "@/hooks/useThemePreference";
import { FiShare } from "react-icons/fi";
import { isMobile } from "react-device-detect";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "@ciaran0/react-share";

interface props {
    guildId?: string;
    isVisible: boolean;
    onClose: () => void;
}
export const InviteModal: FC<props> = ({ isVisible, onClose, guildId }) => {
    const guild = useGetGuild(guildId);
    const assetImage = useAsset(guild?.data?.hero);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isTextCopied, setIsTextCopied] = useState(false);
    const { isOnDarkMode } = useThemePreference();
    const { data: ownReferralCodeData } = useGetOwnReferralCode(fullToken);
    const referralCode = ownReferralCodeData?.referralCodes?.[0]?.code;
    const getInviteLink = () => {
        if (!guildId) return "";
        return `${import.meta.env.VITE_WEB_URL}/guilds?ref=${encodeURI(referralCode)}&guildId=${guild?.data?.id}`;
    };
    const getHeroUrl = () => {
        if (!guildId) return;
        return assetImage?.data?.cdnUrl;
    };

    const getShareMessage = (includeLink = true) => {
        const link = includeLink ? `: ${getInviteLink()}` : "";
        return `Join me as I am levelling up my ${guild?.data?.name} guild on DevourGO! Use my link to sign up now ${link} 🚀`;
    };

    const shareOptions = [
        {
            icon: <TbMessageCircleFilled />,
            title: "Message",
            onClick: () => {
                window.open(`sms:?&body=${encodeURIComponent(`
                    👋 Hey! Join me in the ${guild?.data?.name} Guild on Devour.
                    We earn rewards just for gaming + monthly prizes too.
                    Hop in here:
                        ${getInviteLink()}
                    `)}`);
            },
        },
        {
            icon: <MdEmail />,
            title: "Email",
            render: () => {
                return (<EmailShareButton
                    url={" "}
                    subject={`🎮 Squad Up! Join My ${guild?.data?.name} Guild on Devour and Start Earning Rewards`}
                    body={`👋 Hey! Come join me in the ${guild?.data?.name} Guild on Devour — the platform that rewards us just for playing games.

                        Every month is a competition, with prizes for:
                        🏆 All winning Guild members
                        🎖️ The top XP earner in the winning Guild
                        👑 The top overall XP earner across all players

                        Let’s squad up and start stacking rewards together. 💪

                        👉 [Join My Guild Here](${getInviteLink()})

                        See you in-game! 🎮
                        `}
                    className="invite-modal_body_content_share-options_option_content"
                    style={{
                        backgroundColor: "none",
                    }}
                >
                    <MdEmail />
                </EmailShareButton>);
            },
        },
        {
            icon: <FaTwitter />,
            title: "Twitter",
            render: () => {
                return (
                    <TwitterShareButton
                        title={`🎮 I’m repping the ${guild?.data?.name} Guild on Devour!

Monthly prizes for: 

🏆 Winning Guild
🎖️ Top Guild XP earner
👑 Top XP overall

Let’s run it! 💪🎯
👉 [Join My Guild Here]`}
                        url={getInviteLink()}
                        hashtags={[
                            "DevourGO",
                        ]}
                        className="invite-modal_body_content_share-options_option_content"
                        style={{
                            backgroundColor: "none",
                        }}
                    >
                        <FaTwitter/>
                    </TwitterShareButton>
                );
            },
        },
        // {
        //     icon: <FaFacebook />,
        //     title: "Facebook",
        //     render: () => {
        //         return (
        //             <FacebookShareButton
        //                 quote={getShareMessage(false)}
        //                 url={getInviteLink()}
        //                 hashtag={"#DevourGO"}
        //                 className="invite-modal_body_content_share-options_option_content"
        //                 style={{
        //                     backgroundColor: "none",
        //                 }}
        //             >
        //                 <FaFacebook />
        //             </FacebookShareButton>
        //         );
        //     },
        // },
        {
            icon: <FiShare />,
            onlyOnMobile: true,
            title: "Share",
            onClick: () => {
                if (isGoNative()) {
                    // @ts-ignore
                    gonative.share.sharePage({ url: getInviteLink(), text: getShareMessage(false) });
                } else {
                    navigator.share({
                        title: "I am inviting you to DevourGO!",
                        text: getShareMessage(false),
                        url: getInviteLink(),
                    });
                }
            },
        },
    ];

    const handleCopyLink = () => {
        if (isTextCopied) return;
        setIsTextCopied(true);
        if (isGoNative()) {
            // @ts-ignore
            gonative.clipboard.set({ data: getInviteLink() }); // for app to copy to clipboard
        } else {
            navigator.clipboard.writeText(getInviteLink());
        }
        setTimeout(() => {
            setIsTextCopied(false);
        }, 10000);
    };

    return (
        <FrameOneModal size="xs1" isOpen={isVisible} toggle={onClose} contentClassName="invite-modal">
            <div
                className="invite-modal_background"
                style={{
                    backgroundImage: `url(${getHeroUrl()})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "8.73556rem",
                }}
            ></div>
            <div
                className="invite-modal_shader"
                style={{
                    background: `linear-gradient(180deg, rgba(45, 45, 47, 0.10) 0%, ${
                        isOnDarkMode ? "#2D2D2F" : "white"
                    } 6.5rem)`,
                }}
            ></div>
            <IoMdClose className="invite-modal_close" onClick={onClose} />
            <FrameModalBody className="invite-modal_body">
                <div className="invite-modal_body_heading">
                    <h5>Invite Your Friends</h5>
                    <span>
                        Invite your friends to join your DevourPlay guild ({guild?.data?.name}) and earn referral
                        rewards!
                    </span>
                </div>
                <div className="invite-modal_body_content">
                    <div className="invite-modal_body_content_field">
                        <input value={getInviteLink()} readOnly></input>
                        <FrameButton
                            color={isTextCopied ? "gray" : "purple"}
                            onClick={handleCopyLink}
                            rightIcon={isTextCopied ? FaCheck : MdContentCopy}
                            size="narrow"
                        >
                            {isTextCopied ? "Copied" : "Copy"}
                        </FrameButton>
                    </div>
                    <div className="invite-modal_body_content_share-options">
                        {shareOptions
                            .filter((element) => {
                                if (element.onlyOnMobile && !isMobile) {
                                    return false;
                                }
                                return true;
                            })
                            .map((option, index) =>
                                <div
                                    key={index + option.title}
                                    className="invite-modal_body_content_share-options_option"
                                    onClick={option.onClick}
                                >
                                    {option.render
                                        ? option.render()
                                        : <div className="invite-modal_body_content_share-options_option_content">
                                            {option.icon}
                                        </div>
                                    }

                                    <span>{option.title}</span>
                                </div>)}
                    </div>
                </div>
            </FrameModalBody>
        </FrameOneModal>
    );
};
