import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router";
import {AnyAction} from "redux";
import {store} from "@/redux";
import {MetaAction} from "../redux/meta/metaReducer";
import {useEffect} from "react";

export function initSentry(): void {
    const isProd = import.meta.env.VITE_ENVIRONMENT === "production";
    Sentry.init({
        dsn: "https://858ba88ada6fe724d2dac07e2a677073@o4505830066159616.ingest.sentry.io/4505830068256768",
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/devourgo\.io/,
            /^https:\/\/+\.web\.devourgo\.io/,
        ],
        environment: import.meta.env.VITE_ENVIRONMENT,
        release: import.meta.env.VITE_ENVIRONMENT,
        integrations: [
            Sentry.browserProfilingIntegration(),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [
                    /^https:\/\/production\.backend-rest\.devourgo.io\/((?!stripe).)*$/,
                    /^https:\/\/explorer-api\.walletconnect\.com/,
                ],
            }),
        ],
        normalizeDepth: 10,
        // Performance Monitoring
        tracesSampleRate: isProd
            ? 0.1
            : 1.0, // Capture 100% of the transactions in develop
        profilesSampleRate: 1.0, // Relative to tracesSamplerate (total = tracesSampleRate * profilesSampleRate)
        // Session Replay
        replaysSessionSampleRate: isProd
            ? 0.1
            : 1.0, // This sets the sample rate at 10%.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: [
            "Non-Error promise rejection captured",
            "Non-Error exception captured",
        ],
    });

    getUser().then(_ => {
        if (store?.getState()?.metaStore?.currentUser?.user) {
            Sentry.setUser({
                email: store.getState().metaStore.currentUser.user.email,
            });
            Sentry.setContext("userInfo", store.getState().metaStore.currentUser.user);
        }
    });
}

async function getUser(): Promise<void> {
    return new Promise(resolve => {
        function getUserRecursive(retries = 5): void {
            if (!store?.getState()?.metaStore?.currentUser?.user) {
                if (retries > 0) {
                    setTimeout(() => {
                        getUserRecursive(retries - 1);
                    }, 5000);
                }
            }
        }

        getUserRecursive();
        resolve();
    });
}

export function getSentryReduxEnhancer() {
    return Sentry.createReduxEnhancer({
        actionTransformer: (action: AnyAction): AnyAction | null => {
            if (action.type === MetaAction.UPDATE_CURRENT_USER) {
                return {
                    ...action,
                    wallets: null,
                };
            }

            return action;
        },
    });
}
