import AssetImage from "@/components/AssetImage";
import FrameButton from "@/components/buttons/FrameButton";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import FrameModalFooter from "@/components/modals/modalComponents/FrameModalFooter";
import FrameModalHeader from "@/components/modals/modalComponents/FrameModalHeader";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import { useAsset } from "@/hooks/useAsset";
import { IStore } from "@/redux/defaultStore";
import { addError } from "@/redux/meta/metaActions";
import { formatChallengeDateRange } from "@/utils/formatChallengeDateRange";
import getConfig from "@/utils/getConfig";
import { Challenge, ChallengesApi } from "@devour/client";
import { FC, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

interface props {
    challenge: Challenge;
    isVisible: boolean;
    onAccepted?: () => void;
    onClose: () => void;
}

export const ChallengeAcceptModal: FC<props> = ({ challenge, isVisible, onClose, onAccepted }) => {
    const [termsChecked, setTermsChecked] = useState<boolean>(false);
    const { fullToken } = useSelector((store: IStore) => store.authStore);
    const { data: background } = useAsset(challenge.backgroundImageLarge);
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const [isAccepting, setIsAccepting] = useState(false);
    const acceptChallenge = async () => {
        setIsAccepting(true);
        try {
            await new ChallengesApi(getConfig(fullToken)).acceptChallenge({
                id: challenge.id,
                acceptChallengeBody: {
                    userId: currentUser.id,
                },
            });
            if (onAccepted) {
                onAccepted();
            }
            onClose();
        } catch (err) {
            console.log(err);
            dispatch(await addError(err));
        }
        setIsAccepting(false);
    };
    return (
        <FrameOneModal size="xs1" isOpen={isVisible} toggle={onClose} contentClassName="brand-challenge_accept-modal">
            <div
                className="brand-challenge_accept-modal_background"
                style={{
                    backgroundImage: `url(${background?.cdnUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}></div>
            <div
                className="brand-challenge_accept-modal_shader"
                style={{
                    background: "linear-gradient(180deg, rgba(45, 45, 47, 0.10) 0%, #2D2D2F 31.33%)",
                }}
            ></div>
            <IoMdClose className="brand-challenge_accept-modal_close" onClick={onClose}/>
            <FrameModalBody className="brand-challenge_accept-modal_body">
                <div className="brand-challenge_accept-modal_body_heading">
                    <AssetImage assetId={challenge.brandLogo} />
                    <span>{formatChallengeDateRange(challenge.start, challenge.end)}</span>
                </div>
                <div className="brand-challenge_accept-modal_body_content">
                    <h6>Accept the Challenge?</h6>
                    <p>Accept and start diving into completing the {challenge.name}!</p>
                </div>
                <FrameOneCheckbox
                    onToggle={() => setTermsChecked(!termsChecked)}
                    checked={termsChecked}
                    background={"purple"}
                    className="brand-challenge_accept-modal_body_terms"
                >
                    Accept the <a href={challenge.termsUrl ?? "https://devourgo.io/terms-of-use"} target="_blank" rel="noreferrer">challenge Terms & Conditions</a> of the {challenge.name}
                </FrameOneCheckbox>
            </FrameModalBody>
            <FrameModalFooter className="brand-challenge_accept-modal_footer">
                <FrameButton
                    color="purple"
                    size="normal"
                    showSpinnerWithoutGlobalLoading={isAccepting}
                    onClick={termsChecked ? acceptChallenge : undefined}
                >
                    Accept challenge
                </FrameButton>
                <FrameButton color="gray" size="narrow" onClick={isAccepting ? undefined : onClose}>
                    Not now
                </FrameButton>
            </FrameModalFooter>
        </FrameOneModal>
    );
};
