import {OWReward} from "@devour/client";
import CircularProgressIndicator from "@/components/CircularProgress";
import { useGetChallenge } from "@/hooks/challenges/useGetChallenge";
import { isColorDarkOrLight } from "@/utils/isColorDarkOrLight";
import { lightenHexColor } from "@/utils/lightenHexColor";

interface Props {
    quest: OWReward;
}
export default function ChallengeQuest(props: Props) {
    const {quest} = props;
    const showGlow = quest?.userStat && quest?.userStat.progress >= quest.target;
    const {data: challenge} = useGetChallenge(quest.challenge);
    const getCurrentProgress = () => {
        if (!quest?.userStat) {
            return 0;
        }

        return quest.userStat.progress < quest.target ? quest.userStat.progress : quest.target;
    };

    const getColorOverride = () => {
        if (challenge?.brandColor) {
            return isColorDarkOrLight(challenge?.brandColor) == "dark" ? lightenHexColor(challenge?.brandColor, 40) : challenge?.brandColor;
        }
        return undefined;
    };

    return (
        <div className="brand-challenge_quest">
            <CircularProgressIndicator
                showGlow={showGlow}
                colorOverride={getColorOverride()}
                total={props.quest.target}
                current={getCurrentProgress()}
            />
            <div>
                <strong className="brand-challenge_quest_name">{props.quest.name}</strong>
                <div className="brand-challenge_quest_description">
                    {props.quest.description}
                </div>
            </div>
        </div>
    );
}