import {forwardRef, ReactElement, ReactNode, Ref, useImperativeHandle, useRef} from "react";
import classNames from "classnames";

interface DragScrollProps<T> {
    children: ReactNode,
    className?: string,
    id?: string
    forwardProps?: T;
    onScroll?: (scrollLeft: number) => void;
}

/**
 * Generic component to enable scrolling by dragging the container
 * @param props
 * @param ref
 * @constructor
 */
function DragScroll<T>(props: DragScrollProps<T>, ref: Ref<HTMLDivElement>): ReactElement {
    const {children, className, onScroll, ...dragScrollProps} = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const isDraggingRef = useRef(false);
    const DRAG_THRESHOLD = 5; // Minimum distance to consider as a drag

    useImperativeHandle(ref, () => containerRef.current as HTMLDivElement, []);
    const handleDragStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        const slider = containerRef.current;

        if (!slider) {
            return;
        }

        const startPos = {
            left: slider.scrollLeft,
            top: slider.scrollTop,
            x: "touches" in e ? e.touches[0].clientX : e.clientX,
            y: "touches" in e ? e.touches[0].clientY : e.clientY,
        };

        isDraggingRef.current = false;

        const handleDragMove = (e: MouseEvent | TouchEvent) => {
            let dx;
            let dy;
            if ("touches" in e && !e.touches.length) {
                return;
            } else if ("touches" in e) {
                dx = e.touches[0].clientX - startPos.x;
                dy = e.touches[0].clientY - startPos.y;
            } else {
                dx = e.clientX - startPos.x;
                dy = e.clientY - startPos.y;
            }

            if (Math.abs(dx) > DRAG_THRESHOLD) {
                isDraggingRef.current = true;
                slider.scrollLeft = startPos.left - dx;
                slider.scrollTop = startPos.top - dy;
                props.onScroll?.(slider.scrollLeft);
            }
        };

        const handleDragEnd = () => {
            document.removeEventListener("mousemove", handleDragMove);
            document.removeEventListener("mouseup", handleDragEnd);
            document.removeEventListener("mouseleave", handleDragEnd);
            document.removeEventListener("touchmove", handleDragMove);
            document.removeEventListener("touchend", handleDragEnd);
            document.removeEventListener("touchcancel", handleDragEnd);

            setTimeout(() => {
                isDraggingRef.current = false;
            }, 0); // Slight delay to reset the dragging flag
        };

        document.addEventListener("mousemove", handleDragMove);
        document.addEventListener("mouseup", handleDragEnd);
        document.addEventListener("mouseleave", handleDragEnd);
        document.addEventListener("touchmove", handleDragMove);
        document.addEventListener("touchend", handleDragEnd);
        document.addEventListener("touchcancel", handleDragEnd);
    };

    const handleClickCapture = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDraggingRef.current) {
            e.stopPropagation(); // Prevent click event propagation if dragging occurred
            e.preventDefault(); // Prevent default click action
        }
    };

    return (
        <div
            className={classNames("drag-scroll", props.className)}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            onClickCapture={handleClickCapture}
            ref={containerRef}
            {...dragScrollProps}
        >
            {props.children}
        </div>
    );
}

export default forwardRef(DragScroll);
