import {TransactionButton} from "thirdweb/react";
import {getContract, prepareContractCall, resolveMethod} from "thirdweb";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";
import { ErrorType } from "@devour/client";
import {base} from "thirdweb/chains";

// Only have a single relay and only used for lootboxes currently so will hard code for now.
const forwarderAddress = "0xd04f98c88ce1054c90022ee34d566b9237a1203c";
const relayerUrl = "https://devour.engine-usw2.thirdweb.com/relayer/c67cea66-b066-4323-94c5-6594ab1c1e0b";
const chain = base;

interface Props {
    contractAddress: string;
    nftId: number;
    onTransactionSubmit?: () => void;
    onTransactionConfirmed: (transactionHash: `0x${string}`) => void;
    disabled?: boolean;
}

function RedeemLootboxButton(props: Props) {
    const dispatch = useDispatch();

    return (
        <TransactionButton
            className="redeem-lootbox-button"
            disabled={props.disabled}
            transaction={() => {
                const contract = getContract({
                    client: thirdwebAuthClient,
                    chain: chain,
                    address: props.contractAddress,
                });

                // @ts-ignore This is correct but TypeScript doesn't like it
                return prepareContractCall({
                    contract,
                    method: resolveMethod("burn"),
                    params: [
                        // @ts-ignore This is correct but TypeScript doesn't like it
                        BigInt(props.nftId),
                    ],
                });
            }}
            onClick={() => {
                props.onTransactionSubmit?.();
                // console.log("Transaction Submitted");
            }}
            onTransactionConfirmed={(receipt) => {
                props.onTransactionConfirmed(receipt.transactionHash);
            }}
            onError={async (error) => {
                console.error("Error:", error);
                dispatch(await addError({
                    type: ErrorType.APP,
                    message: error.message || "Failed to burn NFT.",
                }));
            }}
            gasless={{
                provider: "engine",
                relayerUrl: relayerUrl,
                relayerForwarderAddress: forwarderAddress,
            }}
        >
            Continue to Redeem
        </TransactionButton>
    );

}

export default RedeemLootboxButton;