import {FC, PropsWithChildren, ReactElement} from "react";
import FrameModalHeader from "../modalComponents/FrameModalHeader";
import FrameBottomPanelHeader from "../bottomPanelComponents/FrameBottomPanelHeader";
import {isModal} from "@/utils/frameOneIsModal";

interface Props {
    title: string | ReactElement
    toggle?: () => void;
    tooltipContent?: string;
    modalOnTablet?: boolean;
    modalOnMobile?: boolean;
    showXBottomPanel?: boolean;
    isTitleBold?: boolean;
    showLeftChevron?: boolean;
    showXTopRight?: boolean;
    leftIcon?: FC;
    centerTitle?: boolean;
}


function FrameAutoPanelHeader(props: PropsWithChildren<Props>): ReactElement {
    if (isModal(props.modalOnTablet, props.modalOnMobile)) {
        return (
            <FrameModalHeader
                title={props.title}
                toggle={props.toggle}
                tooltipContent={props.tooltipContent}
                isTitleBold={props.isTitleBold}
                showLeftChevron={props.showLeftChevron}
                showXTopRight={props.showXTopRight}
                leftIcon={props.leftIcon}
                centerTitle={props.centerTitle}
            >
                {props.children}
            </FrameModalHeader>
        );
    } else {
        return (
            <FrameBottomPanelHeader
                title={props.title}
                toggle={props.toggle}
                showXBottomPanel={props.showXBottomPanel}
                isTitleBold={props.isTitleBold}
                showLeftChevron={props.showLeftChevron}
                tooltipContent={props.tooltipContent}
                leftIcon={props.leftIcon}
                centerTitle={props.centerTitle}
            >
                {props.children}
            </FrameBottomPanelHeader>
        );
    }

}

export default FrameAutoPanelHeader;
