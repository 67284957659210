import {useQuery} from "@tanstack/react-query";
import {GetTransactionsResponse, Token, TransactionsApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useGate} from "statsig-react";

async function fetchTransactions(fullToken: Token, userId: string): Promise<GetTransactionsResponse> {
    if (!fullToken || !userId) return;

    return await new TransactionsApi(getConfig(fullToken)).getTransactions({
        id: userId,
    });
}

function useTransactionsQuery(fullToken: Token, userId: string, disabled?: boolean) {
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    return {
        queryKey: [
            "transactions",
            fullToken?.id ?? "unauthorized",
            userId ?? "",
        ],
        queryFn: () => fetchTransactions(fullToken, userId),
        enabled: Boolean(fullToken && userId && dpayStatus) && !disabled,
    } as const;
}

export function useGetTransactions(fullToken: Token, userId: string, disabled?: boolean) {
    return useQuery(useTransactionsQuery(fullToken, userId, disabled));
}