import React, {ButtonHTMLAttributes, ReactElement, useEffect, useState} from "react";
import FrameOneAutoPanel from "../../modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "../../modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../../modals/autoPanelComponents/FrameAutoPanelBody";
import FrameOneSelect from "@/components/inputs/FrameOneSelect";
import {GoFrensApi, LeaderboardsDateRange} from "@devour/client";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {LeaderboardView, ViewMode} from "@/components/goFrens/GoFrensTopLeaderboard";
import GoFrensTopLeaderboardCommunityLeadersEmptyState
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityLeadersEmptyState";
import GoFrensTopLeaderboardEmptyState from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardEmptyState";
import GoFrensMyCommunityLeaderboardSkeleton
    from "@/components/skeletons/GoFrensPage/GoFrensMyCommunityLeaderboardSkeleton";
import {getDateInEst} from "@/utils/getDateInEst";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import FrameButton from "@/components/buttons/FrameButton";
import {FaRankingStar} from "react-icons/fa6";
import useThemePreference from "@/hooks/useThemePreference";
import {useNavigate} from "react-router";

interface Props<T> {
    isOpen: boolean;
    onClose: () => void;
    renderedData: Array<T>;
    renderFunction: (leaderboardData: Array<T>) => Array<ReactElement>;
    dateRange?: Array<LeaderboardsDateRange>;
    startingDateValue: string;
    currentView: LeaderboardView;
    periodViewMode: ViewMode;
}

function GoFrensTopLeaderboardCommunitiesModal<T>(props: Props<T>): ReactElement {
    const now = getDateInEst();
    const currMonth = now.getMonth() + 1;
    const currYear = now.getFullYear();

    const { isOnDarkMode } = useThemePreference();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: currentUser} = useGetUserProfile(fullToken);

    const [
        dateValue,
        setDateValue,
    ] = useState<string>(props.startingDateValue);
    const [
        localData,
        setLocalData,
    ] = useState<Array<T>>(props.renderedData);
    const [
        periodView,
        setPeriodView,
    ] = useState<ViewMode>(props.periodViewMode);

    useEffect(() => {
        if (props.isOpen) {
            setLocalData(props.renderedData);
            setDateValue(props.startingDateValue);
        }
    }, [props.isOpen]);

    useEffect(() => {
        setLocalData(undefined);
        void handleGetData();
    }, [periodView]);

    async function handleGetData(newDateValue?: string) {
        const date = newDateValue || dateValue;

        if (props.currentView === LeaderboardView.COMMUNITIES) {
            await getLeaderboardData(`${date},${periodView}`);
        } else {
            await getCommunityLeadersData(`${date},${periodView}`);
        }
    }

    async function handleDateOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
        try {
            setLocalData(undefined);
            const newDateValue = e.target.value;
            setDateValue(newDateValue);
            await handleGetData(newDateValue);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    /*
     * Normally we shouldn't have functions inside a generic component like this fetching the data, but this
     * is a special case seeing as how it relies on the GoFrens page state as a starting point, but also controls
     * its own independent state.
     */
    async function getLeaderboardData(leaderboardTimeParam: string): Promise<void> {
        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getCurrentTopLeaderboardRankings({
                leaderboardTimeParam: leaderboardTimeParam,
            });
            setLocalData(res.leaderboardRankings as Array<T>);

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    async function getCommunityLeadersData(leaderboardTimeParam: string): Promise<void> {
        try {
            const res = await new GoFrensApi(getConfig(fullToken)).getCommunityLeadersRankings({
                leaderboardTimeParam: leaderboardTimeParam,
            });
            setLocalData(res.communityLeaders as Array<T>);
        } catch (e) {
            dispatch(await addError(e));
        }
    }

    function togglePeriodViewMode(): void {
        periodView === ViewMode.MONTHLY
            ? setPeriodView(ViewMode.CUMULATIVE)
            : setPeriodView(ViewMode.MONTHLY);
    }

    function viewModeButtonContent() {
        return periodView === ViewMode.MONTHLY
            ? "Monthly"
            : "All-Time";
    }

    function renderLeaderboardData() {
        if (!localData) {
            return <GoFrensMyCommunityLeaderboardSkeleton rows={5}/>;
        }

        if (localData.length === 0) {
            const isCurrent = dateValue === `${currMonth},${currYear}`;
            const headerText = isCurrent ? "Become a Top Leader" : "Whoops, there's no reigning champs...";
            const contentText = isCurrent
                ? "We are waiting for community leaders to be listed on this Leaderboard. Start earning XP to become a Top Leader of your community!"
                : "We couldn't find any records of community leaders for this month. Please select another month to view the top community leaders!";
            const button: ReactElement = isCurrent
                ? <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    size="normal"
                    color={isOnDarkMode
                        ? "purple"
                        : "purple-blue-gradient"}
                    onClick={() => navigate("/go-vip-dashboard")}
                >
                    Start Earning XP
                </FrameButton>
                : null;
            return props.currentView === LeaderboardView.COMMUNITIES
                ? <GoFrensTopLeaderboardEmptyState
                    isModal={true}
                    headerText={"Boost Your Community!"}
                    contentText={"We are waiting for communities to be listed on this Leaderboard. Start earning XP to support your community!"}
                />
                : <GoFrensTopLeaderboardCommunityLeadersEmptyState
                    isModal={true}
                    headerText={headerText}
                    contentText={contentText}
                    button={button}
                />;
        }
        return props.renderFunction(localData);

    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            toggle={props.onClose}
            size="md"
            contentClassName="gofrens-top-leaderboard_modal-leaderboard"
        >
            <FrameAutoPanelHeader
                title={props.currentView === LeaderboardView.COMMUNITIES
                    ? "Top 50 Communities"
                    : "Top 50 Community Leaders"}
                toggle={props.onClose}
                showXBottomPanel={true}
                isTitleBold={true}
            />

            <FrameAutoPanelBody>

                {currentUser?.user?.community &&
                <div className="gofrens-top-leaderboard_button-container">
                    <div className="gofrens-top-leaderboard_button-container-item1">
                        <FrameOneSelect
                            className="gofrens-top-leaderboard_top-inputs_dropdown-container_select"
                            value={dateValue}
                            onChange={async (e) => await handleDateOnChange(e)}
                            disabled={!localData}
                        >
                            <option value={`${currMonth},${currYear}`}>Current Month</option>
                            {props.dateRange &&
                            <>
                                {props.dateRange.map((date) => {
                                    return (
                                        <option value={date.dateParam} key={date.dateParam}>{date.dateValue}</option>
                                    );
                                })}
                            </>
                            }
                        </FrameOneSelect>
                    </div>

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        className="gofrens-top-leaderboard_view-mode-button"
                        color="gray"
                        size="pill"
                        onClick={togglePeriodViewMode}
                        rightIcon={FaRankingStar}
                    >
                        <div className="gofrens-top-leaderboard_view-mode">
                            {viewModeButtonContent()}
                        </div>
                    </FrameButton>
                </div>
                }

                <div className={currentUser?.user?.community && "gofrens-top-leaderboard_modal-leaderboard_container"}>
                    {(!localData || localData?.length > 0) && <GoFrensTopLeaderboardCommunityTableHeader isModal={true}/>}
                    <div className="gofrens-top-leaderboard_scroll-div">
                        {renderLeaderboardData()}
                    </div>
                </div>

            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default GoFrensTopLeaderboardCommunitiesModal;
