import {useGetTransactions} from "@/hooks/useGetTransactions";
import {useGetTransactionsPaginated} from "@/hooks/useGetTransactionsPaginated";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useGetUserLevel} from "@/hooks/useGetUserLevel";
import {updateAccountLevel, updateCurrentUser} from "@/redux/meta/metaActions";
import {OWRewardType, PrizePoolType} from "@devour/client";
import {useQueryClient} from "@tanstack/react-query";


export default function useRefreshRewardData() {
    const queryClient = useQueryClient();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUserId = useSelector((store: IStore) => store.metaStore.currentUser?.user?.id);
    const dispatch = useDispatch();
    const {refetch: refetchUserProfile} = useGetUserProfile(fullToken, true);
    const {refetch: refetchUserLevel} = useGetUserLevel(fullToken, currentUserId, true);
    const {refetch: refetchTransactionData} = useGetTransactions(fullToken, currentUserId, true);
    const {refetch: refetchTransactionPaginatedData} = useGetTransactionsPaginated(fullToken, currentUserId, undefined, undefined, true);

    const refetch = async (rewardType: string, isGuildReward?: boolean) => {
        if (rewardType === OWRewardType.FUEL || rewardType === "FUEL") {
            void refetchTransactionData();
            void refetchTransactionPaginatedData();
        } else if (rewardType === OWRewardType.XP || rewardType === "XP") {
            const {data} = await refetchUserProfile();
            if (data) {
                dispatch(updateCurrentUser(data));
            }

            const {data: userLevelData} = await refetchUserLevel();
            if (userLevelData) {
                dispatch(updateAccountLevel(userLevelData));
            }
        } else if (rewardType === OWRewardType.NFT || rewardType === "NFT") {
            queryClient.invalidateQueries({ queryKey: ["get-nft-ownerships"]});
            queryClient.invalidateQueries({ queryKey: ["nft-ownerships"]});

            if (isGuildReward) {
                queryClient.invalidateQueries({ queryKey: ["guild-ownerships"]});
            }
        }
    };

    return {refetch};
}