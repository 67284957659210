import {ReactElement} from "react";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import {BrandMap, BrandMapColorTheme} from "@devour/client";
import {Link} from "react-router";
import classNames from "classnames";
import {isMobile, isTablet} from "react-device-detect";
import ThirdwebLogin from "@/pages/ThirdwebLogin.tsx";

interface Props {
    isOpen: boolean;
    toggle: (showModal: boolean) => void;
    brandMap: BrandMap;
}

function BrandEmailModal(props: Props): ReactElement {
    const {isOpen, toggle, brandMap} = props;

    const isTextThemeLight = brandMap.colorTheme === BrandMapColorTheme.LIGHT;

    const cancelImageUrl = isTextThemeLight
        ? `${import.meta.env.VITE_CDN_URL}/images/cancel-light-gray.svg`
        : `${import.meta.env.VITE_CDN_URL}/images/cancel-medium-gray.svg`;

    const renderLegalLink = (to: string, text: string) =>
        <Link
            to={to}
            className="legal-link"
        >
            {text}
        </Link>;

    const renderDevourLogo = (colorTheme: BrandMapColorTheme) => {
        const src = colorTheme === BrandMapColorTheme.DARK
            ? `${import.meta.env.VITE_CDN_URL}/images/DevourGO-logo.webp`
            : `${import.meta.env.VITE_CDN_URL}/images/DevourGO_Final-White.webp`;

        return (
            <img
                className={classNames("brand-signup-modal_logo", {
                    "mobile": isMobile && !isTablet,
                })}
                src={src}
                alt="DevourGO Logo"
            />
        );
    };

    return (
        <>
            <FrameOneAutoPanel
                isOpen={isOpen}
                toggle={() => toggle(false)}
                fullScreenHeight={true}
                contentClassName={classNames("brand-email-modal", {"mobile": isMobile && !isTablet})}
                size="sm2"
                modalOnTablet={true}
            >

                <img
                    onClick={() => toggle(false)}
                    src={cancelImageUrl}
                    alt="close"
                    className="brand-email-modal_close"
                />

                <FrameAutoPanelBody className={classNames("brand-email-modal_body", {
                    "mobile": isMobile && !isTablet,
                })}
                >
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/brand-purple-email.webp`}
                        alt="Purple Email Graphic"
                        className={classNames("brand-email-modal_email-graphic", {
                            "mobile": isMobile && !isTablet,
                        })}
                    />

                    <h1>Welcome</h1>

                    <p>Ready to unlock the ultimate experiences? It all begins with entering your email address.</p>

                    <ThirdwebLogin />

                    <div className="brand-email-modal_legal">
                        <p>
                            By clicking "Continue" above or by using any of the methods under
                            "log in or sign up with" to continue to DevourGO,
                            you acknowledge that you have read and understood, and agree to DevourGO's{" "}
                            {renderLegalLink("/terms-of-use", "Terms of Use")},{" "}
                            {renderLegalLink("/privacy-policy", "Privacy Policy")}, and{" "}
                            {renderLegalLink("/cookie-policy", "Cookie Policy")}
                        </p>
                    </div>

                    {/* DEVOUR GO LOGO */}
                    {renderDevourLogo(brandMap.colorTheme)}

                </FrameAutoPanelBody>

            </FrameOneAutoPanel>
        </>
    );
}

export default BrandEmailModal;
