import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useContext,
    useState,
} from "react";
import {MenuOrdersApi, PhoneNumberBody, UsersApi} from "@devour/client";
import FrameButton from "../../buttons/FrameButton";
import FrameOneAutoPanel from "../../modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelHeader from "../../modals/autoPanelComponents/FrameAutoPanelHeader";
import FrameAutoPanelBody from "../../modals/autoPanelComponents/FrameAutoPanelBody";
import {addError, updateCurrentUser} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {useDispatch, useSelector} from "react-redux";
import FrameOnePhoneNumberInput from "../../inputs/FrameOnePhoneNumberInput";
import {RestaurantContext} from "@/pages/restaurants/context/RestaurantContext";
import AddPhone from "../../../svgs/add-phone.svg?react";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import { useStreamerMode } from "@/hooks/useStreamerMode";
import {IStore} from "@/redux/defaultStore";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";
import {
    formatOptionalPhoneNumberForApiSubmission,
} from "@/utils/formatOptionalPhoneNumberForApiSubmission";
import {sendMessageToOW} from "@/hooks/useOverwolfInterop";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";

interface FormValues {
    firstName: string;
    lastName: string;
    phoneNumber: PhoneNumberBody;
    saveOrderInfo: boolean;
}

const defaultValues: FormValues = {
    firstName: "",
    lastName: "",
    phoneNumber: {
        countryCode: "US",
        nationalNumber: "",
    },
    saveOrderInfo: true,
};

interface Props {
    isOpen: boolean;
    onDone: (saveOrderInfo: boolean) => void;
}

function CheckoutDetailsAccountInfoModal(props: Props): ReactElement {

    const { menuOrderId } = useContext(RestaurantContext);

    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(menuOrderId);
    const { hideInputText } = useStreamerMode();
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData, refetch: refetchUserProfileData} = useGetUserProfile(fullToken);
    const user = userProfileData?.user;

    const [formValues, setFormValues] = useState<FormValues>(defaultValues);

    function inputOnChange(key: keyof FormValues): ChangeEventHandler<HTMLInputElement> {
        return (e) => {
            setFormValues({
                ...formValues,
                [key]: e.target.value,
            });
        };
    }

    function phoneNumberOnChange(phoneNumber: PhoneNumberBody): void {
        setFormValues({
            ...formValues,
            phoneNumber,
        });
    }

    function onSaveOrderInfoCheck() {
        setFormValues({
            ...formValues,
            saveOrderInfo: !formValues.saveOrderInfo,
        });
    }

    async function onFormSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        try {
            await new UsersApi(getConfig(fullToken)).updateProfile({
                updateProfileBody: {
                    phoneNumber: formatOptionalPhoneNumberForApiSubmission(formValues.phoneNumber),
                    firstName: formValues.firstName,
                    lastName: formValues.lastName,
                    ordersEmail: formValues.saveOrderInfo ? user.email : undefined,
                    ordersPhoneNumber: formValues.saveOrderInfo ? formatOptionalPhoneNumberForApiSubmission(formValues.phoneNumber) : undefined,
                    ordersFirstName: formValues.saveOrderInfo ? formValues.firstName : undefined,
                    ordersLastName: formValues.saveOrderInfo ? formValues.lastName : undefined,
                    silentDupeChecks: true,
                },
            });

            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
            await refetchUserProfileData();
            // Tell Overwolf to update the user data
            sendMessageToOW({ type: "de:update-user",
                payload: { userData: userRes } });

            if (formValues.saveOrderInfo) {
                // update menu order
                await new MenuOrdersApi(getConfig()).updateMenuOrder({
                    id: menuOrder.id,
                    createMenuOrderBody: {
                        ...formValues,
                    },
                });
                await refetchMenuOrder();
            }

            props.onDone(formValues.saveOrderInfo);

        } catch (e) {
            dispatch(await addError(e));
        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={props.isOpen}
            size="sm"
            contentClassName="menu-order-contact-info-modal"
            disableOverlayDismiss={true}
            modalOnTablet={true}
        >
            <FrameAutoPanelHeader
                title=""
            >
                <div className="menu-order-contact-info-modal_header">
                    <AddPhone/>
                    <span className="menu-order-contact-info-modal_header_title">
                        Add My Account Information
                    </span>
                </div>
            </FrameAutoPanelHeader>

            <FrameAutoPanelBody className="menu-order-contact-info-modal_body">
                <form onSubmit={onFormSubmit}>
                    <p>
                        This steps serves to proceed with your order and update your account details.
                        You may update your contact information in the Account page.
                    </p>

                    <div className="menu-order-contact-info-modal_body_divider"/>

                    <div className="menu-order-contact-info-modal_body_name-container">
                        <div className="frame-one-phone-input_input">
                            <label>
                                First Name
                            </label>

                            <input
                                type="text"
                                className="menu-order-contact-info-modal_body_name-input"
                                placeholder="First Name"
                                value={formValues.firstName}
                                onChange={ inputOnChange("firstName")}
                                required={true}
                            />
                        </div>

                        <div className="frame-one-phone-input_input">
                            <label>Last Name</label>
                            <input
                                {...hideInputText()}
                                className="menu-order-contact-info-modal_body_name-input"
                                placeholder="Last Name"
                                value={formValues.lastName}
                                onChange={inputOnChange("lastName")}
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="menu-order-contact-info-modal_body_phone-container">
                        <FrameOnePhoneNumberInput
                            value={ formValues.phoneNumber}
                            onChange={phoneNumberOnChange}
                        />
                    </div>

                    <div className="menu-order-contact-info-modal_body_save-container">
                        <FrameOneCheckbox
                            onToggle={onSaveOrderInfoCheck}
                            checked={formValues.saveOrderInfo}
                            background={"purple"}
                        >
                            Save as the order contact info
                        </FrameOneCheckbox>
                    </div>

                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="normal"
                        className="menu-order-contact-info-modal_body_submit-button"
                        showSpinner={true}
                        forwardProps={{type: "submit"}}
                    >
                        Save
                    </FrameButton>
                </form>
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default CheckoutDetailsAccountInfoModal;
