import {StrictMode} from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {store} from "./redux";
import App from "./App";
import "./style/index.scss";
import {initSentry} from "./utils/sentry";
import initHotjar from "@/utils/hotjar";

const metaStore = localStorage.getItem("persist:meta");
if (!metaStore || metaStore?.includes("dark")) {
    document.documentElement.setAttribute("data-theme", "dark");
}
createRoot(document.getElementById("root")).render(<StrictMode>
    <Provider store={store}>
        <App/>
    </Provider>
</StrictMode>);

initSentry();

initHotjar();

