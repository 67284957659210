import {ReactElement, useCallback, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {TokensApi} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";
import useOnLogout from "@/hooks/useOnLogout";
import useOnAuthThirdweb from "@/hooks/useOnAuthThirdweb";
import {useAutoConnect} from "thirdweb/react";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";

// Main function for the Token Manager component
function TokenManager(): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {onReauthorize} = useOnAuthThirdweb();

    // Define a ref to track if a request is currently in flight
    const requestInFlight = useRef(false);

    const {devourLogout} = useOnLogout();

    // Define a function to check if the token is valid
    const checkTokenValid = useCallback(async () => {
        // If there's no token, return immediately
        if (!fullToken?.token) {
            return;
        }

        // If a request is already in flight, return immediately
        if (requestInFlight.current) {
            return;
        }

        // Set the request in flight ref to true
        requestInFlight.current = true;

        try {
            // Make a request to check if the token is expired
            const res = await new TokensApi(getConfig()).checkTokenExpiration({
                tokenBody: {
                    token: fullToken.token,
                },
            });

            // If the token is expired, dispatch a logout action
            if (res.expired === true) {
                requestInFlight.current = false;
                devourLogout();
                return;
            }

            await onReauthorize(fullToken, false);
        } catch (err) {
            /*
             * If the error status is 401 then their token is invalid. Log them out.
             * Don't log them out for every error though. Sometimes general server errors occur.
             */
            if (err.status === 401) {
                devourLogout();
            }
        } finally {
            // After the request is finished, set the request in flight ref to false
            requestInFlight.current = false;
        }
    }, [fullToken?.token]);

    // Use an effect to check the token validity when the location changes
    useEffect(() => {
        void checkTokenValid();
    }, [
        checkTokenValid,
    ]);

    // Connect to Thirdweb session
    useAutoConnect({
        client: thirdwebAuthClient,
        // onConnect: (wallet) => console.log("useAutoConnect", wallet),
    });

    // The component doesn't render anything
    return null;
}

export default TokenManager;

