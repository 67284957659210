import {Dispatch, ReactElement, SetStateAction, useState} from "react";
import {NftPurchaseUpsert, NftPurchaseRequestBody, ThirdwebApi} from "@devour/client";
import {BsFillCreditCardFill} from "react-icons/bs";
import {PaymentStates} from "@/components/modals/mintIndustryPassModal/PaymentStates";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import classNames from "classnames";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import BuyIndustryPassConfirmModal from "@/components/modals/BuyIndustryPassConfirmModal";
import {useGate} from "statsig-react";
import { CustomMintPaymentMethod, useCustomMintPurchase } from "./MintCustomPurchaseContext";

interface Props {
    nftPurchase: NftPurchaseUpsert;
    getClaimableNft: (values: NftPurchaseRequestBody) => Promise<void>;
    setPaymentState: Dispatch<SetStateAction<PaymentStates>>;
    setShowToast: (state: boolean) => void;
    setShowDpayErrorToast: (state: boolean) => void;
}

function MintIndustryPassModalSelectPayments(props: Props): ReactElement {
    const dispatch = useDispatch();
    const customMint = useCustomMintPurchase();
    const { value: dpayStatus } = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dpayBalanceInFiat = props.nftPurchase?.dpayBalance * props.nftPurchase?.dPayPricePerUsd;
    const maxDpayFiatAllowed = Math.min(props.nftPurchase?.total, dpayBalanceInFiat);
    const [
        isLoadingCoinbase,
        setIsLoadingCoinbase,
    ] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const isMethodAllowed = (method: CustomMintPaymentMethod): boolean => {
        if (!customMint) {
            return true;
        }
        return customMint.allowedMethods.includes(method);
    };

    async function payWithCreditCard(): Promise<void> {
        if (!fullToken) {
            props.setShowToast(true);
        } else if (props.nftPurchase == null) {
            props.setShowDpayErrorToast(true);
        } else {
            await props.getClaimableNft({fiatInDpay: 0});
            props.setPaymentState(PaymentStates.CREDIT);
        }
    }

    async function payWithDpay(): Promise<void> {
        if (!fullToken) {
            props.setShowToast(true);
        } else if (props.nftPurchase == null) {
            props.setShowDpayErrorToast(true);
        } else if (dpayBalanceInFiat >= props.nftPurchase?.total) {
            void props.getClaimableNft({fiatInDpay: maxDpayFiatAllowed,
                paymentMethodId: ""});
            props.setPaymentState(PaymentStates.DPAY);
        }
    }

    async function payWithCoinbase(): Promise<void> {

        /*
         * Spamming the Coinbase button can potentially trigger a race condition that overwrites the coinbase charge ID,
         * resulting in the payment not being recorded properly.
         * Disable additional submission calls if API already in progress.
         */
        if (isLoadingCoinbase) {
            return;
        }
        setIsLoadingCoinbase(true);
        try {
            await props.getClaimableNft({
                isCoinbase: true,
            });
            const res = await new ThirdwebApi(getConfig()).submitNftPurchase({
                id: props.nftPurchase.id,
            });
            if (res.nextAction) {
                window.location.href = res.nextAction;
            }
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            setIsLoadingCoinbase(false);
        }
    }

    function paySplit(): void {
        if (!fullToken) {
            props.setShowToast(true);
        } else if (props.nftPurchase == null) {
            props.setShowDpayErrorToast(true);
        } else if (props.nftPurchase?.dpayBalance > 0) {
            props.setPaymentState(PaymentStates.SPLIT_CREDIT_STAGE);
        }
    }

    function toggleConfirmModal() {
        setShowConfirmModal(s => !s);
    }

    return (
        <>
            <BuyIndustryPassConfirmModal
                isOpen={showConfirmModal}
                toggle={toggleConfirmModal}
                onConfirm={payWithCoinbase}
            />
            <div className="mint-industry-credit-card_body_payment-options_header">
                <h4>Payment Method</h4>
            </div>
            {isMethodAllowed("credit") && <div
                className={classNames("mint-industry-credit-card_body_payment-options_row", {
                    "mint-industry-credit-card_body_payment-options_row_disabled": !props.nftPurchase,
                    "mint-industry-credit-card_body_payment-options_row_enabled": props.nftPurchase,
                })}
                onClick={payWithCreditCard}
            >
                <BsFillCreditCardFill className="mint-industry-credit-card_body_payment-options_row_img"/>
                <p>
                    <strong>
                        Pay with Credit Card
                    </strong>
                </p>
            </div>}
            {dpayStatus && isMethodAllowed("dpay") &&
                <div
                    className={classNames("mint-industry-credit-card_body_payment-options_row", {
                        "mint-industry-credit-card_body_payment-options_row_disabled": !props.nftPurchase || dpayBalanceInFiat < props.nftPurchase?.total,
                        "mint-industry-credit-card_body_payment-options_row_enabled": props.nftPurchase && dpayBalanceInFiat >= props.nftPurchase?.total,
                    })}
                    onClick={payWithDpay}
                >
                    <img
                        className="mint-industry-credit-card_body_payment-options_row_img"
                        src={`${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`}
                        alt="DPAY Token Icon"
                    />
                    <p>
                        <strong>
                        Pay with {import.meta.env.VITE_TOKEN_NAME}
                        </strong>
                    </p>
                </div>
            }
            {isMethodAllowed("coinbase") && <div
                className={classNames("mint-industry-credit-card_body_payment-options_row", {
                    "mint-industry-credit-card_body_payment-options_row_disabled": !props.nftPurchase,
                    "mint-industry-credit-card_body_payment-options_row_enabled": props.nftPurchase,
                })}
                onClick={payWithCoinbase}
            >
                <img
                    className="mint-industry-credit-card_body_payment-options_row_img"
                    src={`${import.meta.env.VITE_CDN_URL}/images/coinbase.svg`}
                    alt="Coinbase"
                />
                <p>
                    <strong>
                        Pay with Coinbase
                    </strong>
                </p>
            </div>}
            {dpayStatus && isMethodAllowed("split") &&
            <div
                className={classNames("mint-industry-credit-card_body_payment-options_row", {
                    "mint-industry-credit-card_body_payment-options_row_disabled": !props.nftPurchase || props.nftPurchase?.dpayBalance === 0,
                    "mint-industry-credit-card_body_payment-options_row_enabled": props.nftPurchase && props.nftPurchase?.dpayBalance > 0,
                })}
                onClick={paySplit}
            >
                <p>
                    <strong>
                        Pay with Credit Card and {import.meta.env.VITE_TOKEN_NAME}
                    </strong>
                </p>
            </div>
            }
        </>
    );

}

export default MintIndustryPassModalSelectPayments;
