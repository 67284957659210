import {ReactElement, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import FrameOneAutoPanel from "@/components/modals/autoPanelComponents/FrameOneAutoPanel";
import FrameAutoPanelBody from "@/components/modals/autoPanelComponents/FrameAutoPanelBody";
import FrameButton from "@/components/buttons/FrameButton";
import {addError, toggleReauthenticateModal} from "@/redux/meta/metaActions";
import FrameAutoPanelHeader from "@/components/modals/autoPanelComponents/FrameAutoPanelHeader";
import {inAppWallet,
    preAuthenticate} from "thirdweb/wallets/in-app";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";
import {ErrorType} from "@devour/client";
import LoginVerificationCode from "@/components/login/LoginVerificationCode";
import useOnAuthThirdweb from "@/hooks/useOnAuthThirdweb";
import {useConnect} from "thirdweb/react";

enum AuthState {
    INSTRUCTIONS,
    VERIFY_CODE,
}

function ThirdwebReauthenticateModal(): ReactElement {

    const dispatch = useDispatch();
    const thirdwebReauthenticateModal = useSelector((store: IStore) => store.metaStore.thirdwebReauthenticateModal);
    const isSubmittingRef = useRef(false);
    const {connect} = useConnect();
    const [authState, setAuthState] = useState<AuthState>(AuthState.INSTRUCTIONS);
    const {onAuthComplete} = useOnAuthThirdweb();
    const [hasVerificationCodeError, setHasVerificationCodeError] = useState<boolean>(false);
    const currentUser = useSelector((store: IStore) => store.metaStore?.currentUser);
    const email = currentUser?.user?.email;

    async function onSubmitVerificationCode(code: string): Promise<void> {
        if (isSubmittingRef.current || !email) {
            return;
        }
        isSubmittingRef.current = true;
        await connect(async () => {
            try {
                const wallet = inAppWallet();
                await wallet.connect({
                    client: thirdwebAuthClient,
                    strategy: "email",
                    email,
                    verificationCode: code,
                });
                void onAuthComplete(wallet);
                dispatch(toggleReauthenticateModal(false));
                return wallet;
            } catch (e) {
                if (e?.message === "Failed to verify verification code") {
                    setHasVerificationCodeError(true);
                } else {
                    dispatch(await addError({
                        type: ErrorType.APP,
                        message: e.message || "Unable to verify registration code",
                    }));
                }
            } finally {
                isSubmittingRef.current = false;
            }
        });
    }

    async function onReauthenticate(): Promise<void> {
        if (isSubmittingRef.current || !email) {
            return;
        }
        try {
            isSubmittingRef.current = true;
            // send email verification code
            await preAuthenticate({
                client: thirdwebAuthClient,
                strategy: "email",
                email,
            });
            setAuthState(AuthState.VERIFY_CODE);
        } catch (e) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: e.message || "Unable to submit authentication code",
            }));
        } finally {
            isSubmittingRef.current = false;
        }
    }

    async function onRequestNewCode(): Promise<void> {
        setHasVerificationCodeError(false);
        await onReauthenticate();
    }

    function onModalWrapperToggle() {
        // Don't allow accidental closing if user requested a code. They need to explicitly click the close button.
        if (authState === AuthState.INSTRUCTIONS) {
            dispatch(toggleReauthenticateModal(false));
        }
    }

    function renderBody() {
        switch (authState) {
            case AuthState.VERIFY_CODE:
                return (
                    <LoginVerificationCode
                        email={email}
                        hasError={hasVerificationCodeError}
                        onRequestNewCode={onRequestNewCode}
                        onSubmit={onSubmitVerificationCode}
                    />
                );
            case AuthState.INSTRUCTIONS:
            default:
                return (
                    <div>
                        <p>
                            To protect your security please reauthenticate to access your collectables.
                        </p>
                        <p>
                            We'll send a code to your email. You shouldn't need to do this again for 7 days.
                        </p>
                        <FrameButton
                            color="purple"
                            size="normal"
                            onClick={onReauthenticate}
                            className="w-100"
                        >
                            Reauthenticate
                        </FrameButton>
                    </div>
                );
        }
    }

    return (
        <FrameOneAutoPanel
            isOpen={thirdwebReauthenticateModal}
            toggle={onModalWrapperToggle}
            size="xs2"
            modalOnTablet={true}
        >
            <FrameAutoPanelHeader
                title="Devour Wallet"
                toggle={() => dispatch(toggleReauthenticateModal(false))}
            />
            <FrameAutoPanelBody>
                {renderBody()}
            </FrameAutoPanelBody>
        </FrameOneAutoPanel>
    );
}

export default ThirdwebReauthenticateModal;

