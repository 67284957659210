import React, { createContext, useContext, ReactNode } from "react";


export type CustomMintPaymentMethod = "credit" | "dpay" | "coinbase" | "split";
export interface CustomMintPurchase {
    allowedMethods: CustomMintPaymentMethod[];
    title: string;
    isLootBox: boolean;
    successMessage: string | React.JSX.Element;
    product: {
        name: string;
        shortName: string;
        image: string;
        price: number;
        maxQuantity: number;
        description: string;
    };
    confirmModal?: {
        title: string;
        message: string;
        button: string
    }
    failedModal?: {
        title: string;
        message: string;
        button: string
    }
    address: string;
}

interface CustomMintContextType {
    customPurchase?: CustomMintPurchase;
}

const CustomMintPurchaseContext = createContext<CustomMintContextType | undefined>(undefined);

export const CustomMintProvider: React.FC<{ children: ReactNode, customMintPurchase?: CustomMintPurchase }> = ({ children, customMintPurchase }) => {

    return (
        <CustomMintPurchaseContext.Provider value={{ customPurchase: customMintPurchase }}>
            {children}
        </CustomMintPurchaseContext.Provider>
    );
};

export const useCustomMintPurchase = (): CustomMintPurchase | undefined => {
    const context = useContext(CustomMintPurchaseContext);
    return context.customPurchase;
};
