import {useParams} from "react-router";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {useContext, useEffect, useState} from "react";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {isDesktop, isTablet} from "react-device-detect";
import BrandLandingAddressSearch from "@/components/brands/BrandLandingAddressSearch";
import BrandMapLocationsSearch from "@/components/brands/BrandMapLocationsSearch";
import BrandMapMarketplaceSearch from "@/components/brands/BrandMapMarketplaceSearch";
import classNames from "classnames";
import BrandLandingOrderHeader from "@/components/brands/BrandLandingOrderHeader";
import {Address, AddressBook, UtilsApi} from "@devour/client";

interface Props {
    toggleEmailModal?: (showModal: boolean) => void;
    isInitialState?: boolean;
}

export default function BrandMapLocations(props: Props) {
    const {slug} = useParams<{ slug: string }>();
    const {data: brandMap} = useGetBrandMap(slug);
    const {setBrandMapState, setSelectedRestaurant} = useContext(BrandMapContext);
    const user = useSelector((store: IStore) => store.metaStore.currentUser?.user);
    const {placeId} = useContext(BrandMapContext);

    const [userAddress, setUserAddress] = useState<Address | AddressBook>();

    useEffect(() => {
        void getAddress();
    }, [placeId]);

    async function getAddress() {
        if (!placeId) {
            return;
        }

        const findSelectedAddressBook = user?.addresses?.find(addressBook => addressBook.placeId === placeId);
        if (findSelectedAddressBook) {
            setUserAddress(findSelectedAddressBook);
            return;
        }

        try {
            const address = await new UtilsApi().getAddressFromPlaceId({placeId});
            if (address.placeId === placeId) {
                setUserAddress(address);
            }
        } catch (e) {}
    }

    function handleBackClick() {
        setSelectedRestaurant(null);
        setBrandMapState(BrandMapStates.SELECT_PROMOTION);
    }

    return (
        <div
            className={classNames(
                "brand-map-locations restaurant-map-landing_ordering_step",
                {"default-state": !placeId},
            )}>

            <BrandLandingOrderHeader
                showChevron={!props.isInitialState}
                handleBackClick={handleBackClick}
                title="Store Locations"
                description={`Select a ${brandMap.allowMarketplaceAccess ? "restaurant" : "store location"} to order from!`}
            />

            <div className="brand-map-locations_content no-margin-adjust">
                {!placeId && <>
                    <div className="brand-map-locations_content_overlay"/>
                    <BrandLandingAddressSearch brandMap={brandMap}/>
                </>}

                <div className="brand-map-locations_content_container">
                    {brandMap.allowMarketplaceAccess
                        ? <BrandMapMarketplaceSearch
                            toggleEmailModal={props.toggleEmailModal}
                            isInitialState={props.isInitialState}
                            userAddress={userAddress}
                        />
                        : <BrandMapLocationsSearch
                            toggleEmailModal={props.toggleEmailModal}
                            isInitialState={props.isInitialState}
                            userAddress={userAddress}
                        />
                    }
                </div>
            </div>
        </div>
    );
}