/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetNftMintTransactionsResponseAllOf,
    GetNftMintTransactionsResponseAllOfFromJSON,
    GetNftMintTransactionsResponseAllOfFromJSONTyped,
    GetNftMintTransactionsResponseAllOfToJSON,
    NftMintTransaction,
    NftMintTransactionFromJSON,
    NftMintTransactionFromJSONTyped,
    NftMintTransactionToJSON,
    PaginatedResponse,
    PaginatedResponseFromJSON,
    PaginatedResponseFromJSONTyped,
    PaginatedResponseToJSON,
    PaginationInfo,
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNftMintTransactionsResponse
 */
export interface GetNftMintTransactionsResponse extends PaginatedResponse {
    /**
     * 
     * @type {Array<NftMintTransaction>}
     * @memberof GetNftMintTransactionsResponse
     */
    nftMintTransactions: Array<NftMintTransaction>;
}

export function GetNftMintTransactionsResponseFromJSON(json: any): GetNftMintTransactionsResponse {
    return GetNftMintTransactionsResponseFromJSONTyped(json, false);
}

export function GetNftMintTransactionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNftMintTransactionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'nftMintTransactions': ((json['nftMintTransactions'] as Array<any>).map(NftMintTransactionFromJSON)),
    };
}

export function GetNftMintTransactionsResponseToJSON(value?: GetNftMintTransactionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PaginatedResponseToJSON(value),
        'nftMintTransactions': ((value.nftMintTransactions as Array<any>).map(NftMintTransactionToJSON)),
    };
}


