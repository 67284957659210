/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddBrandBusinessBody,
    AddBrandBusinessBodyFromJSON,
    AddBrandBusinessBodyToJSON,
    AddBrandMerchantBody,
    AddBrandMerchantBodyFromJSON,
    AddBrandMerchantBodyToJSON,
    AddBrandPromoBody,
    AddBrandPromoBodyFromJSON,
    AddBrandPromoBodyToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    Brand,
    BrandFromJSON,
    BrandToJSON,
    BrandMap,
    BrandMapFromJSON,
    BrandMapToJSON,
    BrandToMerchantPermissions,
    BrandToMerchantPermissionsFromJSON,
    BrandToMerchantPermissionsToJSON,
    CreateBrandBody,
    CreateBrandBodyFromJSON,
    CreateBrandBodyToJSON,
    CreateBrandMapBody,
    CreateBrandMapBodyFromJSON,
    CreateBrandMapBodyToJSON,
    GetBrandBusinessesResponse,
    GetBrandBusinessesResponseFromJSON,
    GetBrandBusinessesResponseToJSON,
    GetBrandMapMainPromoResponse,
    GetBrandMapMainPromoResponseFromJSON,
    GetBrandMapMainPromoResponseToJSON,
    GetBrandMapRestaurantsResponse,
    GetBrandMapRestaurantsResponseFromJSON,
    GetBrandMapRestaurantsResponseToJSON,
    GetBrandMapsResponse,
    GetBrandMapsResponseFromJSON,
    GetBrandMapsResponseToJSON,
    GetBrandMerchantsResponse,
    GetBrandMerchantsResponseFromJSON,
    GetBrandMerchantsResponseToJSON,
    GetBrandsEnabledStatus,
    GetBrandsEnabledStatusFromJSON,
    GetBrandsEnabledStatusToJSON,
    GetBrandsResponse,
    GetBrandsResponseFromJSON,
    GetBrandsResponseToJSON,
    GetBrandsSort,
    GetBrandsSortFromJSON,
    GetBrandsSortToJSON,
    GetMerchantBrandsResponse,
    GetMerchantBrandsResponseFromJSON,
    GetMerchantBrandsResponseToJSON,
    NftMintTransaction,
    NftMintTransactionFromJSON,
    NftMintTransactionToJSON,
    RemoveBrandBusinessBody,
    RemoveBrandBusinessBodyFromJSON,
    RemoveBrandBusinessBodyToJSON,
    RemoveBrandPromoBody,
    RemoveBrandPromoBodyFromJSON,
    RemoveBrandPromoBodyToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    UpdateBrandBody,
    UpdateBrandBodyFromJSON,
    UpdateBrandBodyToJSON,
    UpdateBrandMapBody,
    UpdateBrandMapBodyFromJSON,
    UpdateBrandMapBodyToJSON,
    UpdateBrandMapStatusBody,
    UpdateBrandMapStatusBodyFromJSON,
    UpdateBrandMapStatusBodyToJSON,
    UpdateBrandMerchantBody,
    UpdateBrandMerchantBodyFromJSON,
    UpdateBrandMerchantBodyToJSON,
} from '../models';

export interface AddBrandBusinessRequest {
    addBrandBusinessBody?: AddBrandBusinessBody;
}

export interface AddBrandMerchantRequest {
    addBrandMerchantBody?: AddBrandMerchantBody;
}

export interface AddBrandPromoRequest {
    addBrandPromoBody?: AddBrandPromoBody;
}

export interface AutomintBrandMapRequest {
    id: string;
}

export interface CreateBrandRequest {
    createBrandBody?: CreateBrandBody;
}

export interface CreateBrandMapRequest {
    createBrandMapBody?: CreateBrandMapBody;
}

export interface DeleteBrandBusinessRequest {
    removeBrandBusinessBody?: RemoveBrandBusinessBody;
}

export interface DeleteBrandMerchantPermissionRequest {
    id: string;
}

export interface DeleteBrandPromoRequest {
    removeBrandPromoBody?: RemoveBrandPromoBody;
}

export interface GetBrandRequest {
    id: string;
}

export interface GetBrandBusinessesRequest {
    id: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetBrandMapRequest {
    slug: string;
}

export interface GetBrandMapMainPromoRequest {
    slug: string;
}

export interface GetBrandMapRestaurantsRequest {
    slug: string;
    placeId?: string;
    handoff?: string;
    search?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetBrandMapsRequest {
    id: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetBrandMerchantsRequest {
    id: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetBrandsRequest {
    search?: string;
    sort?: GetBrandsSort;
    order?: SortOrder;
    isEnabled?: GetBrandsEnabledStatus;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetMerchantBrandsRequest {
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface UpdateBrandRequest {
    id: string;
    updateBrandBody?: UpdateBrandBody;
}

export interface UpdateBrandMapRequest {
    id: string;
    updateBrandMapBody?: UpdateBrandMapBody;
}

export interface UpdateBrandMapStatusRequest {
    id: string;
    updateBrandMapStatusBody?: UpdateBrandMapStatusBody;
}

export interface UpdateBrandMerchantPermissionsRequest {
    id: string;
    updateBrandMerchantBody?: UpdateBrandMerchantBody;
}

/**
 * BrandsApi - interface
 * @export
 * @interface BrandsApiInterface
 */
export interface BrandsApiInterface {
    /**
     * Used to create new relationships between Brands & Businesses.
     * @param {AddBrandBusinessBody} [addBrandBusinessBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    addBrandBusinessRaw(requestParameters: AddBrandBusinessRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to create new relationships between Brands & Businesses.
     */
    addBrandBusiness(requestParameters: AddBrandBusinessRequest): Promise<void>;

    /**
     * Used to create new relationships between Brands & Merchants.
     * @param {AddBrandMerchantBody} [addBrandMerchantBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    addBrandMerchantRaw(requestParameters: AddBrandMerchantRequest): Promise<runtime.ApiResponse<BrandToMerchantPermissions>>;

    /**
     * Used to create new relationships between Brands & Merchants.
     */
    addBrandMerchant(requestParameters: AddBrandMerchantRequest): Promise<BrandToMerchantPermissions>;

    /**
     * Used to create new relationships between Brands & Promos.
     * @param {AddBrandPromoBody} [addBrandPromoBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    addBrandPromoRaw(requestParameters: AddBrandPromoRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to create new relationships between Brands & Promos.
     */
    addBrandPromo(requestParameters: AddBrandPromoRequest): Promise<void>;

    /**
     * Mint a dropshipping NFT to a customer\'s Magic wallet.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    automintBrandMapRaw(requestParameters: AutomintBrandMapRequest): Promise<runtime.ApiResponse<NftMintTransaction>>;

    /**
     * Mint a dropshipping NFT to a customer\'s Magic wallet.
     */
    automintBrandMap(requestParameters: AutomintBrandMapRequest): Promise<NftMintTransaction>;

    /**
     * Used to create new brands.
     * @param {CreateBrandBody} [createBrandBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    createBrandRaw(requestParameters: CreateBrandRequest): Promise<runtime.ApiResponse<Brand>>;

    /**
     * Used to create new brands.
     */
    createBrand(requestParameters: CreateBrandRequest): Promise<Brand>;

    /**
     * Used to create new maps for brands.
     * @param {CreateBrandMapBody} [createBrandMapBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    createBrandMapRaw(requestParameters: CreateBrandMapRequest): Promise<runtime.ApiResponse<BrandMap>>;

    /**
     * Used to create new maps for brands.
     */
    createBrandMap(requestParameters: CreateBrandMapRequest): Promise<BrandMap>;

    /**
     * Removes a brand from a business
     * @param {RemoveBrandBusinessBody} [removeBrandBusinessBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    deleteBrandBusinessRaw(requestParameters: DeleteBrandBusinessRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Removes a brand from a business
     */
    deleteBrandBusiness(requestParameters: DeleteBrandBusinessRequest): Promise<void>;

    /**
     * Removes a merchant from a brand.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    deleteBrandMerchantPermissionRaw(requestParameters: DeleteBrandMerchantPermissionRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Removes a merchant from a brand.
     */
    deleteBrandMerchantPermission(requestParameters: DeleteBrandMerchantPermissionRequest): Promise<void>;

    /**
     * Removes a promo from a brand
     * @param {RemoveBrandPromoBody} [removeBrandPromoBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    deleteBrandPromoRaw(requestParameters: DeleteBrandPromoRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Removes a promo from a brand
     */
    deleteBrandPromo(requestParameters: DeleteBrandPromoRequest): Promise<void>;

    /**
     * Used to get the details of a single brand
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandRaw(requestParameters: GetBrandRequest): Promise<runtime.ApiResponse<Brand>>;

    /**
     * Used to get the details of a single brand
     */
    getBrand(requestParameters: GetBrandRequest): Promise<Brand>;

    /**
     * Used to get all businesses associated with the specified brand.
     * @param {string} id 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandBusinessesRaw(requestParameters: GetBrandBusinessesRequest): Promise<runtime.ApiResponse<GetBrandBusinessesResponse>>;

    /**
     * Used to get all businesses associated with the specified brand.
     */
    getBrandBusinesses(requestParameters: GetBrandBusinessesRequest): Promise<GetBrandBusinessesResponse>;

    /**
     * Gets single brand map, given slug.
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandMapRaw(requestParameters: GetBrandMapRequest): Promise<runtime.ApiResponse<BrandMap>>;

    /**
     * Gets single brand map, given slug.
     */
    getBrandMap(requestParameters: GetBrandMapRequest): Promise<BrandMap>;

    /**
     * Get the brand map\'s main promo with last redeemed date by requested user.
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandMapMainPromoRaw(requestParameters: GetBrandMapMainPromoRequest): Promise<runtime.ApiResponse<GetBrandMapMainPromoResponse>>;

    /**
     * Get the brand map\'s main promo with last redeemed date by requested user.
     */
    getBrandMapMainPromo(requestParameters: GetBrandMapMainPromoRequest): Promise<GetBrandMapMainPromoResponse>;

    /**
     * Used to get brand map locations by proximity range to customer
     * @param {string} slug 
     * @param {string} [placeId] 
     * @param {string} [handoff] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandMapRestaurantsRaw(requestParameters: GetBrandMapRestaurantsRequest): Promise<runtime.ApiResponse<GetBrandMapRestaurantsResponse>>;

    /**
     * Used to get brand map locations by proximity range to customer
     */
    getBrandMapRestaurants(requestParameters: GetBrandMapRestaurantsRequest): Promise<GetBrandMapRestaurantsResponse>;

    /**
     * Get maps for specified brand, that are linked to the requesting merchant if the requested user is a merchant. (id in query is id of brand, associated merchant is grabbed from our middleware)
     * @param {string} id 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandMapsRaw(requestParameters: GetBrandMapsRequest): Promise<runtime.ApiResponse<GetBrandMapsResponse>>;

    /**
     * Get maps for specified brand, that are linked to the requesting merchant if the requested user is a merchant. (id in query is id of brand, associated merchant is grabbed from our middleware)
     */
    getBrandMaps(requestParameters: GetBrandMapsRequest): Promise<GetBrandMapsResponse>;

    /**
     * Used to get all merchants & their permissions associated with the specified brand.
     * @param {string} id 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandMerchantsRaw(requestParameters: GetBrandMerchantsRequest): Promise<runtime.ApiResponse<GetBrandMerchantsResponse>>;

    /**
     * Used to get all merchants & their permissions associated with the specified brand.
     */
    getBrandMerchants(requestParameters: GetBrandMerchantsRequest): Promise<GetBrandMerchantsResponse>;

    /**
     * Used to get brands in the system according to supplied filter.
     * @param {string} [search] 
     * @param {GetBrandsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {GetBrandsEnabledStatus} [isEnabled] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getBrandsRaw(requestParameters: GetBrandsRequest): Promise<runtime.ApiResponse<GetBrandsResponse>>;

    /**
     * Used to get brands in the system according to supplied filter.
     */
    getBrands(requestParameters: GetBrandsRequest): Promise<GetBrandsResponse>;

    /**
     * Used to get brands associated with the requesting Merchant.
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    getMerchantBrandsRaw(requestParameters: GetMerchantBrandsRequest): Promise<runtime.ApiResponse<GetMerchantBrandsResponse>>;

    /**
     * Used to get brands associated with the requesting Merchant.
     */
    getMerchantBrands(requestParameters: GetMerchantBrandsRequest): Promise<GetMerchantBrandsResponse>;

    /**
     * Used by admins to update brands.
     * @param {string} id 
     * @param {UpdateBrandBody} [updateBrandBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    updateBrandRaw(requestParameters: UpdateBrandRequest): Promise<runtime.ApiResponse<Brand>>;

    /**
     * Used by admins to update brands.
     */
    updateBrand(requestParameters: UpdateBrandRequest): Promise<Brand>;

    /**
     * Used to update an existing map for a brand.
     * @param {string} id 
     * @param {UpdateBrandMapBody} [updateBrandMapBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    updateBrandMapRaw(requestParameters: UpdateBrandMapRequest): Promise<runtime.ApiResponse<BrandMap>>;

    /**
     * Used to update an existing map for a brand.
     */
    updateBrandMap(requestParameters: UpdateBrandMapRequest): Promise<BrandMap>;

    /**
     * Used to update the status for brand maps; able to freely switch to draft (for editing), disabled (delete), and published - which has further validation.
     * @param {string} id 
     * @param {UpdateBrandMapStatusBody} [updateBrandMapStatusBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    updateBrandMapStatusRaw(requestParameters: UpdateBrandMapStatusRequest): Promise<runtime.ApiResponse<BrandMap>>;

    /**
     * Used to update the status for brand maps; able to freely switch to draft (for editing), disabled (delete), and published - which has further validation.
     */
    updateBrandMapStatus(requestParameters: UpdateBrandMapStatusRequest): Promise<BrandMap>;

    /**
     * Used by admins to update existing permission relationships between brands & merchants (id param is id of the permission model, NOT the brand or merchant).
     * @param {string} id 
     * @param {UpdateBrandMerchantBody} [updateBrandMerchantBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandsApiInterface
     */
    updateBrandMerchantPermissionsRaw(requestParameters: UpdateBrandMerchantPermissionsRequest): Promise<runtime.ApiResponse<BrandToMerchantPermissions>>;

    /**
     * Used by admins to update existing permission relationships between brands & merchants (id param is id of the permission model, NOT the brand or merchant).
     */
    updateBrandMerchantPermissions(requestParameters: UpdateBrandMerchantPermissionsRequest): Promise<BrandToMerchantPermissions>;

}

/**
 * no description
 */
export class BrandsApi extends runtime.BaseAPI implements BrandsApiInterface {

    /**
     * Used to create new relationships between Brands & Businesses.
     */
    async addBrandBusinessRaw(requestParameters: AddBrandBusinessRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/business`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBrandBusinessBodyToJSON(requestParameters.addBrandBusinessBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to create new relationships between Brands & Businesses.
     */
    async addBrandBusiness(requestParameters: AddBrandBusinessRequest): Promise<void> {
        await this.addBrandBusinessRaw(requestParameters);
    }

    /**
     * Used to create new relationships between Brands & Merchants.
     */
    async addBrandMerchantRaw(requestParameters: AddBrandMerchantRequest): Promise<runtime.ApiResponse<BrandToMerchantPermissions>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/merchants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBrandMerchantBodyToJSON(requestParameters.addBrandMerchantBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandToMerchantPermissionsFromJSON(jsonValue));
    }

    /**
     * Used to create new relationships between Brands & Merchants.
     */
    async addBrandMerchant(requestParameters: AddBrandMerchantRequest): Promise<BrandToMerchantPermissions> {
        const response = await this.addBrandMerchantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create new relationships between Brands & Promos.
     */
    async addBrandPromoRaw(requestParameters: AddBrandPromoRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/promo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBrandPromoBodyToJSON(requestParameters.addBrandPromoBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to create new relationships between Brands & Promos.
     */
    async addBrandPromo(requestParameters: AddBrandPromoRequest): Promise<void> {
        await this.addBrandPromoRaw(requestParameters);
    }

    /**
     * Mint a dropshipping NFT to a customer\'s Magic wallet.
     */
    async automintBrandMapRaw(requestParameters: AutomintBrandMapRequest): Promise<runtime.ApiResponse<NftMintTransaction>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling automintBrandMap.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/maps/{id}/automint`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NftMintTransactionFromJSON(jsonValue));
    }

    /**
     * Mint a dropshipping NFT to a customer\'s Magic wallet.
     */
    async automintBrandMap(requestParameters: AutomintBrandMapRequest): Promise<NftMintTransaction> {
        const response = await this.automintBrandMapRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create new brands.
     */
    async createBrandRaw(requestParameters: CreateBrandRequest): Promise<runtime.ApiResponse<Brand>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBrandBodyToJSON(requestParameters.createBrandBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandFromJSON(jsonValue));
    }

    /**
     * Used to create new brands.
     */
    async createBrand(requestParameters: CreateBrandRequest): Promise<Brand> {
        const response = await this.createBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create new maps for brands.
     */
    async createBrandMapRaw(requestParameters: CreateBrandMapRequest): Promise<runtime.ApiResponse<BrandMap>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/maps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBrandMapBodyToJSON(requestParameters.createBrandMapBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandMapFromJSON(jsonValue));
    }

    /**
     * Used to create new maps for brands.
     */
    async createBrandMap(requestParameters: CreateBrandMapRequest): Promise<BrandMap> {
        const response = await this.createBrandMapRaw(requestParameters);
        return await response.value();
    }

    /**
     * Removes a brand from a business
     */
    async deleteBrandBusinessRaw(requestParameters: DeleteBrandBusinessRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/business`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveBrandBusinessBodyToJSON(requestParameters.removeBrandBusinessBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a brand from a business
     */
    async deleteBrandBusiness(requestParameters: DeleteBrandBusinessRequest): Promise<void> {
        await this.deleteBrandBusinessRaw(requestParameters);
    }

    /**
     * Removes a merchant from a brand.
     */
    async deleteBrandMerchantPermissionRaw(requestParameters: DeleteBrandMerchantPermissionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBrandMerchantPermission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/merchants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a merchant from a brand.
     */
    async deleteBrandMerchantPermission(requestParameters: DeleteBrandMerchantPermissionRequest): Promise<void> {
        await this.deleteBrandMerchantPermissionRaw(requestParameters);
    }

    /**
     * Removes a promo from a brand
     */
    async deleteBrandPromoRaw(requestParameters: DeleteBrandPromoRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/promos`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveBrandPromoBodyToJSON(requestParameters.removeBrandPromoBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a promo from a brand
     */
    async deleteBrandPromo(requestParameters: DeleteBrandPromoRequest): Promise<void> {
        await this.deleteBrandPromoRaw(requestParameters);
    }

    /**
     * Used to get the details of a single brand
     */
    async getBrandRaw(requestParameters: GetBrandRequest): Promise<runtime.ApiResponse<Brand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBrand.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/brands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandFromJSON(jsonValue));
    }

    /**
     * Used to get the details of a single brand
     */
    async getBrand(requestParameters: GetBrandRequest): Promise<Brand> {
        const response = await this.getBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get all businesses associated with the specified brand.
     */
    async getBrandBusinessesRaw(requestParameters: GetBrandBusinessesRequest): Promise<runtime.ApiResponse<GetBrandBusinessesResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBrandBusinesses.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}/businesses`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandBusinessesResponseFromJSON(jsonValue));
    }

    /**
     * Used to get all businesses associated with the specified brand.
     */
    async getBrandBusinesses(requestParameters: GetBrandBusinessesRequest): Promise<GetBrandBusinessesResponse> {
        const response = await this.getBrandBusinessesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets single brand map, given slug.
     */
    async getBrandMapRaw(requestParameters: GetBrandMapRequest): Promise<runtime.ApiResponse<BrandMap>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getBrandMap.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-maps/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandMapFromJSON(jsonValue));
    }

    /**
     * Gets single brand map, given slug.
     */
    async getBrandMap(requestParameters: GetBrandMapRequest): Promise<BrandMap> {
        const response = await this.getBrandMapRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the brand map\'s main promo with last redeemed date by requested user.
     */
    async getBrandMapMainPromoRaw(requestParameters: GetBrandMapMainPromoRequest): Promise<runtime.ApiResponse<GetBrandMapMainPromoResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getBrandMapMainPromo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brand-maps/{slug}/main-promo`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandMapMainPromoResponseFromJSON(jsonValue));
    }

    /**
     * Get the brand map\'s main promo with last redeemed date by requested user.
     */
    async getBrandMapMainPromo(requestParameters: GetBrandMapMainPromoRequest): Promise<GetBrandMapMainPromoResponse> {
        const response = await this.getBrandMapMainPromoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get brand map locations by proximity range to customer
     */
    async getBrandMapRestaurantsRaw(requestParameters: GetBrandMapRestaurantsRequest): Promise<runtime.ApiResponse<GetBrandMapRestaurantsResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getBrandMapRestaurants.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        if (requestParameters.handoff !== undefined) {
            queryParameters['handoff'] = requestParameters.handoff;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/brand-maps/{slug}/restaurants`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandMapRestaurantsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get brand map locations by proximity range to customer
     */
    async getBrandMapRestaurants(requestParameters: GetBrandMapRestaurantsRequest): Promise<GetBrandMapRestaurantsResponse> {
        const response = await this.getBrandMapRestaurantsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get maps for specified brand, that are linked to the requesting merchant if the requested user is a merchant. (id in query is id of brand, associated merchant is grabbed from our middleware)
     */
    async getBrandMapsRaw(requestParameters: GetBrandMapsRequest): Promise<runtime.ApiResponse<GetBrandMapsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBrandMaps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-brand-maps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandMapsResponseFromJSON(jsonValue));
    }

    /**
     * Get maps for specified brand, that are linked to the requesting merchant if the requested user is a merchant. (id in query is id of brand, associated merchant is grabbed from our middleware)
     */
    async getBrandMaps(requestParameters: GetBrandMapsRequest): Promise<GetBrandMapsResponse> {
        const response = await this.getBrandMapsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get all merchants & their permissions associated with the specified brand.
     */
    async getBrandMerchantsRaw(requestParameters: GetBrandMerchantsRequest): Promise<runtime.ApiResponse<GetBrandMerchantsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBrandMerchants.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/merchants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandMerchantsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get all merchants & their permissions associated with the specified brand.
     */
    async getBrandMerchants(requestParameters: GetBrandMerchantsRequest): Promise<GetBrandMerchantsResponse> {
        const response = await this.getBrandMerchantsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get brands in the system according to supplied filter.
     */
    async getBrandsRaw(requestParameters: GetBrandsRequest): Promise<runtime.ApiResponse<GetBrandsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.isEnabled !== undefined) {
            queryParameters['isEnabled'] = requestParameters.isEnabled;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBrandsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get brands in the system according to supplied filter.
     */
    async getBrands(requestParameters: GetBrandsRequest): Promise<GetBrandsResponse> {
        const response = await this.getBrandsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get brands associated with the requesting Merchant.
     */
    async getMerchantBrandsRaw(requestParameters: GetMerchantBrandsRequest): Promise<runtime.ApiResponse<GetMerchantBrandsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMerchantBrandsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get brands associated with the requesting Merchant.
     */
    async getMerchantBrands(requestParameters: GetMerchantBrandsRequest): Promise<GetMerchantBrandsResponse> {
        const response = await this.getMerchantBrandsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admins to update brands.
     */
    async updateBrandRaw(requestParameters: UpdateBrandRequest): Promise<runtime.ApiResponse<Brand>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBrand.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBrandBodyToJSON(requestParameters.updateBrandBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandFromJSON(jsonValue));
    }

    /**
     * Used by admins to update brands.
     */
    async updateBrand(requestParameters: UpdateBrandRequest): Promise<Brand> {
        const response = await this.updateBrandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to update an existing map for a brand.
     */
    async updateBrandMapRaw(requestParameters: UpdateBrandMapRequest): Promise<runtime.ApiResponse<BrandMap>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBrandMap.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/maps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBrandMapBodyToJSON(requestParameters.updateBrandMapBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandMapFromJSON(jsonValue));
    }

    /**
     * Used to update an existing map for a brand.
     */
    async updateBrandMap(requestParameters: UpdateBrandMapRequest): Promise<BrandMap> {
        const response = await this.updateBrandMapRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to update the status for brand maps; able to freely switch to draft (for editing), disabled (delete), and published - which has further validation.
     */
    async updateBrandMapStatusRaw(requestParameters: UpdateBrandMapStatusRequest): Promise<runtime.ApiResponse<BrandMap>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBrandMapStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/maps/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBrandMapStatusBodyToJSON(requestParameters.updateBrandMapStatusBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandMapFromJSON(jsonValue));
    }

    /**
     * Used to update the status for brand maps; able to freely switch to draft (for editing), disabled (delete), and published - which has further validation.
     */
    async updateBrandMapStatus(requestParameters: UpdateBrandMapStatusRequest): Promise<BrandMap> {
        const response = await this.updateBrandMapStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admins to update existing permission relationships between brands & merchants (id param is id of the permission model, NOT the brand or merchant).
     */
    async updateBrandMerchantPermissionsRaw(requestParameters: UpdateBrandMerchantPermissionsRequest): Promise<runtime.ApiResponse<BrandToMerchantPermissions>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBrandMerchantPermissions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/brands/merchants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBrandMerchantBodyToJSON(requestParameters.updateBrandMerchantBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandToMerchantPermissionsFromJSON(jsonValue));
    }

    /**
     * Used by admins to update existing permission relationships between brands & merchants (id param is id of the permission model, NOT the brand or merchant).
     */
    async updateBrandMerchantPermissions(requestParameters: UpdateBrandMerchantPermissionsRequest): Promise<BrandToMerchantPermissions> {
        const response = await this.updateBrandMerchantPermissionsRaw(requestParameters);
        return await response.value();
    }

}
