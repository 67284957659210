import {isDesktop, isTablet} from "react-device-detect";
import {GetTransactionsResponse, MenuOrder} from "@devour/client";
import {StripePaymentMethodObject} from "@/types/Stripe";
import renderCardInfo from "@/utils/renderCardInfo";

export enum ActivePaymentMethod {
    DPAY = "DPAY", // DPAY only
    CREDIT = "CREDIT", // Credit only
    SPLIT = "SPLIT", // DPAY and credit
    COINBASE = "COINBASE", // Coinbase
}

export enum ActiveDpay {
    VDPAY,
    MAGIC,
    EXTERNAL,
}

interface ActivePayment {
    method: ActivePaymentMethod;
    activeMethodText: string;
    dpayChain?: ActiveDpay;
}

/**
 * Find the active payment method for a menu order.
 * @param menuOrder
 * @param stripePaymentMethods
 * @param account
 * @param transactionData
 */
export function getMenuOrderPaymentMethod(menuOrder: MenuOrder, stripePaymentMethods?: Array<StripePaymentMethodObject>, account?: void, transactionData?: GetTransactionsResponse): ActivePayment {
    if (!menuOrder) {
        return;
    }
    if ((menuOrder.onChainDpay || menuOrder.dpay) && menuOrder.paymentMethodId && menuOrder.stripeTotal) { // Split payment
        if (!stripePaymentMethods) {
            return;
        }

        const targetPaymentMethod = stripePaymentMethods.find((method) => method.id === menuOrder.paymentMethodId);
        if (!targetPaymentMethod) {
            return;
        }

        if (menuOrder.onChainDpay === 0) {
            if (!transactionData || menuOrder.dpay > transactionData.balance) {
                return;
            }
            return {
                method: ActivePaymentMethod.SPLIT,
                activeMethodText: isDesktop || isTablet
                    ? `${renderCardInfo(targetPaymentMethod)} | My Account ${import.meta.env.VITE_TOKEN_NAME}`
                    : "2 Payments Added",
                dpayChain: ActiveDpay.VDPAY,
            };
        } else {
            if (menuOrder.onChainDpay && menuOrder.isMagicWallet) {
                return {
                    method: ActivePaymentMethod.SPLIT,
                    activeMethodText: isDesktop || isTablet
                        ? `${renderCardInfo(targetPaymentMethod)} | DevourGO Wallet`
                        : "2 Payments Added",
                    dpayChain: ActiveDpay.MAGIC,
                };
            } else if (menuOrder.onChainDpay && !menuOrder.isMagicWallet) {
                return;
                // if (!account?.isConnected) {
                //     return;
                // }
                // return {
                //     method: ActivePaymentMethod.SPLIT,
                //     activeMethodText: isDesktop || isTablet
                //         ? `${renderCardInfo(targetPaymentMethod)} | External Wallet`
                //         : "2 Payments Added",
                //     dpayChain: ActiveDpay.EXTERNAL,
                // };
            }
        }
    }
    if (menuOrder.onChainDpay || menuOrder.dpay) {
        if (menuOrder.onChainDpay === 0) {
            if (!transactionData || menuOrder.dpay > transactionData.balance) {
                return;
            }
            return {
                method: ActivePaymentMethod.DPAY,
                activeMethodText: `My Account ${import.meta.env.VITE_TOKEN_NAME}`,
                dpayChain: ActiveDpay.VDPAY,
            };
        } else if (menuOrder.onChainDpay && menuOrder.isMagicWallet) {
            // For the on-chain methods, it's better to do the check of balances at the time of purchase
            return {
                method: ActivePaymentMethod.DPAY,
                activeMethodText: "DevourGO wallet",
                dpayChain: ActiveDpay.MAGIC,
            };
        } else {
            return;
            // if (!account?.isConnected) {
            //     return;
            // }
            // return {
            //     method: ActivePaymentMethod.DPAY,
            //     activeMethodText: `External Wallet | ${truncateMiddle(account?.address, WALLET_ADDRESS_TRUNCATION)}`,
            //     dpayChain: ActiveDpay.EXTERNAL,
            // };
        }
    }
    if (menuOrder.isCoinbase) {
        return {
            method: ActivePaymentMethod.COINBASE,
            activeMethodText: "Coinbase Checkout",
        };
    }
    if (menuOrder.paymentMethodId) {
        if (!stripePaymentMethods) {
            return;
        }

        const targetPaymentMethod = stripePaymentMethods.find((method) => method.id === menuOrder.paymentMethodId);
        if (!targetPaymentMethod) {
            return;
        }
        return {
            method: ActivePaymentMethod.CREDIT,
            activeMethodText: renderCardInfo(targetPaymentMethod),
        };
    }
}
