import { FC, useEffect } from "react";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import ThirdwebLogin from "@/pages/ThirdwebLogin";
import { useGetGuild } from "@/hooks/useGetGuild";
import useThemePreference from "@/hooks/useThemePreference";
import { useAsset } from "@/hooks/useAsset";
import classNames from "classnames";
import { useSearchParams } from "react-router";

interface props {
    isOpen: boolean;
    onClose: () => void;
}

const LoginModal: FC<props> = ({ isOpen, onClose }) => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const isOnOverwolf = useSelector((store: IStore) => store.metaStore.isOnOverwolf);
    const referralCode = useSelector((store: IStore) => store.affiliateStore.referralCode);
    const { isOnDarkMode } = useThemePreference();
    const [searchParams] = useSearchParams();
    const guildInviteId = searchParams.get("guildId");
    const {data: guildInfo} = useGetGuild(guildInviteId);
    const guildAssetImage = useAsset(guildInfo?.hero);
    const isGuildInvite = referralCode && guildInviteId;
    useEffect(() => {
        if (isOpen && fullToken) {
            onClose();
        }
    }, [fullToken?.id, isOpen]);

    return (
        <FrameOneModal
            contentClassName={classNames("magic-login-modal", {
                "is-invite": isGuildInvite,
            })}
            containerClassName="magic-login-modal-container"
            size={isOnOverwolf ? "xs2" : "sm2"}
            isOpen={isOpen}
            // toggle={onClose} Accidental closing the login modal will require the user to get a new code and invalid their previous one
        >
            {guildAssetImage?.data && <>
                <div
                    className="invite-modal_background"
                    style={{
                        backgroundImage: `url(${guildAssetImage?.data?.cdnUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: "8.73556rem",
                    }}
                ></div>
                <div
                    className="invite-modal_shader"
                    style={{
                        background: `linear-gradient(180deg, rgba(45, 45, 47, 0.10) 0%, ${
                            isOnDarkMode ? "#2D2D2F" : "white"
                        } 7rem)`,
                    }}
                ></div>
            </>}
            <FrameModalHeader toggle={onClose}>
                {isOnOverwolf
                    ? <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/DevourPlayLogoWhite.webp`}
                        alt="Devour logo"
                        className="overwolf-landing-page_login-logo"
                    />
                    : <img src={`${import.meta.env.VITE_CDN_URL}/images/DevourGo-logo-white.png`} alt="" />
                }
            </FrameModalHeader>
            <FrameModalBody>
                <ThirdwebLogin isOnDialog={true} />
            </FrameModalBody>
        </FrameOneModal>
    );
};

export default LoginModal;