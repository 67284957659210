import {ReactElement, ReactNode} from "react";
import classNames from "classnames";
import GoldMedal from "../../svgs/gofrens-gold-medal-icon.svg?react";
import SilverMedal from "../../svgs/gofrens-silver-medal-icon.svg?react";
import BronzeMedal from "../../svgs/gofrens-bronze-medal-icon.svg?react";


interface Profile {
    photo: string;
    name: string;
}

export type LeaderboardRowColor =
    "white"
    | "light-gray"
    | "DM-white"
    | "DM-light-gray";

interface Props {
    rank?: number;
    profile: Profile;
    accumulatedPoints: number | string;
    rightIconToName?: ReactNode;
    className?: string
    bgColor?: LeaderboardRowColor;
    communityName?: string;
    communityIcon?: string;
}

function GoFrensLeaderboardRow(props: Props): ReactElement {
    return (
        <div className={classNames(
            "gofrens-leaderboard-row",
            props.className,
            props.bgColor && `gofrens-leaderboard-row_${props.bgColor}`,
        )}
        >
            <div className="gofrens-leaderboard-row_rank">{props.rank || "?"}</div>

            <div className="gofrens-leaderboard-row_profile">
                <div className="gofrens-leaderboard-row_profile_image-container">
                    {props.profile.photo && <img src={props.profile.photo} alt="Profile picture"/>}
                </div>
                {props.communityIcon &&
                    <div className="gofrens-leaderboard-row_profile_community-icon-container">
                        <img src={props.communityIcon} alt="Community icon"/>
                    </div>
                }
                <div className="gofrens-leaderboard-row_profile_name">
                    {props.rank === 1 && <GoldMedal/>}
                    {props.rank === 2 && <SilverMedal/>}
                    {props.rank === 3 && <BronzeMedal/>}
                    {props.communityName
                        ? <div className="gofrens-leaderboard-row_profile_name_header">
                            <h4>{props.profile.name}</h4>
                            <p className="gofrens-leaderboard-row_profile_name_header_community">
                                {props.communityName}
                            </p>
                        </div>
                        : <h4>{props.profile.name}</h4>
                    }
                    {props.rightIconToName}
                </div>

            </div>

            <div className="gofrens-leaderboard-row_exp">
                {typeof props.accumulatedPoints === "number"
                    ? props.accumulatedPoints.toLocaleString("en-US")
                    : props.accumulatedPoints}
            </div>
        </div>
    );
}

export default GoFrensLeaderboardRow;