/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThirdwebLoginBody
 */
export interface ThirdwebLoginBody {
    /**
     * See https://portal.thirdweb.com/references/typescript/v5/VerifyLoginPayloadParams
     * @type {object}
     * @memberof ThirdwebLoginBody
     */
    signedPayload: object;
}

export function ThirdwebLoginBodyFromJSON(json: any): ThirdwebLoginBody {
    return ThirdwebLoginBodyFromJSONTyped(json, false);
}

export function ThirdwebLoginBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThirdwebLoginBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signedPayload': json['signedPayload'],
    };
}

export function ThirdwebLoginBodyToJSON(value?: ThirdwebLoginBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signedPayload': value.signedPayload,
    };
}


