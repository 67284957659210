import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "./buttons/FrameButton";
import {useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import DpayCardInfoModalAlertIcon from "./DpayCardInfoModalAlertIcon";
import {Link} from "react-router";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import useThemePreference from "@/hooks/useThemePreference";
import {useGate} from "statsig-react";
import SignUpToast from "./SignUpToast";

interface Props {
    showWithdrawButton?: boolean
}

function GoDexPageDpay2(props: Props): ReactElement {

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const {isOnDarkMode } = useThemePreference();
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const {data: transactionData, isError: isTransactionsDataError} = useGetTransactions(fullToken, currentUser?.user?.id);
    async function handleToastDismissal() {
        setShowToast(false);
    }

    if (!fullToken || !dpayStatus) {
        return null;
    }

    return (
        <div className="go-dex-page-dpay">
            <DpayCardInfoModalAlertIcon className="go-dex-page-dpay_alert-container"/>

            <div className="go-dex-page-dpay_details">
                <div className="dpay-balance-card_img-con">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`}
                        alt="DPAY Token Icon"
                    />
                </div>
                {
                    fullToken
                        ? <>
                            {transactionData == null && !isTransactionsDataError
                                ? <div className="account-page_loading dpay-balance-card_stats">
                                    <div className="spinner"/>
                                </div>
							 : !transactionData && isTransactionsDataError
                                    ? <div className="dpay-balance-card_stats">
                                        {import.meta.env.VITE_TOKEN_NAME} prices are unavailable at the moment.
                                    </div>
                                    : <div className="dpay-balance-card_stats">
                                        <h3 className="dpay-balance-card_stats_crypto">
                                            {transactionData.balance} {import.meta.env.VITE_TOKEN_NAME}
                                        </h3>

                                        <h6 className="dpay-balance-card_stats_usd">
                                            {`$${Intl.NumberFormat("en-us", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }).format(transactionData.balanceInUsd)} USD`}
                                        </h6>
                                    </div>
                            }
                            <div className="dpay-balance-card_buy-more">
                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    color={isOnDarkMode
                                        ? "purple"
                                        : "purple-outline"}
                                    size="normal"
                                    className={!transactionData
                                        ? "frame-one-button_disabled"
                                        : ""}
                                    to={"/load-fuel"}
                                >
                                    Load Account {import.meta.env.VITE_TOKEN_NAME}
                                </FrameButton>
                            </div>
                        </>
                        : <div className="dpay-balance-card_stats">
                            <h3 className="dpay-balance-card_stats_crypto">
								0 DPAY
                            </h3>
                            <h6 className="dpay-balance-card_stats_usd">
								$0 USD
                            </h6>
                        </div>
                }
            </div>

            <hr style={{width: "100%"}}/>

            <div className="go-dex-page-dpay_details_btn-container">
                <div className="go-dex-page-dpay_details_btn-container_withdraw">
                    {props.showWithdrawButton &&
                    <FrameButton
                        color="gray"
                        size="pill"
                        to="/withdraw"
                    >
                            Withdraw
                    </FrameButton>
                    }
                </div>

                <hr className="go-dex-page-dpay_details_btn-container_hr"/>

                <div className="go-dex-page-dpay_details_btn-container_elements">

                    {
                        fullToken
                            ? <Link to="/withdraw">
                                {import.meta.env.VITE_TOKEN_NAME} History
                            </Link>
                            : <div onClick={() => setShowToast(true)}>
                                <SignUpToast
                                    isOpen={showToast}
                                    onDismiss={handleToastDismissal}
                                />
                                <p>
                                    {import.meta.env.VITE_TOKEN_NAME} History
                                </p>
                            </div>
                    }

                </div>
            </div>
        </div>
    );
}

export default GoDexPageDpay2;
