import {PaginationInfo} from "@devour/client";
import {ReactElement} from "react";
import {omit} from "lodash";
import FramePaginationButtons from "@/components/paginator/FramePaginationButtons";
import classNames from "classnames";
import FramePaginatorLimiter, {framePaginationLimiterOptions} from "@/components/paginator/FramePaginatorLimiter";

export type FrontendPagination = Pick<PaginationInfo, "offset" | "limit">;

// Use this for any api requests with pagination to have consistent defaults across apis. If there are api requests
// that you specifically want to use different defaults for, then go right ahead.
export const defaultFrontendPagination: FrontendPagination = {
    offset: 0,
    limit: framePaginationLimiterOptions?.[1]?.value as number,
};

export type FramePaginatorTheme = "dark" | "light";
interface Props extends PaginationInfo {
    onPaginationChange: (newPagination: FrontendPagination) => void;
    theme?: FramePaginatorTheme;
    showPaginatorLimit?: boolean;
}

function FramePaginator({
    onPaginationChange,
    theme = "light",
    showPaginatorLimit = false,
    limit,
    ...props
}: Props): ReactElement {
    function onChangeLimit(limit: number): void {
        onPaginationChange(omit({
            offset: 0,
            limit,
        }, "onPaginationChange"));
    }

    function onChangeOffset(offset: number): void {
        onPaginationChange(omit({
            limit: limit,
            offset,
        }, "onPaginationChange"));
    }

    return (
        <div
            className={classNames("frame-paginator", {
                "theme-dark": theme === "dark",
                "show-limiter": showPaginatorLimit,
            })}
        >
            {showPaginatorLimit &&
                <FramePaginatorLimiter
                    {...omit(props, "onPaginationChange")}
                    limit={limit}
                    onChangeLimit={onChangeLimit}
                />
            }

            <FramePaginationButtons
                {...omit(props, "onPaginationChange")}
                limit={limit}
                onChangeOffset={onChangeOffset}
            />
        </div>
    );

}

export default FramePaginator;