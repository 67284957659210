/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddNftGroupingBody,
    AddNftGroupingBodyFromJSON,
    AddNftGroupingBodyToJSON,
    AddNftTrackerBody,
    AddNftTrackerBodyFromJSON,
    AddNftTrackerBodyToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    EVMChain,
    EVMChainFromJSON,
    EVMChainToJSON,
    ForceUpdateNftOwnershipBody,
    ForceUpdateNftOwnershipBodyFromJSON,
    ForceUpdateNftOwnershipBodyToJSON,
    ForceUpdateNftOwnershipResponse,
    ForceUpdateNftOwnershipResponseFromJSON,
    ForceUpdateNftOwnershipResponseToJSON,
    GetNftGroupingResponse,
    GetNftGroupingResponseFromJSON,
    GetNftGroupingResponseToJSON,
    GetNftGroupingsResponse,
    GetNftGroupingsResponseFromJSON,
    GetNftGroupingsResponseToJSON,
    GetNftGroupingsSort,
    GetNftGroupingsSortFromJSON,
    GetNftGroupingsSortToJSON,
    GetNftMintTransactionsResponse,
    GetNftMintTransactionsResponseFromJSON,
    GetNftMintTransactionsResponseToJSON,
    GetNftMintTransactionsSort,
    GetNftMintTransactionsSortFromJSON,
    GetNftMintTransactionsSortToJSON,
    GetNftOwnershipsResponse,
    GetNftOwnershipsResponseFromJSON,
    GetNftOwnershipsResponseToJSON,
    GetNftRewardPrizesForAdminResponse,
    GetNftRewardPrizesForAdminResponseFromJSON,
    GetNftRewardPrizesForAdminResponseToJSON,
    GetNftRewardResponse,
    GetNftRewardResponseFromJSON,
    GetNftRewardResponseToJSON,
    GetNftTrackerResponse,
    GetNftTrackerResponseFromJSON,
    GetNftTrackerResponseToJSON,
    GetNftTrackersFromGroupingResponse,
    GetNftTrackersFromGroupingResponseFromJSON,
    GetNftTrackersFromGroupingResponseToJSON,
    GetNftTrackersResponse,
    GetNftTrackersResponseFromJSON,
    GetNftTrackersResponseToJSON,
    GetNftTrackersSort,
    GetNftTrackersSortFromJSON,
    GetNftTrackersSortToJSON,
    GetUserRedeemableNftOwnershipsResponse,
    GetUserRedeemableNftOwnershipsResponseFromJSON,
    GetUserRedeemableNftOwnershipsResponseToJSON,
    LootboxReward,
    LootboxRewardFromJSON,
    LootboxRewardToJSON,
    NftType,
    NftTypeFromJSON,
    NftTypeToJSON,
    RedeemNftRewardBody,
    RedeemNftRewardBodyFromJSON,
    RedeemNftRewardBodyToJSON,
    RedeemNftRewardResponse,
    RedeemNftRewardResponseFromJSON,
    RedeemNftRewardResponseToJSON,
    SimpleHashWebhookPayload,
    SimpleHashWebhookPayloadFromJSON,
    SimpleHashWebhookPayloadToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    UpdateNftGroupingBody,
    UpdateNftGroupingBodyFromJSON,
    UpdateNftGroupingBodyToJSON,
    UpdateNftRewardPrizeBody,
    UpdateNftRewardPrizeBodyFromJSON,
    UpdateNftRewardPrizeBodyToJSON,
    UpsertNftRewardBody,
    UpsertNftRewardBodyFromJSON,
    UpsertNftRewardBodyToJSON,
} from '../models';

export interface AddNftGroupingRequest {
    addNftGroupingBody?: AddNftGroupingBody;
}

export interface AddNftRewardPrizeRequest {
    updateNftRewardPrizeBody?: UpdateNftRewardPrizeBody;
}

export interface AddNftTrackerRequest {
    addNftTrackerBody?: AddNftTrackerBody;
}

export interface DeleteNftGroupingRequest {
    id: string;
}

export interface DeleteNftTrackerRequest {
    id: string;
}

export interface ForceNftOwnershipUpdateRequest {
    forceUpdateNftOwnershipBody?: ForceUpdateNftOwnershipBody;
}

export interface GetNftGroupingRequest {
    id: string;
}

export interface GetNftGroupingsRequest {
    onlyShowEligible?: string;
    evmChain?: EVMChain;
    categoryId?: string;
    search?: string;
    sort?: GetNftGroupingsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetNftMintTransactionsRequest {
    evmChain?: EVMChain;
    type?: NftType;
    userId?: string;
    trackerId?: string;
    dateStart?: string;
    dateEnd?: string;
    sort?: GetNftMintTransactionsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetNftRewardRequest {
    id: string;
}

export interface GetNftRewardPrizesForAdminRequest {
    search?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetNftTrackerRequest {
    id: string;
}

export interface GetNftTrackerFromGroupingRequest {
    id: string;
}

export interface GetNftTrackersRequest {
    evmChain?: EVMChain;
    contractAddress?: string;
    search?: string;
    sort?: GetNftTrackersSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface RedeemNftRewardRequest {
    redeemNftRewardBody?: RedeemNftRewardBody;
}

export interface SimplehashWebhookListenerRequest {
    id: string;
    simpleHashWebhookPayload?: SimpleHashWebhookPayload;
}

export interface UpdateNftGroupingRequest {
    updateNftGroupingBody?: UpdateNftGroupingBody;
}

export interface UpdateNftRewardPrizeRequest {
    id: string;
    updateNftRewardPrizeBody?: UpdateNftRewardPrizeBody;
}

export interface UpdateNftTrackerRequest {
    id: string;
    addNftTrackerBody?: AddNftTrackerBody;
}

export interface UpsertNftRewardRequest {
    upsertNftRewardBody?: UpsertNftRewardBody;
}

export interface ValidateEligiblePrizesRemainingRequest {
    id: string;
}

/**
 * NftsApi - interface
 * @export
 * @interface NftsApiInterface
 */
export interface NftsApiInterface {
    /**
     * Adds a new nft grouping to the system
     * @param {AddNftGroupingBody} [addNftGroupingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    addNftGroupingRaw(requestParameters: AddNftGroupingRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Adds a new nft grouping to the system
     */
    addNftGrouping(requestParameters: AddNftGroupingRequest): Promise<void>;

    /**
     * Adds a new prize to the system
     * @param {UpdateNftRewardPrizeBody} [updateNftRewardPrizeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    addNftRewardPrizeRaw(requestParameters: AddNftRewardPrizeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Adds a new prize to the system
     */
    addNftRewardPrize(requestParameters: AddNftRewardPrizeRequest): Promise<void>;

    /**
     * Adds a new nft tracker to the system
     * @param {AddNftTrackerBody} [addNftTrackerBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    addNftTrackerRaw(requestParameters: AddNftTrackerRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Adds a new nft tracker to the system
     */
    addNftTracker(requestParameters: AddNftTrackerRequest): Promise<void>;

    /**
     * Delete a single NFT Grouping\'s associated NFT Trackers & Ownerships, then set it as disabled.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    deleteNftGroupingRaw(requestParameters: DeleteNftGroupingRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a single NFT Grouping\'s associated NFT Trackers & Ownerships, then set it as disabled.
     */
    deleteNftGrouping(requestParameters: DeleteNftGroupingRequest): Promise<void>;

    /**
     * Delete a single NFT Tracker along with its associated NFT Ownerships and any simplehash webhooks.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    deleteNftTrackerRaw(requestParameters: DeleteNftTrackerRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete a single NFT Tracker along with its associated NFT Ownerships and any simplehash webhooks.
     */
    deleteNftTracker(requestParameters: DeleteNftTrackerRequest): Promise<void>;

    /**
     * Updates all NFT Ownerships in the system with SimpleHash data, creates webhooks if needed.
     * @param {ForceUpdateNftOwnershipBody} [forceUpdateNftOwnershipBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    forceNftOwnershipUpdateRaw(requestParameters: ForceNftOwnershipUpdateRequest): Promise<runtime.ApiResponse<ForceUpdateNftOwnershipResponse>>;

    /**
     * Updates all NFT Ownerships in the system with SimpleHash data, creates webhooks if needed.
     */
    forceNftOwnershipUpdate(requestParameters: ForceNftOwnershipUpdateRequest): Promise<ForceUpdateNftOwnershipResponse>;

    /**
     * Gets all lootbox rewards and their associated prizes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getLootboxNftRewardsRaw(): Promise<runtime.ApiResponse<Array<LootboxReward>>>;

    /**
     * Gets all lootbox rewards and their associated prizes.
     */
    getLootboxNftRewards(): Promise<Array<LootboxReward>>;

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftGroupingRaw(requestParameters: GetNftGroupingRequest): Promise<runtime.ApiResponse<GetNftGroupingResponse>>;

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    getNftGrouping(requestParameters: GetNftGroupingRequest): Promise<GetNftGroupingResponse>;

    /**
     * 
     * @param {string} [onlyShowEligible] 
     * @param {EVMChain} [evmChain] 
     * @param {string} [categoryId] 
     * @param {string} [search] 
     * @param {GetNftGroupingsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftGroupingsRaw(requestParameters: GetNftGroupingsRequest): Promise<runtime.ApiResponse<GetNftGroupingsResponse>>;

    /**
     */
    getNftGroupings(requestParameters: GetNftGroupingsRequest): Promise<GetNftGroupingsResponse>;

    /**
     * 
     * @param {EVMChain} [evmChain] 
     * @param {NftType} [type] 
     * @param {string} [userId] 
     * @param {string} [trackerId] 
     * @param {string} [dateStart] 
     * @param {string} [dateEnd] 
     * @param {GetNftMintTransactionsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftMintTransactionsRaw(requestParameters: GetNftMintTransactionsRequest): Promise<runtime.ApiResponse<GetNftMintTransactionsResponse>>;

    /**
     */
    getNftMintTransactions(requestParameters: GetNftMintTransactionsRequest): Promise<GetNftMintTransactionsResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftOwnershipsRaw(): Promise<runtime.ApiResponse<GetNftOwnershipsResponse>>;

    /**
     */
    getNftOwnerships(): Promise<GetNftOwnershipsResponse>;

    /**
     * Gets a single reward metadata for the specified tracker and all of its associated prizes.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftRewardRaw(requestParameters: GetNftRewardRequest): Promise<runtime.ApiResponse<GetNftRewardResponse>>;

    /**
     * Gets a single reward metadata for the specified tracker and all of its associated prizes.
     */
    getNftReward(requestParameters: GetNftRewardRequest): Promise<GetNftRewardResponse>;

    /**
     * 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftRewardPrizesForAdminRaw(requestParameters: GetNftRewardPrizesForAdminRequest): Promise<runtime.ApiResponse<GetNftRewardPrizesForAdminResponse>>;

    /**
     */
    getNftRewardPrizesForAdmin(requestParameters: GetNftRewardPrizesForAdminRequest): Promise<GetNftRewardPrizesForAdminResponse>;

    /**
     * Gets a single Nft Tracker with all of its associated Nft trackers.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftTrackerRaw(requestParameters: GetNftTrackerRequest): Promise<runtime.ApiResponse<GetNftTrackerResponse>>;

    /**
     * Gets a single Nft Tracker with all of its associated Nft trackers.
     */
    getNftTracker(requestParameters: GetNftTrackerRequest): Promise<GetNftTrackerResponse>;

    /**
     * Gets all the NFT trackers which belong to a grouping.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftTrackerFromGroupingRaw(requestParameters: GetNftTrackerFromGroupingRequest): Promise<runtime.ApiResponse<GetNftTrackersFromGroupingResponse>>;

    /**
     * Gets all the NFT trackers which belong to a grouping.
     */
    getNftTrackerFromGrouping(requestParameters: GetNftTrackerFromGroupingRequest): Promise<GetNftTrackersFromGroupingResponse>;

    /**
     * 
     * @param {EVMChain} [evmChain] 
     * @param {string} [contractAddress] 
     * @param {string} [search] 
     * @param {GetNftTrackersSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getNftTrackersRaw(requestParameters: GetNftTrackersRequest): Promise<runtime.ApiResponse<GetNftTrackersResponse>>;

    /**
     */
    getNftTrackers(requestParameters: GetNftTrackersRequest): Promise<GetNftTrackersResponse>;

    /**
     * Get all redeemable NFT ownerships in user\'s Magic Wallet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    getUserRedeemableNftOwnershipsRaw(): Promise<runtime.ApiResponse<GetUserRedeemableNftOwnershipsResponse>>;

    /**
     * Get all redeemable NFT ownerships in user\'s Magic Wallet
     */
    getUserRedeemableNftOwnerships(): Promise<GetUserRedeemableNftOwnershipsResponse>;

    /**
     * Validates burn transaction hash and returns the prize won by user from redeeming their NFT reward.
     * @param {RedeemNftRewardBody} [redeemNftRewardBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    redeemNftRewardRaw(requestParameters: RedeemNftRewardRequest): Promise<runtime.ApiResponse<RedeemNftRewardResponse>>;

    /**
     * Validates burn transaction hash and returns the prize won by user from redeeming their NFT reward.
     */
    redeemNftReward(requestParameters: RedeemNftRewardRequest): Promise<RedeemNftRewardResponse>;

    /**
     * finds all the NFTs belonging to this user and refreshes the tokenUri on them if empty string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    refreshNftTokenUriRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * finds all the NFTs belonging to this user and refreshes the tokenUri on them if empty string
     */
    refreshNftTokenUri(): Promise<void>;

    /**
     * listens for webhook events emitted by SimpleHash
     * @param {string} id 
     * @param {SimpleHashWebhookPayload} [simpleHashWebhookPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    simplehashWebhookListenerRaw(requestParameters: SimplehashWebhookListenerRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * listens for webhook events emitted by SimpleHash
     */
    simplehashWebhookListener(requestParameters: SimplehashWebhookListenerRequest): Promise<void>;

    /**
     * Update nft grouping in the system
     * @param {UpdateNftGroupingBody} [updateNftGroupingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    updateNftGroupingRaw(requestParameters: UpdateNftGroupingRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Update nft grouping in the system
     */
    updateNftGrouping(requestParameters: UpdateNftGroupingRequest): Promise<void>;

    /**
     * Updates an existing NFT reward prize in the system for Admin.
     * @param {string} id 
     * @param {UpdateNftRewardPrizeBody} [updateNftRewardPrizeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    updateNftRewardPrizeRaw(requestParameters: UpdateNftRewardPrizeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Updates an existing NFT reward prize in the system for Admin.
     */
    updateNftRewardPrize(requestParameters: UpdateNftRewardPrizeRequest): Promise<void>;

    /**
     * Updates a nft tracker in the system
     * @param {string} id 
     * @param {AddNftTrackerBody} [addNftTrackerBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    updateNftTrackerRaw(requestParameters: UpdateNftTrackerRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Updates a nft tracker in the system
     */
    updateNftTracker(requestParameters: UpdateNftTrackerRequest): Promise<void>;

    /**
     * Updates or Creates a NFT reward\'s metadata in the system and add prizes to the reward
     * @param {UpsertNftRewardBody} [upsertNftRewardBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    upsertNftRewardRaw(requestParameters: UpsertNftRewardRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Updates or Creates a NFT reward\'s metadata in the system and add prizes to the reward
     */
    upsertNftReward(requestParameters: UpsertNftRewardRequest): Promise<void>;

    /**
     * Pre-burn validation so that users do not destroy their NFTs when there\'s no eligible prizes remaining.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NftsApiInterface
     */
    validateEligiblePrizesRemainingRaw(requestParameters: ValidateEligiblePrizesRemainingRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Pre-burn validation so that users do not destroy their NFTs when there\'s no eligible prizes remaining.
     */
    validateEligiblePrizesRemaining(requestParameters: ValidateEligiblePrizesRemainingRequest): Promise<void>;

}

/**
 * no description
 */
export class NftsApi extends runtime.BaseAPI implements NftsApiInterface {

    /**
     * Adds a new nft grouping to the system
     */
    async addNftGroupingRaw(requestParameters: AddNftGroupingRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-groupings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNftGroupingBodyToJSON(requestParameters.addNftGroupingBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds a new nft grouping to the system
     */
    async addNftGrouping(requestParameters: AddNftGroupingRequest): Promise<void> {
        await this.addNftGroupingRaw(requestParameters);
    }

    /**
     * Adds a new prize to the system
     */
    async addNftRewardPrizeRaw(requestParameters: AddNftRewardPrizeRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-reward-prizes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNftRewardPrizeBodyToJSON(requestParameters.updateNftRewardPrizeBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds a new prize to the system
     */
    async addNftRewardPrize(requestParameters: AddNftRewardPrizeRequest): Promise<void> {
        await this.addNftRewardPrizeRaw(requestParameters);
    }

    /**
     * Adds a new nft tracker to the system
     */
    async addNftTrackerRaw(requestParameters: AddNftTrackerRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-trackers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddNftTrackerBodyToJSON(requestParameters.addNftTrackerBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds a new nft tracker to the system
     */
    async addNftTracker(requestParameters: AddNftTrackerRequest): Promise<void> {
        await this.addNftTrackerRaw(requestParameters);
    }

    /**
     * Delete a single NFT Grouping\'s associated NFT Trackers & Ownerships, then set it as disabled.
     */
    async deleteNftGroupingRaw(requestParameters: DeleteNftGroupingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNftGrouping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single NFT Grouping\'s associated NFT Trackers & Ownerships, then set it as disabled.
     */
    async deleteNftGrouping(requestParameters: DeleteNftGroupingRequest): Promise<void> {
        await this.deleteNftGroupingRaw(requestParameters);
    }

    /**
     * Delete a single NFT Tracker along with its associated NFT Ownerships and any simplehash webhooks.
     */
    async deleteNftTrackerRaw(requestParameters: DeleteNftTrackerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNftTracker.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-trackers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single NFT Tracker along with its associated NFT Ownerships and any simplehash webhooks.
     */
    async deleteNftTracker(requestParameters: DeleteNftTrackerRequest): Promise<void> {
        await this.deleteNftTrackerRaw(requestParameters);
    }

    /**
     * Updates all NFT Ownerships in the system with SimpleHash data, creates webhooks if needed.
     */
    async forceNftOwnershipUpdateRaw(requestParameters: ForceNftOwnershipUpdateRequest): Promise<runtime.ApiResponse<ForceUpdateNftOwnershipResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update-nft-ownership`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForceUpdateNftOwnershipBodyToJSON(requestParameters.forceUpdateNftOwnershipBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ForceUpdateNftOwnershipResponseFromJSON(jsonValue));
    }

    /**
     * Updates all NFT Ownerships in the system with SimpleHash data, creates webhooks if needed.
     */
    async forceNftOwnershipUpdate(requestParameters: ForceNftOwnershipUpdateRequest): Promise<ForceUpdateNftOwnershipResponse> {
        const response = await this.forceNftOwnershipUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets all lootbox rewards and their associated prizes.
     */
    async getLootboxNftRewardsRaw(): Promise<runtime.ApiResponse<Array<LootboxReward>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/lootbox-nft-rewards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LootboxRewardFromJSON));
    }

    /**
     * Gets all lootbox rewards and their associated prizes.
     */
    async getLootboxNftRewards(): Promise<Array<LootboxReward>> {
        const response = await this.getLootboxNftRewardsRaw();
        return await response.value();
    }

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    async getNftGroupingRaw(requestParameters: GetNftGroupingRequest): Promise<runtime.ApiResponse<GetNftGroupingResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNftGrouping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-groupings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftGroupingResponseFromJSON(jsonValue));
    }

    /**
     * Gets a single Nft Grouping with all of its associated Nft trackers.
     */
    async getNftGrouping(requestParameters: GetNftGroupingRequest): Promise<GetNftGroupingResponse> {
        const response = await this.getNftGroupingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftGroupingsRaw(requestParameters: GetNftGroupingsRequest): Promise<runtime.ApiResponse<GetNftGroupingsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onlyShowEligible !== undefined) {
            queryParameters['onlyShowEligible'] = requestParameters.onlyShowEligible;
        }

        if (requestParameters.evmChain !== undefined) {
            queryParameters['evmChain'] = requestParameters.evmChain;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-groupings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftGroupingsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNftGroupings(requestParameters: GetNftGroupingsRequest): Promise<GetNftGroupingsResponse> {
        const response = await this.getNftGroupingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftMintTransactionsRaw(requestParameters: GetNftMintTransactionsRequest): Promise<runtime.ApiResponse<GetNftMintTransactionsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.evmChain !== undefined) {
            queryParameters['evmChain'] = requestParameters.evmChain;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.trackerId !== undefined) {
            queryParameters['trackerId'] = requestParameters.trackerId;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['dateStart'] = requestParameters.dateStart;
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['dateEnd'] = requestParameters.dateEnd;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-mint-transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftMintTransactionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNftMintTransactions(requestParameters: GetNftMintTransactionsRequest): Promise<GetNftMintTransactionsResponse> {
        const response = await this.getNftMintTransactionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftOwnershipsRaw(): Promise<runtime.ApiResponse<GetNftOwnershipsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-nft-ownerships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftOwnershipsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNftOwnerships(): Promise<GetNftOwnershipsResponse> {
        const response = await this.getNftOwnershipsRaw();
        return await response.value();
    }

    /**
     * Gets a single reward metadata for the specified tracker and all of its associated prizes.
     */
    async getNftRewardRaw(requestParameters: GetNftRewardRequest): Promise<runtime.ApiResponse<GetNftRewardResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNftReward.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nft-trackers/{id}/reward`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftRewardResponseFromJSON(jsonValue));
    }

    /**
     * Gets a single reward metadata for the specified tracker and all of its associated prizes.
     */
    async getNftReward(requestParameters: GetNftRewardRequest): Promise<GetNftRewardResponse> {
        const response = await this.getNftRewardRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftRewardPrizesForAdminRaw(requestParameters: GetNftRewardPrizesForAdminRequest): Promise<runtime.ApiResponse<GetNftRewardPrizesForAdminResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-reward-prizes-for-admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftRewardPrizesForAdminResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNftRewardPrizesForAdmin(requestParameters: GetNftRewardPrizesForAdminRequest): Promise<GetNftRewardPrizesForAdminResponse> {
        const response = await this.getNftRewardPrizesForAdminRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets a single Nft Tracker with all of its associated Nft trackers.
     */
    async getNftTrackerRaw(requestParameters: GetNftTrackerRequest): Promise<runtime.ApiResponse<GetNftTrackerResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNftTracker.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-tracker/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftTrackerResponseFromJSON(jsonValue));
    }

    /**
     * Gets a single Nft Tracker with all of its associated Nft trackers.
     */
    async getNftTracker(requestParameters: GetNftTrackerRequest): Promise<GetNftTrackerResponse> {
        const response = await this.getNftTrackerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets all the NFT trackers which belong to a grouping.
     */
    async getNftTrackerFromGroupingRaw(requestParameters: GetNftTrackerFromGroupingRequest): Promise<runtime.ApiResponse<GetNftTrackersFromGroupingResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNftTrackerFromGrouping.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-trackers-from-grouping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftTrackersFromGroupingResponseFromJSON(jsonValue));
    }

    /**
     * Gets all the NFT trackers which belong to a grouping.
     */
    async getNftTrackerFromGrouping(requestParameters: GetNftTrackerFromGroupingRequest): Promise<GetNftTrackersFromGroupingResponse> {
        const response = await this.getNftTrackerFromGroupingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getNftTrackersRaw(requestParameters: GetNftTrackersRequest): Promise<runtime.ApiResponse<GetNftTrackersResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.evmChain !== undefined) {
            queryParameters['evmChain'] = requestParameters.evmChain;
        }

        if (requestParameters.contractAddress !== undefined) {
            queryParameters['contractAddress'] = requestParameters.contractAddress;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-trackers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNftTrackersResponseFromJSON(jsonValue));
    }

    /**
     */
    async getNftTrackers(requestParameters: GetNftTrackersRequest): Promise<GetNftTrackersResponse> {
        const response = await this.getNftTrackersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all redeemable NFT ownerships in user\'s Magic Wallet
     */
    async getUserRedeemableNftOwnershipsRaw(): Promise<runtime.ApiResponse<GetUserRedeemableNftOwnershipsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/redeemable-nft-ownerships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserRedeemableNftOwnershipsResponseFromJSON(jsonValue));
    }

    /**
     * Get all redeemable NFT ownerships in user\'s Magic Wallet
     */
    async getUserRedeemableNftOwnerships(): Promise<GetUserRedeemableNftOwnershipsResponse> {
        const response = await this.getUserRedeemableNftOwnershipsRaw();
        return await response.value();
    }

    /**
     * Validates burn transaction hash and returns the prize won by user from redeeming their NFT reward.
     */
    async redeemNftRewardRaw(requestParameters: RedeemNftRewardRequest): Promise<runtime.ApiResponse<RedeemNftRewardResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/redeem-nft-reward`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RedeemNftRewardBodyToJSON(requestParameters.redeemNftRewardBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RedeemNftRewardResponseFromJSON(jsonValue));
    }

    /**
     * Validates burn transaction hash and returns the prize won by user from redeeming their NFT reward.
     */
    async redeemNftReward(requestParameters: RedeemNftRewardRequest): Promise<RedeemNftRewardResponse> {
        const response = await this.redeemNftRewardRaw(requestParameters);
        return await response.value();
    }

    /**
     * finds all the NFTs belonging to this user and refreshes the tokenUri on them if empty string
     */
    async refreshNftTokenUriRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/refresh-nft-token-uri`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * finds all the NFTs belonging to this user and refreshes the tokenUri on them if empty string
     */
    async refreshNftTokenUri(): Promise<void> {
        await this.refreshNftTokenUriRaw();
    }

    /**
     * listens for webhook events emitted by SimpleHash
     */
    async simplehashWebhookListenerRaw(requestParameters: SimplehashWebhookListenerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling simplehashWebhookListener.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/simplehash/webhook-listener/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SimpleHashWebhookPayloadToJSON(requestParameters.simpleHashWebhookPayload),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * listens for webhook events emitted by SimpleHash
     */
    async simplehashWebhookListener(requestParameters: SimplehashWebhookListenerRequest): Promise<void> {
        await this.simplehashWebhookListenerRaw(requestParameters);
    }

    /**
     * Update nft grouping in the system
     */
    async updateNftGroupingRaw(requestParameters: UpdateNftGroupingRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-groupings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNftGroupingBodyToJSON(requestParameters.updateNftGroupingBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update nft grouping in the system
     */
    async updateNftGrouping(requestParameters: UpdateNftGroupingRequest): Promise<void> {
        await this.updateNftGroupingRaw(requestParameters);
    }

    /**
     * Updates an existing NFT reward prize in the system for Admin.
     */
    async updateNftRewardPrizeRaw(requestParameters: UpdateNftRewardPrizeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNftRewardPrize.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-reward-prizes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNftRewardPrizeBodyToJSON(requestParameters.updateNftRewardPrizeBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an existing NFT reward prize in the system for Admin.
     */
    async updateNftRewardPrize(requestParameters: UpdateNftRewardPrizeRequest): Promise<void> {
        await this.updateNftRewardPrizeRaw(requestParameters);
    }

    /**
     * Updates a nft tracker in the system
     */
    async updateNftTrackerRaw(requestParameters: UpdateNftTrackerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNftTracker.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-trackers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddNftTrackerBodyToJSON(requestParameters.addNftTrackerBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a nft tracker in the system
     */
    async updateNftTracker(requestParameters: UpdateNftTrackerRequest): Promise<void> {
        await this.updateNftTrackerRaw(requestParameters);
    }

    /**
     * Updates or Creates a NFT reward\'s metadata in the system and add prizes to the reward
     */
    async upsertNftRewardRaw(requestParameters: UpsertNftRewardRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/nft-rewards`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertNftRewardBodyToJSON(requestParameters.upsertNftRewardBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates or Creates a NFT reward\'s metadata in the system and add prizes to the reward
     */
    async upsertNftReward(requestParameters: UpsertNftRewardRequest): Promise<void> {
        await this.upsertNftRewardRaw(requestParameters);
    }

    /**
     * Pre-burn validation so that users do not destroy their NFTs when there\'s no eligible prizes remaining.
     */
    async validateEligiblePrizesRemainingRaw(requestParameters: ValidateEligiblePrizesRemainingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateEligiblePrizesRemaining.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/validate-eligible-prizes-remaining/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Pre-burn validation so that users do not destroy their NFTs when there\'s no eligible prizes remaining.
     */
    async validateEligiblePrizesRemaining(requestParameters: ValidateEligiblePrizesRemainingRequest): Promise<void> {
        await this.validateEligiblePrizesRemainingRaw(requestParameters);
    }

}
