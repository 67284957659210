/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    ChallengeAllOf,
    ChallengeAllOfFromJSON,
    ChallengeAllOfFromJSONTyped,
    ChallengeAllOfToJSON,
    ChallengeFaqDetails,
    ChallengeFaqDetailsFromJSON,
    ChallengeFaqDetailsFromJSONTyped,
    ChallengeFaqDetailsToJSON,
    ChallengeOnboarding,
    ChallengeOnboardingFromJSON,
    ChallengeOnboardingFromJSONTyped,
    ChallengeOnboardingToJSON,
    ChallengeReward,
    ChallengeRewardFromJSON,
    ChallengeRewardFromJSONTyped,
    ChallengeRewardToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains challenges that can be completed by users
 * @export
 * @interface Challenge
 */
export interface Challenge {
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Challenge
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Challenge
     */
    updatedAt: Date;
    /**
     * The title of the challenge
     * @type {string}
     * @memberof Challenge
     */
    name: string;
    /**
     * The title of the challenge on the card on DevourPlay Play tab.
     * @type {string}
     * @memberof Challenge
     */
    cardTitle?: string;
    /**
     * The description of the challenge
     * @type {string}
     * @memberof Challenge
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    backgroundImageLarge?: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    backgroundImageSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    brandLogo?: string;
    /**
     * Hex color code for the brand
     * @type {string}
     * @memberof Challenge
     */
    brandColor?: string;
    /**
     * 
     * @type {Array<ChallengeReward>}
     * @memberof Challenge
     */
    rewards: Array<ChallengeReward>;
    /**
     * UNIX timestamp for when the challenge starts
     * @type {number}
     * @memberof Challenge
     */
    start: number;
    /**
     * UNIX timestamp for when the challenge ends
     * @type {number}
     * @memberof Challenge
     */
    end: number;
    /**
     * 
     * @type {boolean}
     * @memberof Challenge
     */
    hasEnded?: boolean;
    /**
     * Whether the challenge is active
     * @type {boolean}
     * @memberof Challenge
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    instantRewardQuest?: string;
    /**
     * 
     * @type {number}
     * @memberof Challenge
     */
    numberOfEntrants?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Challenge
     */
    showChallengeCompleteNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Challenge
     */
    showInstantRewardQuestCompleteNotification?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    challengeCompleteNotificationMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    instantRewardQuestCompleteNotificationMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Challenge
     */
    termsUrl?: string;
    /**
     * 
     * @type {ChallengeOnboarding}
     * @memberof Challenge
     */
    onboardingSection?: ChallengeOnboarding;
    /**
     * 
     * @type {Array<ChallengeFaqDetails>}
     * @memberof Challenge
     */
    faqs?: Array<ChallengeFaqDetails>;
    /**
     * The slug of the brand map associated with this challenge.
     * @type {string}
     * @memberof Challenge
     */
    brandMapSlug?: string;
}

export function ChallengeFromJSON(json: any): Challenge {
    return ChallengeFromJSONTyped(json, false);
}

export function ChallengeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Challenge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'name': json['name'],
        'cardTitle': !exists(json, 'cardTitle') ? undefined : json['cardTitle'],
        'description': json['description'],
        'backgroundImageLarge': !exists(json, 'backgroundImageLarge') ? undefined : json['backgroundImageLarge'],
        'backgroundImageSmall': !exists(json, 'backgroundImageSmall') ? undefined : json['backgroundImageSmall'],
        'brandLogo': !exists(json, 'brandLogo') ? undefined : json['brandLogo'],
        'brandColor': !exists(json, 'brandColor') ? undefined : json['brandColor'],
        'rewards': ((json['rewards'] as Array<any>).map(ChallengeRewardFromJSON)),
        'start': json['start'],
        'end': json['end'],
        'hasEnded': !exists(json, 'hasEnded') ? undefined : json['hasEnded'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'instantRewardQuest': !exists(json, 'instantRewardQuest') ? undefined : json['instantRewardQuest'],
        'numberOfEntrants': !exists(json, 'numberOfEntrants') ? undefined : json['numberOfEntrants'],
        'showChallengeCompleteNotification': !exists(json, 'showChallengeCompleteNotification') ? undefined : json['showChallengeCompleteNotification'],
        'showInstantRewardQuestCompleteNotification': !exists(json, 'showInstantRewardQuestCompleteNotification') ? undefined : json['showInstantRewardQuestCompleteNotification'],
        'challengeCompleteNotificationMessage': !exists(json, 'challengeCompleteNotificationMessage') ? undefined : json['challengeCompleteNotificationMessage'],
        'instantRewardQuestCompleteNotificationMessage': !exists(json, 'instantRewardQuestCompleteNotificationMessage') ? undefined : json['instantRewardQuestCompleteNotificationMessage'],
        'termsUrl': !exists(json, 'termsUrl') ? undefined : json['termsUrl'],
        'onboardingSection': !exists(json, 'onboardingSection') ? undefined : ChallengeOnboardingFromJSON(json['onboardingSection']),
        'faqs': !exists(json, 'faqs') ? undefined : ((json['faqs'] as Array<any>).map(ChallengeFaqDetailsFromJSON)),
        'brandMapSlug': !exists(json, 'brandMapSlug') ? undefined : json['brandMapSlug'],
    };
}

export function ChallengeToJSON(value?: Challenge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'name': value.name,
        'cardTitle': value.cardTitle,
        'description': value.description,
        'backgroundImageLarge': value.backgroundImageLarge,
        'backgroundImageSmall': value.backgroundImageSmall,
        'brandLogo': value.brandLogo,
        'brandColor': value.brandColor,
        'rewards': ((value.rewards as Array<any>).map(ChallengeRewardToJSON)),
        'start': value.start,
        'end': value.end,
        'hasEnded': value.hasEnded,
        'isActive': value.isActive,
        'instantRewardQuest': value.instantRewardQuest,
        'numberOfEntrants': value.numberOfEntrants,
        'showChallengeCompleteNotification': value.showChallengeCompleteNotification,
        'showInstantRewardQuestCompleteNotification': value.showInstantRewardQuestCompleteNotification,
        'challengeCompleteNotificationMessage': value.challengeCompleteNotificationMessage,
        'instantRewardQuestCompleteNotificationMessage': value.instantRewardQuestCompleteNotificationMessage,
        'termsUrl': value.termsUrl,
        'onboardingSection': ChallengeOnboardingToJSON(value.onboardingSection),
        'faqs': value.faqs === undefined ? undefined : ((value.faqs as Array<any>).map(ChallengeFaqDetailsToJSON)),
        'brandMapSlug': value.brandMapSlug,
    };
}


