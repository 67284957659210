import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useGetOverwolfGameInfo from "@/hooks/useGetOverwolfGameInfo";
import useGetOverwolfGameEventsDisabled from "@/hooks/useGetOverwolfGameEventsDisabled";
import classNames from "classnames";
import {capitalizeFirstLetter} from "@/utils/formatString/capitalizeFirstLetter";


export default function OverwolfGameStatusChip() {
    const gameInfo = useSelector((store: IStore) => store.metaStore.overwolfGameInfo);
    const { data: devourGameInfo } = useGetOverwolfGameInfo(gameInfo?.gameId);
    const { gameEventsDisabled, state} = useGetOverwolfGameEventsDisabled(gameInfo?.gameId);
    const warning = devourGameInfo?.isEventSupported && gameEventsDisabled
        ? ` (quests${state === 2 ? "may be " : " "}off)` : "";

    return (
        <div
            className={classNames("overwolf-govip_rewards_tag", {
                "overwolf-govip_rewards_tag_active": gameInfo && !warning,
                "overwolf-govip_rewards_tag_warning": gameInfo && warning,
            })}
        >
            <div
                className={classNames("circle", {
                    circle_active: gameInfo && !warning,
                    circle_warning: gameInfo && warning,
                })}
            />
            {gameInfo ? `In ${capitalizeFirstLetter(gameInfo.gameTitle)} ${warning}` : "Game not detected"}
        </div>
    );
}