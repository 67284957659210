import {ChallengesApi, OWReward } from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useQuery} from "@tanstack/react-query";

async function fetchChallengeQuests(challengeId: string, onlyActive: boolean = true): Promise<Array<OWReward>> {
    return await new ChallengesApi(getConfig()).getChallengeQuests({
        id: challengeId,
        onlyActive,
    });
}

function getChallengeQuestsQuery(challengeId: string, onlyActive: boolean = true) {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    return {
        queryKey: [
            "challengeQuests",
            challengeId,
            fullToken?.id ?? "unauthenticated",
            onlyActive,
        ],
        queryFn: () => fetchChallengeQuests(challengeId, onlyActive),
        staleTime: 30 * 60000,
        enabled: !!challengeId,
    } as const;
}

export function useGetChallengeQuests(challengeId: string, onlyActive: boolean = true) {
    return useQuery(getChallengeQuestsQuery(challengeId, onlyActive));
}