import {ButtonHTMLAttributes, ReactElement} from "react";
import {isMobile} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import GoFrensLeaderboardRow from "@/components/goFrens/GoFrensLeaderboardRow";
import useThemePreference from "@/hooks/useThemePreference";
import {useNavigate} from "react-router";

interface Props {
    leaderboardType: string;
}

function GoFrensTopLeaderboardEmptyStateRows(props: Props): ReactElement {
    const navigate = useNavigate();
    const {isOnDarkMode, getThemedImageUrl} = useThemePreference();

    return (
        <div className="gofrens-top-leaderboard_empty-state_right">
            <GoFrensLeaderboardRow
                rank={1}
                profile={{
                    photo: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/gofrens-top-members-empty-state-profile.webp`),
                    name: `Your ${props.leaderboardType}!`,
                }}
                accumulatedPoints="100 XP"
                bgColor={`${isOnDarkMode ? "DM-" : ""}light-gray`}
            />
            <GoFrensLeaderboardRow
                rank={2}
                profile={{
                    photo: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/gofrens-top-members-empty-state-profile.webp`),
                    name: `Other ${props.leaderboardType}`,
                }}
                accumulatedPoints="99 XP"
                bgColor={`${isOnDarkMode ? "DM-" : ""}white`}
            />
            <GoFrensLeaderboardRow
                rank={3}
                profile={{
                    photo: getThemedImageUrl(`${import.meta.env.VITE_CDN_URL}/images/gofrens-top-members-empty-state-profile.webp`),
                    name: `Other ${props.leaderboardType}`,
                }}
                accumulatedPoints="98 XP"
                bgColor={`${isOnDarkMode ? "DM-" : ""}light-gray`}
            />
            {isMobile &&
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    size="normal"
                    color={isOnDarkMode ? "purple" : "purple-blue-gradient"}
                    onClick={() => navigate("/go-vip-dashboard")}
                >
                        Start Earning XP
                </FrameButton>
            }
        </div>
    );
}

export default GoFrensTopLeaderboardEmptyStateRows;
