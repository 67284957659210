/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    EVMChain,
    EVMChainFromJSON,
    EVMChainFromJSONTyped,
    EVMChainToJSON,
    NftType,
    NftTypeFromJSON,
    NftTypeFromJSONTyped,
    NftTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Used to save a NFT mint transaction.
 * @export
 * @interface NftMintTransaction
 */
export interface NftMintTransaction {
    /**
     * 
     * @type {string}
     * @memberof NftMintTransaction
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NftMintTransaction
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof NftMintTransaction
     */
    updatedAt: number;
    /**
     * 
     * @type {string}
     * @memberof NftMintTransaction
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof NftMintTransaction
     */
    nftTracker?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NftMintTransaction
     */
    isComplete: boolean;
    /**
     * 
     * @type {string}
     * @memberof NftMintTransaction
     */
    contractAddress?: string;
    /**
     * 
     * @type {object}
     * @memberof NftMintTransaction
     */
    error?: object;
    /**
     * 
     * @type {string}
     * @memberof NftMintTransaction
     */
    walletAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof NftMintTransaction
     */
    transactionHash?: string;
    /**
     * 
     * @type {number}
     * @memberof NftMintTransaction
     */
    userLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof NftMintTransaction
     */
    nftId?: number;
    /**
     * 
     * @type {object}
     * @memberof NftMintTransaction
     */
    receipt?: object;
    /**
     * 
     * @type {NftType}
     * @memberof NftMintTransaction
     */
    type: NftType;
    /**
     * 
     * @type {EVMChain}
     * @memberof NftMintTransaction
     */
    chain: EVMChain;
}

export function NftMintTransactionFromJSON(json: any): NftMintTransaction {
    return NftMintTransactionFromJSONTyped(json, false);
}

export function NftMintTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftMintTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'user': json['user'],
        'nftTracker': !exists(json, 'nftTracker') ? undefined : json['nftTracker'],
        'isComplete': json['isComplete'],
        'contractAddress': !exists(json, 'contractAddress') ? undefined : json['contractAddress'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'walletAddress': !exists(json, 'walletAddress') ? undefined : json['walletAddress'],
        'transactionHash': !exists(json, 'transactionHash') ? undefined : json['transactionHash'],
        'userLevel': !exists(json, 'userLevel') ? undefined : json['userLevel'],
        'nftId': !exists(json, 'nftId') ? undefined : json['nftId'],
        'receipt': !exists(json, 'receipt') ? undefined : json['receipt'],
        'type': NftTypeFromJSON(json['type']),
        'chain': EVMChainFromJSON(json['chain']),
    };
}

export function NftMintTransactionToJSON(value?: NftMintTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'user': value.user,
        'nftTracker': value.nftTracker,
        'isComplete': value.isComplete,
        'contractAddress': value.contractAddress,
        'error': value.error,
        'walletAddress': value.walletAddress,
        'transactionHash': value.transactionHash,
        'userLevel': value.userLevel,
        'nftId': value.nftId,
        'receipt': value.receipt,
        'type': NftTypeToJSON(value.type),
        'chain': EVMChainToJSON(value.chain),
    };
}


