/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetExistingUserResponse
 */
export interface GetExistingUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetExistingUserResponse
     */
    _exists?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExistingUserResponse
     */
    disabled?: boolean;
}

export function GetExistingUserResponseFromJSON(json: any): GetExistingUserResponse {
    return GetExistingUserResponseFromJSONTyped(json, false);
}

export function GetExistingUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExistingUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': !exists(json, 'exists') ? undefined : json['exists'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function GetExistingUserResponseToJSON(value?: GetExistingUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
        'disabled': value.disabled,
    };
}


