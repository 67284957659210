import {ReactElement, useEffect} from "react";
import ReferralCodeGeneration from "../components/ReferralCodeGeneration";
import {AiFillCheckCircle} from "react-icons/ai";
import useWindowSize from "../hooks/useWindowSize";
import {useNavigate} from "react-router";
import {useGate} from "statsig-react";

function MobileReferAFriendPage(): ReactElement {
    const {value: dpayStatus} = useGate(import.meta.env.VITE_TOKEN_STATSIG_STATUS);
    const navigate = useNavigate();
    const [
        windowWidth,
    ] = useWindowSize();

    useEffect(() => {
        if (windowWidth > 768) {
            navigate("/account/#referafriend");
        }

    }, [windowWidth]);

    return (
        <div className="mobile-refer-a-friend">
            <div className="account-page_referrals">
                <h1>Refer-A-Friend</h1>

                <div className="mobile-refer-a-friend_info-container">
                    <img
                        src={`${import.meta.env.VITE_CDN_URL}/images/referafriend.webp`}
                        alt="Refer a Friend Graphic"
                        className="mobile-refer-a-friend_info-container_graphic"
                    />
                    <p className="mobile-refer-a-friend_info-container_text">
												Give $30 in discounts to your friends
                        {dpayStatus ? `, and get $20 in ${import.meta.env.VITE_TOKEN_NAME} ` : " "}
												when they place their first order!
                    </p>
                </div>

                <div className="account-page_referrals_copy-container">
                    <AiFillCheckCircle
                        className="sign-up_content_referral-link-container_header_icon"/>
                    <p className="mobile-refer-a-friend_promo-text">
												Grab your referral code or link below to share with friends to earn!
                    </p>
                </div>

                <p className="referral-code-generation_input-container_text">
										*First order must be over $15 to qualify
                </p>
                <ReferralCodeGeneration/>
            </div>

        </div>
    );
}

export default MobileReferAFriendPage;
