/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeFromJSONTyped,
    OWRewardTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OWQuestReward
 */
export interface OWQuestReward {
    /**
     * 
     * @type {OWRewardType}
     * @memberof OWQuestReward
     */
    rewardType: OWRewardType;
    /**
     * 
     * @type {number}
     * @memberof OWQuestReward
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof OWQuestReward
     */
    nftTrackerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OWQuestReward
     */
    nftImageUrl?: string;
}

export function OWQuestRewardFromJSON(json: any): OWQuestReward {
    return OWQuestRewardFromJSONTyped(json, false);
}

export function OWQuestRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWQuestReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rewardType': OWRewardTypeFromJSON(json['rewardType']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'nftTrackerId': !exists(json, 'nftTrackerId') ? undefined : json['nftTrackerId'],
        'nftImageUrl': !exists(json, 'nftImageUrl') ? undefined : json['nftImageUrl'],
    };
}

export function OWQuestRewardToJSON(value?: OWQuestReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rewardType': OWRewardTypeToJSON(value.rewardType),
        'amount': value.amount,
        'nftTrackerId': value.nftTrackerId,
        'nftImageUrl': value.nftImageUrl,
    };
}


