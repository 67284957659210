import AssetImage from "@/components/AssetImage";
import Skeleton from "@/components/skeletons/Skeleton";
import { useAsset } from "@/hooks/useAsset";
import { ChallengeInfoResponse } from "@devour/client";
import {FC, ReactElement, useState} from "react";
import { isMobile } from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {isColorDarkOrLight} from "@/utils/isColorDarkOrLight";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {toggleLoginOpen} from "@/redux/meta/metaActions";
import {useGetOverwolfSummary} from "@/hooks/useGetOverwolfSummary";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import { ChallengeAcceptModal } from "./ChallengeAcceptModal";
import {DateTime} from "luxon";
import { useGetChallenge } from "@/hooks/challenges/useGetChallenge";
import OverwolfGameStatusChip from "@/pages/overwolf/components/OverwolfGameStatusChip.tsx";

interface Props {
    challenge: ChallengeInfoResponse | undefined;
    toggleBrandLogin?: (showModal: boolean) => void;
}
export const BrandChallengeCard: FC<Props> = ({challenge, toggleBrandLogin}) => {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const challengeImage = isMobile ? (challenge?.backgroundImageSmall || challenge?.backgroundImageLarge) : challenge?.backgroundImageLarge;
    const { refetch: refetchChallenge } = useGetChallenge(challenge?.id);
    const { data } = useAsset((challenge ? challengeImage : "") as any);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const {data: overwolfSummary} = useGetOverwolfSummary();
    const { isOnOverwolf } = useOverwolfInterop();

    if (!challenge) {
        return <Skeleton height={210} width="100%" />;
    }

    function getButton(): ReactElement {
        let label = "Join Challenge";
        let onClick = () => {
            setShowAcceptModal(true);
        }; // TODO: toggle modal
        if (!fullToken) {
            label = "Login / Sign-Up";
            onClick = () => toggleBrandLogin ? toggleBrandLogin(true) : dispatch(toggleLoginOpen(true));
        } else if (!overwolfSummary?.isDevourPlayUser && !isOnOverwolf) {
            label = "Download DevourPlay";
            onClick = () => window.open("https://www.overwolf.com/app/Devour-DevourPlay", "_blank");
        }

        const now = DateTime.now().setZone("America/New_York");
        if (challenge.isAccepted || challenge.hasEnded || now.toMillis() >= challenge.end) {
            return;
        }

        return <FrameButton
            size="normal"
            className={classNames("brand-challenge_card_content_button", isColorDarkOrLight(challenge.brandColor))}
            forwardProps={{
                style: {backgroundColor: challenge.brandColor},
            }}
            onClick={onClick}
        >
            {label}
        </FrameButton>;
    }
    return (
        <>
            <ChallengeAcceptModal challenge={challenge} isVisible={showAcceptModal} onAccepted={refetchChallenge} onClose={() => setShowAcceptModal(false)} />
            <OverwolfGameStatusChip />
            <div className="brand-challenge_card" >
                {data?.cdnUrl && <img src={data.cdnUrl} className="brand-challenge_card_background" alt="" />}
                <div className="brand-challenge_card_content">
                    <div className="brand-challenge_card_content_info">
                        <AssetImage assetId={challenge.brandLogo as any} alt="brand-logo" />
                        <h5>{challenge.name}</h5>
                        <span>{challenge.description}</span>
                    </div>
                    {getButton()}
                </div>
            </div>
        </>
    );
};