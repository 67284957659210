import { IStore } from "@/redux/defaultStore";
import { setPreferredTheme } from "@/redux/meta/metaActions";
import { ThemePreference } from "@/types/Theme";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useLocation} from "react-router";

// Define the routes where the theme should not be active
const themeExcludedRoutes: string[] = [
    "/brands",
];

const useThemePreference = () => {
    const themePreference = useSelector((store: IStore) => store.metaStore.themePreference);
    const dispatch = useDispatch();
    const location = useLocation();

    // Determine if the user is on dark mode. if the theme is set to auto, it will return true if the browser is on dark mode.
    const isOnDarkMode = useMemo(() => {
        if (themeExcludedRoutes.find(route => location.pathname.includes(route))) {
            return false;
        }

        if (themePreference === ThemePreference.AUTO) {
            return window.matchMedia("(prefers-color-scheme: dark)").matches;
        }
        return themePreference === ThemePreference.DARK;
    }, [themePreference]);

    /**
     * Returns the themed image url based on the current theme.
     * @param imageUrl
     */
    const getThemedImageUrl = (imageUrl: string) => {
        const lastSlashIndex = imageUrl.lastIndexOf("/");
        if (lastSlashIndex !== -1) {
            const base = imageUrl.substring(0, lastSlashIndex + 1);
            const fileName = imageUrl.substring(lastSlashIndex + 1);
            const themedFileName = `${isOnDarkMode
                ? "DM-"
                : ""}${fileName}`;
            return base + themedFileName;
        }
        return imageUrl;
    };

    /**
     * Applies the specified theme and saves it to the redux store.
     * If the specified theme is automatic, it will use the browser's
     * pefers-color-scheme to determine the theme.
     * @param theme
     */
    const setTheme = (theme: ThemePreference, persistOnStore = true) => {
        let newTheme = theme;
        if (theme == ThemePreference.AUTO) {
            newTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
                ? ThemePreference.DARK
                : ThemePreference.LIGHT;
        }
        document.documentElement.setAttribute("data-theme", newTheme);
        if (persistOnStore) {
            dispatch(setPreferredTheme(theme));
        }
    };
    return {setTheme,
        isOnDarkMode,

        /**
         * Returns the themed image url based on the current theme.
         * @param imageUrl
         */
        getThemedImageUrl};

};

export default useThemePreference;