import React, {
    ButtonHTMLAttributes,
    ChangeEvent,
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    GetMenuResponse,
    MenuCategory,
    MenuItem,
    MenuOrderItem,
    ValidateDeliveryDistanceResponse,
} from "@devour/client";
import {useParams} from "react-router";
import MenuItemCard from "./MenuItemCard";
import {RestaurantContext} from "../context/RestaurantContext";
import RestaurantMenusJumper from "@/pages/restaurants/components/RestaurantMenusJumper";
import {useRestaurantPreviousOrderedItems} from "@/hooks/useRestaurantPrevouslyOrderedItems";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import RestaurantMenusOrderAgain from "@/pages/restaurants/components/RestaurantMenusOrderAgain";
import {isDesktop, isTablet, isMobile} from "react-device-detect";
import SearchInput from "@/components/inputs/SearchInput";
import classNames from "classnames";
import FrameButton from "@/components/buttons/FrameButton";
import {FaChevronLeft, FaShoppingCart} from "react-icons/fa";
import {useRestaurant} from "@/hooks/useRestaurant";
import { CategoryCarousel } from "./CategoryCarousel/CategoryCarousel";
import { useSearchParams } from "react-router-dom";
import { ConvenienceMenuItemCard } from "./ConvenienceMenuItemCard";
import Divider from "@/components/Divider";


interface Props {
    validateDeliveryDistanceResponse?: ValidateDeliveryDistanceResponse;
    setMobileSearchMode: Dispatch<SetStateAction<boolean>>;
    restaurantMenu: GetMenuResponse;
    toggleShowCartPanel: () => void;
    updateMenuOrderItemManager: (key: MenuOrderItem[]) => void;
    menuOrderItemsManager: { [key: string]: MenuOrderItem };
}

function ConvenienceMenusBrowse(props: Props): ReactElement {

    const {restaurantId, isDigitalStore} = useContext(RestaurantContext);
    const {placeId} = useParams<{ placeId: string; }>();
    const {data: restaurant} = useRestaurant(restaurantId, placeId);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const isSidebarModalActive = useSelector((store: IStore) => store.metaStore.sidebarModalActive);
    const queryRestaurantPreviousOrderedItems = useRestaurantPreviousOrderedItems(restaurantId, fullToken);

    const [
        searchValue,
        setSearchValue,
    ] = useState("");


    const menuCategoriesRoot = useMemo(() => {
        return props.restaurantMenu?.menus?.filter((category) => category.isEnabled);
    }, [props.restaurantMenu?.menus]);

    const filteredCategories = useMemo(() => {
        if (!menuCategoriesRoot) {
            return [];
        }
        return getFilteredCategories(menuCategoriesRoot);
    }, [menuCategoriesRoot, searchValue]);


    function getFilteredCategories(_sortedRootCats: Array<MenuCategory>): Array<MenuCategory> {
        if (!_sortedRootCats) {
            return [];
        }
        return _sortedRootCats.reduce((acc: Array<MenuCategory>, category) => {
            const foundMenuItems = category.menuItems?.filter(item => filterItem(item, searchValue));

            if (foundMenuItems.length > 0) {
                acc.push(category);
            }

            if (category.menuSubcategories.length > 0) {
                acc = acc.concat(getFilteredCategories(category.menuSubcategories));
            }

            return acc;
        }, []);
    }

    const [
        onSticky,
        setOnSticky,
    ] = useState<boolean>(false);

    const jumperRef = useRef(null);

    const sumQuantity: number = Object.keys(props.menuOrderItemsManager || {})
        .map(k => props.menuOrderItemsManager[k].quantity)
        .reduce((acc, curr) => acc + curr, 0) || 0;


    // Check if jumper is sticky or not to apply classes conditionally
    useEffect(() => {
        const handleScroll = () => {
            if (isDesktop || isTablet) {
                const divTop = jumperRef.current?.getBoundingClientRect()?.top;
                if (divTop <= 0) {
                    setOnSticky(true);
                } else {
                    setOnSticky(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function findLikelyTallestItem(items) {
        let maxEstimate = -1;
        let likelyTallest = null;
        items.forEach(item => {
            const estimated = item.name.length;
            if (estimated > maxEstimate) {
                maxEstimate = estimated;
                likelyTallest = item;
            }
        });
        return likelyTallest;
    }

    const renderConvencienceMenuItems = useCallback((menuItems: Array<MenuItem> = []): Array<JSX.Element> => {
        return menuItems
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .filter((menuItem) => filterItem(menuItem, searchValue))
            .map((menuItem) =>
                <ConvenienceMenuItemCard
                    restaurantMenu={props.restaurantMenu}
                    placeId={placeId}
                    menuItem={menuItem}
                    updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                    menuOrderItemsManager={props.menuOrderItemsManager}
                />);
    }, [props.menuOrderItemsManager, props.restaurantMenu, searchValue]);


    const itemMap = useMemo(() => {
        return filteredCategories.reduce((acc, category) => {
            const largestItem = findLikelyTallestItem(category.menuItems.filter((menuItem) => filterItem(menuItem, searchValue)));
            const menuItemsWithoutLargest = category.menuItems.filter((menuItem) => filterItem(menuItem, searchValue)).filter(item => item.id !== largestItem.id);
            const menuItems = [...renderConvencienceMenuItems([largestItem]), ...renderConvencienceMenuItems(menuItemsWithoutLargest)];
            acc[category.id] = menuItems;
            return acc;
        }, {});
    }, [filteredCategories, searchValue]);

    const renderConvenienceCategory = useCallback((category: MenuCategory) => {
        const menuItems = itemMap[category.id];
        return (
            <div className={classNames("restaurant-page_section-margin", "convenience-menu_carousel-margin")} id={`menu-category-${category.id}`} key={`menu-category-${category.id}`}>
                <CategoryCarousel categoryData={category} viewAllHref={`?categoryId=${category.id}`} items={menuItems}/>
            </div>
        );
    }, [renderConvencienceMenuItems, searchValue]);

    function filterItem(item: MenuItem, search: string): boolean {
        if (!item.isEnabled) {
            return false;
        }
        return item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
            item.description?.toLowerCase()?.includes(search.toLowerCase());
    }

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    function clearSearch(): void {
        setSearchValue("");
    }

    if (!restaurant) {
        return null;
    }

    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get("categoryId");
    const selectedCategory = categoryId ? filteredCategories?.find(category => category.id === categoryId) : null;
    if (selectedCategory) {
        return (
            <>
                <div id="modal-portal"/>
                <div className={classNames("restaurant-page_section-margin", "convenience-menu_category-page")}>
                    {isDesktop
                        ? <div
                            ref={jumperRef}
                            className={classNames(
                                "restaurant-menus-browse_jumper-search-container",
                                {
                                    "restaurant-menus-browse_jumper-search-container_toggle-sticky": !isSidebarModalActive, // stop sticky from displaying on top of sidebar modals
                                    "restaurant-menus-browse_jumper-sticky": onSticky, // bottom border spans page width when sticky
                                },
                            )}>
                            <div className="convenience-menu_category-page_header">
                                <button className="convenience-menu_category-page_category-name" onClick={() => window.history.back()}>
                                    <FaChevronLeft/>
                                    {selectedCategory.name}
                                </button>
                                <div className="convenience-menu_category-page_header_search">
                                    <div style={{paddingRight: "0px !important"}} className="restaurant-menus-browse_jumper-search-container_input">
                                        <SearchInput
                                            placeholder={isDigitalStore ? "Search an item" : "Search restaurant menu"}
                                            value={searchValue}
                                            onChange={handleSearchChange}
                                            maxLength={15}
                                            onClear={clearSearch}
                                            className="restaurant-menus-browse_jumper-search-container_input"
                                        />
                                    </div>
                                    {onSticky &&
                                        <div style={{marginLeft: "0.6rem"}}>
                                            <FrameButton
                                                <ButtonHTMLAttributes<HTMLButtonElement>>
                                                onClick={props.toggleShowCartPanel}
                                                color="purple"
                                                size="narrow"
                                                leftIcon={FaShoppingCart}
                                                forwardProps={{type: "button"}}
                                            >
                                                {sumQuantity}
                                            </FrameButton>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        : <div
                            className={classNames(
                                "restaurant-menus-jumper",
                                {
                                    "restaurant-menus-jumper_toggle-sticky": !isSidebarModalActive, // stop sticky from displaying on top of sidebar modals
                                    "restaurant-menus-browse_jumper-sticky": onSticky, // bottom border spans page width when sticky
                                },
                                "convenience-menu_category-page_header_mobile",
                            )}
                        >
                            <button className="convenience-menu_category-page_category-name" onClick={() => window.history.back()}>
                                <FaChevronLeft/>
                                {selectedCategory.name}
                            </button>
                            <div style={{display: "flex"}} className="restaurant-menus-browse_jumper-search-container_input">
                                <SearchInput
                                    placeholder="Search restaurant menu"
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                    maxLength={15}
                                    onClear={clearSearch}
                                    className="restaurant-menus-browse_jumper-search-container_input"
                                />
                            </div>
                        </div>
                    }
                    <div className={classNames("convenience-menu_category-page_item-grid")}>
                        {
                            selectedCategory.menuItems
                                .sort((a, b) => a.sortOrder - b.sortOrder)
                                .filter((menuItem) => filterItem(menuItem, searchValue))
                                .map((menuItem) =>
                                    <div style={{height: "100%"}}>
                                        <ConvenienceMenuItemCard
                                            restaurantMenu={props.restaurantMenu}
                                            placeId={placeId}
                                            menuItem={menuItem}
                                            updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                                            menuOrderItemsManager={props.menuOrderItemsManager}
                                        />
                                    </div>)
                        }
                    </div>
                </div>
            </>

        );
    }

    return (
        <>
            <div id="modal-portal"/>
            <div className="restaurant-menus-browse" style={{position: "relative"}}>
                <div className="restaurant-menus-browse_results">
                    {isDesktop
                        ? <div
                            ref={jumperRef}
                            className={classNames(
                                "restaurant-menus-browse_jumper-search-container",
                                {
                                    "restaurant-menus-browse_jumper-search-container_toggle-sticky": !isSidebarModalActive, // stop sticky from displaying on top of sidebar modals
                                    "restaurant-menus-browse_jumper-sticky": onSticky, // bottom border spans page width when sticky
                                },
                            )}>
                            <div>
                                <RestaurantMenusJumper
                                    searchValue={searchValue}
                                    setMobileSearchMode={props.setMobileSearchMode}
                                    restaurantMenu={props.restaurantMenu}
                                    onSticky={onSticky}
                                />
                                <div className="restaurant-menus-browse_jumper-search-container_input">
                                    <SearchInput
                                        placeholder={isDigitalStore ? "Search an item" : "Search restaurant menu"}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                        maxLength={15}
                                        onClear={clearSearch}
                                        className="restaurant-menus-browse_jumper-search-container_input"
                                    />
                                </div>

                                {onSticky &&
                                <FrameButton
                                    <ButtonHTMLAttributes<HTMLButtonElement>>
                                    onClick={props.toggleShowCartPanel}
                                    color="purple"
                                    size="narrow"
                                    leftIcon={FaShoppingCart}
                                    forwardProps={{type: "button"}}
                                >
                                    {sumQuantity}
                                </FrameButton>
                                }
                            </div>
                        </div>
                        : <div
                            className={classNames(
                                "restaurant-menus-jumper",
                                {
                                    "restaurant-menus-jumper_toggle-sticky": !isSidebarModalActive, // stop sticky from displaying on top of sidebar modals
                                    "restaurant-menus-browse_jumper-sticky": onSticky, // bottom border spans page width when sticky
                                },
                            )}
                        >
                            {isTablet &&
                            <div className="restaurant-menus-browse_jumper-search-container_input">
                                <SearchInput
                                    placeholder="Search restaurant menu"
                                    value={searchValue}
                                    onChange={handleSearchChange}
                                    maxLength={15}
                                    onClear={clearSearch}
                                    className="restaurant-menus-browse_jumper-search-container_input"
                                />
                            </div>
                            }
                            <RestaurantMenusJumper
                                searchValue={searchValue}
                                setMobileSearchMode={props.setMobileSearchMode}
                                restaurantMenu={props.restaurantMenu}
                                onSticky={onSticky}
                            />
                        </div>
                    }
                    {queryRestaurantPreviousOrderedItems.data &&
                    <>
                        <RestaurantMenusOrderAgain
                            restaurantMenu={props.restaurantMenu}
                            searchValue={searchValue}
                            updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                            menuOrderItemsManager={props.menuOrderItemsManager}
                        />
                    </>
                    }

                    {filteredCategories.length > 0 && filteredCategories
                        .sort((a, b) => a.sortOrder - b.sortOrder)
                        .map((category) =>
                            <>
                                {renderConvenienceCategory(category)}
                                <Divider height={"0.25rem"}/>
                            </>)
                    }

                    {menuCategoriesRoot?.length === 0 &&
                    <div className="restaurant-menus-browse_category restaurant-page_section-margin">
                        <p className="restaurant-menus-browse_results_empty-message">
                            No menus are active at this current time. Please try again later.
                        </p>
                    </div>
                    }

                    {menuCategoriesRoot?.length > 0 && filteredCategories.length === 0 &&
                    <div className="restaurant-menus-browse_category restaurant-page_section-margin">
                        <p className="restaurant-menus-browse_results_empty-message">
                            No items on this menu match your current search.
                        </p>
                    </div>
                    }
                </div>
            </div>
        </>

    );
}

export default ConvenienceMenusBrowse;
