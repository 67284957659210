/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The method at which we process time logs for time quests
 * @export
 * @enum {string}
 */
export enum GuildQuestMode {
    ALLTIME = 'ALLTIME',
    DIFFDAYS = 'DIFF_DAYS',
    SINGLEDAY = 'SINGLE_DAY'
}

export function GuildQuestModeFromJSON(json: any): GuildQuestMode {
    return GuildQuestModeFromJSONTyped(json, false);
}

export function GuildQuestModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuildQuestMode {
    return json as GuildQuestMode;
}

export function GuildQuestModeToJSON(value?: GuildQuestMode | null): any {
    return value as any;
}

