/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcceptChallengeBody,
    AcceptChallengeBodyFromJSON,
    AcceptChallengeBodyToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ChallengeBody,
    ChallengeBodyFromJSON,
    ChallengeBodyToJSON,
    ChallengeInfoResponse,
    ChallengeInfoResponseFromJSON,
    ChallengeInfoResponseToJSON,
    ChallengeMembership,
    ChallengeMembershipFromJSON,
    ChallengeMembershipToJSON,
    ChallengeOnboardingBody,
    ChallengeOnboardingBodyFromJSON,
    ChallengeOnboardingBodyToJSON,
    ChallengePrizeBody,
    ChallengePrizeBodyFromJSON,
    ChallengePrizeBodyToJSON,
    ChallengeResponse,
    ChallengeResponseFromJSON,
    ChallengeResponseToJSON,
    ClaimOverwolfRewardResponse,
    ClaimOverwolfRewardResponseFromJSON,
    ClaimOverwolfRewardResponseToJSON,
    FormError,
    FormErrorFromJSON,
    FormErrorToJSON,
    GetChallengesResponse,
    GetChallengesResponseFromJSON,
    GetChallengesResponseToJSON,
    GetChallengesSort,
    GetChallengesSortFromJSON,
    GetChallengesSortToJSON,
    OWReward,
    OWRewardFromJSON,
    OWRewardToJSON,
    PrizePool,
    PrizePoolFromJSON,
    PrizePoolToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface AcceptChallengeRequest {
    id: string;
    acceptChallengeBody: AcceptChallengeBody;
}

export interface ClaimChallengeRewardRequest {
    id: string;
}

export interface ConfirmNotifyPrizeWonRequest {
    id: string;
}

export interface CreateChallengeRequest {
    challengeBody?: ChallengeBody;
}

export interface CreateChallengePrizeRequest {
    challengeId: string;
    challengePrizeBody?: ChallengePrizeBody;
}

export interface DeleteChallengePrizeRequest {
    id: string;
    challengeId: string;
}

export interface GetAllChallengePrizesWonRequest {
    newPrizesOnly?: boolean;
}

export interface GetChallengeInfoRequest {
    id: string;
}

export interface GetChallengeQuestsRequest {
    id: string;
    onlyActive?: boolean;
}

export interface GetChallengesRequest {
    userId?: string;
    search?: string;
    sort?: GetChallengesSort;
    order?: SortOrder;
    unassignedOnly?: boolean;
    brandMap?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface RemoveChallengeRequest {
    id: string;
}

export interface UpdateChallengeRequest {
    id: string;
    challengeBody?: ChallengeBody;
}

export interface UpdateChallengeOnboardingSectionRequest {
    id: string;
    challengeOnboardingBody?: ChallengeOnboardingBody;
}

export interface UpdateChallengePrizeRequest {
    id: string;
    challengeId: string;
    challengePrizeBody?: ChallengePrizeBody;
}

/**
 * ChallengesApi - interface
 * @export
 * @interface ChallengesApiInterface
 */
export interface ChallengesApiInterface {
    /**
     * Create a new challenge membership for a user when they accept a challenge
     * @param {string} id 
     * @param {AcceptChallengeBody} acceptChallengeBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    acceptChallengeRaw(requestParameters: AcceptChallengeRequest): Promise<runtime.ApiResponse<ChallengeMembership>>;

    /**
     * Create a new challenge membership for a user when they accept a challenge
     */
    acceptChallenge(requestParameters: AcceptChallengeRequest): Promise<ChallengeMembership>;

    /**
     * Claims a challenge reward.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    claimChallengeRewardRaw(requestParameters: ClaimChallengeRewardRequest): Promise<runtime.ApiResponse<ClaimOverwolfRewardResponse>>;

    /**
     * Claims a challenge reward.
     */
    claimChallengeReward(requestParameters: ClaimChallengeRewardRequest): Promise<ClaimOverwolfRewardResponse>;

    /**
     * Confirm that the user has been notified of a prize win
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    confirmNotifyPrizeWonRaw(requestParameters: ConfirmNotifyPrizeWonRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Confirm that the user has been notified of a prize win
     */
    confirmNotifyPrizeWon(requestParameters: ConfirmNotifyPrizeWonRequest): Promise<void>;

    /**
     * Create a new Challenge
     * @param {ChallengeBody} [challengeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    createChallengeRaw(requestParameters: CreateChallengeRequest): Promise<runtime.ApiResponse<ChallengeResponse>>;

    /**
     * Create a new Challenge
     */
    createChallenge(requestParameters: CreateChallengeRequest): Promise<ChallengeResponse>;

    /**
     * Create a new Challenge prize
     * @param {string} challengeId 
     * @param {ChallengePrizeBody} [challengePrizeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    createChallengePrizeRaw(requestParameters: CreateChallengePrizeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Create a new Challenge prize
     */
    createChallengePrize(requestParameters: CreateChallengePrizeRequest): Promise<void>;

    /**
     * Remove a specific prize from a challenge
     * @param {string} id 
     * @param {string} challengeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    deleteChallengePrizeRaw(requestParameters: DeleteChallengePrizeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Remove a specific prize from a challenge
     */
    deleteChallengePrize(requestParameters: DeleteChallengePrizeRequest): Promise<void>;

    /**
     * Fetch all challenge prizes won by the user
     * @param {boolean} [newPrizesOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    getAllChallengePrizesWonRaw(requestParameters: GetAllChallengePrizesWonRequest): Promise<runtime.ApiResponse<Array<PrizePool>>>;

    /**
     * Fetch all challenge prizes won by the user
     */
    getAllChallengePrizesWon(requestParameters: GetAllChallengePrizesWonRequest): Promise<Array<PrizePool>>;

    /**
     * Gets a single Challenge with all of its associated Prize Pools.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    getChallengeInfoRaw(requestParameters: GetChallengeInfoRequest): Promise<runtime.ApiResponse<ChallengeInfoResponse>>;

    /**
     * Gets a single Challenge with all of its associated Prize Pools.
     */
    getChallengeInfo(requestParameters: GetChallengeInfoRequest): Promise<ChallengeInfoResponse>;

    /**
     * Gets challenge quests for a single Challenge.
     * @param {string} id 
     * @param {boolean} [onlyActive] If true, returns only active quests. If false or not provided, returns all quests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    getChallengeQuestsRaw(requestParameters: GetChallengeQuestsRequest): Promise<runtime.ApiResponse<Array<OWReward>>>;

    /**
     * Gets challenge quests for a single Challenge.
     */
    getChallengeQuests(requestParameters: GetChallengeQuestsRequest): Promise<Array<OWReward>>;

    /**
     * Get list of all challenges with user acceptance status
     * @param {string} [userId] 
     * @param {string} [search] 
     * @param {GetChallengesSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {boolean} [unassignedOnly] 
     * @param {string} [brandMap] The slug of the brand map to filter challenges by
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    getChallengesRaw(requestParameters: GetChallengesRequest): Promise<runtime.ApiResponse<GetChallengesResponse>>;

    /**
     * Get list of all challenges with user acceptance status
     */
    getChallenges(requestParameters: GetChallengesRequest): Promise<GetChallengesResponse>;

    /**
     * Remove a specific challenge and any associated challengeMemberships
     * @param {string} id ID of the challenge to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    removeChallengeRaw(requestParameters: RemoveChallengeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Remove a specific challenge and any associated challengeMemberships
     */
    removeChallenge(requestParameters: RemoveChallengeRequest): Promise<void>;

    /**
     * Create or update a Challenge
     * @param {string} id 
     * @param {ChallengeBody} [challengeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    updateChallengeRaw(requestParameters: UpdateChallengeRequest): Promise<runtime.ApiResponse<ChallengeResponse>>;

    /**
     * Create or update a Challenge
     */
    updateChallenge(requestParameters: UpdateChallengeRequest): Promise<ChallengeResponse>;

    /**
     * Update a Challenge\'s onboarding section
     * @param {string} id 
     * @param {ChallengeOnboardingBody} [challengeOnboardingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    updateChallengeOnboardingSectionRaw(requestParameters: UpdateChallengeOnboardingSectionRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Update a Challenge\'s onboarding section
     */
    updateChallengeOnboardingSection(requestParameters: UpdateChallengeOnboardingSectionRequest): Promise<void>;

    /**
     * Updates a challenge prize
     * @param {string} id 
     * @param {string} challengeId 
     * @param {ChallengePrizeBody} [challengePrizeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChallengesApiInterface
     */
    updateChallengePrizeRaw(requestParameters: UpdateChallengePrizeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Updates a challenge prize
     */
    updateChallengePrize(requestParameters: UpdateChallengePrizeRequest): Promise<void>;

}

/**
 * no description
 */
export class ChallengesApi extends runtime.BaseAPI implements ChallengesApiInterface {

    /**
     * Create a new challenge membership for a user when they accept a challenge
     */
    async acceptChallengeRaw(requestParameters: AcceptChallengeRequest): Promise<runtime.ApiResponse<ChallengeMembership>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling acceptChallenge.');
        }

        if (requestParameters.acceptChallengeBody === null || requestParameters.acceptChallengeBody === undefined) {
            throw new runtime.RequiredError('acceptChallengeBody','Required parameter requestParameters.acceptChallengeBody was null or undefined when calling acceptChallenge.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptChallengeBodyToJSON(requestParameters.acceptChallengeBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChallengeMembershipFromJSON(jsonValue));
    }

    /**
     * Create a new challenge membership for a user when they accept a challenge
     */
    async acceptChallenge(requestParameters: AcceptChallengeRequest): Promise<ChallengeMembership> {
        const response = await this.acceptChallengeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Claims a challenge reward.
     */
    async claimChallengeRewardRaw(requestParameters: ClaimChallengeRewardRequest): Promise<runtime.ApiResponse<ClaimOverwolfRewardResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling claimChallengeReward.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{id}/claim-reward`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClaimOverwolfRewardResponseFromJSON(jsonValue));
    }

    /**
     * Claims a challenge reward.
     */
    async claimChallengeReward(requestParameters: ClaimChallengeRewardRequest): Promise<ClaimOverwolfRewardResponse> {
        const response = await this.claimChallengeRewardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Confirm that the user has been notified of a prize win
     */
    async confirmNotifyPrizeWonRaw(requestParameters: ConfirmNotifyPrizeWonRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling confirmNotifyPrizeWon.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/confirm-notify-prize-win/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm that the user has been notified of a prize win
     */
    async confirmNotifyPrizeWon(requestParameters: ConfirmNotifyPrizeWonRequest): Promise<void> {
        await this.confirmNotifyPrizeWonRaw(requestParameters);
    }

    /**
     * Create a new Challenge
     */
    async createChallengeRaw(requestParameters: CreateChallengeRequest): Promise<runtime.ApiResponse<ChallengeResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChallengeBodyToJSON(requestParameters.challengeBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChallengeResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Challenge
     */
    async createChallenge(requestParameters: CreateChallengeRequest): Promise<ChallengeResponse> {
        const response = await this.createChallengeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new Challenge prize
     */
    async createChallengePrizeRaw(requestParameters: CreateChallengePrizeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.challengeId === null || requestParameters.challengeId === undefined) {
            throw new runtime.RequiredError('challengeId','Required parameter requestParameters.challengeId was null or undefined when calling createChallengePrize.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{challengeId}/prizes`.replace(`{${"challengeId"}}`, encodeURIComponent(String(requestParameters.challengeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChallengePrizeBodyToJSON(requestParameters.challengePrizeBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new Challenge prize
     */
    async createChallengePrize(requestParameters: CreateChallengePrizeRequest): Promise<void> {
        await this.createChallengePrizeRaw(requestParameters);
    }

    /**
     * Remove a specific prize from a challenge
     */
    async deleteChallengePrizeRaw(requestParameters: DeleteChallengePrizeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteChallengePrize.');
        }

        if (requestParameters.challengeId === null || requestParameters.challengeId === undefined) {
            throw new runtime.RequiredError('challengeId','Required parameter requestParameters.challengeId was null or undefined when calling deleteChallengePrize.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{challengeId}/prizes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"challengeId"}}`, encodeURIComponent(String(requestParameters.challengeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a specific prize from a challenge
     */
    async deleteChallengePrize(requestParameters: DeleteChallengePrizeRequest): Promise<void> {
        await this.deleteChallengePrizeRaw(requestParameters);
    }

    /**
     * Fetch all challenge prizes won by the user
     */
    async getAllChallengePrizesWonRaw(requestParameters: GetAllChallengePrizesWonRequest): Promise<runtime.ApiResponse<Array<PrizePool>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.newPrizesOnly !== undefined) {
            queryParameters['newPrizesOnly'] = requestParameters.newPrizesOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenge-prizes-won`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrizePoolFromJSON));
    }

    /**
     * Fetch all challenge prizes won by the user
     */
    async getAllChallengePrizesWon(requestParameters: GetAllChallengePrizesWonRequest): Promise<Array<PrizePool>> {
        const response = await this.getAllChallengePrizesWonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets a single Challenge with all of its associated Prize Pools.
     */
    async getChallengeInfoRaw(requestParameters: GetChallengeInfoRequest): Promise<runtime.ApiResponse<ChallengeInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getChallengeInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChallengeInfoResponseFromJSON(jsonValue));
    }

    /**
     * Gets a single Challenge with all of its associated Prize Pools.
     */
    async getChallengeInfo(requestParameters: GetChallengeInfoRequest): Promise<ChallengeInfoResponse> {
        const response = await this.getChallengeInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets challenge quests for a single Challenge.
     */
    async getChallengeQuestsRaw(requestParameters: GetChallengeQuestsRequest): Promise<runtime.ApiResponse<Array<OWReward>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getChallengeQuests.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onlyActive !== undefined) {
            queryParameters['onlyActive'] = requestParameters.onlyActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{id}/quests`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OWRewardFromJSON));
    }

    /**
     * Gets challenge quests for a single Challenge.
     */
    async getChallengeQuests(requestParameters: GetChallengeQuestsRequest): Promise<Array<OWReward>> {
        const response = await this.getChallengeQuestsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of all challenges with user acceptance status
     */
    async getChallengesRaw(requestParameters: GetChallengesRequest): Promise<runtime.ApiResponse<GetChallengesResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.unassignedOnly !== undefined) {
            queryParameters['unassignedOnly'] = requestParameters.unassignedOnly;
        }

        if (requestParameters.brandMap !== undefined) {
            queryParameters['brandMap'] = requestParameters.brandMap;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChallengesResponseFromJSON(jsonValue));
    }

    /**
     * Get list of all challenges with user acceptance status
     */
    async getChallenges(requestParameters: GetChallengesRequest): Promise<GetChallengesResponse> {
        const response = await this.getChallengesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Remove a specific challenge and any associated challengeMemberships
     */
    async removeChallengeRaw(requestParameters: RemoveChallengeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeChallenge.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a specific challenge and any associated challengeMemberships
     */
    async removeChallenge(requestParameters: RemoveChallengeRequest): Promise<void> {
        await this.removeChallengeRaw(requestParameters);
    }

    /**
     * Create or update a Challenge
     */
    async updateChallengeRaw(requestParameters: UpdateChallengeRequest): Promise<runtime.ApiResponse<ChallengeResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateChallenge.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChallengeBodyToJSON(requestParameters.challengeBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChallengeResponseFromJSON(jsonValue));
    }

    /**
     * Create or update a Challenge
     */
    async updateChallenge(requestParameters: UpdateChallengeRequest): Promise<ChallengeResponse> {
        const response = await this.updateChallengeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a Challenge\'s onboarding section
     */
    async updateChallengeOnboardingSectionRaw(requestParameters: UpdateChallengeOnboardingSectionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateChallengeOnboardingSection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{id}/onboarding-section`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChallengeOnboardingBodyToJSON(requestParameters.challengeOnboardingBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a Challenge\'s onboarding section
     */
    async updateChallengeOnboardingSection(requestParameters: UpdateChallengeOnboardingSectionRequest): Promise<void> {
        await this.updateChallengeOnboardingSectionRaw(requestParameters);
    }

    /**
     * Updates a challenge prize
     */
    async updateChallengePrizeRaw(requestParameters: UpdateChallengePrizeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateChallengePrize.');
        }

        if (requestParameters.challengeId === null || requestParameters.challengeId === undefined) {
            throw new runtime.RequiredError('challengeId','Required parameter requestParameters.challengeId was null or undefined when calling updateChallengePrize.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/challenges/{challengeId}/prizes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"challengeId"}}`, encodeURIComponent(String(requestParameters.challengeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChallengePrizeBodyToJSON(requestParameters.challengePrizeBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a challenge prize
     */
    async updateChallengePrize(requestParameters: UpdateChallengePrizeRequest): Promise<void> {
        await this.updateChallengePrizeRaw(requestParameters);
    }

}
