import {
    ReactElement,
    useContext,
    useState,
    MouseEvent,
    ButtonHTMLAttributes,
} from "react";
import { MenuItem, GetMenuResponse, MenuOrderItem } from "@devour/client";
import classNames from "classnames";
import FrameButton from "../../../components/buttons/FrameButton";
import MenuItemCustomizationModal from "../../../components/modals/MenuItemCustomizationModal";
import { isDesktop } from "react-device-detect";
import { RestaurantContext } from "../context/RestaurantContext";
import MenuItemCardAddButton from "./MenuItemCardAddButton";
import {
    isRestaurantAvailable,
    useRestaurantAvailability,
} from "@/hooks/useRestaurantAvailability";
import { useRestaurant } from "@/hooks/useRestaurant";
import { getUniqueIDForMenuOrderItem } from "@/utils/restaurantPageHelperFunctions";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";

interface Props {
    placeId: string;
    restaurantMenu: GetMenuResponse;
    menuItem: MenuItem;
    updateMenuOrderItemManager: (key: MenuOrderItem[]) => void;
    menuOrderItemsManager: { [key: string]: MenuOrderItem };
}

export function ConvenienceMenuItemCard(props: Props): ReactElement {
    const { restaurantId, activeMenuItemId } = useContext(RestaurantContext);
    const { data: restaurant } = useRestaurant(restaurantId, props.placeId);
    const [showCustomizationModal, setShowCustomizationModal] = useState<boolean>(activeMenuItemId === props.menuItem.id);
    const [lockNftGroupings, setLockNftGroupings] = useState<boolean>(false);
    const [hoverNftGroupings, setHoverNftGroupings] = useState<boolean>(false);
    const { data: restaurantAvailability } =
    useRestaurantAvailability(restaurant);
    const availabilityCheckFailed = !isRestaurantAvailable(restaurantAvailability);
    const isMenuOrderUpdating = useSelector((store: IStore) => store.metaStore.isMenuOrderUpdating);

    function toggleCustomizationModal(open: boolean = false): void {
        setShowCustomizationModal(open);
    }

    function onFinishedUpdatingMenuItemFromModal(menuOrderItem: MenuOrderItem): void {
        const id: string = getUniqueIDForMenuOrderItem(menuOrderItem);
        const originalItem = props.menuOrderItemsManager
            ? props.menuOrderItemsManager[id]
            : undefined;
        if (originalItem) {
            // This item already exists, update its quantity instead
            props.updateMenuOrderItemManager([
                {
                    ...originalItem,
                    quantity: originalItem.quantity + menuOrderItem.quantity,
                },
            ]);
        } else {
            props.updateMenuOrderItemManager([menuOrderItem]);
        }
        toggleCustomizationModal();
    }

    function toggleLockNftGroupings(event: MouseEvent<HTMLButtonElement>): void {
        setLockNftGroupings((s) => !s);
        event.stopPropagation();
    }

    function cardOnClick(event: MouseEvent<HTMLDivElement>): void {
        event?.stopPropagation();
        if (availabilityCheckFailed || isMenuOrderUpdating) {
            return;
        }
        toggleCustomizationModal(true);
    }

    function toggleHoverNftGroupings(): void {
        if (isDesktop) {
            setHoverNftGroupings((s) => !s);
        }
    }

    if (!props.menuItem || !props.restaurantMenu || !restaurant) {
        return null;
    }

    return (
        <>
            <MenuItemCustomizationModal
                isOpen={showCustomizationModal}
                placeId={props.placeId}
                restaurantId={restaurant.id}
                restaurantMenu={props.restaurantMenu}
                menuItem={props.menuItem}
                onClose={() => toggleCustomizationModal()}
                onDone={onFinishedUpdatingMenuItemFromModal}
                shouldUsePortal={true}
            />

            <div
                onClick={cardOnClick}
                className={classNames("convenience-menu-item-card", {
                    "is-enabled": restaurant.isOpen && props.menuItem.isEnabled,
                    "is-lock-nft": lockNftGroupings,
                    "is-hover-nft": hoverNftGroupings,
                })}
                key={props.menuItem.id}
            >
                <div style={{ position: "relative", minHeight: "50%" }}>
                    <div className="convenience-menu-item-card_image">
                        <div className="convenience-menu-item-card_image_inner">
                            <img
                                src={props.menuItem?.images?.[0]?.url}
                                alt={props.menuItem?.name}
                                style={{ marginBottom: "0.6rem"}}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = `${
                                        import.meta.env.VITE_CDN_URL
                                    }/images/empty.png`;
                                }}
                            />
                        </div>
                    </div>
                    <div className="convenience-menu-item-card_add-button">
                        <MenuItemCardAddButton
                            menuItem={props.menuItem}
                            setShowCustomizationModal={setShowCustomizationModal}
                            placeId={props.placeId}
                            updateMenuOrderItemManager={props.updateMenuOrderItemManager}
                            menuOrderItemsManager={props.menuOrderItemsManager}
                        />
                    </div>
                </div>
                <div className="convenience-menu-item-card_price">
                    ${props.menuItem.price.toFixed(2)}
                </div>
                <div
                    title={props.menuItem.name}
                    className="convenience-menu-item-card_name"
                >
                    {props.menuItem.name}
                </div>
                {props.menuItem.nftGroupings?.length > 0 &&
          <div className="menu-item-card_secret-item">
              <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                  onClick={toggleLockNftGroupings}
                  color="purple"
                  size="pill"
                  forwardProps={{
                      onMouseEnter: toggleHoverNftGroupings,
                      onMouseLeave: toggleHoverNftGroupings,
                  }}
              >
                  Secret
              </FrameButton>
          </div>
                }
            </div>
        </>
    );
}
