import {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Address,
    BrandMapColorTheme,
    BrandPageType,
    BrandsApi,
    MenuOrder,
    MenuOrderItem,
    UtilsApi,
} from "@devour/client";
import {useParams} from "react-router";
import classNames from "classnames";
import RestaurantMapHeader from "@/components/brands/RestaurantMapHeader";
import DevourCart from "@/components/sideBar/DevourCart";
import {useGetNftOwnershipsForUser} from "@/hooks/useGetNftOwnershipsForUser";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import BrandLandingFooter from "@/components/brands/BrandLandingFooter";
import Toast from "@/components/Toast";
import {FaCheckCircle} from "react-icons/fa";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import {IStore} from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";
import {addError, decrementLoading, incrementLoading, updateLastBrandMap} from "@/redux/meta/metaActions";
import RestaurantMapTopBar from "@/components/RestaurantMapTopBar";
import {RestaurantContext} from "../restaurants/context/RestaurantContext";
import BrandMapPromotions from "@/components/brands/BrandMapPromotions";
import BrandMapLocations from "@/components/brands/BrandMapLocations";
import BrandMapProgressBar from "@/components/brands/BrandMapProgressBar";
import BrandLandingAdvertisementBlocks from "@/components/brands/BrandLandingAdvertisementBlocks";
import BrandEmailModal from "@/components/brands/BrandEmailModal";
import BrandLandingRestaurantMenu from "@/components/brands/BrandLandingRestaurantMenu";
import BrandLandingRestaurantMenuCheckout from "@/components/brands/BrandLandingRestaurantMenuCheckout";
import MenuOrderFailPage from "@/pages/menu-orders/MenuOrderFailPage";
import BrandMenuOrderSuccessPage from "@/components/brands/BrandMenuOrderSuccessPage";
import FrameOneSidebar from "@/components/sideBar/FrameOneSidebar";
import DevourStickyFooter from "@/components/sideBar/DevourStickyFooter";
import BrandSkeleton from "@/components/brands/BrandSkeleton";
import {useUpdateMenuOrderItems} from "@/hooks/menuOrder/useUpdateMenuOrderItems";
import {useCreateMenuOrderItems} from "@/hooks/menuOrder/useCreateMenuOrderItems";
import _ from "lodash";
import {useQueryClient} from "@tanstack/react-query";
import {useRestaurant} from "@/hooks/useRestaurant";
import {getUniqueIDForMenuOrderItem} from "@/utils/restaurantPageHelperFunctions";
import {LoadScript} from "@react-google-maps/api";
import MenuOrderErrorModal from "../restaurants/components/MenuOrderErrorModal";
import {useGetChallenge} from "@/hooks/challenges/useGetChallenge";
import {BrandChallengeCard} from "@/pages/challenge/BrandChallengeCard";
import ChallengeQuestsAndPrizes from "@/pages/challenge/ChallengeQuestsAndPrizes";
import {BrandMapEmbeddableHtml} from "@/components/brands/BrandMapEmbeddableHtml";
import {BrandChallengeHeading} from "@/pages/challenge/BrandChallengeHeading";
import BrandChallengeOnboarding from "@/pages/challenge/BrandChallengeOnboarding";
import BrandChallengeFAQs from "@/pages/challenge/BrandChallengeFAQs";

function RestaurantMapLandingPage(): ReactElement {
    const dispatch = useDispatch();
    const {slug} = useParams<{ slug: string }>();

    const urlParams = new URLSearchParams(window.location.search);
    const menuOrderIdParam = urlParams.get("orderId"); // menu order ID

    const menuOrders = useSelector((store: IStore) => store.metaStore.menuOrders);
    const menuOrdersRefresh = useSelector((store: IStore) => store.metaStore.menuOrdersRefresh);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const [selectedRestaurant, setSelectedRestaurant] = useState<string>(undefined);
    const [selectedPromo, setSelectedPromo] = useState<string>(undefined);
    const [brandMapState, setBrandMapState] = useState<BrandMapStates>(BrandMapStates.NOT_INITIATED);
    const [showCartPanel, setShowCartPanel] = useState(false);
    const [showMobileDrawer, setShowMobileDrawer] = useState(false);

    const [showPreAutoMintToast, setShowPreAutoMintToast] = useState<boolean>(false);
    const [showAutoMintToast, setShowAutoMintToast] = useState<boolean>(false);

    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);

    // Local state to store the menu order id. Needed since we need access to it after placing the menu order (e.g: for OrderSuccess page)
    const [savedMenuOrderId, setSavedMenuOrderId] = useState<string>(undefined);

    const existingMenuOrderId = menuOrders ? menuOrders[selectedRestaurant] : undefined;

    const {data: brandMap} = useGetBrandMap(slug);
    const {data: challenge} = useGetChallenge(brandMap?.challenge);
    const {data: menuOrder, refetch: refetchMenuOrder} = useMenuOrder(existingMenuOrderId);
    const {data: userData} = useGetUserProfile(fullToken);
    const {data: nftOwnershipData,
        refetch: refetchNftOwnerships,
        isFetched: isNftOwnershipDataFetched} = useGetNftOwnershipsForUser(fullToken);

    const availableSecondaryPromos = brandMap?.promos?.filter(promo => promo.isBrandMapValid) ?? [];
    const isNoPromosAvailable = brandMap && !brandMap.mainPromo && !availableSecondaryPromos.length;

    const locationRef = useRef(null);
    const automintCalled = useRef<boolean>(false);

    /**
     *  The following is copied from RestaurantPage.tsx
     */
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);
    const queryClient = useQueryClient();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {
        mutateAsync: updateMenuOrderItems,
    } = useUpdateMenuOrderItems({
        menuOrder: menuOrder,
        menuOrderErrorModal: true,
        backgroundCallback: true,
    });
    const {
        mutateAsync: createMenuOrderItems,
    } = useCreateMenuOrderItems({
        restaurantId: selectedRestaurant,
    });
    const [
        menuOrderItemsManager,
        setMenuOrderItemsManager,
    ] = useState<{ [key: string]: MenuOrderItem }>();

    const sumQuantity: number = Object.keys(menuOrderItemsManager || {})
        .map(k => menuOrderItemsManager[k].quantity).
        reduce((acc, curr) => acc + curr, 0) || 0;

    const debounceLoadData = useCallback(_.debounce((manager: { [key: string]: MenuOrderItem }) => {
        if (!menuOrder) {
            return createMenuOrderWithManager(manager);
        }
        return updateMenuOrderItems({
            orderItems: Object.keys(manager).map(k => manager[k]),
        });

    }, 250), [menuOrder]);

    const defaultAddress = currentUser?.user.addresses?.find(address => address.isDefault);

    useEffect(() => {
        if (menuOrder) {
            setMenuOrderItemsManager(menuOrder.orderItems.reduce((acc, curr) => {
                const id: string = getUniqueIDForMenuOrderItem(curr);
                acc[id] = curr;
                return acc;
            }, {}));
        } else {
            setMenuOrderItemsManager({});
        }
    }, [menuOrder?.id]);

    async function createMenuOrderWithManager(manager: { [key: string]: MenuOrderItem }): Promise<MenuOrder> {
        dispatch(incrementLoading());
        try {
            const findAddressInBook = currentUser?.user?.addresses.find(a => a.placeId === lastSearchedPlaceId);
            let address: Address;
            if (lastSearchedPlaceId) {
                address = await new UtilsApi().getAddressFromPlaceId({
                    placeId: lastSearchedPlaceId,
                });
            } else {
                const {data: restaurantData} = useRestaurant(selectedRestaurant, lastSearchedPlaceId);
                address = restaurantData?.address;
            }
            const result = await createMenuOrderItems({
                address: address,
                orderItems: Object.keys(manager).map(k => manager[k]),
                deliveryNotes: findAddressInBook?.deliveryInstructions,
                deliveryHandoffInstructions: findAddressInBook?.handoffInstructions,
            });
            return result;
        } catch (e) {
            dispatch(await addError(e));
            await queryClient.invalidateQueries({queryKey: ["restaurant-menu", selectedRestaurant]});
        } finally {
            dispatch(decrementLoading());
        }
    }

    function handleUpdateMenuOrderItemsManager(items: MenuOrderItem[], clearCart: boolean = false): void {
        if (clearCart) {
            setMenuOrderItemsManager({});
            void debounceLoadData({});
            return;
        }

        const itemManager = menuOrderItemsManager || {};
        for (const item of items) {
            const id: string = getUniqueIDForMenuOrderItem(item);
            if (item.quantity < 0) {
                // delete the item from the manager
                delete itemManager[id];
            } else {
                // Update the item in the manager otherwise
                itemManager[id] = item;
            }
        }
        setMenuOrderItemsManager(itemManager);
        void debounceLoadData(itemManager);
    }

    /**
     *  End copied section from RestaurantPage.tsx
     */

    useEffect(() => {
        if (brandMap?.colorBackground) {
            document.body.classList.toggle("brand-dark", brandMap.colorTheme === BrandMapColorTheme.LIGHT);
            document.body.classList.toggle("brand-light", brandMap.colorTheme === BrandMapColorTheme.DARK);
            document.body.style.setProperty("--map-background-color", brandMap.colorBackground);
        }
    }, [brandMap?.colorBackground]);

    /**
     * Selectively apply system dark mode theme to certain elements if the brand map color theme is light
     */
    useEffect(() => {
        if (brandMap?.colorTheme && brandMap?.colorTheme === BrandMapColorTheme.LIGHT) {
            // For restaurant menu, order success page
            document.querySelector(".brand-map-restaurant_container")?.setAttribute("data-theme", "dark");

            // For restaurant menu page and checkout
            document.querySelector(".brand-map-restaurant")?.setAttribute("data-theme", "dark");

            // Container with elements we want to apply dark-mode theming to (can apply class to multiple containers)
            document.querySelectorAll(".brand-map-apply-theme").forEach(element => {
                element.setAttribute("data-theme", "dark");
            });
            document.querySelector(".restaurant-search-address-button")?.setAttribute("data-theme", "dark");

            document.querySelector(".restaurant-promo-header")?.setAttribute("data-theme", "dark");
        }

        const stickyFooter = document.querySelector(".devour-sticky-footer");
        const mobileDrawer = document.querySelector(".brand-map-restaurant-drawer");

        if (stickyFooter && mobileDrawer) {
            mobileDrawer.classList.add("with-sticky-footer");
        } else if (mobileDrawer) {
            mobileDrawer.classList.remove("with-sticky-footer");
        }
    }, [brandMap?.colorTheme, brandMapState, showMobileDrawer]);

    /**
     * Start with select location if there is no promos available on map
     */
    useEffect(() => {
        if (isNoPromosAvailable && [BrandMapStates.SELECT_PROMOTION, BrandMapStates.NOT_INITIATED].includes(brandMapState)) {
            setBrandMapState(BrandMapStates.SELECT_LOCATION);
        }
    }, [brandMap, brandMapState]);

    /**
     * Scroll to the top of each section when brand map state changes
     */
    useEffect(() => {
        if (!brandMap) return;
        const shouldScrollToLocation =
            isNoPromosAvailable && brandMapState !== BrandMapStates.SELECT_LOCATION || // select location is initial state
            !isNoPromosAvailable && ![BrandMapStates.SELECT_PROMOTION, BrandMapStates.NOT_INITIATED].includes(brandMapState);

        if (shouldScrollToLocation) {
            locationRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [brandMapState]);

    /**
     * Save the menu order id locally since we still need access to it after placing the order (e.g: to display success/failure page)
     */
    useEffect(() => {
        if (menuOrder && selectedRestaurant) {
            const existingMenuOrder = menuOrders[selectedRestaurant];
            if (existingMenuOrder) {
                setSavedMenuOrderId(existingMenuOrder);
            }
        }
    }, [menuOrder, selectedRestaurant]);

    /**
     * Update the last public brand map data in the redux store.
     * Last brand map data is used to display the "go back" card in the nav sidebar
     * so users can navigate back to the last visited public brand page.
     */
    useEffect(() => {
        if (brandMap && brandMap.brandPageType === BrandPageType.PUBLIC) {
            dispatch(updateLastBrandMap(brandMap));
        }
    }, [brandMap]);

    useEffect(() => {
        if (selectedRestaurant && menuOrdersRefresh) {
            void refetchMenuOrder();
        }
    }, [selectedRestaurant, menuOrdersRefresh]);

    useEffect(() => {
        if (!userData?.user) {
            automintCalled.current = false;
        } else if (brandMap?.autoMintContract && brandMap?.autoMintChain && isNftOwnershipDataFetched) {
            void handleAutomint();
        }
    }, [userData?.user?.id, brandMap?.autoMintContract, isNftOwnershipDataFetched]);

    useEffect(() => {
        if (!fullToken && nftOwnershipData) {
            void refetchNftOwnerships();
        }
    }, [fullToken?.id]);

    async function handleAutomint(): Promise<void> {
        if (!fullToken || automintCalled.current) {
            return;
        }

        const targetTrackerIndex = nftOwnershipData?.nftTrackers
            .findIndex(tracker => tracker.contractAddress === brandMap.autoMintContract);

        const targetNftOwnership = targetTrackerIndex >= 0
            ? nftOwnershipData?.nftOwnerships[0].find(ownership =>
                ownership.nftTrackerIndex === targetTrackerIndex)
            : null;

        if (targetNftOwnership) {
            return;
        }

        try {
            automintCalled.current = true;
            setShowPreAutoMintToast(true);

            await new BrandsApi(getConfig(fullToken)).automintBrandMap({
                id: brandMap.id,
            });

            setShowPreAutoMintToast(false);

            // So there is a slight transition period
            await new Promise(r => setTimeout(r, 1000));

            setShowAutoMintToast(true);
            await refetchNftOwnerships();
        } catch (e) {
            if (e.status !== 403) {
                dispatch(await addError(e));
            }
            setShowPreAutoMintToast(false);
        }
    }

    function toggleShowCartPanel(): void {
        setShowCartPanel(s => !s);
    }

    function handleDismissAutoMintToast(): void {
        setShowAutoMintToast(false);
    }

    function handleDismissPreAutoMintToast(): void {
        setShowPreAutoMintToast(false);
    }

    if (!brandMap) {
        return <BrandSkeleton/>;
    }

    function toggleMobileDrawer(isOpen?: boolean): void {
        if (isOpen) {
            setShowMobileDrawer(isOpen);
            return;
        }
        setShowMobileDrawer(!showMobileDrawer);
    }

    function renderOrderingFlow() {
        if (brandMapState === BrandMapStates.NOT_INITIATED) {
            return <BrandMapPromotions toggleEmailModal={toggleEmailModal}/>;
        }

        // select promo, select location, order in progress
        return (
            <div className="restaurant-map-landing_ordering">
                {brandMapState === BrandMapStates.SELECT_PROMOTION && <BrandMapPromotions toggleEmailModal={toggleEmailModal}/>}

                {brandMapState === BrandMapStates.SELECT_LOCATION &&
                    <BrandMapLocations
                        toggleEmailModal={toggleEmailModal}
                        isInitialState={isNoPromosAvailable}
                    />
                }

                {brandMapState === BrandMapStates.ORDER_IN_PROGRESS &&
                    <BrandLandingRestaurantMenu
                        brandMap={brandMap}
                        restaurantId={selectedRestaurant}
                        toggleCartPanel={toggleShowCartPanel}
                        showCartPanel={showCartPanel}
                        toggleMobileDrawer={toggleMobileDrawer}
                        showMobileDrawer={showMobileDrawer}
                        updateMenuOrderItemManager={handleUpdateMenuOrderItemsManager}
                        menuOrderItemsManager={menuOrderItemsManager}
                    />
                }

                {brandMapState === BrandMapStates.CHECKOUT_IN_PROGRESS &&
                    <BrandLandingRestaurantMenuCheckout
                        brandMap={brandMap}
                        restaurantId={selectedRestaurant}
                        menuOrderId={menuOrder?.id || menuOrderIdParam || savedMenuOrderId}
                        toggleMobileDrawer={toggleMobileDrawer}
                        showMobileDrawer={showMobileDrawer}
                    />
                }

                {brandMapState === BrandMapStates.SUCCESS &&
                    <BrandMenuOrderSuccessPage
                        brandMap={brandMap}
                        menuOrderId={menuOrder?.id || menuOrderIdParam || savedMenuOrderId}
                        restaurantId={selectedRestaurant}
                        toggleMobileDrawer={toggleMobileDrawer}
                        showMobileDrawer={showMobileDrawer}
                    />
                }

                {brandMapState === BrandMapStates.FAILURE && <MenuOrderFailPage
                    menuOrderId={menuOrder?.id || menuOrderIdParam || savedMenuOrderId}
                />
                }
            </div>
        );
    }

    function toggleEmailModal(showModal: boolean): void {
        setShowEmailModal(showModal);
    }

    // Need to wrap the page contents with the padding adjuster if the sidebar is docked
    function wrapSidebarContent(isSidebar: boolean, content: JSX.Element) {
        if (isSidebar) {
            return <div className="page-padding-adjuster_active">{content}</div>;
        }
        return content;
    }

    const pageContent =
        <>
            <MenuOrderErrorModal updateMenuOrderItemManager={handleUpdateMenuOrderItemsManager} forceDarkMode={true}/>
            <Toast
                remainIndefinitely={true}
                message={brandMap?.preAutoMintToastMessage}
                isOpen={showPreAutoMintToast}
                variant={"background"}
                onDismiss={handleDismissPreAutoMintToast}
                removeMarginAdjustment={true}
                backgroundImageUrl={brandMap.preAutoMintToastBackground?.url}
                icon={FaCheckCircle}
                textColor={brandMap?.autoMintToastTextColor}
            />

            <Toast
                message={brandMap.autoMintToastMessage || "Your redemption token is now available!"}
                isOpen={showAutoMintToast}
                variant={"background"}
                onDismiss={handleDismissAutoMintToast}
                removeMarginAdjustment={true}
                backgroundImageUrl={brandMap.autoMintToastBackground?.url}
                icon={FaCheckCircle}
                textColor={brandMap?.autoMintToastTextColor}
            />

            <div className="brand-map-apply-theme">
                <div className="brand-map-apply-theme_container">
                    {/* Sidebar and mobile sticky footer for when brand page type is PUBLIC */}
                    <FrameOneSidebar dockingEnabled={brandMap?.brandPageType === BrandPageType.PUBLIC}/>
                    {brandMap?.brandPageType === BrandPageType.PUBLIC && <DevourStickyFooter/>}
                </div>
            </div>

            <BrandEmailModal
                isOpen={showEmailModal}
                toggle={toggleEmailModal}
                brandMap={brandMap}
            />

            <div
                style={{backgroundColor: brandMap.colorBackground}}
                className={classNames("restaurant-map-landing", `theme-${brandMap.colorTheme}`, {
                    "has-challenge": !!brandMap.challenge,
                })}>
                <BrandMapContext.Provider value={{
                    brandMapState: brandMapState,
                    setBrandMapState: setBrandMapState,
                    selectedPromo: selectedPromo,
                    setSelectedPromo: setSelectedPromo,
                    selectedRestaurant: selectedRestaurant,
                    setSelectedRestaurant: setSelectedRestaurant,
                    placeId: lastSearchedPlaceId || defaultAddress?.placeId,
                }}>
                    <RestaurantContext.Provider value={{
                        restaurantId: selectedRestaurant,
                        embeddedMenu: true,
                        setCheckoutState: setBrandMapState,
                        menuOrderId: existingMenuOrderId,
                        menuOrder: menuOrder,
                    }}>
                        <RestaurantMapTopBar
                            brandMap={brandMap}
                            toggleShowCartPanel={toggleShowCartPanel}
                            sumQuantity={sumQuantity}
                        />
                        {/* BRAND CHALLENGES */}
                        <BrandChallengeHeading challenge={challenge} hideTopBar={true}/>

                        <div className="brand-map-apply-theme">
                            <div className="brand-map-apply-theme_container">
                                <DevourCart
                                    show={showCartPanel}
                                    toggle={toggleShowCartPanel}
                                    brandMap={brandMap}
                                    menuOrderItemsManager={menuOrderItemsManager}
                                    updateMenuOrderItemManager={handleUpdateMenuOrderItemsManager}
                                />
                            </div>
                        </div>

                        <div
                            className="restaurant-map-landing_content"
                        >
                            {!brandMap.challenge && <RestaurantMapHeader
                                brandMap={brandMap}
                                toggleEmailModal={toggleEmailModal}
                            />}


                            <div className="restaurant-map-landing_content_wrapper">
                                {/* BRAND CHALLENGES */}
                                {brandMap.challenge && <div className="brand-challenge_content">
                                    <BrandChallengeCard challenge={challenge} toggleBrandLogin={toggleEmailModal}/>
                                    {challenge?.onboardingSection && <BrandChallengeOnboarding challenge={challenge}/>}
                                    <ChallengeQuestsAndPrizes challengeId={challenge?.id}/>
                                </div>}
                                <BrandMapEmbeddableHtml/>

                                {!brandMap.challenge && !isNoPromosAvailable && <BrandMapProgressBar/>}
                                {(brandMap.businesses?.length > 0 || brandMap.allowMarketplaceAccess) &&
                                 <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY_FRONTEND_ONLY}>
                                     <div
                                         ref={locationRef}
                                         id="restaurant-map-landing_content_flow-container"
                                         className="restaurant-map-landing_content_flow-container"
                                     >
                                         {renderOrderingFlow()}
                                     </div>
                                 </LoadScript>}
                                <BrandLandingAdvertisementBlocks brandMap={brandMap}/>
                                {brandMap.challenge && <BrandChallengeFAQs challenge={challenge}/>}
                                <BrandLandingFooter darkMode={brandMap?.colorTheme !== BrandMapColorTheme.DARK}/>
                            </div>
                        </div>
                    </RestaurantContext.Provider>
                </BrandMapContext.Provider>
            </div>
        </>;
    return wrapSidebarContent(brandMap?.brandPageType === BrandPageType.PUBLIC, pageContent);
}

export default RestaurantMapLandingPage;