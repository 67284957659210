/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Represents a raw Overwolf event.
 * @export
 * @interface OWEventDump
 */
export interface OWEventDump {
    /**
     * The ID of the user who triggered the event.
     * @type {string}
     * @memberof OWEventDump
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof OWEventDump
     */
    gameId: string;
    /**
     * 
     * @type {string}
     * @memberof OWEventDump
     */
    gameTitle: string;
    /**
     * Raw event info from Overwolf.
     * @type {object}
     * @memberof OWEventDump
     */
    eventInfo: object;
    /**
     * 
     * @type {string}
     * @memberof OWEventDump
     */
    eventName?: string;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWEventDump
     */
    category: OWLogCategory;
    /**
     * 
     * @type {string}
     * @memberof OWEventDump
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OWEventDump
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OWEventDump
     */
    updatedAt: number;
}

export function OWEventDumpFromJSON(json: any): OWEventDump {
    return OWEventDumpFromJSONTyped(json, false);
}

export function OWEventDumpFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWEventDump {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'gameId': json['gameId'],
        'gameTitle': json['gameTitle'],
        'eventInfo': json['eventInfo'],
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'category': OWLogCategoryFromJSON(json['category']),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function OWEventDumpToJSON(value?: OWEventDump | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'gameId': value.gameId,
        'gameTitle': value.gameTitle,
        'eventInfo': value.eventInfo,
        'eventName': value.eventName,
        'category': OWLogCategoryToJSON(value.category),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


