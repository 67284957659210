import FrameButton from "@/components/buttons/FrameButton";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import FrameModalNoHeaderToggle from "@/components/modals/modalComponents/FrameModalNoHeaderToggle";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import { IStore } from "@/redux/defaultStore";
import { addError } from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {ChallengesApi, PrizePool } from "@devour/client";
import { FC, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

interface props {
    prize: PrizePool;
    onAccepted: () => Promise<void>;
    isOpen: boolean;
}
export const BrandChallengePrizeWonModal: FC<props> = ({ prize, isOpen, onAccepted }) => {
    const [isAccepting, setIsAccepting] = useState(false);
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const handleAccept = async () => {
        setIsAccepting(true);
        try {
            await new ChallengesApi(getConfig(fullToken)).confirmNotifyPrizeWon({ id: prize.id });
            await onAccepted();
        } catch (e) {
            dispatch(await addError(e));
        }
        setIsAccepting(false);
    };

    const dateMintedString = new Date(prize?.challenge.end).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });

    return (
        <FrameOneModal
            contentClassName="brand-challenge-prize-won-modal"
            size="xs2"
            isOpen={isOpen}
            disableOverlayDismiss={false}
            toggle={handleAccept}
        >
            {prize && <FrameModalBody className="brand-challenge-prize-won-modal_body">
                <FrameModalNoHeaderToggle toggle={handleAccept} />
                <img src={prize.image.cdnUrl} alt="Prize image" />
                <div className="brand-challenge-prize-won-modal_body_info">
                    <h6>
                        {prize.name} <br /> <FaCheckCircle /> Claimed
                    </h6>
                    <span>Claimed on {dateMintedString}</span>
                    <p>Congratulations! You have won the above prize in the {prize.challenge.name}. {prize.winnerMessage}</p>
                </div>
                <FrameButton onClick={handleAccept} showSpinnerWithoutGlobalLoading={isAccepting} size="normal" color="purple">
                    Confirm
                </FrameButton>
            </FrameModalBody>}
        </FrameOneModal>
    );
};
