import {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {NftPurchaseRequestBody, NftPurchaseUpsert, ThirdwebApi} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import PaymentFailedModal from "@/components/modals/PaymentFailedModal";
import MintIndustryPassModalSuccess from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalSuccess";
import MintIndustryPassModalInProgress from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalInProgress";
import {PaymentStates} from "@/components/modals/mintIndustryPassModal/PaymentStates";
import { CustomMintProvider, CustomMintPurchase } from "./MintCustomPurchaseContext";

interface props {
    customPurchase?: CustomMintPurchase
}

function MintIndustryPassModal({customPurchase}: props): ReactElement {

    const [
        paymentState,
        setPaymentState,
    ] = useState<PaymentStates>(PaymentStates.SELECT_PAYMENT);
    const [
        nftPurchase,
        setNftPurchase,
    ] = useState<NftPurchaseUpsert>();
    const [
        showPaymentFailed,
        setShowPaymentFailed,
    ] = useState<boolean>(false);

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const dispatch = useDispatch();

    const {refetch: refetchTransactionData} = useGetTransactions(fullToken, currentUser?.user?.id);


    useEffect(() => {
        // Fetch next claimable NFT information
        void getClaimableNft({});
    }, [fullToken?.id]);

    useEffect(() => {
        if (paymentState === PaymentStates.SELECT_PAYMENT) {
            resetStates();
        }
    }, [paymentState]);

    function resetStates(): void {
        void getClaimableNft({
            fiatInDpay: 0,
            paymentMethodId: "",
        });
        setPaymentState(PaymentStates.SELECT_PAYMENT);
    }

    async function getClaimableNft(values: NftPurchaseRequestBody): Promise<void> {
        if (!fullToken) {
            return;
        }
        dispatch(incrementLoading());
        try {
            const res = await new ThirdwebApi(getConfig()).upsertNftPurchase({
                nftPurchaseRequestBody: {...values, isLootBox: customPurchase?.isLootBox ?? false},
            });
            setNftPurchase(res);
        } catch (e) {
            dispatch(await addError(e));
            // Reset form value to previous value
        }
        dispatch(decrementLoading());
    }

    async function onSubmit(): Promise<void> {
        dispatch(incrementLoading());
        try {
            const res = await new ThirdwebApi(getConfig()).submitNftPurchase({
                id: nftPurchase.id,
            });
            if (res.nextAction) {
                window.location.href = res.nextAction;

            } else {
                setPaymentState(PaymentStates.SUCCESS);
                await refetchTransactionData();
            }
        } catch (e) {
            setShowPaymentFailed(true);
        }
        dispatch(decrementLoading());
    }

    function renderContents(): ReactElement {
        if (paymentState !== PaymentStates.SUCCESS) {
            return (
                <MintIndustryPassModalInProgress
                    nftPurchase={nftPurchase}
                    getClaimableNft={getClaimableNft}
                    setPaymentState={setPaymentState}
                    onSubmit={onSubmit}
                    paymentState={paymentState}
                />
            );
        }
        return (
            <MintIndustryPassModalSuccess
                nftPurchase={nftPurchase}
                resetStates={resetStates}
            />
        );

    }

    return (
        <CustomMintProvider customMintPurchase={customPurchase}>
            <>

                <PaymentFailedModal
                    isOpen={showPaymentFailed}
                    toggle={() => setShowPaymentFailed(!showPaymentFailed)}
                />
                <div className="the-industry-pass-page_purchase_modal-template">
                    <div className="mint-industry-credit-card">
                        <div className="mint-industry-credit-card_body">
                            {renderContents()}
                        </div>
                    </div>
                </div>

            </>
        </CustomMintProvider>
    );
}

export default MintIndustryPassModal;
