import { DateTime } from "luxon";

export const formatChallengeDateRange = (start: number, end: number) => {
    const startDate = DateTime.fromMillis(start).setZone("America/New_York");
    const endDate = DateTime.fromMillis(end).setZone("America/New_York");

    const startSuffix = getDaySuffix(startDate.day);
    const endSuffix = getDaySuffix(endDate.day);
    return `${startDate.toFormat("MMM d")}${startSuffix} - ${endDate.toFormat("MMM d")}${endSuffix}`;
};

export const formatChallengeDateRangeFull = (start: number, end: number, showTime = true) => {
    const startDate = DateTime.fromMillis(start).setZone("America/New_York");
    const endDate = DateTime.fromMillis(end).setZone("America/New_York");
    const getFormattedDate = (date: DateTime) => {
        const time = showTime ? `${date.toFormat("h:mma")}` : "";
        return `${date.toFormat("MMMM")} ${date.day}${getDaySuffix(date.day)} ${time}`;
    };

    if (startDate.hasSame(endDate, "day")) {
        return `${getFormattedDate(startDate)} - ${endDate.toFormat("h:mma")} ${showTime ? "(EST)" : ""}`;
    }

    return `${getFormattedDate(startDate)} - ${getFormattedDate(endDate)} ${showTime ? "(EST)" : ""}`;
};

function getDaySuffix(day) {
    if (day >= 11 && day <= 13) return "th";
    switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}
